/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import AuthService from '../services/AuthService';
import * as LoginVerificationStates from '../states/LoginVerificationStates';
import LoginVerificationService from '../services/LoginVerificationService';
import { capitalizeFirst } from '../utils/simpleActions';
import userInfoCacheService from '../services/userInfoCacheService';

export const SignupStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

// I'm not exporting this becaus I'm already exporting the exact object from LoginSlice.js
const LoginStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  PIN_SET_FALSE: 'no_pin',
  UNVERIFIED: 'unverified',
  FAILED: 'failed',
};

const initialState = {
  token: AuthService._getUserToken(),
  user: null,
  user_account_discard: false, // by user
  user_account_blocked: false, // by roqqu
  request_verification: false, // not verified
  primaryCurrency: AuthService._getPrimaryCurrency() || {
    display: '',
  },
  loading: false,
  error: false,
  loginVerification: {
    completionState: LoginVerificationStates.BASE,
  },
  toast: {
    isOpen: true,
    type: 'error',
    name: 'Error',
    desc: "This action can't be performed",
    toastArrays: [],
  },
  login: {
    status: LoginStates.BASE,
    data: {
      email: '',
      password: '',
      auth: '',
    },
    extraData: undefined,
    pinSet: undefined,
    pendingAuthorization: undefined,
    error: undefined,
  },
  signup: {
    status: SignupStates.BASE,
    data: {
      username: '',
      email: '',
      password: '',
      fname: '',
      lname: '',
      phone: '',
      country: '',
    },
    error: '',
    showPassword: false,
  },
};

export const fetchUser = createAsyncThunk('auth/fetchUser', async (_, { rejectWithValue }) => {
  try {
    const response = await AuthService.fetchUser();
    return response;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const loginSubmitPin = createAsyncThunk(
  'auth/loginSubmitPin',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LoginVerificationService.submitPin({
        ...data,
        email: LoginVerificationService.getStoredEmail(),
      });
      return response;
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  },
);

export const register = createAsyncThunk(
  'signup/register',
  async ({ primaryCurrency }, thunkAPI) => {
    try {
      const data = await AuthService.signUp({
        country: primaryCurrency,
        ...thunkAPI.getState().signup.data,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const signOut = createAsyncThunk('auth/signOut', async (_, thunkAPi) => {
  try {
    const user = thunkAPi.getState().auth.user.user_info;
    const response = await AuthService.signOut(user.email);
    return response;
  } catch (e) {
    return thunkAPi.rejectWithValue(e.toString());
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToggleToast: (state, action) => {
      const _name = action.payload?.name || action.payload?.heading;
      const _desc = action.payload?.desc || action.payload?.description;
      // const closeBool = action.payload?.isClose;
      // state.toast.name = _name;
      // if (state.toast.toastArrays[state.toast.toastArrays.length - 1]?.name !== _name) {
      state.toast.toastArrays = [
        ...state.toast.toastArrays,
        {
          _id: uuid(),
          type: action.payload?.type,
          name: _name,
          desc: capitalizeFirst(_desc.replaceAll('Error: ', '')),
        },
      ];
      // }
      // if (action.payload.type) {
      //   state.toast.type = action.payload?.type;
      // } else {
      //   state.toast.type = initialState.toast.type;
      // }
      // if (closeBool) {
      //   state.toast.isOpen = false;
      // } else if (!state.toast.isOpen) {
      //   state.toast.isOpen = true;
      // }
      // state.toast.desc = capitalizeFirst(_desc);
    },
    removeToastFromQueue: (state, action) => {
      const _id = action.payload;
      state.toast.toastArrays = [...state.toast.toastArrays].filter((d) => d._id !== _id);
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = initialState.user;
      state.primaryCurrency = {
        display: '',
      };
      state.token = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    resetVerificationStatus: (state) => {
      state.loginVerification = initialState.loginVerification;
    },
    resetAuthSignup: (state) => {
      state.signup = initialState.signup;
    },
    resetLogin: (state) => {
      state.login = initialState.login;
    },
    setPrimaryCurrency: (state, action) => {
      const { symbol, display, rate } = action.payload;
      state.primaryCurrency.display = display;
      state.primaryCurrency.symbol = symbol;
      state.primaryCurrency.rate = rate;
    },
    clearAuthData: () => {
      AuthService._signOutClear();
    },
    disableUserGoogleAuth: (state) => {
      state.user.user_info.google_auth = false;
    },
    resetBannedStatus: (state) => {
      state.user_account_discard = false;
      state.user_account_blocked = false;
    },
    resetAuthError: (state) => {
      state.error = initialState.error;
      state.request_verification = initialState.request_verification;
      state.loading = initialState.loading;
    }
  },
  extraReducers: (builder) => {
    // fetchUser reducer
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      if (action.payload && action.payload?.status !== 'fail') {
        state.error = false;
        state.loading = false;
        state.user = action.payload.data;
        userInfoCacheService.encryptAndCacheUserInfo(action.payload.data);
        // AuthService._saveUser(action.payload.data);
        state.primaryCurrency = JSON.parse(action.payload.data.user_info.primary_currency);
        AuthService._savePrimaryCurrency(
          JSON.parse(action.payload.data.user_info.primary_currency),
        );
      } else {
        AuthService._signOutClear();
        state.token = null;
        state.error = true;
        state.loading = false;
      }
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      const account_discard = action.payload.includes('_discard'); // by user
      const account_blocked = action.payload.includes('_blocked'); // by roqqu
      const invalid_token = action.payload.includes('invalid_token'); // token is invalid
      const request_verification = action.payload.includes('request_verification_failed'); // request_verification
      if (request_verification) {
        state.request_verification = true;
      }

      if (account_discard || account_blocked) {
        state.user_account_discard = account_discard;
        state.user_account_blocked = account_blocked;
        state.token = initialState.token;
      }
      // If the token is not invalid, we just fetch the user that is stored in local storage.
      // If there is no user in local storage, we just bounce the user out.
      if (!invalid_token) {
        const cachedUser = userInfoCacheService.fetchAndDecryptUser();
        if (cachedUser) {
          // if a cached user is found, basically repeat all the steps we would normally repeat if
          // a user is returned from the user info endpoint.
          state.error = false;
          state.loading = false;
          state.user = cachedUser;
          state.primaryCurrency = JSON.parse(cachedUser.user_info.primary_currency);
          AuthService._savePrimaryCurrency(
            JSON.parse(cachedUser.user_info.primary_currency)
          );
          return;
        }
      }
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    });

    // loginSubmitPin reducer
    builder.addCase(loginSubmitPin.pending, (state, action) => {
      state.loginVerification.completionState = LoginVerificationStates.PENDING;
    });
    builder.addCase(loginSubmitPin.fulfilled, (state, action) => {
      if (action.payload.status !== 'fail') {
        AuthService._clearEmail();
        AuthService._saveToken(action.payload.data);
        state.token = action.payload.data;
        state.loginVerification.completionState = LoginVerificationStates.COMPLETE;
      } else {
        state.loginVerification.completionState = LoginVerificationStates.FAIL;
        // state.error
      }
    });
    builder.addCase(loginSubmitPin.rejected, (state, action) => {
      state.loginVerification.completionState = LoginVerificationStates.FAIL;
    });

    // register reducer
    builder.addCase(register.pending, (state) => {
      state.signup.status = SignupStates.LOADING;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      const { data, message: error } = action.payload;
      if (data) {
        state.token = data;
        AuthService._saveToken(data);
        state.signup.status = SignupStates.SUCCESSFUL;
      } else {
        state.signup.error = error;
        state.status = SignupStates.FAILED;
      }
    });
    builder.addCase(register.rejected, (state, action) => {
      const { payload } = action;

      state.signup.error = payload;
      state.signup.status = SignupStates.FAILED;
    });

    // signout
    builder.addCase(signOut.pending, (action) => {});
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = initialState.user;
      state.primaryCurrency = {
        display: '',
      };
      state.token = null;
    });
    builder.addCase(signOut.rejected, (state) => {});
  },
});

export const getUser = (state) => state.auth.user;
export const getPrimaryCurrency = (state) => state.auth.primaryCurrency;
export const selectAuth = (state) => state.auth;
export const getToken = (state) => state.auth.token;
export const selectLoginVerification = (state) => state.auth.loginVerification;
export const selectRegister = (state) => state.auth.loginVerification;
export const selectAuthSignup = (state) => state.auth.signup;

export default authSlice.reducer;
export const {
  setToggleToast,
  removeToastFromQueue,
  setUser,
  setToken, resetAuthError,
  resetUser,
  resetVerificationStatus,
  setPrimaryCurrency,
  resetAuthSignup,
  resetLogin,
  clearAuthData,
  disableUserGoogleAuth,
  resetBannedStatus,
} = authSlice.actions;
