/* eslint-disable import/prefer-default-export */
import React from 'react';
// import AirtimeBill from '../../../../pages/dashboard/home/paybills/AirtimeBill';
// import CableBill from '../../../../pages/dashboard/home/paybills/CableBill';
// import DataBill from '../../../../pages/dashboard/home/paybills/DataBill';
// import ElectricityBill from '../../../../pages/dashboard/home/paybills/ElectricityBill';

const PayBillsIndex = React.lazy(() => import('../../../../pages/dashboard/home/paybills'));

export const Dashboard_home_pay_bill_route_group = [
  { path: '/dashboard-home-pay-bills', component: <PayBillsIndex /> },
  // { path: '/dashboard-home-pay-bills-airtime', component: AirtimeBill },
  // { path: '/dashboard-home-pay-bills-data', component: DataBill },
  // { path: '/dashboard-home-pay-bills-electricity', component: ElectricityBill },
  // { path: '/dashboard-home-pay-bills-cable', component: CableBill },
];
