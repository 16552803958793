/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
import React from 'react';
import ContentLoader from 'react-content-loader';

function LoaderContainer({ speed = 2, width = 162, height = 32, children }) {
  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f5f5f51a"
      foregroundColor="#f5f5f53d"
      className="content-loader"
    >
      {children}
    </ContentLoader>
  );
}

export default LoaderContainer;
