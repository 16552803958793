/* eslint-disable object-curly-newline */
/* eslint-disable operator-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import LoginVerificationService from '../services/LoginVerificationService';
import * as LoginVerificationStates from '../states/LoginVerificationStates';

const initialState = {
  completionState: LoginVerificationStates.BASE,
  loading: false,
  resendCount: 0,
  data: {
    ctoken: '',
  },
  showSnackbar: false,
  error: undefined,
  token: undefined,
  connectedPhoneAccounts: [],
  selectedPhoneAccount: null,
};

export const submitPin = createAsyncThunk('loginVerification/submitPin', async (data, { rejectWithValue }) => {
  try {
    const response = await LoginVerificationService.submitPin({
      ...data,
      email: LoginVerificationService.getStoredEmail(),
    });
    return response;
  } catch (e) {
    return rejectWithValue(e.toString());
  }
});

export const resendCode = createAsyncThunk('loginVerification/resendCode', async (_, { rejectWithValue }) => {
  try {
    const response = await LoginVerificationService.resendCode();
    return response;
  } catch (e) {
    return rejectWithValue(e.toString());
  }
});

export const submitPhonePin = createAsyncThunk('loginVerification/submitPhonePin', async (data, { rejectWithValue }) => {
  try {
    const response = await LoginVerificationService.submitPhonePin({
      ...data,
    });
    return response;
  } catch (e) {
    return rejectWithValue(e.toString());
  }
});

const LoginVerificationSlice = createSlice({
  name: 'loginVerification',
  initialState,
  reducers: {
    updateAuthCode: (state, action) => {
      state.data = {
        ...state.data,
        auth_code: action.payload,
      };
    },
    makeSnackbar: (state, action) => {
      state.snackbarData = action.payload;
      state.showSnackbar = true;
    },
    hideSnackbar: (state) => {
      state.showSnackbar = false;
    },
    resetResendCount: (state) => {
      state.resendCount = 0;
    },
    resetLoginVerificationError: (state) => {
      state.error = undefined;
    },
    resetLoginVerificationStatus(state) {
      state.completionState = LoginVerificationStates.BASE;
    },
    storeSelectedPhoneAccount(state, action) {
      state.selectedPhoneAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitPin.pending, (state) => {
      state.error = undefined;
      state.completionState = LoginVerificationStates.PENDING;
    });
    builder.addCase(submitPin.fulfilled, (state, action) => {
      const { data, message, status } = action.payload;
      if (status !== 'fail') {
        state.error = undefined;
        AuthService._clearEmail();
        state.token = data;
        state.completionState = LoginVerificationStates.COMPLETE;
      } else {
        state.error = message;
        state.completionState = LoginVerificationStates.ERROR;
      }
    });
    builder.addCase(submitPin.rejected, (state, action) => {
      state.error = action.payload;
      state.completionState = LoginVerificationStates.ERROR;
    });
    builder.addCase(resendCode.pending, (state) => {
      state.error = undefined;
      state.completionState = LoginVerificationStates.PENDING;
    });
    builder.addCase(resendCode.fulfilled, (state) => {
      state.error = undefined;
      state.completionState = LoginVerificationStates.CODE_RESENT;
      state.resendCount = state.resendCount + 1;
    });
    builder.addCase(resendCode.rejected, (state, action) => {
      state.error = action.payload;
      state.completionState = LoginVerificationStates.ERROR;
    });
    // Submit Phone Pin
    builder.addCase(submitPhonePin.pending, (state) => {
      state.error = undefined;
      state.completionState = LoginVerificationStates.PENDING;
    });
    builder.addCase(submitPhonePin.fulfilled, (state, action) => {
      const { data, message, status } = action.payload;
      if (status !== 'fail') {
        state.error = undefined;
        AuthService._clearEmail();
        state.connectedPhoneAccounts = data.map((account) => ({
          ...account,
          reported: false,
        }));
        state.completionState = LoginVerificationStates.COMPLETE;
      } else {
        state.error = message;
        state.completionState = LoginVerificationStates.ERROR;
      }
    });
    builder.addCase(submitPhonePin.rejected, (state, action) => {
      state.error = action.payload;
      state.completionState = LoginVerificationStates.ERROR;
    });
  },
});

// eslint-disable-next-line max-len
export const selectConnectedPhoneAccounts = (state) => state.loginVerification.connectedPhoneAccounts;
export const selectSelectedPhoneAccount = (state) => state.loginVerification.selectedPhoneAccount;

export default LoginVerificationSlice.reducer;
export const {
  updateAuthCode,
  makeSnackbar,
  hideSnackbar,
  resetResendCount,
  resetLoginVerificationError,
  resetLoginVerificationStatus,
  storeSelectedPhoneAccount,
} = LoginVerificationSlice.actions;
