import React, { Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';

import './styles/SelectedBagSummary.css';
import uuid from 'react-uuid';
import BagDropdown from './BagDropdown';
import { selectRIBsApplication } from '../../../../../features/ribs/application/ribsApplicationSlice';
import ButtonReuse from '../../../../fragments/Button';
import { RIBsQuestionNavigationHandlerContext } from '../RIBsQuestionStackContext';
import ConfirmPin from './ConfirmPin';

function SelectedBagSummary() {
  const dispatchRIBsQuestionStack = useContext(RIBsQuestionNavigationHandlerContext);
  const { selectedBag } = useSelector(selectRIBsApplication);

  const summary = [
    {
      name: 'Creation date',
      value: '15 May 2023, 9:30 am',
    },
    {
      name: 'Amount',
      value: '$1500.00 USDT',
    },
    {
      name: 'RIB',
      value: selectedBag.bagName,
    },
    {
      name: 'Risk Level',
      value: 1,
    },
    {
      name: 'Start Date',
      value: '15 May 2023, 9:30 am',
    },
    {
      name: 'Fee',
      value: '$0.99 USD',
    },
  ];

  function proceed() {
    dispatchRIBsQuestionStack({
      type: 'push',
      payload: <ConfirmPin />,
    });
  }

  return (
    <div className="ribs-questions-container">
      <p className="note-title">
        What a great choice. See your summary below
      </p>

      <div className="ribs-bags-container">
        <BagDropdown
          // onSelect={() => selectBag()}
          coins={selectedBag.coins}
          showCardDescription={false}
        />

        <div className="ribs-summary-container default-background">
          {summary.map((d) => (
            <Fragment key={uuid()}>
              <div className="summary-item">
                <div className="secondary-white-black-color3">{d.name}</div>
                {typeof d.value !== 'number' ? (
                  <div className="">{d.value}</div>
                ) : (
                  <div className={`risk-levels ${d.value ? 'high' : 'low'}`}>{d.value ? 'High' : 'Low'}</div>
                )}
              </div>
            </Fragment>
          ))}
        </div>

        <ButtonReuse text="Proceed" onClick={() => proceed()} btnStyle="btn-reuse" style={{ width: '100%' }} />
      </div>
    </div>
  );
}

export default SelectedBagSummary;
