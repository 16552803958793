import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Portal from '../../widgets/Portal';
import Button from '../../fragments/Button';
import '../../../styles/dashboard/FAB.css';

function FAB() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);

  function toggleIntercom() {
    setIsOpen((value) => !value);
    window.Intercom('show');
  }

  return pathname.includes('dashboard-help-support') && (
    <Portal selector="#FAB">
      <Button text=" " id="intercom-launcher-btn" btnStyle="FAB" onClick={() => toggleIntercom()}>
        <span style={{ marginRight: '2rem' }}>{t('dsh.settings.contactSupport')}</span>
        { isOpen ? <FaAngleUp /> : <FaAngleRight /> }
      </Button>
    </Portal>
  );
}

export default FAB;
