import { FIAT_TF_DATA } from '../utils/storageKeys';

export default class FiatTransferDataStore {
  static saveData(data) {
    try {
      const serialized = JSON.stringify(data);
      sessionStorage.setItem(FIAT_TF_DATA, serialized);
      return serialized;
    } catch (e) {
      return 'failed';
    }
  }

  static getData() {
    try {
      return sessionStorage.getItem(FIAT_TF_DATA);
    } catch (e) {
      return 'failed';
    }
  }

  static clearData() {
    try {
      sessionStorage.removeItem(FIAT_TF_DATA);
      return 'success';
    } catch (e) {
      return 'failed';
    }
  }
}
