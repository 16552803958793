/* eslint-disable react/prop-types */
import React from 'react';
import './RoqqFunStatsBanner.css';
import { useIcon } from '../../../../../../hooks/useIcon';

function RoqqFunStatsBanner({ text, icon }) {
  const { statsBorderTopIcon, statsBorderBottomIcon, statsIcon } = useIcon();
  return (
    <div className="roqq-stats-banner">
      <img src={statsBorderTopIcon} alt="top bling" className="top-bling" />
      <img src={statsBorderBottomIcon} alt="bottom bling" className="bottom-bling" />
      <img src={icon || statsIcon} alt="stats icon" className="stats-icon" />
      <p className="stats-text">{text || 'Fun stats: You have made 5 spins 2 wins '}</p>
    </div>
  );
}

export default RoqqFunStatsBanner;
