/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useIcon } from '../../../hooks/useIcon';
import ButtonReuse from '../../../components/fragments/Button';
import { formatNumber } from '../../../utils/simpleActions';
import useUser from '../../../hooks/useUser';
// import KYCService from '../KYCService';
import { setToggleToast } from '../../../slices/authSlice';
import * as ToastTypes from '../../../utils/toast-types';

function TierLayout({
  currentTier, _index, proceedData, tiers,
  isCompleted, usersPresentTier,
}) {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { t } = useTranslation();
  const {
    kycIllustration, kycCheck, kycBuyCrypto, kycSwapCrypto, kycTransfer,
    kycInfoIllustration, kycInactiveIllustration,
  } = useIcon();
  // const [linkLoading, setLinkLoading] = useState(false);

  // async function getKYCTierLink() {
  //   setLinkLoading(true);
  //   try {
  //     const resp = await KYCService.getKYCSelectedLink({
  //       country: user?.kyc_currencies[0], level: `${currentTier?.level}`,
  //     });

  //   } catch (error) {
  //   } finally {
  //     setLinkLoading(false);
  //   }
  // }

  function checkIfTierVerified(_limit) {
    const _withdrawal_limit = user.withdrawal_limit;
    // if user limit is greater or equal to level limit
    return _withdrawal_limit >= _limit;
  }

  const tierVerified = useMemo(
    () => checkIfTierVerified(currentTier?.limit),
    [currentTier, user?.withdrawal_limit],
  );

  function checkCanVerifyStage() {
    if (_index === 0) return true;
    const previous_verified = user.withdrawal_limit >= tiers[_index - 1]?.limit;

    // return true;
    return previous_verified;
  }

  function proceed() {
    const can_proceed = checkCanVerifyStage(_index);
    if (!can_proceed) {
      // show toast
      dispatch(
        setToggleToast({
          type: ToastTypes.WARNING,
          name: 'Verification Warning',
          desc: `To verify current Tier you need to verify Tier ${_index}`,
        }),
      );
      // return false;
    }

    if (can_proceed) {
      proceedData(currentTier);
    }
  }

  return (
    <div>
      <div className="kyc-title-section">
        <figure className="img-box">
          <img src={kycIllustration} className="img" alt="" style={{ display: `${tierVerified ? 'block' : 'none'}` }} />
          <img src={kycInactiveIllustration} className="img" alt="" style={{ display: `${!tierVerified ? 'block' : 'none'}` }} />
        </figure>

        <div className="kyc-title">
          {/* Standard Identity Verification */}
          {currentTier?.description?.toUpperCase()}
        </div>

        <div className="secondary-white-black-color3 kyc-subtitle">
          Completing this step enables you perform up to
          {' '}
          {formatNumber(currentTier?.limit || 0)?.replace('.00', '')}
          {' '}
          USD worth of transactions.
        </div>
      </div>

      <div className="kyc-details-section">
        <div className="kyc-details-title">
          Benefits you’ll enjoy once you’re tier
          {' '}
          {currentTier?.level}
          {' '}
          verified
        </div>

        <div className="kyc-details-container border-light">
          <div className="details-item">
            <div className="description-section">
              <div className="default-background illustration-container">
                <figure className="img-box">
                  <img src={kycBuyCrypto} alt="" className="img" />
                </figure>
              </div>
              <div className="desc">
                Buying and selling
              </div>
            </div>

            <figure className="img-box">
              <img src={kycCheck} alt="" className="img" />
            </figure>
          </div>

          <div className="details-item">
            <div className="description-section">
              <div className="default-background illustration-container">
                <figure className="img-box">
                  <img src={kycSwapCrypto} alt="" className="img" />
                </figure>
              </div>
              <div className="desc">
                Swap crypto
              </div>
            </div>

            <figure className="img-box">
              <img src={kycCheck} alt="" className="img" />
            </figure>
          </div>

          <div className="details-item">
            <div className="description-section">
              <div className="default-background illustration-container">
                <figure className="img-box">
                  <img src={kycTransfer} alt="" className="img" />
                </figure>
              </div>
              <div className="desc">
                Transfer limit
              </div>
            </div>

            <div className="kyc-details-amount">
              $
              {formatNumber(currentTier?.limit || 0)?.replace('.00', '')}
            </div>
          </div>
        </div>
      </div>

      <div className="kyc-info-section">
        <figure className="img-box"><img src={kycInfoIllustration} alt="" className="img" /></figure>
        <div className="kyc-info secondary-white-black-color3">
          Your current tier is
          {' '}
          {usersPresentTier}
        </div>
      </div>

      {!isCompleted && (
      <div>
        <ButtonReuse
          onClick={() => proceed()}
          style={{ width: '100%' }}
          btnStyle="btn-reuse"
          text={t('dsh.getStarted.button')}
        />
      </div>
      )}
    </div>
  );
}

export default TierLayout;
