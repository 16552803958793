/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPinField from 'react-pin-field';
import { useIcon } from '../../../../../hooks/useIcon';
import useUser from '../../../../../hooks/useUser';
import getIconUrl from '../../../../../utils/getIconUrl';
import '../../../../../styles/fragments/AddCoinDrawer.css';

// item {
//   name;
//   value;
// }

function DrawerConfirmation({
  title,
  coin = false,
  amountTitle = 'Amount Title',
  amount = 0,
  items = [],
  withPin = true,
  setPin = () => {},
  pinForm = useRef(null),
  pinRef = useRef(null),
}) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { visibilityIcon } = useIcon();
  const [pinVisible, setPinVisible] = useState(user?.google_auth);

  function updateVisibility() {
    setPinVisible((prev) => !prev);
  }

  function setPinHandler(value) {
    let _obj = {};
    if (user.google_auth) {
      _obj = { two_f_code: value };
    } else {
      _obj = { pin: value };
    }
    setPin(_obj);
  }

  useEffect(() => {
    pinRef && pinRef.current[0].focus();
  }, [amount]);

  return (
    <section>
      {title
        && (
        <div className="section-title" style={{ fontSize: '1.8rem' }}>
          {title || t('dsh.nfts.confirmTransactions')}
        </div>
        )}
      {/* {coin && (
        <div className="current-coin-detail" style={{ textAlign: 'center' }}>
          <img
            style={{ height: '7rem' }}
            className="coin-badge"
            src={getIconUrl(coin)}
            alt={coin}
          />
        </div>
      )} */}
      <div className="withdraw-routes-container confirm">
        <div
          className="secondary-white-black-color"
          style={{
            marginBottom: '1.6rem',
            textAlign: 'center',
            fontSize: '1.6rem',
            textTransform: 'capitalize',
          }}
        >
          {amountTitle}
        </div>
        <div style={{
          display: 'grid',
          placeItems: 'center',
          gap: '0.8rem',
          gridTemplateColumns: '4rem auto',
          maxWidth: 'fit-content',
          margin: '0 auto',
          // justifyItems: 'center',
        }}
        >
          {coin && (
          <div>
            <img
              style={{ height: '4rem', width: '4rem' }}
              className="coin-badge"
              src={getIconUrl(coin)}
              alt={coin}
            />
          </div>
          )}
          <div
            style={{
              fontSize: '4rem',
              lineHeight: '4.8rem',
              fontFamily: 'var(--font-family-nexabold)',
            }}
          >
            {amount}
          </div>
        </div>
        <div className="confirm-transaction">
          {/* <div
            className="sub-title secondary-white-black-color"
            style={{ textAlign: 'center' }}
          >
            {amountTitle}
          </div>
          <div className="amount" style={{ textAlign: 'center' }}>
            {amount}
          </div> */}
          <div style={{ marginTop: '3.2rem', marginBottom: '4rem' }}>
            {items.map((child) => {
              const { name, value } = child;

              return (
                <div className="withdraw-route" key={name}>
                  <p className="confirm-name secondary-white-black-color">
                    {name}
                  </p>
                  <p className="confirm-value white-black-color">{value}</p>
                </div>
              );
            })}
            <div className="withdraw-route">
              <p className="confirm-name secondary-white-black-color">
                {t('dsh.status.title')}
              </p>
              <p className="confirm-value white-black-color">
                {t('dsh.status.pending')}
              </p>
            </div>
          </div>

          {withPin && (
            <>
              <div className="title secondary-white-black-color" style={{ marginBottom: '1.6rem' }}>
                {t('dsh.wallet.enterWhatInstruction', {
                  what: user.google_auth ? '2FA' : 'PIN',
                })}
              </div>
              <form className="" ref={pinForm}>
                <div className="pin-input-group">
                  <ReactPinField
                    length={6}
                    pattern="\d*"
                    type={pinVisible ? 'number' : 'password'}
                    className="pin-field white-black-color white-black-bg"
                    onChange={(num) => setPinHandler(num)}
                  //   onComplete={() => handleSubmit()}
                    format={(k) => k?.toUpperCase()}
                  //  disabled={showTime}
                    validate="0123456789"
                  // autoFocus
                    ref={pinRef}
                  />
                  <figure className="img-box" onClick={() => updateVisibility()}>
                    <img src={visibilityIcon} className="img" alt="" />
                  </figure>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default DrawerConfirmation;
