/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  Navigate,
  Route, Routes,
} from 'react-router-dom';
import uuid from 'react-uuid';
// import DashboardLayout from '../components/layouts/dashboard';
import Error404 from '../pages/error/Error404';
import { Onboarding_route_group } from './auth';
import { Dashboard_route_group } from './dashboard';
import routeNames from './routenames';
import DashboardRouteLayout from '../components/layouts/dashboard/DashboardRouteLayout';
import RedirectedSignup from '../pages/auth/RedirectedSignup';
import RoqqnRollLayout from '../features/gamefi/roqqnroll/layout/RoqqnRollLayout';
import { Dashboard_roqqnroll_route_group } from './dashboard/roqqnroll';
import AuthGuard from '../components/guards/AuthGuard';

export default function AppRouter() {
  return (
    <Routes>
      <Route
        exact
        path={routeNames.base}
        element={<Navigate to={routeNames.getStarted} replace />}
      />

      {/* This helps to re-route the referral links */}
      {/* Todo: Not a permanent solution */}
      {/* <Route
        exact
        path={routeNames.signup}
        element={({ location }) => (
          <Navigate
            to={location.pathname.replace(/signup/, 'register')}
            replace
          />
        )}
      /> */}
      <Route
        exact
        path={routeNames.signup}
        element={<RedirectedSignup />}
      />
      <Route
        exact
        path={routeNames.dashboard}
        element={<Navigate to="/dashboard-home" replace />}
      />

      {
        Onboarding_route_group.map((route) => (
          <Route key={uuid()} exact {...route} element={route.component} />
        ))
      }

      <Route element={<AuthGuard />}>
        <Route element={<DashboardRouteLayout />}>
          {
            Dashboard_route_group.map((route) => (
              // <React.Suspense key={uuid()} fallback={<LoadingPage />}>
              <Route key={uuid()} exact {...route} element={route.component} />
              // </React.Suspense>
            ))
          }
        </Route>

        <Route element={<RoqqnRollLayout />}>
          {
            Dashboard_roqqnroll_route_group.map((route) => (
              <Route key={uuid()} exact {...route} element={route.component} />
            ))
          }
        </Route>
      </Route>
      <Route path={routeNames.notFound} element={() => <Error404 />} />
      <Route path={routeNames.wildCard} element={() => <Error404 />} />
    </Routes>
  );
}
