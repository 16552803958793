const registrationRoutes = {
  // Step 1
  startRegistration: '/register',
  personalDetails: '/register/personal-details',
  findHomeAddress: '/register/personal-details/home-address',
  enterUsername: '/register/personal-details/username',
  personalDetailsVerified: '/register/personal-details/success',

  // Step 2
  createPin: '/register/create-pin',
  successPinSetup: '/register/create-pin/success',

  // Step 3
  verifyIdentity: '/register/verify-identity',

  // Step 4
  bankIndex: '/register/bank',
  bankSetupSuccess: '/register/bank-setup-success',
  userPreference: '/register/user-preferences',
  referral: '/register/user-referral',
  preferenceSuccess: '/register/user-preferences/success',
};

export default registrationRoutes;
