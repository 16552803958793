/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import OutsideClickHandler from 'react-outside-click-handler';
import { useIcon } from '../../../hooks/useIcon';
import useWindowSize from '../../../hooks/useWindowSize';
import ImagePlaceholder from '../ImagePlaceholder';

function SelectOptions({
  optionsOpen,
  fullscreen,
  heightStyles,
  canSearch,
  placeholder,
  setSearchQuery,
  // blurHandler,
  onSearchHandler,
  options,
  selected,
  onSelectChange,
  closeSelectoptions,
  darkBg,
  coordinates,
  hasOptionImage,
  optionImageKey,
  customDropdownBody,
  optionsHorizontalPosition,
}) {
  const [horizontalPosition, setHorizontalPosition] = useState(optionsHorizontalPosition);

  useEffect(() => {
    setHorizontalPosition(optionsHorizontalPosition);
  }, [optionsHorizontalPosition]);

  const { searchIcon } = useIcon();

  const [listStyles, setListStyles] = useState({});

  const { windowSize } = useWindowSize();

  useEffect(() => {
    if (fullscreen && coordinates) {
      setListStyles({
        width: `${coordinates?.width}px`,
        maxWidth: `${coordinates?.width}px`,
        left: `${(coordinates.left) + (coordinates.width / 2)}px`,
      });
    } else if (customDropdownBody) {
      setListStyles({
        width: '20.6rem',
        maxWidth: '20.6rem',
        ...(horizontalPosition === 'left' ? { left: '0px' } : { right: '0px' }),
      });
    }
  }, [coordinates, windowSize, customDropdownBody]);

  return (
    optionsOpen && (
      <OutsideClickHandler onOutsideClick={() => closeSelectoptions()}>
        <ul
          style={{ ...heightStyles, ...listStyles }}
          className={`select-options ${darkBg ? 'input-box-in-container-color' : 'container-color'} ${
            fullscreen ? 'fullscreen' : ''
          }`}
        >
          {canSearch && (
            <li className="search-option search container-wrap input-box-in-container-color card-color-border">
              {/* search... */}
              {/* search box start */}
              <div className="search-box search-box-default-color">
                <label htmlFor="search" className="label">
                  <figure className="img-box">
                    <img src={searchIcon} alt="" className="img" />
                  </figure>
                </label>
                <input
                  placeholder={placeholder || 'Search...'}
                  type="text"
                  name="search"
                  id="search"
                  className="input"
                  onInput={(e) => setSearchQuery(e.target.value)}
                  // onBlur={blurHandler}
                />
              </div>
              {/* search box end */}
            </li>
          )}
          {(canSearch ? onSearchHandler(options) : options).map((child, idx) => {
            const { text, value } = child;
            return (
              <li
                key={uuid()}
                className={`select-option card-color ${
                  idx === selected ? 'select-option--active' : ''
                }`}
                data-id={value}
                style={{ color: child?.color }}
                onClick={() => onSelectChange(value)}
              >
                {hasOptionImage && (
                  <ImagePlaceholder
                    src={child[optionImageKey]}
                  />
                )}
                <span className="line-clamp-1">{text}</span>
              </li>
            );
          })}
        </ul>
      </OutsideClickHandler>
    )
  );
}

export default SelectOptions;
