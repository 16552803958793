/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import './index.css';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// import { useIcon } from '../../../../hooks/useIcon';
import faqs from '../../../../pages/dashboard/earn/ribs/faqs';
import raffleFaqs from '../../../../pages/dashboard/earn/raffle/raffle-faqs';

function RIBsFAQ({ forFaq = 'ribs' }) {
  const history = useNavigate();
  // const { chevronRightBlunt } = useIcon();
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [openFaq, setOpenFaq] = useState(null);

  // function handle

  return (
    <div className="ribs-faq-section">
      <div className="article-wrap-header dsk">
        <p className="section-title">{t('dsh.roqqnroll.faqHeading')}</p>
        {/* <div className="toggle-blue-color view-all" onClick={() => history('/dashboard-earn-ribs-faq')}>
          <span>
            {t('dsh.nfts.viewAll')}
            {' '}
          </span>
          <img src={chevronRightBlunt} alt="" />
        </div> */}
      </div>

      <div className="article-collection-container">
        {(forFaq === 'ribs' ? faqs : raffleFaqs).map((d, index) => (
          <div
            key={`${d.slug}-${_.uniqueId()}`}
          >
            <div
              className={`article-item-conponent container-color border-light2 ${openFaq === index && 'open'}`}
              onClick={() => setOpenFaq(index)}
            >
              <div className="article-title">
                <span>{d.title}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 10H18C16.8646 11.3315 12 16 12 16L6 10Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div onClick={() => history(`/dashboard-earn-ribs-faq/${d.slug}`)} className="secondary-white-black-color article-description">
                {d.description}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="article-wrap-header mbl" style={{ marginTop: '2.4rem' }}>
        <p className="section-title" />
        {/* <div className="toggle-blue-color view-all" onClick={() => history('/dashboard-earn-ribs-faq')}>
          <span>View All</span>
          <img src={chevronRightBlunt} alt="" />
        </div> */}
      </div>

    </div>
  );
}

export default RIBsFAQ;
