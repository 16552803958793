/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import errorResolverMap from '../utils/ErrorResolverMap';
import MobileMoneyService from '../services/MobileMoneyService';

export const MomoLoadingStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
};

const initialState = {
  selectedCurrency: '',
  selectedPhoneNumberPrefix: '',
  providers: {
    loadingState: MomoLoadingStates.BASE,
    data: null,
    error: null,
  },
  creationData: {
    provider: '',
    mobile: '',
    name: '',
  },
  newAccountData: {
    loadingState: MomoLoadingStates.BASE,
    data: null,
    error: null,
  },
  removalData: {
    mobile: '',
  },
  removedAccountData: {
    loadingState: MomoLoadingStates.BASE,
    data: null,
    error: null,
  },
  withdrawalData: {
    mobile: '',
    amount: '',
  },
  setupFlow: {
    currentStage: 0,
  },
};

const mobileMoneySlice = createSlice({
  name: 'momo',
  initialState,
  reducers: {
    resetMomoState: (state) => {
      state.selectedCurrency = initialState.selectedCurrency;
      state.selectedPhoneNumberPrefix = initialState.selectedPhoneNumberPrefix;
      state.providers = initialState.providers;
      state.creationData = initialState.creationData;
      state.newAccountData = initialState.newAccountData;
      state.removalData = initialState.removalData;
      state.removedAccountData = initialState.removedAccountData;
      state.withdrawalData = initialState.withdrawalData;
      state.setupFlow = initialState.setupFlow;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    updateProviderData: (state, { payload }) => {
      state.providers[payload.propertyName] = payload.value;
    },
    updateCreationData: (state, { payload }) => {
      state.creationData[payload.propertyName] = payload.value;
    },
    setPhoneNumberPrefix: (state, { payload }) => {
      state.selectedPhoneNumberPrefix = payload;
    },
    incrementCurrentStage: (state) => {
      if (state.setupFlow.currentStage === 4) return;
      state.setupFlow.currentStage += 1;
    },
    decrementCurrentStage: (state) => {
      if (state.setupFlow.currentStage === 0) return;
      state.setupFlow.currentStage -= 1;
    },
    resetCurrentStage: (state) => {
      state.setupFlow.currentStage = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMomoProviders.pending, (state) => {
      state.providers.loadingState = MomoLoadingStates.FETCHING;
    });
    builder.addCase(getMomoProviders.fulfilled, (state, action) => {
      state.providers.error = null;
      state.providers.data = action.payload;
      state.providers.loadingState = MomoLoadingStates.FETCHED;
    });
    builder.addCase(getMomoProviders.rejected, (state, action) => {
      state.providers.data = [];
      state.error = action.payload;
      state.providers.loadingState = MomoLoadingStates.ERROR;
    });
    builder.addCase(createMomoAccount.pending, (state) => {
      state.newAccountData.loadingState = MomoLoadingStates.FETCHING;
    });
    builder.addCase(createMomoAccount.fulfilled, (state, action) => {
      state.newAccountData.error = null;
      state.newAccountData.data = action.payload;
      state.newAccountData.loadingState = MomoLoadingStates.FETCHED;
    });
    builder.addCase(createMomoAccount.rejected, (state, action) => {
      state.newAccountData.data = null;
      state.newAccountData.error = action.payload;
      state.newAccountData.loadingState = MomoLoadingStates.ERROR;
    });
    builder.addCase(removeMomoAccount.pending, (state) => {
      state.removedAccountData.loadingState = MomoLoadingStates.FETCHING;
    });
    builder.addCase(removeMomoAccount.fulfilled, (state, { payload }) => {
      state.removedAccountData.error = null;
      state.removedAccountData.data = payload;
      state.removedAccountData.loadingState = MomoLoadingStates.FETCHED;
    });
    builder.addCase(removeMomoAccount.rejected, (state, { payload }) => {
      state.removedAccountData.error = payload;
      state.removedAccountData.data = null;
      state.removedAccountData.loadingState = MomoLoadingStates.ERROR;
    });
  },
});

export const getMomoProviders = createAsyncThunk('momo/getProviders', async (currency, { rejectWithValue }) => {
  try {
    const data = await MobileMoneyService.getMomoProviders(currency);
    return data;
  } catch (e) {
    return rejectWithValue(e.toString());
  }
});

export const createMomoAccount = createAsyncThunk('momo/createAccount', async (_, { getState, rejectWithValue }) => {
  try {
    const { provider, mobile, name } = getState().mobileMoney.creationData;
    const data = await MobileMoneyService.createMomoAccount({
      provider,
      name,
      mobile: MobileMoneyService.convertNumberToInternationalFormat(mobile, getState().mobileMoney.selectedPhoneNumberPrefix),
    });
    return data;
  } catch (e) {
    return rejectWithValue(errorResolverMap[e.message] || e.message);
  }
});

export const removeMomoAccount = createAsyncThunk('momo/removeAccount', async (mobile, { rejectWithValue }) => {
  try {
    const data = await MobileMoneyService.removeMomoAccount(mobile);
    return data;
  } catch (e) {
    return rejectWithValue(e.toString());
  }
});

export const {
  updateOTP,
  resetMomoState,
  setSelectedCurrency,
  updateProviderData,
  updateCreationData,
  setPhoneNumberPrefix,
  incrementCurrentStage,
  decrementCurrentStage,
  resetCurrentStage,
} = mobileMoneySlice.actions;

export const momoProvidersState = (state) => state.mobileMoney.providers;
export const newAccountState = (state) => state.mobileMoney.newAccountData;
export const existingMomoAccountsState = (state) => state.mobileMoney.existingMomoAccounts;
export const getMomoCreationData = (state) => state.mobileMoney.creationData;
export const getRemovedMomoAccountState = (state) => state.mobileMoney.removedAccountData;
export const selectMomoSetupFlow = (state) => state.mobileMoney.setupFlow;

export default mobileMoneySlice.reducer;
