import { useMemo } from 'react';
import { THEME_MODES } from '../utils/themes/useDarkMode';
import { blackTheme, darkTheme, lightTheme } from '../utils/themes/theme';

function useThemeMode(theme) {
  const themeMode = useMemo(() => {
    let _ret_theme = '';
    let _ret_color = '';

    switch (theme) {
      case THEME_MODES.black:
        _ret_theme = blackTheme;
        _ret_color = '#0D0E10';
        break;
      case THEME_MODES.light:
        _ret_theme = lightTheme;
        _ret_color = '#F8F8F9';
        break;
      case THEME_MODES.dark:
        _ret_theme = darkTheme;
        _ret_color = '#1C2127';
        break;

      default:
        _ret_theme = blackTheme;
        break;
    }

    return { mode: _ret_theme, color: _ret_color };
  }, [theme]);

  return themeMode;
}

export default useThemeMode;
