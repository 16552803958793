/* eslint-disable react/prop-types */
import React from 'react';
import './BalanceModal.css';

import { useIcon } from '../../../../../../../hooks/useIcon';

import RoqqDialog from '../../RoqqDialog/RoqqDialog';
import RoqqPill from '../../RoqqPill/RoqqPill';
import RoqqFunStatsBanner from '../../RoqqFunStatsBanner/RoqqFunStatsBanner';

function BalanceModal({ show, onClose }) {
  const { statsIcon } = useIcon();

  return (
    <RoqqDialog
      show={show}
      onClose={onClose}
      buttonType="single"
      headerText="Balance!"
      neutralBtnText="Transfer to Wallet"
    >
      <div className="roqq-balance-container">
        <section className="roqq-balance-tile roqq-diamond-tile">
          <p className="available-bal">$16.00</p>
          <RoqqPill text="Available Balance" />
        </section>
        <hr className="horz-rule" />
        <RoqqFunStatsBanner icon={statsIcon} text="Fun stats: You have made 5 spins 2 wins" />
      </div>

    </RoqqDialog>
  );
}

export default BalanceModal;
