/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';
import RoqquQRCodeLogo from '../../../assets/QRLogo.svg';
import './QRCodeGen.css';

export default function QRCodeGen({ data, img }) {
  const ref = useRef(null);
  const qrCode = new QRCodeStyling({
    width: 224,
    height: 224,
    image: (img || RoqquQRCodeLogo),
    type: 'svg',
    dotsOptions: {
      gradient: {
        type: 'radial',
        rotation: 0,
        colorStops: [
          {
            offset: 0.6,
            color: '#2764ff',
          },
          {
            offset: 1,
            color: '#000000',
          },
        ],
      },
      type: 'dots',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 5,
    },
    cornersSquareOptions: {
      type: 'dot',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          {
            offset: 0.6,
            color: '#000000',
          },
          {
            offset: 1,
            color: '#2764ff',
          },
        ],
      },
    },
    qrOptions: {
      errorCorrectionLevel: 'M',
    },
    cornersDotOptions: {
      type: 'dot',
    },
  });
  useEffect(() => {
    if (data) {
      qrCode.update({
        data,
      });
    }
  }, [data]);
  useEffect(() => {
    qrCode.append(ref.current);
  }, []);
  return <div id="QRCode_Container" ref={ref} />;
}
