/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import Portal from '../../widgets/Portal';
import '../../../styles/dashboard/Modal.css';
import ButtonReuse from '../../fragments/Button';
import { useIcon } from '../../../hooks/useIcon';

/**
 *
 * @param {string} backgroundVariant 'modal-primary-background' | 'modal-body-background'
 * @returns
 */
function ModalLayout({
  visible,
  children,
  onClose = () => {},
  width = '46.8rem',
  noPadding = false,
  auth = false,
  showLine = true,
  style = {},
  backgroundVariant = 'modal-primary-background',
  // bottomPortal = null,
}) {
  function closeModal() {
    onClose && onClose();
  }

  const [localVisibility, setLocalVisibility] = useState(visible);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setLocalVisibility(true);
      }, 100);
    } else {
      setLocalVisibility(false);
    }
  }, [visible]);

  return (
    visible && (
      <Portal selector="#modal">
        <div
          onClick={(event) => event.stopPropagation()}
          className="modal-parent"
          style={{ '---width': width, ...style }}
        >
          <Modal
            effect="fadeInUp"
            onClickAway={() => closeModal()}
            visible={localVisibility}
          >
            {localVisibility && (
              <div
                className="modal-wrap"
                style={{ width: '100%', maxWidth: width }}
              >
                {auth ? children : (
                  <>
                    {showLine && <div className="line" />}
                    <div
                      className={`content-box ${backgroundVariant} ${
                        noPadding ? 'no-padding' : ''
                      }`}
                    >
                      {children}
                    </div>
                  </>
                )}
              </div>
            )}
          </Modal>
        </div>
      </Portal>
    )
  );
}

ModalLayout.BigText = function ({ text = '', className = '', center = true }) {
  return (
    <p className={`big-text ${className} white-black-color`}>
      {text.split('\n').map((item) => (
        <React.Fragment key={item}>
          <span style={{ textAlign: center ? 'center' : 'left' }}>{item}</span>
        </React.Fragment>
      ))}
    </p>
  );
};

ModalLayout.SmallText = function ({ text = '', className = '' }) {
  return (
    <p className={`small-text white-black-color ${className}`}>
      {text.split('\n').map((item) => (
        <React.Fragment key={item}>
          <span>{item}</span>
        </React.Fragment>
      ))}
    </p>
  );
};

ModalLayout.PortalButtons = function ({
  text, onClick = () => {}, btnStyle, loading = false, isDisabled = false,
}) {
  return (
    <ButtonReuse
      text={text}
      onClick={() => onClick()}
      btnStyle={btnStyle ?? 'outline btn-reuse-outline'}
      loading={loading}
      isDisabled={isDisabled}
    />
  );
};

ModalLayout.DeleteWarnContent = function ({
  src,
  title = 'Delete?',
  desc = 'Delete description',
  yesButtonText = 'Yes, Remove',
  loading, toggleModal, handleDelete,
  noButtonText = 'No',
}) {
  const { warnYellowIcon } = useIcon();
  return (
    <div className="delete-modal-wrap">
      <div className="delete-info-section">
        <div className="link-illustration-container">
          <img src={src || warnYellowIcon} alt="" className="" />
        </div>
        <ModalLayout.BigText className="large" text={title} />
        <ModalLayout.SmallText text={desc} />
      </div>
      <div className="delete-btn-wrap">
        <ButtonReuse
          loading={loading}
          btnStyle="btn-reuse"
          onClick={() => handleDelete()}
          text={yesButtonText}
        />
        <ButtonReuse
          isDisabled={loading}
          btnStyle="btn-outline"
          onClick={() => !loading && toggleModal && toggleModal()}
          text={noButtonText}
        />
      </div>
    </div>
  );
};

ModalLayout.SuccessContent = function ({
  src,
  loading,
  toggleModal,
  title = 'Success',
  desc = 'Completed Successfully',
  buttonText = 'Close',
  children,
  titleClassName,
  descClassName,
  successBtnStyle,
}) {
  const { successWhiteGIF } = useIcon();
  return (
    <div className="success-modal-wrap">
      <div className="success-info-section">
        <div className="link-illustration-container">
          <img src={src || successWhiteGIF} alt="successful" />
        </div>
        <ModalLayout.BigText
          className={titleClassName || 'large'}
          text={title}
        />
        <ModalLayout.SmallText
          text={desc}
          className={descClassName || ''}
        />
      </div>
      { children && children}
      <div className="success-btn-wrap">
        <ButtonReuse
          loading={loading}
          btnStyle={`btn-reuse ${successBtnStyle}`}
          onClick={() => !loading && toggleModal && toggleModal()}
          text={buttonText}
        />
      </div>
    </div>
  );
};

ModalLayout.ErrorContent = function ({
  src,
  loading,
  toggleModal,
  title = 'Error',
  desc = 'Failed to complete',
  buttonText = 'Close',
  showDesc = false,
}) {
  const { xRoundedIcon } = useIcon();
  return (
    <div className="success-modal-wrap">
      <div className="success-info-section">
        <div className="link-illustration-container">
          <img src={src || xRoundedIcon} alt="successful" />
        </div>
        <ModalLayout.BigText
          className="large"
          text={title}
        />
        { showDesc && <ModalLayout.SmallText text={desc} />}
      </div>
      <div className="success-btn-wrap">
        <ButtonReuse
          loading={loading}
          btnStyle="btn-reuse"
          onClick={() => !loading && toggleModal && toggleModal()}
          text={buttonText}
        />
      </div>
    </div>
  );
};

export default ModalLayout;
