/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
// import { createPortal } from 'react-dom';
import { FaAngleDown } from 'react-icons/fa';
import '../../styles/dashboard/CurrencySelectAlt.css';
// import naira from '../../assets/naira-icon-bordered.svg';
// import dollar from '../../assets/dollar-icon.svg';
// import euro from '../../assets/euro-icon.svg';
// import pound from '../../assets/pound-icon.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import useCurrency from '../../hooks/useCurrency';
import useThunkEffectCalls from '../../hooks/useThunkEffectCalls';
import { getCurrencyIcon } from '../../utils/getStaticAssets';

function CurrencySelectAlt({
  onChange, showIcon, showTitle = true, defaultCurrency = null, canChange = true, containerClassNames = ''
}) {
  const { primaryCurrency, currencies } = useCurrency();
  const mainCurrency = defaultCurrency || primaryCurrency;
  const [selectedItem, setSelectedItem] = useState(mainCurrency);
  const [isOpen, setIsOpen] = useState(false);

  const onItemClick = (value) => {
    setIsOpen(false);
    setSelectedItem(value);
    onChange && onChange(value);
  };

  function setOnMount() {
    // onChange && onChange(selectedItemIndex, dropdownItems[selectedItemIndex].value);
  }

  useThunkEffectCalls(setOnMount);

  const toggleOpen = () => {
    if (canChange) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`currency-select-alt-wrap ${containerClassNames}`} onClick={toggleOpen}>
      <div className="currency-select-header">
        <figure className="img-box selected-currency-icon-wrap">
          <img
            src={getCurrencyIcon(selectedItem)}
            alt="selected currency icon img"
          />
        </figure>
        <p className="selected-currency-text">
          {showTitle ? currencies.find((currency) => currency.c === selectedItem)?.n
            : currencies.find((currency) => currency.c === selectedItem)?.c?.toUpperCase()}
        </p>
        {showIcon && canChange && <FaAngleDown className="dropdown-icon" />}
      </div>
      <div
        className={`currency-select-options container-wrap ${isOpen && 'open'}`}
      >
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          {currencies.map((item) => (
            <div
              className="currency-item card-color"
              onClick={() => onItemClick(item.symbol)}
              key={item.symbol}
            >
              <div className="currency-icon-wrap">
                <img
                  className="currency-icon"
                  src={getCurrencyIcon(item?.symbol)}
                  alt="currency icon"
                />
              </div>
              <div className="currency-text">{showTitle ? item.n : item.c?.toUpperCase()}</div>
            </div>
          ))}
        </OutsideClickHandler>
      </div>
    </div>
  );
}

export default CurrencySelectAlt;
