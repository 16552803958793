/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsernameService from '../services/UsernameService';

export const UsernameStates = {
  BASE: 'base',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

const initialState = {
  isOpen: false,
  data: null,
  isLoading: false,
  status: UsernameStates.BASE,
  errorMessage: null,
  successMessage: null,
};

export const setUsername = createAsyncThunk('username/set', async (username, { rejectWithValue }) => {
  try {
    const { data } = await UsernameService.setUsername(username);
    return data;
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

const usernameSlice = createSlice({
  name: 'username',
  initialState,
  reducers: {
    toggleUsernameDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
    closeUsernameDrawer: (state) => {
      state.isOpen = false;
    },
    resetUsernameStatus: (state) => {
      state.status = UsernameStates.BASE;
    },
    activateTypingMode: (state) => {
      state.status = UsernameStates.BASE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUsername.pending, (state) => {
      state.isLoading = true;
      state.status = UsernameStates.PENDING;
    });
    builder.addCase(setUsername.fulfilled, (state, action) => {
      const { message, status, data } = action.payload;
      if (status !== 'fail') {
        state.data = data;
        state.successMessage = message;
        state.status = UsernameStates.SUCCESS;
      } else {
        state.errorMessage = message;
        state.status = UsernameStates.ERROR;
      }
      state.isLoading = false;
    });
    builder.addCase(setUsername.rejected, (state, action) => {
      state.errorMessage = (action.payload === 'user already has a username') ? 'You already have a username' : action.payload;
      state.isLoading = false;
      state.status = UsernameStates.ERROR;
    });
  },
});

export const {
  activateTypingMode,
  toggleUsernameDrawer,
  closeUsernameDrawer,
  resetUsernameStatus,
} = usernameSlice.actions;
export default usernameSlice.reducer;
