/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Dashboard_home_loan_route_group } from './loans';
import { Dashboard_home_pay_bill_route_group } from './paybillRoutes';
import { Dashboard_home_savings_route_group } from './savingsRoutes';
import { Dashboard_invest_route_group } from './investRoutes';

const DashboardHome = React.lazy(() => import('../../../pages/dashboard/home'));
const BuyAssets = React.lazy(() => import('../../../pages/dashboard/home/buy-asset/BuyAssets'));
const LegacyWalletRedirect = React.lazy(() => import('../../../pages/dashboard/home/LegacyWalletRedirect'));
const SingleAsset = React.lazy(() => import('../../../pages/dashboard/home/SingleAsset'));
const AddCurrency = React.lazy(() => import('../../../pages/dashboard/home/add-currency'));

export const Dashboard_home_route_group = [
  // TODO: here is a redirect route
  { path: '/wallet', component: <LegacyWalletRedirect /> },
  { path: '/dashboard-home', component: <DashboardHome /> },
  { path: '/dashboard-home-buy-asset', component: <BuyAssets type="buy" /> },
  { path: '/dashboard-home-sell-asset', component: <BuyAssets type="sell" /> },
  { path: '/dashboard-home-swap-asset', component: <BuyAssets type="swap" /> },
  { path: '/dashboard-home-single-asset/:coin', component: <SingleAsset /> },
  { path: '/dashboard-home-add-currency', component: <AddCurrency /> },
  ...Dashboard_home_pay_bill_route_group,
  ...Dashboard_home_savings_route_group,
  ...Dashboard_home_loan_route_group,
  ...Dashboard_invest_route_group,
];
