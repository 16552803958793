/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import './Paragraph.css';

export default function Paragraph({ children, classNames = '' }) {
  return (
    <p className={`invest-intro-paragraph ${classNames}`}>{children}</p>
  );
}
