/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BankingService from '../services/banksService';
import * as states from '../states/ComponentStates';

const initialState = {
  myBanks: {
    status: states.BASE,
    data: [],
    error: false,
  },
  myMomoAccounts: null,
  addBank: {
    status: states.BASE,
    data: [],
    error: '',
  },
  allBanks: {
    status: states.BASE,
    data: [],
    error: false,
  },
  bankName: {
    status: states.BASE,
    data: '',
    error: false,
  },
  deleteBank: {
    status: states.BASE,
    error: false,
  },
  codeVerification: {
    emailCodeSendStatus: states.BASE,
    emailCodeSendError: false,
    phoneCodeSendStatus: states.BASE,
    phoneCodeSendError: false,
    emailCodeCheckStatus: states.BASE,
    emailCodeCheckError: false,
    phoneCodeCheckStatus: states.BASE,
    phoneCodeCheckError: false,
  },
  addBankSubFlow: {
    isOpen: false,
    currentStage: 0,
  },
  accountConfiguration: {
    requestVerification: false,
    status: states.BASE,
    data: '',
    error: false,
  },
  connectBank: {
    status: states.BASE,
    data: '',
    error: false,
  },
};

export const fetchMyBanks = createAsyncThunk(
  'banks/fetchMyBanks',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await BankingService.getMyBanks();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const addABank = createAsyncThunk('banks/addABank', async (params, { rejectWithValue }) => {
  try {
    const { data } = await BankingService.addABankService(params);
    return { params, response: data };
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const deleteABank = createAsyncThunk(
  'banks/deleteABank',
  async (params, { rejectWithValue }) => {
    try {
      await BankingService.deleteABankService(params);
      return params;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchAllBanks = createAsyncThunk(
  'banks/fetchAllBanks',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await BankingService.getAllBanks(token);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchResolvedName = createAsyncThunk(
  'banks/fetchResolvedName',
  async (banksDetails, { rejectWithValue }) => {
    try {
      const { data } = await BankingService.resolveBankAccountName(banksDetails);
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const addHighRiskBank = createAsyncThunk('banks/addHighRiskBank', async (params, { getState, rejectWithValue }) => {
  try {
    const { s_code, e_code } = getState().banks.codeVerification;
    const { data } = await BankingService.addHighRiskBank({ ...params, s_code, e_code });
    return { params, response: data };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const sendPhoneCode = createAsyncThunk('banks/sendPhoneCode', async (_, { rejectWithValue }) => {
  try {
    return await BankingService.sendPhoneCode();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const sendEmailCode = createAsyncThunk('banks/sendEmailCode', async (_, { rejectWithValue }) => {
  try {
    return await BankingService.sendEmailCode();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const checkEmailCode = createAsyncThunk('banks/checkEmailCode', async (code, { rejectWithValue }) => {
  try {
    return await BankingService.checkEmailCode(code);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const checkPhoneCode = createAsyncThunk('banks/checkPhoneCode', async (code, { rejectWithValue }) => {
  try {
    return await BankingService.checkPhoneCode(code);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchAccountConfiguration = createAsyncThunk(
  'banks/fetchAccountConfiguration',
  async (_, thunkApi) => {
    try {
      const { data } = await BankingService.getAccountConfiguration();
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  },
);

export const fetchConnectBankLink = createAsyncThunk(
  'banks/fetchConnectBankLink',
  async (currency, { rejectWithValue }) => {
    try {
      const { data } = await BankingService.connectBank(currency);
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    resetBankName: (state) => {
      state.bankName.data = initialState.bankName.data;
      state.bankName.status = initialState.bankName.status;
      state.bankName.error = initialState.bankName.error;
    },
    resetAddBankStatus: (state) => {
      state.addBank.status = initialState.addBank.status;
      state.addBank.error = initialState.addBank.error;
      state.addBank.data = initialState.addBank.data;
    },
    resetDeleteBankStatus: (state) => {
      state.deleteBank.status = initialState.deleteBank.status;
      state.deleteBank.error = initialState.deleteBank.error;
    },
    resetCodeVerification: (state) => {
      state.codeVerification = initialState.codeVerification;
    },
    openAddBankSubFlow: (state) => {
      state.addBankSubFlow.isOpen = true;
    },
    closeAddBankSubFlow: (state) => {
      state.addBankSubFlow.isOpen = false;
    },
    incrementAddBankSubFlowStage: (state) => {
      state.addBankSubFlow.currentStage += 1;
    },
    decrementAddBankSubFlowStage: (state) => {
      state.addBankSubFlow.currentStage -= 1;
    },
    resetConnectBankStatus: (state) => {
      state.connectBank.status = initialState.connectBank.status;
      state.connectBank.error = initialState.connectBank.error;
      state.connectBank.data = initialState.connectBank.data;
    },
    resetAccountConfigurationStatus: (state) => {
      state.accountConfiguration.status = initialState.accountConfiguration.status;
      state.accountConfiguration.error = initialState.accountConfiguration.error;
      state.accountConfiguration.requestVerification = initialState
        .accountConfiguration
        .requestVerification;
    },
  },
  extraReducers: (builder) => {
    // myBanks thunk reducers...
    builder.addCase(fetchMyBanks.pending, (state) => {
      state.myBanks.status = states.FETCHING;
    });
    builder.addCase(fetchMyBanks.fulfilled, (state, { payload }) => {
      state.myBanks.data = payload.bank;
      state.myMomoAccounts = payload.momo;
      state.myBanks.status = states.FETCHED;
    });
    builder.addCase(fetchMyBanks.rejected, (state) => {
      state.myBanks.status = states.ERROR;
    });

    // add a bank thunk
    builder.addCase(addABank.pending, (state) => {
      state.addBank.status = states.FETCHING;
    });
    builder.addCase(addABank.fulfilled, (state, action) => {
      const { response, params } = action.payload;

      if (response.status !== 'fail') {
        if (!state.myBanks.data.find((d) => d.account_number === params.account_number)) {
          state.myBanks.data.unshift({
            ...params,
            account_number: params.account_number || params.iban,
            account_name: state.bankName.data || params.account_name,
          });
        }
        state.addBank.status = states.FETCHED;
      } else {
        state.addBank.error = response.message;
        state.addBank.status = states.ERROR;
      }
    });
    builder.addCase(addABank.rejected, (state, action) => {
      state.addBank.status = states.ERROR;
      state.addBank.error = action.payload;
    });

    // delete a bank thunk
    builder.addCase(deleteABank.pending, (state) => {
      state.deleteBank.status = states.FETCHING;
    });
    builder.addCase(deleteABank.fulfilled, (state, action) => {
      state.myBanks.data = state.myBanks.data.filter(
        (d) => d.account_number !== action.payload.account_number,
      );
      state.deleteBank.status = states.FETCHED;
    });
    builder.addCase(deleteABank.rejected, (state) => {
      state.deleteBank.status = states.ERROR;
    });

    // allBanks thunk reducers...
    builder.addCase(fetchAllBanks.pending, (state) => {
      state.allBanks.status = states.FETCHING;
    });
    builder.addCase(fetchAllBanks.fulfilled, (state, action) => {
      state.allBanks.data = action.payload;
      state.allBanks.status = states.FETCHED;
    });
    builder.addCase(fetchAllBanks.rejected, (state) => {
      state.allBanks.status = states.ERROR;
    });

    // resolve bank thunk reducers...
    builder.addCase(fetchResolvedName.pending, (state) => {
      state.bankName.status = states.FETCHING;
    });
    builder.addCase(fetchResolvedName.fulfilled, (state, action) => {
      if (action.payload.status !== 'fail') {
        state.bankName.data = action.payload.data;
        state.bankName.status = states.FETCHED;
      } else {
        state.bankName.status = states.ERROR;
        state.bankName.error = action.payload.message;
      }
    });
    builder.addCase(fetchResolvedName.rejected, (state) => {
      state.bankName.status = states.ERROR;
    });

    // add high risk bank thunk
    builder.addCase(addHighRiskBank.pending, (state) => {
      state.addBank.status = states.FETCHING;
    });
    builder.addCase(addHighRiskBank.fulfilled, (state, action) => {
      const { response, params } = action.payload;

      if (response.status !== 'fail') {
        if (!state.myBanks.data.find((d) => d.account_number === params.account_number)) {
          state.myBanks.data.unshift({
            ...params,
            account_number: params.account_number || params.iban,
            account_name: state.bankName.data || params.account_name,
          });
        }
        state.addBank.status = states.FETCHED;
      } else {
        state.addBank.error = response.message;
        state.addBank.status = states.ERROR;
      }
    });
    builder.addCase(addHighRiskBank.rejected, (state, action) => {
      state.addBank.status = states.ERROR;
      state.addBank.error = action.payload;
    });

    // send phone code thunk
    builder.addCase(sendPhoneCode.pending, (state) => {
      state.codeVerification.phoneCodeSendStatus = states.FETCHING;
    });
    builder.addCase(sendPhoneCode.fulfilled, (state) => {
      state.codeVerification.phoneCodeSendError = null;
      state.codeVerification.phoneCodeSendStatus = states.FETCHED;
    });
    builder.addCase(sendPhoneCode.rejected, (state, action) => {
      state.codeVerification.phoneCodeSendError = action.payload;
      state.codeVerification.phoneCodeSendStatus = states.ERROR;
    });

    // send email code thunk
    builder.addCase(sendEmailCode.pending, (state) => {
      state.codeVerification.emailCodeSendStatus = states.FETCHING;
    });
    builder.addCase(sendEmailCode.fulfilled, (state) => {
      state.codeVerification.emailCodeSendError = null;
      state.codeVerification.emailCodeSendStatus = states.FETCHED;
    });
    builder.addCase(sendEmailCode.rejected, (state, action) => {
      state.codeVerification.emailCodeSendError = action.payload;
      state.codeVerification.emailCodeSendStatus = states.ERROR;
    });

    // check email confirmation code thunk
    builder.addCase(checkEmailCode.pending, (state) => {
      state.codeVerification.emailCodeCheckStatus = states.FETCHING;
    });
    builder.addCase(checkEmailCode.fulfilled, (state) => {
      state.codeVerification.emailCodeCheckError = null;
      state.codeVerification.emailCodeCheckStatus = states.FETCHED;
    });
    builder.addCase(checkEmailCode.rejected, (state, action) => {
      state.codeVerification.emailCodeCheckError = action.payload;
      state.codeVerification.emailCodeCheckStatus = states.ERROR;
    });

    // check phone confirmation code thunk
    builder.addCase(checkPhoneCode.pending, (state) => {
      state.codeVerification.phoneCodeCheckStatus = states.FETCHING;
    });
    builder.addCase(checkPhoneCode.fulfilled, (state) => {
      state.codeVerification.phoneCodeCheckError = null;
      state.codeVerification.phoneCodeCheckStatus = states.FETCHED;
    });
    builder.addCase(checkPhoneCode.rejected, (state, action) => {
      state.codeVerification.phoneCodeCheckError = action.payload;
      state.codeVerification.phoneCodeCheckStatus = states.ERROR;
    });

    // fetchAccountConfiguration reducers...
    builder.addCase(fetchAccountConfiguration.pending, (state) => {
      state.accountConfiguration.status = states.FETCHING;
    });
    builder.addCase(fetchAccountConfiguration.fulfilled, (state, action) => {
      if (action.payload.status !== 'fail') {
        state.accountConfiguration.data = action.payload.data;
        state.accountConfiguration.status = states.FETCHED;
      } else {
        state.accountConfiguration.status = states.ERROR;
        state.accountConfiguration.error = action.payload.message;
      }
    });
    builder.addCase(fetchAccountConfiguration.rejected, (state, action) => {
      state.accountConfiguration.status = states.ERROR;
      if (action.payload.message === 'request_verification_failed') {
        state.accountConfiguration.requestVerification = true;
      }
      state.accountConfiguration.error = action.payload.message;
    });

    // fetchConnectBankLink reducers...
    builder.addCase(fetchConnectBankLink.pending, (state) => {
      state.connectBank.status = states.FETCHING;
    });
    builder.addCase(fetchConnectBankLink.fulfilled, (state, action) => {
      if (action.payload.status !== 'fail') {
        state.connectBank.data = action.payload.data;
        state.connectBank.status = states.FETCHED;
      } else {
        state.connectBank.status = states.ERROR;
        state.connectBank.error = action.payload.message;
      }
    });
    builder.addCase(fetchConnectBankLink.rejected, (state) => {
      state.connectBank.status = states.ERROR;
    });
  },
});

// Accessors
export const selectBankName = (state) => state.banks.bankName;
export const selectAllBanks = (state) => state.banks.allBanks;
export const selectMyBanks = (state) => state.banks.myBanks;
export const selectAddBank = (state) => state.banks.addBank;
export const selectDeleteBank = (state) => state.banks.deleteBank;
export const selectCodeConfirmation = (state) => state.banks.codeVerification;
export const selectAddBankSubFlow = (state) => state.banks.addBankSubFlow;
export const selectAccountConfiguration = (state) => state.banks.accountConfiguration;
export const selectConnectBank = (state) => state.banks.connectBank;

export default banksSlice.reducer;
export const {
  resetBankName,
  resetAddBankStatus,
  resetDeleteBankStatus,
  resetCodeVerification,
  incrementAddBankSubFlowStage,
  decrementAddBankSubFlowStage,
  openAddBankSubFlow, resetAccountConfigurationStatus,
  closeAddBankSubFlow, resetConnectBankStatus,
} = banksSlice.actions;
