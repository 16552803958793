/* eslint-disable object-curly-newline */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import { decrypt, encrypt } from '../utils/simpleActions';
import { CACHED_USER_BALANCE_DATA } from '../utils/storageKeys';
import AuthService from './AuthService';

export default class UserService {
  static async getUserBalance() {
    const { response } = await post({
      url: apiRoutes.getUserBalance,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response?.data) {
      UserService.persistUserBalanceResponseData(response.data);
    }
    if (response === false) {
      const cachedResponseData = UserService.getPersistedUserBalanceResponse();
      return { data: cachedResponseData };
    }
    return response;
  }

  static persistUserBalanceResponseData(data) {
    const jsonData = JSON.stringify(data);
    const encryptedUser = encrypt(jsonData);
    localStorage.setItem(CACHED_USER_BALANCE_DATA, encryptedUser);
  }

  static getPersistedUserBalanceResponse() {
    const cachedResponseData = localStorage.getItem(CACHED_USER_BALANCE_DATA);
    if (!cachedResponseData) {
      return undefined;
    }
    const parsedResponse = JSON.parse(decrypt(cachedResponseData));
    return parsedResponse;
  }

  static getDefaultResponse() {
    return ({
      status: 'success',
      message: 'balances retrieved successfully',
      data: {
        crypto: [
          { currency: 'btc', title: 'Bitcoin', available_bal: '0.000000', _fav: 1 },
          { currency: 'eth', title: 'Ethereum', available_bal: '0.000000', _fav: 1 },
          { currency: 'ltc', title: 'Litecoin', available_bal: '0.000000', _fav: 1 },
          { currency: 'doge', title: 'Dogecoin', available_bal: '0.000000', _fav: 1 },
          { currency: 'usdt', title: 'Tether', available_bal: '0.000000', _fav: 1 },
          { currency: 'xrp', title: 'Ripple', available_bal: '0.000000', _fav: 1 },
          { currency: 'dash', title: 'Dashcoin', available_bal: '0.000000', _fav: 1 },
          { currency: 'trx', title: 'Tron', available_bal: '0.000000', _fav: 1 },
          { currency: 'bnb', title: 'Binance Token', available_bal: '0.000000', _fav: 1 },
          { currency: 'eos', title: 'EOS', available_bal: '0.000000', _fav: 1 },
          { currency: 'steem', title: 'Steem', available_bal: '0.000000', _fav: 1 },
          { currency: 'hive', title: 'Hive', available_bal: '0.000000', _fav: 1 },
        ],
        fiat: [
          { currency: 'ngn', title: 'Nigerian Naira', available_bal: '0.0000' },
          { currency: 'usd', title: 'United States Dollar', available_bal: '0.0000' },
          { currency: 'gbp', title: 'British Pound Sterling', available_bal: '0.0000' },
          { currency: 'eur', title: 'European Euro', available_bal: '0.0000' },
          { currency: 'ghs', title: 'Ghanaian Cedi', available_bal: '0.0000' },
        ],
      },
    });
  }

  /*
   * SAMPLE SUCCESS RESPONSE:
   *{
      "status": "success",
      "message": "token added to account successfully",
      "data": null
    }
    SAMPLE ERROR RESPONSE:
    {
      "status": "fail",
      "message": "user already has wallet for token",
      "data": null
    }
  */
  static async createNewCoinBalance(symbol) {
    const response = await post({
      url: apiRoutes.createTokenBalance,
      data: {
        token: AuthService._getUserToken(),
        symbol,
      },
    });
    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    const { status, message } = response.response.data;
    return ({ symbol, status, message });
  }

  static async createNewCoinBalances(symbols) {
    const responses = [];
    for (let i = 0; i < symbols.length; i++) {
      try {
        const response = await UserService.createNewCoinBalance(symbols[i]);
        responses.push(response);
      } catch (e) {
        responses.push({
          symbol: symbols[i],
          status: 'fail',
          message: e.toString(),
        });
      }
    }
    return responses;
  }

  static async favoriteCoin(symbol) {
    const response = await post({
      url: apiRoutes.favoriteCoin,
      data: {
        token: AuthService._getUserToken(),
        symbol,
        fav: `${1}`,
      },
    });
    if (response.response.data.status === 'success') {
      return response.response.data;
    }
    throw new Error(response.response.data.message || 'unable to favorite token');
  }

  static async favoriteCoins(symbols) {
    const responses = [];
    symbols.forEach(async (symbol) => {
      try {
        const response = await UserService.favoriteCoin(symbol);
        responses.push(response);
      } catch (e) {
        responses.push({
          symbol,
          status: 'error',
          message: e.message,
        });
      }
    });
    return responses;
  }

  static async unfavoriteCoin(symbol) {
    const response = await post({
      url: apiRoutes.favoriteCoin,
      data: {
        token: AuthService._getUserToken(),
        symbol,
        fav: `${0}`,
      },
    });
    if (response.response.data.status === 'success') {
      return response.response.data;
    }
    throw new Error(response.response.data.message || 'unable to favorite token');
  }

  static async unfavoriteCoins(symbols) {
    const responses = [];
    symbols.forEach(async (symbol) => {
      try {
        const response = await UserService.unfavoriteCoin(symbol);
        responses.push(response);
      } catch (e) {
        responses.push({
          symbol,
          status: 'error',
          message: e.message,
        });
      }
    });
    return responses;
  }

  static async setUsername() {
    //
  }

  static async resetPIN() {
    const { response } = await post({
      url: apiRoutes.resetPin,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response.data.status === 'fail') {
      throw new Error(response.data.message);
    }
    return response;
  }

  static async resetPINConfirmation({ auth, s_code, e_code }) {
    const { response } = await post({
      url: apiRoutes.resetPinConfirmation,
      data: {
        token: AuthService._getUserToken(),
        auth,
        s_code,
        e_code,
      },
    });
    if (response.data.status === 'fail') {
      throw new Error(response.data.message);
    }
    return response;
  }

  static async saveUserPreferences(data) {
    const response = await post({
      url: apiRoutes.saveUserPreference,
      data: {
        token: AuthService._getUserToken(),
        data,
      },
    });

    if (!response.response) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }

  static async getUserFavouriteCurrencies() {
    const { response } = await post({
      url: apiRoutes.getFavouriteCurrencies,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (response.data.status === 'fail') {
      throw new Error(response.data.message);
    }

    return response;
  }

  static async saveToFavouriteCurrencies(currency) {
    const { response } = await post({
      url: apiRoutes.addToFavouriteCurrencies,
      data: {
        token: AuthService._getUserToken(),
        currency,
      },
    });

    if (response.data.status === 'fail') {
      throw new Error(response.data.message);
    }

    return response;
  }
}
