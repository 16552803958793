/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import './RoqqTextTile.css';

export default function RoqqTextTile({ text }) {
  return (
    <div className="roqq-text-tile">
      <p className="tile-text">{text}</p>
    </div>
  );
}
