/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as states from '../states/ComponentStates';
import RibsService from '../services/ribsService';

import '../features/ribs/views/flows/buy_bag/components/types/RibsBuyBagStateType';

const initialState = {
  newToCrypto: '',
  investAmount: '',
  selectedBag: null,
  bags: {
    status: states.BASE,
    data: [],
    error: false,
  },
  buyBag: {
    status: states.BASE,
    data: [],
    error: false,
  },
};

export const fetchAllBags = createAsyncThunk(
  'ribsApplication/fetchAllBags',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await RibsService.getAllBags();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const buyBag = createAsyncThunk(
  'ribsApplication/buyBag',
  /**
   * @param {BuyBagProps} param
   * @returns
   */
  async ({
    amount, bag_reference, buy_type, currency,
  }, { rejectWithValue }) => {
    try {
      const { data } = await RibsService.buyABag({
        amount, bag_reference, buy_type, currency,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const ribsApplicationSlice = createSlice({
  name: 'ribsApplication',
  initialState,
  reducers: {
    setNewToCrypto(state, action) {
      state.newToCrypto = action.payload;
    },
    setInvestAmount(state, action) {
      state.investAmount = action.payload;
    },
    setSelectedBag(state, action) {
      state.selectedBag = action.payload;
    },
    resetAll(state) {
      state.selectedBag = initialState.selectedBag;
      state.investAmount = initialState.investAmount;
    },
    resetBuyBagError(state) {
      state.buyBag.error = states.BASE;
      state.buyBag.status = states.BASE;
    },
  },
  extraReducers: (builder) => {
    // fetchAllBags thunk reducers...
    builder.addCase(fetchAllBags.pending, (state) => {
      state.bags.status = states.FETCHING;
    });
    builder.addCase(fetchAllBags.fulfilled, (state, { payload }) => {
      state.bags.data = payload.filter((d) => d.status === 1).map((d) => ({
        ...d,
        items: JSON.parse(d.items),
      }));
      state.bags.status = states.FETCHED;
    });
    builder.addCase(fetchAllBags.rejected, (state) => {
      state.bags.status = states.ERROR;
    });

    // buyBag thunk
    builder.addCase(buyBag.pending, (state) => {
      state.buyBag.status = states.FETCHING;
    });
    builder.addCase(buyBag.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload.status !== 'fail') {
        state.buyBag.status = states.FETCHED;
      } else {
        state.buyBag.error = payload.message;
        state.buyBag.status = states.ERROR;
      }
    });
    builder.addCase(buyBag.rejected, (state, action) => {
      state.buyBag.status = states.ERROR;
      state.buyBag.error = action.payload;
    });
  },
});

export const selectRIBsApplication = (state) => state.ribsApplication;
export const selectAllRIBsBags = (state) => state.ribsApplication.bags;
export const selectBuyBags = (state) => state.ribsApplication.buyBag;

export const {
  setNewToCrypto,
  setInvestAmount,
  setSelectedBag,
  resetAll,
  resetBuyBagError,
} = ribsApplicationSlice.actions;
export default ribsApplicationSlice.reducer;
