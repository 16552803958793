/* eslint-disable react/prop-types */
import React from 'react';

function GroupWithAvailableBalance({ children }) {
  return (
    <div className="dsh-input-group group">{children}</div>
  );
}

export default GroupWithAvailableBalance;
