/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-as-default-member */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SettingsService from '../services/settingsService';
import * as states from '../states/ComponentStates';

export const SettingsToggleNames = {
  NEWSLETTER_SUB: 'newsletter_subscribe',
  NEWSLETTER_UNSUB: 'newsletter_unsubscribe',
  PUSH_NOTIFICATIONS_SUB: 'push_notifications_sub',
  PUSH_NOTIFICATIONS_UNSUB: 'push_notifications_unsub',
  SMS_NOTIFICATIONS_SUB: 'sms_notifications_sub',
  SMS_NOTIFICATIONS_UNSUB: 'sms_notifications_unsub',
};

const initialState = {
  devices: {
    status: states.BASE,
    data: [],
    error: false,
  },
  userSettings: {
    data: {},
    status: states.BASE,
    error: false,
  },
  // I gave this a name s that this state won't be restricted to only newsletter unsubscriptions
  // To use this state with another type of subscription/unsibscription, just change the name field
  // of this state in the thunk's builder thunk cases
  settingsToggle: {
    name: null,
    status: states.BASE,
    error: false,
  },
};

export const fetchDevices = createAsyncThunk(
  'settings/fetchDevices',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await SettingsService.getDevices(token);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const deleteADevice = createAsyncThunk(
  'settings/deleteADevice',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await SettingsService.deleteDevice(params);
      return { params, data };
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchUserSettings = createAsyncThunk('settings/fetch', async (_, { rejectWithValue }) => {
  try {
    const { data } = await SettingsService.getUserSettings();
    return data;
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

export const subscribeToNewsletter = createAsyncThunk('settings/subscribeNewsletter', async (_, { rejectWithValue }) => {
  try {
    const { data } = await SettingsService.subscribeToNewsletter();
    return data;
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

export const unsubscribeFromNewsletter = createAsyncThunk('settings/unsubscribeNewsletter', async (_, { rejectWithValue }) => {
  try {
    const { data } = await SettingsService.unsubscribeFromNewsletter();
    return data;
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    deleteADeviceReducer(state, action) {
      state.devices.data = state.devices.data.filter(
        (d) => `${d.key}` !== action.payload,
      );
    },
    resetToggleState(state) {
      state.settingsToggle = initialState.settingsToggle;
    },
  },
  extraReducers: (builder) => {
    // myDevices thunk reducers...
    builder.addCase(fetchDevices.pending, (state) => {
      state.devices.status = states.FETCHING;
    });
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      state.devices.data = action.payload;
      state.devices.status = states.FETCHED;
    });
    builder.addCase(fetchDevices.rejected, (state) => {
      state.devices.status = states.ERROR;
    });

    // Delete device
    builder.addCase(deleteADevice.pending, (state) => {
      state.devices.status = states.FETCHING;
    });
    builder.addCase(deleteADevice.fulfilled, (state, action) => {
      state.devices.data = state.devices.data.filter(
        (d) => `${d.key}` !== action.payload.params.key,
      );
      state.devices.status = states.FETCHED;
    });
    builder.addCase(deleteADevice.rejected, (state) => {
      state.devices.status = states.ERROR;
    });

    // Fetch user settings
    builder.addCase(fetchUserSettings.pending, (state) => {
      state.userSettings.status = states.FETCHING;
    });
    builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
      state.userSettings.data = action.payload.data;
      state.userSettings.status = states.FETCHED;
    });
    builder.addCase(fetchUserSettings.rejected, (state, action) => {
      state.userSettings.error = action.payload;
      state.userSettings.status = states.ERROR;
    });

    // Newsletter subscribe settings
    builder.addCase(subscribeToNewsletter.pending, (state) => {
      state.settingsToggle.name = SettingsToggleNames.NEWSLETTER_SUB;
      state.settingsToggle.status = states.FETCHING;
    });
    builder.addCase(subscribeToNewsletter.fulfilled, (state) => {
      state.settingsToggle.name = SettingsToggleNames.NEWSLETTER_SUB;
      state.settingsToggle.status = states.FETCHED;
    });
    builder.addCase(subscribeToNewsletter.rejected, (state, action) => {
      state.settingsToggle.name = SettingsToggleNames.NEWSLETTER_SUB;
      state.settingsToggle.error = action.payload;
      state.settingsToggle.status = states.ERROR;
    });

    // Newsletter Unsubscribe settings
    builder.addCase(unsubscribeFromNewsletter.pending, (state) => {
      state.settingsToggle.name = SettingsToggleNames.NEWSLETTER_UNSUB;
      state.settingsToggle.status = states.FETCHING;
    });
    builder.addCase(unsubscribeFromNewsletter.fulfilled, (state) => {
      state.settingsToggle.name = SettingsToggleNames.NEWSLETTER_UNSUB;
      state.settingsToggle.status = states.FETCHED;
    });
    builder.addCase(unsubscribeFromNewsletter.rejected, (state, action) => {
      state.settingsToggle.name = SettingsToggleNames.NEWSLETTER_UNSUB;
      state.settingsToggle.error = action.payload;
      state.settingsToggle.status = states.ERROR;
    });
  },
});

// Accessors
export const selectDevices = (state) => state.settings.devices;
export const selectUserSettings = (state) => state.settings.userSettings;
export const selectSettingsToggle = (state) => state.settings.settingsToggle;

export default settingsSlice.reducer;
export const { deleteADeviceReducer, resetToggleState } = settingsSlice.actions;
