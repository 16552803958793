import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class RaffleService {
  static async getPoolPrize() {
    const response = await post({
      url: apiRoutes.getPoolPrize,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response.response && response.response.data.status === 'success') {
      return response.response.data;
    } if (response.error) {
      throw new Error(response.error.response.data.message || 'unable to get user entries');
    }
    throw new Error(response.response.data.message || 'unable to get pool prize');
  }

  static async getUserRaffleEntries() {
    const response = await post({
      url: apiRoutes.getUserRaffleEntries,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response.response && response.response.data.status === 'success') {
      return response.response.data;
    } if (response.error) {
      throw new Error(response.error.response.data.message || 'unable to get user entries');
    }
    throw new Error(response.response.data.message || 'unable to get user entries');
  }

  /**
   * @param {'open' | 'coded'} mode
   * @returns
   */
  static async playRaffle(mode) {
    const response = await post({
      url: process.env.NODE_ENV === 'development' ? apiRoutes.testPlayRaffle : apiRoutes.playRaffle,
      data: {
        token: AuthService._getUserToken(),
        mode,
        ...(process.env.NODE_ENV === 'development' ? { rarity: 3 } : {}),
      },
    });
    if (response.response && response.response.data.status === 'success') {
      return response.response.data;
    } if (response.error) {
      throw new Error(response.error.response.data.message || 'unable to get user entries');
    }
    throw new Error(response.response.data.message || 'unable to get user entries');
  }

  /**
   * @param {'open' | 'coded'} mode
   * @returns
   */
  static async canPlayFreeRaffle(mode) {
    const response = await post({
      url: apiRoutes.canPlayFreeRaffle,
      data: {
        token: AuthService._getUserToken(),
        mode,
      },
    });
    if (response.response && response.response.data.status === 'success') {
      return response.response.data;
    } if (response.error) {
      throw new Error(response.error.response.data.message || 'unable to get user entries');
    }
    throw new Error(response.response.data.message || 'unable to get user entries');
  }

  /**
   * @typedef SocialShareCheck
   * @property {string} rarityLevel
   * @property {string} timestamp
   * @property {string} refid
   */

  /**
   * @param {SocialShareCheck} prop
   * @returns
   */
  static async socialShareCheck(prop) {
    const response = await post({
      url: apiRoutes.socialShareCheck,
      data: {
        token: AuthService._getUserToken(),
        social_media_link: prop.social_media_link,
        refid: prop.refid,
      },
    });
    if (response.response && response.response.data.status === 'success') {
      return response.response.data;
    } if (response.error) {
      throw new Error(response.error.response.data.message || 'unable to get user entries');
    }
    throw new Error(response.response.data.message || 'unable to get user entries');
  }
}
