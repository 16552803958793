/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sortDecendingDate } from '../utils/simpleActions';
import CardService from '../services/cardService';
import * as states from '../states/ComponentStates';

const initialState = {
  cards: {
    status: states.BASE,
    data: [],
    error: false,
    config: null,
  },
  aCard: {
    status: states.BASE,
    data: null,
    error: false,
  },
  cardTransactions: {
    status: states.BASE,
    data: null,
    error: false,
  },
  fundCreateCard: {
    status: states.BASE,
    data: null,
    error: false,
  },
};

export const fetchAllCards = createAsyncThunk(
  'cards/fetchAllCards',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await CardService.getAllCards(token);
      return { data: data.data, config: data.config };
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchCard = createAsyncThunk('cards/fetchCard', async ({ token, card_id }, { rejectWithValue }) => {
  try {
    const { data } = await CardService.getCard({ token, card_id });
    return data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const fetchCardTransactions = createAsyncThunk('cards/fetchCardTransactions', async ({ token, card_id }, { rejectWithValue }) => {
  try {
    const { data } = await CardService.getCardTransactions({ token, card_id });
    return data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const createNewCard = createAsyncThunk('cards/createNewCard', async ({
  token, currency, amount, label, skin, pin,
}, { rejectWithValue }) => {
  try {
    const { data } = await CardService.createNewCard({
      token, currency, amount, label, skin, pin,
    });
    return data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const fundCard = createAsyncThunk('cards/fundCard', async ({
  token, card_id, amount, pin,
}, { rejectWithValue }) => {
  try {
    const { data } = await CardService.fundCard({
      token, card_id, amount, pin,
    });
    return data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const withdrawFromCard = createAsyncThunk('cards/withdrawFromCard', async ({
  token, card_id, amount, pin,
}, { rejectWithValue }) => {
  try {
    const { data } = await CardService.withdrawFromCard({
      token, card_id, amount, pin,
    });
    return data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    resetACard: (state) => {
      state.aCard = initialState.aCard;
      state.cardTransactions = initialState.cardTransactions;
    },
    resetFundCreate: (state) => {
      state.fundCreateCard = initialState.fundCreateCard;
    },
  },
  extraReducers: (builder) => {
    // reducer for fetchAllCards
    builder.addCase(fetchAllCards.pending, (state) => {
      state.cards.status = states.FETCHING;
    });
    builder.addCase(fetchAllCards.fulfilled, (state, action) => {
      state.cards.data = sortDecendingDate(action.payload.data, 'created_at');
      state.cards.config = action.payload.config;
      state.cards.status = states.FETCHED;
    });
    builder.addCase(fetchAllCards.rejected, (state) => {
      state.cards.status = states.ERROR;
    });

    // reducer for fetchCard
    builder.addCase(fetchCard.pending, (state) => {
      state.aCard.status = states.FETCHING;
    });
    builder.addCase(fetchCard.fulfilled, (state, action) => {
      state.aCard.data = action.payload;
      state.aCard.status = states.FETCHED;
    });
    builder.addCase(fetchCard.rejected, (state) => {
      state.aCard.status = states.ERROR;
    });

    // reducer for fetchCardTransactions
    builder.addCase(fetchCardTransactions.pending, (state) => {
      state.cardTransactions.status = states.FETCHING;
    });
    builder.addCase(fetchCardTransactions.fulfilled, (state, action) => {
      state.cardTransactions.data = sortDecendingDate(action.payload, 'trx_date');
      state.cardTransactions.data = state.cardTransactions.data.map((d) => ({
        id: d.tx_id,
        currency: d.currency,
        name: d.narration,
        status: d.direction === 'credit' ? 'increase' : 'decrease',
        date: d.trx_date,
        amount: d.amount,
      }));
      state.cardTransactions.status = states.FETCHED;
    });
    builder.addCase(fetchCardTransactions.rejected, (state) => {
      state.cardTransactions.status = states.ERROR;
    });

    // reducer for createNewCard
    builder.addCase(createNewCard.pending, (state) => {
      state.fundCreateCard.status = states.FETCHING;
    });
    builder.addCase(createNewCard.fulfilled, (state) => {
      // state.cards.data = action.payload;
      // state.cards.config = action.payload.config;
      state.fundCreateCard.status = states.FETCHED;
    });
    builder.addCase(createNewCard.rejected, (state) => {
      state.fundCreateCard.status = states.ERROR;
    });

    // reducer for fundCard
    builder.addCase(fundCard.pending, (state) => {
      state.fundCreateCard.status = states.FETCHING;
    });
    builder.addCase(fundCard.fulfilled, (state) => {
      // state.aCard.data = action.payload;
      state.fundCreateCard.status = states.FETCHED;
    });
    builder.addCase(fundCard.rejected, (state) => {
      state.fundCreateCard.status = states.ERROR;
    });

    // reducer for withdrawFromCard
    builder.addCase(withdrawFromCard.pending, (state) => {
      state.aCard.status = states.FETCHING;
    });
    builder.addCase(withdrawFromCard.fulfilled, (state) => {
      // state.aCard.data = action.payload;
      state.aCard.status = states.FETCHED;
    });
    builder.addCase(withdrawFromCard.rejected, (state) => {
      state.aCard.status = states.ERROR;
    });
  },
});

export const selectACard = (state) => state.card.aCard;
export const selectAllCards = (state) => state.card.cards;
export const selectCardConfig = (state) => state.card.cards.config;
export const selectFundCreateCard = (state) => state.card.fundCreateCard;
export const selectCardTransactions = (state) => state.card.cardTransactions;

export default cardsSlice.reducer;
export const { resetACard, resetFundCreate } = cardsSlice.actions;
