export const BASE = 'base';
export const FETCHING = 'fetching';
export const ERROR = 'error';
export const FETCHED = 'fetched';
export const POPULATING = 'populating';
export const POPULATED = 'populated';
export const SUCCESSFUL = 'successful';

const ComponentStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

export default ComponentStates;
