/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../utils/simpleActions';
import useCurrency from '../../../hooks/useCurrency';
import './styles.css';

function AvailableBalanceBadge({
  value, fillWithBalance = null, text = 'Available Balance', __css = {},
}) {
  const { t } = useTranslation();
  const { stringCurrencyBalance } = useCurrency();
  return (
    <div
      className="available-balance-badge"
      onClick={() => fillWithBalance && fillWithBalance()}
      style={{
        cursor: `${fillWithBalance ? 'pointer' : 'initial'}`,
        ...__css,
      }}
    >
      {/* balance box start */}
      <div className="balance-box container-color2">
        <p className="text secondary-white-black-color3">
          {!text ? t('dsh.availableBalance') : text}:{' '}
        </p>
        <p className="value white-black-color">
          {/* {!value ? '...' : formatNumber(value, 6)} */}
          {/* {!value ? '...' : value} */}
          {!value ? stringCurrencyBalance : value}
        </p>
      </div>
      {/* balance box end */}
    </div>
  );
}

export default AvailableBalanceBadge;
