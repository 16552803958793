import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class NotificationService {
  // Get user botification
  static async getUserNotifications() {
    const response = await post({
      url: apiRoutes.getNotifications,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    /**
     * [[ INSTRUCTION PERTAINING TO THE IF BLOCK STARTING ON THE NEXT LINE ]]
     * I'm checking if response.response === false because a failed request will return a
     * response object that has a property response which has a value of false. This code
     * below is therefore, strictly dependent on the structure of the data being returned
     * from the server and if it changes at some point, we'll need to update this code
     */
    if (response.response === false) {
      throw new Error('failed to get user notification');
    }
    return response;
  }

  // Marl all as read
  static async markAllAsRead() {
    const response = await post({
      url: apiRoutes.markAllAsRead,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    /**
     * [[ INSTRUCTION PERTAINING TO THE IF BLOCK STARTING ON THE NEXT LINE ]]
     * I'm checking if response.response === false because a failed request will return a
     * response object that has a property response which has a value of false. This code
     * below is therefore, strictly dependent on the structure of the data being returned
     * from the server and if it changes at some point, we'll need to update this code
     */
    if (response.response === false) {
      throw new Error('failed to get user notification');
    }
    return response;
  }
}
