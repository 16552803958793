/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import uuid from 'react-uuid';
import * as toastTypes from '../../utils/toast-types';
import '../../styles/dashboard/Copy.css';
import { capitalizeFirst } from '../../utils/simpleActions';
import { useIcon } from '../../hooks/useIcon';
// eslint-disable-next-line no-unused-vars
import { removeToastFromQueue } from '../../slices/authSlice';

function Copy() {
  const { toast } = useSelector((state) => state.auth);

  return (
    (toast?.toastArrays && toast?.toastArrays.length > 0) && (
      <div className="fixed-container">
        {toast.toastArrays.map((d) => (
          <Fragment key={uuid()}>
            <CopyItem toast={d} />
          </Fragment>
        ))}
      </div>
    )
  );
}

function CopyItem({ toast }) {
  const dispatch = useDispatch();
  const [timerSet, setTimer] = useState(false);

  const {
    toastGood,
    toastWarning,
    toastDanger,
  } = useIcon();

  function closeToast() {
    dispatch(removeToastFromQueue(toast._id));
  }

  useEffect(() => {
    if (!timerSet) {
      setTimer(true);
      setTimeout(() => {
        closeToast();
      }, 5000);
    }
  }, []);

  return (
    <div className={`copy-container-component ${toast.type === toastTypes.SUCCESS && 'success-bg'} ${toast.type === toastTypes.WARNING && 'warning-bg'} ${toast.type === toastTypes.ERROR && 'danger-bg'}`}>
      <div className="copy">
        <span className="check">
          {/* <FaCheck /> */}
          {toast.type === toastTypes.ERROR && (
            <img src={toastDanger} alt="t" />
          )}
          {toast.type === toastTypes.WARNING && (
            <img src={toastWarning} alt="t" />
          )}
          {toast.type === toastTypes.SUCCESS && (
            <img src={toastGood} alt="t" />
          )}
        </span>
        <div className="copy-info">
          <h3 className="info">{capitalizeFirst(toast.name)}</h3>
          <p className="text">{capitalizeFirst(toast.desc)}</p>
        </div>
        <span
          role="presentation"
          className="cancel-copied"
          onClick={closeToast}
        >
          <FaTimes />
        </span>
      </div>
    </div>
  );
}

export default Copy;
