/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

/**
 *
 * @param {string} name Tooltip value
 * @param {string | JSX.Element} image Image to be used
 * @param {Function} action Action to trigger on click
 * @param {string} className Image Figure classname
 * @param {boolean} isElement Tell TooltipIcon image is JSX
 * @param {boolean} additionalProps Additional props for Element if isElement is true
 * @returns
 */
export default function TooltipIcon({
  name, image, action = () => { }, className = '',
  isElement = false, additionalProps = {},
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <figure className={`img-box profile-icon-box rq-global-tooltip ${className}`}>
      {!isElement ? (
        <img
          src={image}
          onClick={() => {
            action();
          }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          alt=""
          className="img icon-dim-color language-icon"
        />
      )
        : image({
          onClick: () => {
            action();
          },
          // className: 'img icon-dim-color language-icon',
          onMouseEnter: () => setShowTooltip(true),
          onMouseLeave: () => setShowTooltip(false),
          ...additionalProps,
        })}

      {name && showTooltip && (
        <span className="icon-tooltip profile-color">{name}</span>
      )}
    </figure>
  );
}
