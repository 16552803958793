/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import useCurrency from '../../../hooks/useCurrency';
import { useIcon } from '../../../hooks/useIcon';
import useThunkEffectCalls from '../../../hooks/useThunkEffectCalls';
import ImagePlaceholder from '../ImagePlaceholder';
import './styles.css';
import { getCurrencyIcon } from '../../../utils/getStaticAssets';

function SelectCurrency({
  name = 'inputcurrency',
  label = 'Label',
  showCountryName = false,
  onChange = () => { },
  parentData = null,
  shouldSelectChange,
  initialDefaultCurrency,
  returnAttemptedChange = false,
  returnAttemptedChangeFunc,

}) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const { kYCCurrency, setSelectedCurrency, currencies } = useCurrency();
  const [selected, setSelected] = React.useState(
    parentData ?
      parentData[0].code
      : initialDefaultCurrency || kYCCurrency
  );
  useThunkEffectCalls(() => {
    const initCurrency = parentData ?
      parentData[0].code
      : initialDefaultCurrency || kYCCurrency;
    setSelected(initCurrency);
    if (parentData) {
      onChange(parentData[0].code);
    } else {
      onChange(showCountryName ? {
        country: currencies.find((currency) => currency.c === initCurrency)?.countryCode,
        currency: initCurrency
      } : initCurrency);
    }
  });
  const closeSelectoptions = () => {
    setOptionsOpen(false);
  };

  const onSelectChange = (vlu) => {
    if (returnAttemptedChange) returnAttemptedChangeFunc(vlu);
    const selectChange = () => {
      setSelectedCurrency(vlu);
      setSelected(vlu);
      if (parentData) {
        onChange(vlu);
      } else {
        onChange(showCountryName ? {
          country: currencies.find((currency) => currency.c === vlu)?.countryCode,
          currency: vlu
        } : vlu);
      }
    };
    closeSelectoptions();

    if (shouldSelectChange) {
      if (!shouldSelectChange(vlu)) {
        return;
      }
      selectChange();
    } else {
      selectChange();
    }
  };

  const { filledChevDown } = useIcon();

  return (
    <div className="currency-select-group">
      <label
        htmlFor={name}
        className="input-group-label secondary-white-black-color"
      >
        {label}
      </label>
      <div
        className="c-select-group"
        onClick={() => setOptionsOpen(!optionsOpen)}
      >
        <div className="icon-select-container">
          <ImagePlaceholder
            figureClassName="img-box flag"
            imgClassName="img"
            src={parentData
              ? parentData.find((d) => d.code === selected).icon : getCurrencyIcon(selected)}
          />
          {/* <figure className="img-box flag">
            <img
              src={parentData
                ? parentData.find((d) => d.code === selected).icon : flagIcons[selected]}
              className="img"
              alt=""
            />
          </figure> */}
          <figure className="img-box chev-icon">
            <img src={filledChevDown} className="img" alt="" />
          </figure>
        </div>
        <div className="selected-display">{parentData ? parentData.find((d) => d.code === selected).name :
          currencies.find((currency) => currency.c === selected)?.[showCountryName ? 'country' : 'n']}
        </div>
      </div>
      {optionsOpen && (
        <ul className="currency-options">
          <OutsideClickHandler
            onOutsideClick={() => setOptionsOpen(!optionsOpen)}
          >
            {(parentData || currencies).map((child) => (
              <li
                key={parentData ? child.code : child.c}
                onClick={() => onSelectChange(parentData ? child.code : child.c)}
              >
                {/* <figure className="img-box flag">
                  <img src={parentData ? child.icon : flagIcons[child]} className="img" alt="" />
                </figure> */}
                <ImagePlaceholder
                  figureClassName="img-box flag"
                  imgClassName="img"
                  src={parentData ? child.icon : getCurrencyIcon(child?.c)}
                />
                <div className="option-text">
                  <div className="name">{parentData ? child.name : child?.[showCountryName ? 'country' : 'n']}</div>
                  <div className="currency">{parentData ? child.code : showCountryName ? child?.countryCode : child.c}</div>
                </div>
              </li>
            ))}
          </OutsideClickHandler>
        </ul>
      )}
    </div>
  );
}

export default SelectCurrency;
