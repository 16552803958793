/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as states from '../states/ComponentStates';
import WalletService from '../services/walletService';

const initialState = {
  status: states.BASE,
  walletData: {},
  recentlyFetchedWallet: {},
  walletFetched: true,
  currentNetwork: {},
  currentNetworkIndex: 0,
  confirmTransactionDetails: {
    title: '',
    amount: 0,
    currency: '',
    // to be an array of objects with name, value properties
    // for fields to display
    summary: null,
  },
  userTransferData: {},

  // Coin Send Form Data
  sendForm: {
    amount: '',
    wallet: '',
    network: '',
  },
};

export const fetchUserWallet = createAsyncThunk(
  'wallet/fetch',
  async (data, { rejectWithValue }) => {
    try {
      const response = await WalletService.getUserWallets(data);
      /**
       * [[ IMPORTANT ]]
       * It is recommended to return only the part of the response that you need from this thunk
       * If you return the whole response object from this function, you will have to access the
       * data you need inside
       * your builder.addCase() callback and if you run into any errors there, you will have to
       * handle the exceptions
       * there. It's better to catch and handle all exceptions here. That's why we need to access
       * the data here and
       * return it.
       */
      return response.response.data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchExtraWallet = createAsyncThunk(
  'wallet/fetch-extra',
  async (data, { rejectWithValue }) => {
    try {
      const response = await WalletService.getUserWallets(data);

      return response.response.data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const createNewWallet = createAsyncThunk(
  'wallet/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await WalletService.generateWallet(data);

      return response.response.data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

// export const getAssetPrice = createAsyncThunk('wallet/getPrice',
// async (data, { rejectWithValue }) => {
//   try {
//     const {token, network} = data
//     const response = await WalletService.generateWallet({token, network});

//     return response.response.data.data;
//   } catch (error) {
//     return rejectWithValue(error.toString());
//   }
// });

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      if (
        !(
          action.payload === states.BASE
          || action.payload === states.FETCHING
          || action.payload === states.ERROR
          || action.payload === states.FETCHED
          || action.payload === states.POPULATING
          || action.payload === states.POPULATED
        )
      ) {
        return;
      }
      state.status = action.payload;
    },
    updateUserWallet: (state, action) => {
      state.walletData = action.payload;
    },
    updateCurrentNetwork: (state, action) => {
      state.currentNetwork = action.payload.network;
      state.currentNetworkIndex = action.payload.index; // To keep track of the selected network
    },
    clearCreatedWallet: (state) => {
      state.recentlyFetchedWallet = {};
    },
    setConfirmTransactionDetails: (state, action) => {
      if (action.payload) {
        state.confirmTransactionDetails.amount = action.payload.amount;
        state.confirmTransactionDetails.title = action.payload.title || 'Transfer Amount';
        state.confirmTransactionDetails.summary = action.payload.summary;
        state.confirmTransactionDetails.currency = action.payload.currency;
      } else {
        state.confirmTransactionDetails = initialState.confirmTransactionDetails;
      }
    },
    setUserTransactionData: (state, action) => {
      state.userTransferData = action.payload;
    },
    appendToUserTransferData: (state, action) => {
      const { name, value } = action.payload;
      state.userTransferData = {
        ...state.userTransferData,
        [name]: value,
      };
    },
    updateSendForm: (state, action) => {
      // console.log('UPdated');
      state.sendForm = action.payload;
    },
    clearSendForm: (state) => {
      state.sendForm = {};
    },
  },
  extraReducers: (builder) => {
    // extraReducers for fetchUserWallet
    builder.addCase(fetchUserWallet.pending, (state) => {
      state.walletFetched = false;
      state.status = states.FETCHING;
    });
    builder.addCase(fetchUserWallet.fulfilled, (state, action) => {
      state.walletData = action.payload;
      state.currentNetwork = action.payload?.receive?.length ? action.payload?.receive?.[0] : {};
      state.status = states.FETCHED;
    });
    builder.addCase(fetchUserWallet.rejected, (state) => {
      state.walletFetched = false;
      state.status = states.ERROR;
    });

    // extraReducers for fetchExtraWallet
    builder.addCase(fetchExtraWallet.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(fetchExtraWallet.fulfilled, (state, action) => {
      state.walletData = action.payload;
      state.walletFetched = true;
      const thisNetwork = action.payload.receive.find(
        (i) => i.network === state.currentNetwork.network,
      ); // Makes sure the selected is still selected after the re-fetch
      state.currentNetwork = thisNetwork;
      state.status = states.FETCHED;
    });
    builder.addCase(fetchExtraWallet.rejected, (state) => {
      state.status = states.ERROR;
    });
    // extraReducers for createNewWallet
    builder.addCase(createNewWallet.pending, (state) => {
      state.walletFetched = false;
      state.status = states.FETCHING;
    });
    builder.addCase(createNewWallet.fulfilled, (state, action) => {
      state.recentlyFetchedWallet = { address: action.payload };
      state.status = states.FETCHED;
    });
    builder.addCase(createNewWallet.rejected, (state) => {
      state.status = states.ERROR;
    });
  },
});

export const selectConfirmTransactionDetails = (state) => state.wallet.confirmTransactionDetails;

export default walletSlice.reducer;
export const {
  updateStatus,
  updateUserWallet,
  updateCurrentNetwork,
  clearCreatedWallet,
  setConfirmTransactionDetails,
  setUserTransactionData,
  appendToUserTransferData,
  updateSendForm,
  clearSendForm,
} = walletSlice.actions;
