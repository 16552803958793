import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';

export default class PaymentLinkService {
  static async createPaymentLink({
    title,
    token,
    description,
    amount,
    currency,
    // img,
  }) {
    const { response } = await post({
      url: apiRoutes.createPaymentLink,
      data: {
        title,
        token,
        description,
        amount,
        currency,
        // img,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }

    return response;
  }

  static async getPaymentLinks(token) {
    const { response } = await post({
      url: apiRoutes.getAllPaymentLink,
      data: {
        token,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }

    return response;
  }

  static async getPaymentOrders({ token, ref }) {
    const { response } = await post({
      url: apiRoutes.getPaymentOrders,
      data: { token, ref },
    });
    if (response === false) {
      throw new Error('some error');
    }

    return response;
  }

  static async disablePaymentLink({ token, ref }) {
    const { response } = await post({
      url: apiRoutes.disablePaymentLink,
      data: {
        token,
        ref,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }

    return response;
  }
}
