/* eslint-disable react/prop-types */
import React from 'react';
import './ErrorModal.css';

import RoqqDialog from '../../RoqqDialog/RoqqDialog';
import RoqqDiamondTile from '../../RoqqDiamondTile/RoqqDiamondTile';
import { useIcon } from '../../../../../../../hooks/useIcon';

function ErrorModal({ show, onClose }) {
  const { errorIcon } = useIcon();
  return (
    <RoqqDialog
      show={show}
      onClose={onClose}
      headerText="Oh No!"
      buttonType="none"
    >
      <RoqqDiamondTile
        icon={errorIcon}
        text="Error message"
      />
    </RoqqDialog>
  );
}

export default ErrorModal;
