/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Fragment, useContext } from 'react';

import './styles/SuccessApplication.css';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SuccessIllustration from '../../../assets/ribs/success-1.gif';
import ButtonReuse from '../../fragments/Button';
import BagItem from './BagItem';
import { selectRecommendedRIBs } from '../../../features/ribs/global/ribsGlobalSlice';
import { RIBsDrawerCloserContext } from '../../../context/RIBsApplicationNavigationContext';

function SuccessApplication({ hasSuggestions = true }) {
  const history = useNavigate();
  const { RIBs: bags } = useSelector(selectRecommendedRIBs);

  const closeWithdrawalDrawer = useContext(RIBsDrawerCloserContext);

  function viewBags() {
    if (closeWithdrawalDrawer) {
      closeWithdrawalDrawer();
    }
    history('/dashboard-earn-ribs-with');
  }

  return (
    <div className="drawer-body">
      <div className="ribs-main-success-body">
        <img
          src={SuccessIllustration}
          alt=""
          style={{
            width: '262px',
            height: 'auto',
            marginTop: '-118px',
            marginBottom: '-104px',
          }}
        />
        <p className="summary-text" style={{ marginTop: '3.2rem' }}>
          Quick money Invested successfully
        </p>
        <ButtonReuse onClick={() => viewBags()} btnStyle="btn-reuse" text="View Investments" style={{ width: '100%' }} />
      </div>

      {hasSuggestions && (
      <div className="ribs-other-bags-section">
        <p className="section-title">
          What others are investing.
        </p>
        <p className="sub-section-title secondary-white-black-color3">
          People who invested in quick money also invested in these.
        </p>

        <div className="ribs-questions-container">
          <div className="ribs-bags-container">
            {bags.map((d, idx) => (
              <Fragment key={uuid()}>
                <BagItem
                  item={d}
              // toggleActiveIndex={() => toggleAccordion(idx)}
                  index={idx}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

export default SuccessApplication;
