/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import uuid from 'react-uuid';

import '../../../../styles/auth/Register.css';
import { RIBsQuestionNavigationHandlerContext, RIBsQuestionNavigationTreeContext } from './RIBsQuestionStackContext';
import { RIBsApplicationNavigationHandlerContext } from '../../../../context/RIBsApplicationNavigationContext';

function RIBsQuestions() {
  const dispatchRIBsStack = useContext(RIBsApplicationNavigationHandlerContext);
  const dispatchRIBsQuestionStack = useContext(RIBsQuestionNavigationHandlerContext);
  const ribsQuestionState = useContext(RIBsQuestionNavigationTreeContext);

  return (
    <div className="drawer-body" style={{ height: '100%' }}>
      {/*  */}
      <div className="register-wrap" style={{ display: 'flex', flexDirection: 'column' }}>
        <p className="current-step-text">
          {`Step ${ribsQuestionState.history.length}`}
          <span className="total-steps-text">{` / ${ribsQuestionState.stages}`}</span>
        </p>
        <div className="step-progress-indicator">
          {Array(ribsQuestionState.stages).fill(null).map((_, index) => (
            <div
              key={uuid()}
              className={`step-progress-bar ${index <= (ribsQuestionState.history.length - 1) && 'active'}`}
              onClick={() => {
                // if (index < currentScreenIndex) setCurrentScreenIndex(index);
              }}
            />
          ))}
        </div>

        {
          ribsQuestionState && ribsQuestionState.getCurrentScreen()
        }
      </div>
    </div>
  );
}

export default RIBsQuestions;
