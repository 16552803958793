import { useState, useEffect } from 'react';

const useNavigator = () => {
  const [isOnline, setIsOnline] = useState(true);
  function setOnline() {
    setIsOnline(true);
  }
  function setOffline() {
    setIsOnline(false);
  }
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  return { isOnline, navigator };
};

export default useNavigator;
