/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useCallback, useContext } from 'react';

import '../../flows/RIBsSetupFlow/styles/index.css';
import { useDispatch } from 'react-redux';
import DrawerLayout from '../Drawer';
import { RIBsApplicationNavigationHandlerContext, RIBsApplicationNavigationTreeContext, RIBsDrawerCloserContext } from '../../../context/RIBsApplicationNavigationContext';
import { RIBsQuestionNavigationHandlerContext, RIBsQuestionNavigationTreeContext } from '../../flows/RIBsSetupFlow/RIBsQuestions/RIBsQuestionStackContext';
import { resetAll } from '../../../features/ribs/application/ribsApplicationSlice';

function RIBsApplyDrawer({ isVisible, onClose }) {
  const ribsNavStack = useContext(RIBsApplicationNavigationTreeContext);
  const ribsQuestionNavStack = useContext(RIBsQuestionNavigationTreeContext);
  const dispatch = useDispatch();
  const dispatchNavAction = useContext(RIBsApplicationNavigationHandlerContext);
  const dispatchRIBsQuestionStack = useContext(RIBsQuestionNavigationHandlerContext);

  const closeDrawer = useCallback(() => {
    onClose();
    dispatch(resetAll());
    dispatchNavAction({ type: 'reset' });
    dispatchRIBsQuestionStack({ type: 'reset' });
  }, [onClose, dispatch, dispatchNavAction, dispatchRIBsQuestionStack]);

  function goBack() {
    if (ribsQuestionNavStack.history.length === 1) {
      dispatchNavAction({ type: 'pop' });
    } else {
      dispatchRIBsQuestionStack({ type: 'pop' });
    }
  }

  return (
    <DrawerLayout
      title={ribsNavStack?.title || 'RIBs'}
      isVisible={isVisible}
      onClose={() => closeDrawer()}
      goBack={ribsNavStack && ribsNavStack.history.length > 1}
      goBackFunc={goBack}
    >
      <RIBsDrawerCloserContext.Provider value={closeDrawer}>
        <div className="withdraw-routes-container body">
          {
          ribsNavStack && ribsNavStack.getCurrentScreen()
        }
        </div>
      </RIBsDrawerCloserContext.Provider>
    </DrawerLayout>
  );
}

export default RIBsApplyDrawer;
