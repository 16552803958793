import React, { useContext } from 'react';

// import infoIllustration from '../../../assets/ribs/init-dummy.png';
import './styles/initStage.css';
import ButtonReuse from '../../fragments/Button';
import { RIBsApplicationNavigationHandlerContext } from '../../../context/RIBsApplicationNavigationContext';
import RIBsQuestions from './RIBsQuestions';
import { useIcon } from '../../../hooks/useIcon';

function InitStage() {
  const {
    infoManIllustrationFaded, initInfoBg1, initInfoBg2, dummyAvatar,
  } = useIcon();
  const dispatchNavAction = useContext(RIBsApplicationNavigationHandlerContext);

  function goToQuestions() {
    dispatchNavAction({
      type: 'push',
      payload: <RIBsQuestions />,
    });
  }

  return (
    <div className="drawer-body">
      <div className="ribs_init-info-section">
        <div className="info-1">
          <div className="user-img" style={{ marginTop: '1rem' }}>
            <img src={dummyAvatar} alt="" />
          </div>
          <div className="bg-section">
            <div className="txt-container">
              <p className="bold">
                Hello Jaykay, I am RIBs
              </p>
              <span>
                I will help you make your first crypto investment decision...
              </span>
            </div>
            <img src={initInfoBg1} alt="" />
          </div>
        </div>
        <div className="info-1" style={{ marginTop: '1.2rem' }}>
          <div className="user-img">
            <img src={dummyAvatar} alt="" />
          </div>
          <div className="bg-section">
            <div className="txt-container small">
              <span>
                Just answer some few questions and  your bag will be up and running
              </span>
            </div>
            <img src={initInfoBg2} alt="" />
          </div>
        </div>
        {/* <img src={infoIllustration} alt="" /> */}
      </div>

      <div className="ribs_init-proceed-section">
        <img src={infoManIllustrationFaded} alt="" />
        <ButtonReuse
          onClick={() => goToQuestions()}
          btnStyle="btn-reuse"
          text="Let’s Go"
        />
      </div>
    </div>
  );
}

export default InitStage;
