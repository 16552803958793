/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import './styles/index.css';
import uuid from 'react-uuid';
import { useIcon } from '../../../hooks/useIcon';

function SubFlow({
  title, children, isVisible, goBack, goBackFunc, onClose,
  maxWidth, showBackArrow = true,
}) {
  const { breadReturn } = useIcon();

  return (
    <>
      {isVisible && (
        <div className="subflow-master default-background container-fade-in-animation" id="subflow-master">
          <div className="subflow-container" style={{ maxWidth }}>
            {onClose && (
              <div className="breadcrumbs default-background">
                {showBackArrow
                  && (goBack ? (
                  <figure className="container-color" onClick={() => goBackFunc()}>
                    <img src={breadReturn} alt="return" />
                  </figure>
                  ) : (
                  <figure className="container-color" onClick={() => onClose()}>
                    <img src={breadReturn} alt="return" />
                  </figure>
                  ))}
                <span className="parent text">
                  {title}
                </span>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
}

SubFlow.TitleBreadcrumbs = function SubFlowTitleBreadcrumbs({
  onBackPress = () => { }, crumbs = [], initialBreadcrumb = 'Dashboard',
}) {
  // crumbs = [{ title: '', link: '' }]
  const { breadReturn, chevronRight } = useIcon();

  return (
    <div className="breadcrumbs default-background">
      <figure className="container-color" onClick={() => onBackPress()}>
        <img src={breadReturn} alt="return" />
      </figure>

      <span className="parent text">
        {initialBreadcrumb}
      </span>

      <img src={chevronRight} alt="" />

      {crumbs.map((d, index) => (
        <Fragment key={uuid()}>
          <span className="blue-color child">
            {d.title}
          </span>

          {(index < crumbs.length - 1) && (
            <img src={chevronRight} alt="" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

SubFlow.BodyBGWrapper = function SubFlowBodyBGWrapper({ children, noPadding = false }) {
  return (
    <div className={`background-wrapper ${noPadding && 'no-padding'}`}>{children}</div>
  );
};

export default SubFlow;
