/* eslint-disable import/prefer-default-export */
export default class ClipboardService {
  static async writeToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      return text;
    } catch (error) {
      return error.toString();
    }
  }
}
