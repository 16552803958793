/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useIcon } from '../../../hooks/useIcon';
import '../../../styles/dashboard/layout/LanguageDropdown.css';

const TYPE = {
  local: 'LOCAL',
  international: 'INTERNATIONAL',
};

export default function LanguageDropdown({ visible, onClose }) {
  const [type, setType] = useState(TYPE.local);
  const {
    englishIcon,
    frenchIcon,
    italianIcon,
    swedishIcon,
    spanishIcon,
    germanIcon,
    danishIcon,
    englishLocal,
    igbo,
    yoruba,
    hausa,
  } = useIcon();
  const { i18n, t } = useTranslation();

  const languages =
    type === TYPE.local
      ? [
          { name: 'English', icon: englishLocal, abr: 'en' },
          { name: 'Yoruba', icon: yoruba, abr: 'yo' },
          { name: 'Igbo', icon: igbo, abr: 'ig' },
          { name: 'Hausa', icon: hausa, abr: 'ha' },
        ]
      : [
          { name: 'English', icon: englishIcon, abr: 'en' },
          { name: 'French', icon: frenchIcon, abr: 'fr' },
          { name: 'Swedish', icon: swedishIcon, abr: 'sv' },
          { name: 'Danish', icon: danishIcon, abr: 'da' },
          { name: 'Spanish', icon: spanishIcon, abr: 'es' },
          { name: 'Italian', icon: italianIcon, abr: 'it' },
          { name: 'German', icon: germanIcon, abr: 'de' },
        ];

  const changeLanguage = (param) => {
    i18n.changeLanguage(param);
    onClose();
  };

  return (
    <OutsideClickHandler onOutsideClick={() => onClose(i18n.language)}>
      {visible && (
        <div className="language-dropdown default-drop-arrow default-background border-this container-fade-in-animation">
          <div className="language-nav secondary-white-black-color">
            <div
              className={`${type === TYPE.local && 'active'}`}
              onClick={() => setType(TYPE.local)}
            >
              {t('dsh.languageModal.local')}
            </div>
            <div
              className={`${type === TYPE.international && 'active'}`}
              onClick={() => setType(TYPE.international)}
            >
              {t('dsh.languageModal.international')}
            </div>
          </div>
          <div className="language-options-wrapper">
            <h2 className="title">{t('dsh.languageModal.selectLanguage')}</h2>
            <div className="language-select">
              {languages.map((item) => (
                <div
                  className={`language-item item-hover ${
                    i18n.language === item.abr && 'language-active'
                  }`}
                  onClick={() => changeLanguage(item.abr)}
                  key={item.abr}
                >
                  <img src={item.icon} alt="" />
                  <p className="white-black-color">{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </OutsideClickHandler>
  );
}
