/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const LoadingStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  ERROR: 'error',
};

const initialState = {
  user: {
    myRIBs: [
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
    ],
  },
  recommended: {
    RIBs: [
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Bag name goes here',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Bag name goes here',
      },
    ],
  },

  rinsApplicationDrawerOpen: false,

  inspectBagURL: '/dashboard-earn-ribs-inspect',
};

const ribsGlobalSlice = createSlice({
  name: 'ribsGlobal',
  initialState,
  reducers: {
  },
});

export function returnRIBsInspectBagURL(slug) {
  return initialState.inspectBagURL + slug;
}

export const selectUserRIBs = (state) => state.ribsGlobal.user;
export const selectInspectBagURL = (state) => state.ribsGlobal.inspectBagURL;
export const selectRecommendedRIBs = (state) => state.ribsGlobal.recommended;

// export const {
// } = ribsGlobalSlice.actions;
export default ribsGlobalSlice.reducer;
