/* eslint-disable import/prefer-default-export */

import React from 'react';
import roqqnrollRouteNames from './roqqnrollRouteNames';
import RoqqNRollHome from '../../../features/gamefi/roqqnroll/components/RoqqNRollHome/RoqqNRollHome';

const RoqqnRollIndex = React.lazy(() => import('../../../features/gamefi/roqqnroll'));
const RoqqnRollBreak = React.lazy(() => import('../../../features/gamefi/roqqnroll/components/BreakTime/BreakTime'));

export const Dashboard_roqqnroll_route_group = [
  { path: roqqnrollRouteNames.roqqnrollIndex, component: <RoqqnRollIndex /> },
  { path: roqqnrollRouteNames.roqqnrollBreak, component: <RoqqnRollBreak /> },
  { path: roqqnrollRouteNames.testPage, component: <RoqqNRollHome /> },
];
