/* eslint-disable react/prop-types */
import React from 'react';
import './ClaimSuccessfulModal.css';

import RoqqDialog from '../../RoqqDialog/RoqqDialog';
import RoqqDiamondTile from '../../RoqqDiamondTile/RoqqDiamondTile';
import { useIcon } from '../../../../../../../hooks/useIcon';

function ClaimSuccessfulModal({ show, onClose }) {
  const { roqqClaimCoin } = useIcon();
  return (
    <RoqqDialog
      show={show}
      onClose={onClose}
      buttonType="single"
      headerText="Congratulations!"
      neutralBtnText="Go to Dashboard"
    >
      <RoqqDiamondTile icon={roqqClaimCoin} text="You have successfully claimed your prize. Your lottery balance has been updated." />
    </RoqqDialog>
  );
}

export default ClaimSuccessfulModal;
