/* eslint-disable no-array-constructor */
import { useEffect, useState } from 'react';

function useDrawerNavigationStack({
  title, initialItem, otherData = {}, _end = false,
}) {
  const [data, setData] = useState({
    title,
    history: initialItem ? [initialItem] : [],
    end: _end,
    ...otherData,
  });

  const [currentScreen, setCurrentScreen] = useState(null);

  useEffect(() => {
    let _item = null;
    if (data.history.length > 0) {
      _item = data.history[data.history.length - 1];
    }
    setCurrentScreen(_item);
  }, [data]);

  function updateData({ name, value }) {
    setData((prev) => ({ ...prev, [name]: value }));
  }

  function push(route) {
    const _history = [...data.history];
    _history.push(route);

    updateData({ name: 'history', value: [...(new Set(_history))] });
  }

  function pop() {
    const _history = [...data.history];
    _history.pop();

    updateData({ name: 'history', value: _history });
  }

  function reset() {
    updateData({ name: 'history', value: [initialItem] });
    updateData({ name: 'end', value: false });
  }

  function getCurrentScreen() {
    return currentScreen;
  }

  function changeNavState(action) {
    switch (action.type) {
      case 'push':
        push(action.payload);
        break;
      case 'pop':
        pop();
        if (data.end) {
          updateData({ name: 'end', value: false });
        }
        break;
      case 'reset':
        reset();
        break;
      case 'end':
        updateData({ name: 'end', value: true });
        push(action.payload);
        break;
      default:
    }
  }

  return {
    dispatchNavigation: changeNavState,
    state: {
      ...data,
      getCurrentScreen,
    },
  };
}

export default useDrawerNavigationStack;
