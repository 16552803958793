import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

import '../features/ribs/views/flows/buy_bag/components/types/RibsBuyBagStateType';

const ribsService = {
  getAllBags: async () => {
    const { response } = await post({
      url: apiRoutes.getAllBags,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  },

  getUserBags: async () => {
    const { response } = await post({
      url: apiRoutes.getUserBags,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  },

  /**
   *
   * @param {BuyBagProps} data
   * @returns
   */
  buyABag: async (data) => {
    const response = await post({
      url: apiRoutes.buyBag,
      data: {
        ...data,
        token: AuthService._getUserToken(),
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }

    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }

    return response.response;
  },
  async fetchSingleBag(bagRef) {
    const { response, error } = await post({
      url: apiRoutes.getSingleBag,
      data: {
        token: AuthService._getUserToken(),
        bag_reference: bagRef,
      },
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response;
  },
  async dumpBag({ bag_reference, currency }) {
    const { response, error } = await post({
      url: apiRoutes.dumpSingleBag,
      data: {
        token: AuthService._getUserToken(),
        bag_reference,
        currency,
      },
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response;
  },

  async dumpBagPartially(data) {
    const response = await post({
      url: apiRoutes.dumpBagPartially,
      data: {
        token: AuthService._getUserToken(),
        ...data,
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  },

  async dumpBagSingleToken(data) {
    const response = await post({
      url: apiRoutes.dumpBagPartially,
      data: {
        token: AuthService._getUserToken(),
        ...data,
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  },
};

export default ribsService;
