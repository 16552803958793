import React from 'react';

/* eslint-disable import/prefer-default-export */
const DashboardSetting = React.lazy(() => import('../../../pages/dashboard/setting'));
const DashboardSettingsOthers = React.lazy(() => import('../../../pages/dashboard/setting/others'));
const PriceAlert = React.lazy(() => import('../../../pages/dashboard/setting/others/PriceAlert'));
const Refer = React.lazy(() => import('../../../pages/dashboard/setting/others/Refer'));
const DashboardSettingsPreference = React.lazy(() => import('../../../pages/dashboard/setting/preference'));
const DashboardSettingsSecurity = React.lazy(() => import('../../../pages/dashboard/setting/security'));
const PaymentMethods = React.lazy(() => import('../../../pages/dashboard/setting/payment-methods/PaymentMethods'));

export const Dashboard_settings_route_group = [
  { path: '/dashboard-setting', component: <DashboardSetting /> },
  { path: '/dashboard-setting-security', component: <DashboardSettingsSecurity /> },
  { path: '/dashboard-setting-preference', component: <DashboardSettingsPreference /> },
  { path: '/dashboard-setting-others', component: <DashboardSettingsOthers /> },
  { path: '/dashboard-setting-others-price-alert', component: <PriceAlert /> },
  { path: '/dashboard-setting-others-refer', component: <Refer /> },

  { path: '/dashboard-setting-payment-methods', component: <PaymentMethods /> },
];
