/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrimaryCurrency, setPrimaryCurrency } from '../slices/authSlice';
import {
  fetchAllCurrencyRates, fetchPrices, selectAllRates, selectPrices, updatePrimaryCurrency,
} from '../slices/globalSlice';
import { fetchUserBalance, selectUserBalance } from '../slices/UserSlice';
import { formatNumber, getCurrency, removeAlpha } from '../utils/simpleActions';
import useThunkEffectCalls from './useThunkEffectCalls';
import useUser from './useUser';

const useCurrency = () => {
  const dispatch = useDispatch();

  const { token, user } = useUser();

  const {
    status, error, currencies, data,
  } = useSelector(selectAllRates);
  const { data: userBalance } = useSelector(selectUserBalance);
  const { data: cryptoPrices } = useSelector(selectPrices);
  const primaryCurrency = useSelector(getPrimaryCurrency);

  const [selectedCurrency, setSelectedCurrency] = useState(primaryCurrency?.display?.toLowerCase());
  const [balance, setBalance] = useState(0);
  const [stringBalance, setStringBalance] = useState('');
  const kYCCurrency = () => (user?.kyc_currencies?.includes(selectedCurrency)
    ? selectedCurrency
    : user?.kyc_currencies[0]
  );
  function getAllCurrencyRates() {
    if (!currencies || currencies.length === 0) {
      dispatch(fetchAllCurrencyRates()); // check if it exists first
    }
  }

  function getUserBalances() {
    if (!userBalance) {
      dispatch(fetchUserBalance()); // check if it exists first
    }
  }

  function getPrices() {
    if (!cryptoPrices) {
      dispatch(fetchPrices());
    }
  }

  function handleUpdatePrimaryCurrency(symbol) {
    // e.g symbol = 'ngn' or 'usd'
    dispatch(updatePrimaryCurrency({ symbol, token }));
    const rate = data.find((d) => d.symbol === symbol)?.rate || '';
    dispatch(setPrimaryCurrency({
      symbol: getCurrency(symbol).s,
      display: symbol,
      rate,
    }));
  }

  function convertFromUSDTo({ usd_amount, to_symbol }) {
    if (!usd_amount) return usd_amount;

    const _activeCurrenncyRate = currencies.find((d) => d.symbol === to_symbol)?.rate;
    const _usd_amount = parseFloat(removeAlpha(usd_amount) || 0);
    let _usd_equivalent = 0;
    if (_usd_amount) {
      _usd_equivalent = _usd_amount * _activeCurrenncyRate;
    }

    return formatNumber(_usd_equivalent);
  }

  function getCurrencyDisplayIcon(_symbol) {
    return currencies.find((d) => d.symbol === _symbol)?.ep_icon || '';
  }

  useThunkEffectCalls(() => {
    // getAllCurrencyRates();
    // getUserBalances();
    // getPrices();
  });

  function setNewCurrency(value) {
    const _balance = !userBalance?.fiat
      ? 0
      : userBalance?.fiat.find((d) => d.currency === value)?.available_bal;
    setBalance(_balance);
    setStringBalance(`${value?.toUpperCase()} ${formatNumber(_balance)}`);
    setSelectedCurrency(value);
  }

  useEffect(() => {
    setNewCurrency(primaryCurrency?.display?.toLowerCase());
  }, [userBalance, primaryCurrency]);

  return {
    getAllCurrencyRates,
    getUserBalances,
    getPrices,

    balance, // currenct balance of the current currency
    stringCurrencyBalance: stringBalance, // string balance, include currecy balance
    setSelectedCurrency: setNewCurrency, // reuseable temp currency change
    selectedCurrency, // reuseable temp currency
    primaryCurrency: primaryCurrency?.display?.toLowerCase(), // api user currency
    updatePrimaryCurrency: handleUpdatePrimaryCurrency, // api change user currency
    userBalance, // users fiats and cryptos balances
    currencies, // list of all currencies supported
    cryptoPrices, // list of all prices and change of all 75+ tokens
    fiatRates: data, // rates of all currencies
    status, // status of currencies fetcch
    error, // error of currencies fatch
    kYCCurrency: kYCCurrency(), // api user KYC currency,
    convertFromUSDTo,
    getCurrencyDisplayIcon,
  };
};

export default useCurrency;
