import apiRoutes from '../http/apiRoutes';
import Helpers from '../utils/Helpers';
import { get, post } from '../http/https';
import AuthService from './AuthService';

export default class CoreAppService {
  static async getAllCurrencyRates() {
    const { response } = await get({
      url: apiRoutes.getAllCurrencyRate,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getCurrencyRate() {
    const { response } = await get({
      url: apiRoutes.getCurrencyRate,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getPrices() {
    const { response } = await get({
      url: apiRoutes.getPrices,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getTokenHistory(symbol, interval = '1H') {
    const { response } = await get({
      url: `${apiRoutes.getTokenHistory}${symbol}&interval=${interval}`,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async updatePrimaryCurrency({ token, symbol }) {
    const { response } = await post({
      url: apiRoutes.updatePrimaryCurrency,
      data: {
        token,
        currency: symbol,
      },
    });
    return response;
  }

  static async getBalances() {
    const { response } = await post({
      url: apiRoutes.getUserBalance,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    return response.data.data;
  }

  static async getUser() {
    const response = await post({
      url: apiRoutes.getUserInfo,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    return response.response.data;
  }

  static async getAllTokens() {
    const response = await get({
      url: apiRoutes.getAllTokens,
    });
    return Helpers.assignIDs(response.response.data.data);
  }

  static async getAllNews(start, limit, slug, symbol, language) {
    const url = `${apiRoutes.getAllNews}?start=${start}${limit ? `&limit=${limit}` : ''}${symbol ? `&symbol=${symbol}` : ''}${slug ? `&slug=${slug}` : ''}${language ? `&language=${language}` : ''}`;
    const response = await post({
      url,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    /**
     * [[ INSTRUCTION PERTAINING TO THE IF BLOCK STARTING ON THE NEXT LINE ]]
     * I'm checking if response.response === false because a failed request will return a
     * response object that has a property response which has a value of false. This code
     * below is therefore, strictly dependent on the structure of the data being returned
     * from the server and if it changes at some point, we'll need to update this code
     */
    if (response.response === false) {
      throw new Error('failed to get news');
    }
    return response;
  }

  static async getSupportedCountries() {
    const response = await get({
      url: apiRoutes.getSupportedCountries,
    });
    return response.response.data.data;
  }
}
