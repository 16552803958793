/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';

export const PinStates = {
  BASE: 'base',
  LOADING: 'loading',
  CONFIRMED_PIN_MATCH: 'confirmed_pin_match',
  COMPLETED: 'pin_creation_complete',
  ERROR: 'error',
};

const initialState = {
  status: PinStates.BASE,
  initialPin: '',
  secondPin: '',
  password: '',
  showPassword: false,
  passwordFound: false,
  pinCreationComplete: false,
  error: '',
};

export const createPin = createAsyncThunk('pin/create', async ({ pin, password, checkPassword }, { rejectWithValue }) => {
  try {
    const result = await AuthService.setTransactionPin(pin, password, checkPassword);
    if (result.data.message === 'incorrect password for user') {
      return rejectWithValue(result.data.message);
    }
    return result.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

const pinSlice = createSlice({
  name: 'pin',
  initialState,
  reducers: {
    updateInitialPin: (state, action) => {
      state.initialPin = action.payload;
    },
    updateSecondPin: (state, action) => {
      state.secondPin = action.payload;
    },
    confirmPinsMatch: (state, action) => {
      if (state.initialPin === action.payload) {
        state.pinMatchConfirmed = true;
        state.status = PinStates.CONFIRMED_PIN_MATCH;
      } else {
        state.error = 'pins do not match';
      }
    },
    checkUserHasSetTransactionPin: (state) => {
      const existingPassword = AuthService._getPassword();
      if (existingPassword === null) {
        state.passwordFound = false;
      } else {
        state.passwordFound = true;
      }
    },
    toggleShowPassword: (state) => {
      state.showPassword = !state.showPassword;
    },
    updatePassword: (state, action) => {
      state.password = action.payload;
    },
    resetPinSlice(state) {
      state.status = initialState.status;
      state.error = initialState.error;
      state.initialPin = initialState.initialPin;
      state.password = initialState.password;
      state.passwordFound = initialState.passwordFound;
      state.secondPin = initialState.secondPin;
      state.showPassword = initialState.showPassword;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPin.pending, (state) => {
      state.error = '';
      state.status = PinStates.LOADING;
    });
    builder.addCase(createPin.fulfilled, (state, action) => {
      const { status, message } = action.payload;
      if (status !== 'fail') {
        state.pinCreationComplete = true;
        state.status = PinStates.COMPLETED;
      } else {
        state.status = PinStates.ERROR;
        state.error = message;
      }
      AuthService._clearPassword();
    });
    builder.addCase(createPin.rejected, (state, action) => {
      state.error = action.payload;
      if (action.payload === 'password not found. Create the session again') {
        state.passwordNotFound = true;
        state.status = PinStates.PASSWORD_NOT_FOUND;
      } else if (action.payload === 'pin already set') {
        state.status = PinStates.ERROR;
      }
      state.status = PinStates.ERROR;
    });
  },
});

export default pinSlice.reducer;
export const {
  updateInitialPin,
  updateSecondPin,
  confirmPinsMatch,
  checkUserHasSetTransactionPin,
  toggleShowPassword,
  updatePassword,
  resetPinSlice,
} = pinSlice.actions;
