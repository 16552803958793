/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import './RoqqDiamondTile.css';
import { useIcon } from '../../../../../../hooks/useIcon';

export default function RoqqDiamondTile({ text, icon }) {
  const { roqqDiamond } = useIcon();
  return (
    <div className="roqq-diamond-tile">
      <div className="leading">
        <div className="diamond-box">
          <figure className="diamond-img-wrap">
            <img className="img" src={icon || roqqDiamond} alt="roqqnroll diamond" />
          </figure>
        </div>
      </div>
      <p className="tile-text">{text}</p>
    </div>
  );
}
