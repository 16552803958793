/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './style.css';
import '../../../../fragments/InputGroup/styles.css';
import { useTranslation } from 'react-i18next';
import profileIcon from '../../../../../assets/profile-mail-icon.svg';
import InputMessage from '../../../../fragments/InputGroup/components/InputMessage';
import InputErrorMessage from '../../../../fragments/InputGroup/components/InputErrorMessage';

function FormGroupInput({
  icon = profileIcon,
  label = 'Label',
  type = 'text',
  name = 'input-1',
  placeholder = 'Placeholder Here',
  value = '',
  onChange = () => { },
  onInput = () => { },
  showPassword,
  togglePasswordVisibility = () => {},
  isError,
  errorMessage = 'Error Message',
  isWarning,
  warningMessage = 'Warning Message',
  isInputMessage,
  inputMessage = 'Input Message',
  group = false,
  ...rest
}) {
  const { t } = useTranslation();

  // const { profileIcon } = useIcon();
  return (
    <div
      className={`form-input-label-group dsh-input-group ${
        group ? 'group' : ''
      } ${(isError !== undefined || isInputMessage !== undefined) ? 'with-error' : ''}`}
    >
      <label
        htmlFor={name}
        className="form-group-label secondary-white-black-color3"
      >
        {label}
      </label>
      <div
        className={`form-group input-group-with-state  ${
          isError ? 'has-error' : ''
        }`}
      >
        {icon && (
        <label htmlFor={name} className="form-label">
          <figure className="img-box">
            <img src={icon} className="img" alt="" />
          </figure>
        </label>
        )}
        <input
          type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
          name={name}
          id={name}
          // autoComplete="off"
          className="form-input white-black-color"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onInput={(e) => onInput(e)}
          {...rest}
        />
        {type === 'password' && (
          <p
            onClick={() => togglePasswordVisibility()}
            className="show-hide secondary-white-black-color"
          >
            {showPassword ? t('auth.form.hide') : t('auth.form.show')}
          </p>
        )}
      </div>
      {(isError !== undefined || isInputMessage !== undefined || isWarning !== undefined) && (
        <div className="error-message-container" style={{ height: '12.67px' }}>
          {!isError && isInputMessage && (
            <InputMessage
              inputMessage={inputMessage}
            />
          )}
          {(isError || isWarning) && (
            <InputErrorMessage
              isWarning={isWarning}
              warningMessage={warningMessage}
              errorMessage={errorMessage}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default FormGroupInput;
