import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class SettingsService {
  static async getDevices(token) {
    const { response } = await post({
      url: apiRoutes.getDevices,
      data: {
        token,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async deleteDevice(data) {
    const { response } = await post({
      url: apiRoutes.removeDevice,
      data: { ...data, token: AuthService._getUserToken() },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async deactivateAccount() {
    const { response, error } = await post({
      url: apiRoutes.deactivateAccount,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (response === false) {
      throw new Error(error.response.data.message);
    }

    return response;
  }

  static async getUserSettings() {
    const { response, error } = await post({
      url: apiRoutes.getUserSettings,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (response === false || error) {
      throw new Error(error.response.data.message);
    }

    return response;
  }

  static async subscribeToNewsletter() {
    const { response, error } = await post({
      url: apiRoutes.newsletterSubscribe,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (response === false || error) {
      throw new Error(error.response.data.message);
    }

    return response;
  }

  static async unsubscribeFromNewsletter() {
    const { response, error } = await post({
      url: apiRoutes.newsletterUnsubscribe,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (response === false || error) {
      throw new Error(error.response.data.message);
    }

    return response;
  }
}
