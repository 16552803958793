import React from 'react';
import ModalLayout from '../../layouts/dashboard/ModalLayout';

function VerifyModal() {
  return (
    <ModalLayout>VerifyModal</ModalLayout>
  );
}

export default VerifyModal;
