/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import '../../../styles/dashboard/layout/HeaderNavContent.css';
import { FaAngleRight } from 'react-icons/fa';
import searchIcon from '../../../assets/search-icon.svg';
import userImg from '../../../assets/user-profile-img.png';
import ActionContext from '../../../context/ActionContext';
// import logoImg from '../../../assets/roqqu-nav-logo.svg';
// import logoImgWhite from '../../../assets/roqqu-color-logo.svg';
import useUser from '../../../hooks/useUser';
import SearchResultsSheet from '../../fragments/SearchResultsSheet';
import SearchResultsPopup from '../../fragments/SearchResultsPopup';
import { useIcon } from '../../../hooks/useIcon';
import useWindowSize from '../../../hooks/useWindowSize';
import LanguageDropdown from './LanguageDropdown';
import TooltipIcon from '../../fragments/TooltipIcon';
import ModalLayout from './ModalLayout';
import { getDummyUserImage } from '../../../utils/getIconUrl';
import ProfileDropDown from './ProfileDropDown';
import NotificationDropdown from './notification/NotificationDropdown';
import NotificationItemModal from './notification/NotificationItemModal';
import HeaderNavSearchAllCoins from './HeaderNavSearchAllCoins';
import ImagePlaceholder from '../../fragments/ImagePlaceholder';

function HeaderNavContent({ pageTitle, goBack, goBackFunc, theme, toggleTheme }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSlideBarOpen } = useContext(ActionContext);
  const { user, signOut } = useUser();
  const [openSignOut, setOpenSignOut] = useState(false);
  const {
    globeIcon, logoImg,
    signOut: signOutIcon,
  } = useIcon();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchBarRef = useRef(null);
  const [dropdownRightCoord, setDropdownRightCoord] = useState(0);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const { isMobile, windowSize } = useWindowSize();

  const searchSheet = useRef(null);

  const [notificationModalItem, setNotificationModalItem] = useState(null);

  function openNotificationSelectModal(_item) {
    setNotificationModalItem(_item);
  }

  function closeNotificationSelectModal() {
    setNotificationModalItem(null);
  }

  const toggleDropdown = () => {
    setShowProfileDropdown((value) => !value);
  };

  const closeDropdown = () => {
    setShowProfileDropdown(false);
  };

  const searchFocusHandler = (e) => {
    setDropdownRightCoord(searchBarRef.current.getBoundingClientRect().right);
    setShowSearchDropdown(true);
  };

  const openProfile = () => {
    setProfileDropdown(true);
    // history('/dashboard-setting');
  };

  const closeProfile = () => {
    setProfileDropdown(false);
    // history('/dashboard-setting');
  };

  const onClickProfileItem = (index) => {
    switch (index) {
      case 0:
        openProfile();
        break;

      default:
        break;
    }
  };

  const searchbarBlurHandler = (e) => {
    setTimeout(() => {
      e.target.value = '';
      setSearchQuery('');
      setShowSearchDropdown(false);
    }, [200]);
  };

  useEffect(() => {
    setDropdownRightCoord(searchBarRef.current.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    if (showSearchDropdown) setShowSearchDropdown(false);
  }, [windowSize]);

  const [showInSearchAllAssets, setShowInSearchAllAssets] = useState(false);

  function handleShowInSearchAllAssets() {
    setShowSearchDropdown(false);
    setShowInSearchAllAssets(true);
  }

  return (
    // the `dsh-input-group` serves for the focus states of the search input
    <>
      <div className="header-nav-content--container">
        <div
          className={`header-nav-content dsh-input-group ${isMobile && 'mobile'}`}
        >
          {/* showSearchDropdown */}
          {/* {showSearchDropdown && (
          <div className="search-results-sheet-container">
            <SearchResultsSheet
              ref={searchSheet}
              query={searchQuery}
              onClickShowAll={handleShowInSearchAllAssets}
              rightMargin={dropdownRightCoord}
            />
            <SearchResultsPopup
              onClose={() => setShowSearchDropdown(false)}
              onClickShowAll={handleShowInSearchAllAssets}
            />
          </div>
          )}

          <HeaderNavSearchAllCoins
            visible={showInSearchAllAssets}
            onClose={() => setShowInSearchAllAssets(false)}
          /> */}

          {/* mobile roqqu logo start */}
          {!isSlideBarOpen && (
          <div className="mobile-logo-box" onClick={() => navigate('/dashboard-get-started')}>
            <figure className="img-box">
              <img
                src={logoImg}
                alt=""
                className="img"
              />
            </figure>
          </div>
          )}
          {/* mobile roqqu logo end */}
          {/* search box start */}
          <div
            ref={searchBarRef}
            className="search-box input-group-with-state search-box-default-color"
          />
          {/* search box end */}
          <div className="mobile-spacer" />
          {/* mobile search box start */}
          {/* <div className="mobile-search-box mobile-search-box-default-color">
            <figure className="img-box">
              <img
                src={searchIcon}
                alt="search"
                className="img search-icon-btn"
                onClick={() => setShowSearchDropdown(!showSearchDropdown)}
              />
            </figure>
          </div> */}
          {/* mobile search box end */}
          {/* profile box start */}
          <div className="user-profile-container">
            <div
              onClick={openProfile}
              style={{ zIndex: '99999' }}
              className={`profile-name-box profile-color ${isMobile && 'mobile'}`}
            >
              <ImagePlaceholder
                src={
                  user?.fname && user?.lname
                    ? getDummyUserImage({ fname: user?.fname, lname: user?.lname })
                    : userImg
                }
                fallback={userImg}
              />
              <div
                className="name-email-box line-clamp-1"
              >
                <p className="name white-black-color">
                  {user?.fname} {user?.lname}
                </p>
                {/* <p className="email">ayenkeh@gmail.com</p> */}
              </div>
              <FaAngleRight
                style={{ marginRight: '0', height: 'fit-content' }}
                className="icon options-icon"
                onClick={toggleDropdown}
              />
              <ProfileDropDown
                openNotification={() => setNotificationDropdown(true)}
                closeNotification={() => setNotificationDropdown(false)}
                signOut={() => setOpenSignOut(true)}
                theme={theme}
                toggleTheme={toggleTheme}
                visible={profileDropdown}
                onClose={closeProfile}
              />
            </div>

            <NotificationDropdown
              onClose={() => setNotificationDropdown(false)}
              visible={notificationDropdown}
              openNotificationSelectModal={openNotificationSelectModal}
            />

            <div style={{ position: 'relative', zIndex: '99999' }}>
              {/* <div style={{ position: 'relative' }}> */}
              <TooltipIcon
                name="Language"
                image={globeIcon}
                action={() => setLanguageDropdown(!languageDropdown)}
              />
              <LanguageDropdown
                visible={languageDropdown}
                onClose={() => setLanguageDropdown(false)}
              />
            </div>

            <TooltipIcon
              className="nav-logout"
              name="Logout"
              image={signOutIcon}
              action={() => setOpenSignOut(true)}
            />
          </div>
          {/* profile box .end */}
        </div>
      </div>
      <ModalLayout width="53.6rem" auth visible={Boolean(notificationModalItem)}>
        <NotificationItemModal
          notificationModalItem={notificationModalItem}
          closeNotificationSelectModal={closeNotificationSelectModal}
        />
      </ModalLayout>
      <ModalLayout
        noPadding
        visible={openSignOut}
        onClose={() => setOpenSignOut(false)}
      >
        <ModalLayout.DeleteWarnContent
          title={t('dsh.logout.title')}
          desc={t('dsh.logout.description')}
          handleDelete={() => signOut()}
          toggleModal={() => setOpenSignOut(false)}
          yesButtonText={t('dsh.logout.logout')}
          noButtonText={t('dsh.logout.cancel')}
        />
      </ModalLayout>
    </>
  );
}

export default HeaderNavContent;

// /* eslint-disable react/jsx-no-bind */
// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable react/jsx-one-expression-per-line */
// /* eslint-disable object-curly-newline */
// /* eslint-disable no-unused-vars */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable jsx-a11y/no-static-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable react/prop-types */
// import React, { useContext, useEffect, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import Modal from 'react-awesome-modal';
// import '../../../styles/dashboard/layout/HeaderNavContent.css';
// import { FaAngleRight } from 'react-icons/fa';
// import searchIcon from '../../../assets/search-icon.svg';
// import userImg from '../../../assets/user-profile-img.png';
// import ActionContext from '../../../context/ActionContext';
// // import logoImg from '../../../assets/roqqu-nav-logo.svg';
// // import logoImgWhite from '../../../assets/roqqu-color-logo.svg';
// import useUser from '../../../hooks/useUser';
// import SearchResultsSheet from '../../fragments/SearchResultsSheet';
// import SearchResultsPopup from '../../fragments/SearchResultsPopup';
// import { useIcon } from '../../../hooks/useIcon';
// import useWindowSize from '../../../hooks/useWindowSize';
// import LanguageDropdown from './LanguageDropdown';
// import TooltipIcon from '../../fragments/TooltipIcon';
// import ModalLayout from './ModalLayout';
// import { getDummyUserImage } from '../../../utils/getIconUrl';
// import ProfileDropDown from './ProfileDropDown';
// import NotificationDropdown from './notification/NotificationDropdown';
// import NotificationItemModal from './notification/NotificationItemModal';
// import HeaderNavSearchAllCoins from './HeaderNavSearchAllCoins';
// import ImagePlaceholder from '../../fragments/ImagePlaceholder';

// function HeaderNavContent({ pageTitle, goBack, goBackFunc, theme, toggleTheme }) {
//   const { t } = useTranslation();
//   const { isSlideBarOpen } = useContext(ActionContext);
//   const { user, signOut } = useUser();
//   const [openSignOut, setOpenSignOut] = useState(false);
//   const {
//     globeIcon, logoImg,
//     signOut: signOutIcon,
//   } = useIcon();
//   const [showProfileDropdown, setShowProfileDropdown] = useState(false);
//   const [showSearchDropdown, setShowSearchDropdown] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('');
//   const searchBarRef = useRef(null);
//   const [dropdownRightCoord, setDropdownRightCoord] = useState(0);
//   const [notificationDropdown, setNotificationDropdown] = useState(false);
//   const [languageDropdown, setLanguageDropdown] = useState(false);
//   const [profileDropdown, setProfileDropdown] = useState(false);
//   const { isMobile, windowSize } = useWindowSize();

//   const searchSheet = useRef(null);

//   const [notificationModalItem, setNotificationModalItem] = useState(null);

//   function openNotificationSelectModal(_item) {
//     setNotificationModalItem(_item);
//   }

//   function closeNotificationSelectModal() {
//     setNotificationModalItem(null);
//   }

//   const toggleDropdown = () => {
//     setShowProfileDropdown((value) => !value);
//   };

//   const closeDropdown = () => {
//     setShowProfileDropdown(false);
//   };

//   const searchFocusHandler = (e) => {
//     setDropdownRightCoord(searchBarRef.current.getBoundingClientRect().right);
//     setShowSearchDropdown(true);
//   };

//   const openProfile = () => {
//     setProfileDropdown(true);
//     // history('/dashboard-setting');
//   };

//   const closeProfile = () => {
//     setProfileDropdown(false);
//     // history('/dashboard-setting');
//   };

//   const onClickProfileItem = (index) => {
//     switch (index) {
//       case 0:
//         openProfile();
//         break;

//       default:
//         break;
//     }
//   };

//   const searchbarBlurHandler = (e) => {
//     setTimeout(() => {
//       e.target.value = '';
//       setSearchQuery('');
//       setShowSearchDropdown(false);
//     }, [200]);
//   };

//   useEffect(() => {
//     setDropdownRightCoord(searchBarRef.current.getBoundingClientRect().right);
//   }, []);

//   useEffect(() => {
//     if (showSearchDropdown) setShowSearchDropdown(false);
//   }, [windowSize]);

//   const [showInSearchAllAssets, setShowInSearchAllAssets] = useState(false);

//   function handleShowInSearchAllAssets() {
//     setShowSearchDropdown(false);
//     setShowInSearchAllAssets(true);
//   }

//   return (
//     // the `dsh-input-group` serves for the focus states of the search input
//     <>
//       <div className="header-nav-content--container">
//         <div
//           className={`header-nav-content dsh-input-group ${isMobile && 'mobile'}`}
//         >
//           {/* showSearchDropdown */}
//           {/* {showSearchDropdown && (
//           <div className="search-results-sheet-container">
//             <SearchResultsSheet
//               ref={searchSheet}
//               query={searchQuery}
//               onClickShowAll={handleShowInSearchAllAssets}
//               rightMargin={dropdownRightCoord}
//             />
//             <SearchResultsPopup
//               onClose={() => setShowSearchDropdown(false)}
//               onClickShowAll={handleShowInSearchAllAssets}
//             />
//           </div>
//           )}

//           <HeaderNavSearchAllCoins
//             visible={showInSearchAllAssets}
//             onClose={() => setShowInSearchAllAssets(false)}
//           /> */}

//           {/* mobile roqqu logo start */}
//           {!isSlideBarOpen && (
//           <div className="mobile-logo-box">
//             <figure className="img-box">
//               <img
//                 src={logoImg}
//                 alt=""
//                 className="img"
//               />
//             </figure>
//           </div>
//           )}
//           {/* mobile roqqu logo end */}
//           {/* search box start */}
//           <div
//             ref={searchBarRef}
//             className="search-box input-group-with-state search-box-default-color"
//           />
//           {/* search box end */}
//           <div className="mobile-spacer" />
//           {/* mobile search box start */}
//           {/* <div className="mobile-search-box mobile-search-box-default-color">
//             <figure className="img-box">
//               <img
//                 src={searchIcon}
//                 alt="search"
//                 className="img search-icon-btn"
//                 onClick={() => setShowSearchDropdown(!showSearchDropdown)}
//               />
//             </figure>
//           </div> */}
//           {/* mobile search box end */}
//           {/* profile box start */}
//           <div className="user-profile-container">
//             <div
//               onClick={openProfile}
//               style={{ zIndex: '99999' }}
//               className={`profile-name-box profile-color ${isMobile && 'mobile'}`}
//             >
//               <ImagePlaceholder
//                 src={
//                   user?.fname && user?.lname
//                     ? getDummyUserImage({ fname: user?.fname, lname: user?.lname })
//                     : userImg
//                 }
//                 fallback={userImg}
//               />
//               <div
//                 className="name-email-box line-clamp-1"
//               >
//                 <p className="name white-black-color">
//                   {user?.fname} {user?.lname}
//                 </p>
//                 {/* <p className="email">ayenkeh@gmail.com</p> */}
//               </div>
//               <FaAngleRight
//                 style={{ marginRight: '0', height: 'fit-content' }}
//                 className="icon options-icon"
//                 onClick={toggleDropdown}
//               />
//               <ProfileDropDown
//                 openNotification={() => setNotificationDropdown(true)}
//                 closeNotification={() => setNotificationDropdown(false)}
//                 signOut={() => setOpenSignOut(true)}
//                 theme={theme}
//                 toggleTheme={toggleTheme}
//                 visible={profileDropdown}
//                 onClose={closeProfile}
//               />
//             </div>

//             <NotificationDropdown
//               onClose={() => setNotificationDropdown(false)}
//               visible={notificationDropdown}
//               openNotificationSelectModal={openNotificationSelectModal}
//             />

//             <div style={{ position: 'relative', zIndex: '99999' }}>
//               {/* <div style={{ position: 'relative' }}> */}
//               <TooltipIcon
//                 name="Language"
//                 image={globeIcon}
//                 action={() => setLanguageDropdown(!languageDropdown)}
//               />
//               <LanguageDropdown
//                 visible={languageDropdown}
//                 onClose={() => setLanguageDropdown(false)}
//               />
//             </div>

//             <TooltipIcon
//               className="nav-logout"
//               name="Logout"
//               image={signOutIcon}
//               action={() => setOpenSignOut(true)}
//             />
//           </div>
//           {/* profile box .end */}
//         </div>
//       </div>
//       <ModalLayout width="53.6rem" auth visible={Boolean(notificationModalItem)}>
//         <NotificationItemModal
//           notificationModalItem={notificationModalItem}
//           closeNotificationSelectModal={closeNotificationSelectModal}
//         />
//       </ModalLayout>
//       <ModalLayout
//         noPadding
//         visible={openSignOut}
//         onClose={() => setOpenSignOut(false)}
//       >
//         <ModalLayout.DeleteWarnContent
//           title={t('dsh.logout.title')}
//           desc={t('dsh.logout.description')}
//           handleDelete={() => signOut()}
//           toggleModal={() => setOpenSignOut(false)}
//           yesButtonText={t('dsh.logout.logout')}
//           noButtonText={t('dsh.logout.cancel')}
//         />
//       </ModalLayout>
//     </>
//   );
// }

// export default HeaderNavContent;
