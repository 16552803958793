import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import RoqqNavBar from '../components/ui/RoqqNavBar/RoqqNavBar';

import '../styles/index.css';
import FloatingNav from '../../../../components/layouts/dashboard/FloatingNav';
import RoqqVerifyIdentity from '../components/ui/modals/roqq-verify-identity/RoqqVerifyIdentity';

function RoqqnRollLayout() {
  return (
    <div className="roqq-verse">
      <Helmet>
        <meta name="theme-color" content="#3A21CC" />
      </Helmet>
      <RoqqNavBar />
      <Outlet />
      <FloatingNav theme="roqqnroll" />
      <RoqqVerifyIdentity />
    </div>
  );
}

export default RoqqnRollLayout;
