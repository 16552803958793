/* eslint-disable react/prop-types */
import './ErrorFallback.css';
// import unsupported from '../../assets/unsupported.svg';
import Error500 from './Error500';

/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line no-unused-vars
export default function ErrorFallback({ errorName, info }) {
  return <Error500 />;
}
