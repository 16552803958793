/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {
  Fragment, useContext,
} from 'react';
import uuid from 'react-uuid';
import withBagsAccordionContext from './withBagsAccordionContext';
import { AccordionStateToggleContext } from '../../../../../context/AccordionContext';
import BagItem from '../../BagItem';
import SelectedBagSummary from './SelectedBagSummary';
import { RIBsQuestionNavigationHandlerContext } from '../RIBsQuestionStackContext';
import useRIBs from '../../../../../hooks/useRIBs';

function RecommendedBags() {
  const { recommendedBags: bags } = useRIBs();

  const dispatchRIBsQuestionStack = useContext(RIBsQuestionNavigationHandlerContext);
  const setActiveAccordionIndex = useContext(AccordionStateToggleContext);

  function toggleAccordion(_index) {
    setActiveAccordionIndex(_index);
  }

  function selectBag() {
    dispatchRIBsQuestionStack({
      type: 'push',
      payload: <SelectedBagSummary />,
    });
  }

  return (
    <div className="ribs-questions-container">
      <p className="note-title">
        Here are some few healthy bags we recommend
      </p>

      <div className="ribs-bags-container">
        {bags.map((d, idx) => (
          <Fragment key={uuid()}>
            <BagItem
              onSelectBag={() => selectBag()}
              item={d}
              toggleActiveIndex={() => toggleAccordion(idx)}
              index={idx}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default withBagsAccordionContext(RecommendedBags);
