/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ButtonReuse from '../../../../components/fragments/Button';
import DrawerLayout from '../../../../components/fragments/Drawer';
import FormGroupInput from '../../../../components/layouts/auth/form/input';
import { fetchUser, setToggleToast } from '../../../../slices/authSlice';
import * as ToastTypes from '../../../../utils/toast-types';
import {
  activateTypingMode, closeUsernameDrawer, resetUsernameStatus, setUsername, toggleUsernameDrawer, UsernameStates,
} from '../../../../slices/UsernameSlice';
import { useIcon } from '../../../../hooks/useIcon';
import './UsernameDrawer.css';
import SubFlow from '../../../../components/fragments/subFlow';

export default function UsernameDrawer() {
  const { t } = useTranslation();
  const { profileIcon, waveImg } = useIcon();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const {
    isOpen,
    isLoading,
    status,
    errorMessage,
    successMessage,
  } = useSelector((state) => state.username);

  function toggleDrawer() {
    dispatch(toggleUsernameDrawer());
  }
  function submitUsername() {
    dispatch(setUsername(name));
  }
  function handleTextChange(e) {
    setName(e.target.value);
    if (status === UsernameStates.ERROR) {
      dispatch(activateTypingMode());
    }
  }

  useEffect(() => {
    if (status === UsernameStates.SUCCESS) {
      dispatch(fetchUser());
      // toggleDrawer();
      dispatch(closeUsernameDrawer());
      dispatch(resetUsernameStatus());
      dispatch(setToggleToast({
        type: ToastTypes.SUCCESS,
        name: 'Username Set Success',
        desc: successMessage,
      }));
    } else if (status === UsernameStates.ERROR) {
      dispatch(setToggleToast({
        type: ToastTypes.ERROR,
        name: 'Could not set username',
        desc: errorMessage,
      }));
    }
  }, [status]);

  return (
    <SubFlow goBackFunc={() => toggleDrawer()} goBack isVisible={isOpen} onClose title="Enter Username">
      <div className="username-drawer">
        <h2 className="username-header">
          {t('auth.signup.hey')}
          <span>
            <img className="wave-img" src={waveImg} alt="wave welcome to Roqqu" />
          </span>
          <br />
          {t('auth.signup.welcome')}
        </h2>
        <p className="username-subheader">{t('auth.signup.letsGetToKnowYou')}<br />{`${t('auth.signup.chooseName')} 😜`}</p>
        <FormGroupInput
          icon={profileIcon}
          label="Username"
          name="username"
          onChange={handleTextChange}
          value={name}
          placeholder="Enter username"
          isError={status === UsernameStates.ERROR}
          errorMessage={errorMessage}
        />
        <ButtonReuse
          text="Save Changes"
          btnStyle="submit-username-btn"
          loading={isLoading}
          onClick={submitUsername}
        />
      </div>
    </SubFlow>
  );
}
