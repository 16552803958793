/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BillService from '../services/BillPaymentService';
import * as states from '../states/ComponentStates';

const initialState = {
  status: states.BASE,
  country: 'ng',
  currency: 'ngn',
};

// Get Data Subscription
// This function have been moved to DataBill.jsx: we don't need the data stored globally

// Buy Airtime
export const buyAirtime = createAsyncThunk(
  'bill/buy-airtime',
  async (data, { rejectWithValue }) => {
    try {
      const response = await BillService.buyAirtime(data);

      return response.response.data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

const billSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      if (
        !(
          action.payload === states.BASE ||
          action.payload === states.FETCHING ||
          action.payload === states.ERROR ||
          action.payload === states.FETCHED ||
          action.payload === states.POPULATING ||
          action.payload === states.POPULATED
        )
      ) {
        return;
      }
      state.status = action.payload;
    },

    updateState: (state, action) => {
      const { item, value } = action.payload;
      state[item] = value;
    },
  },

  extraReducers: (builder) => {
    // extraReducers for buyAirtime
    builder.addCase(buyAirtime.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(buyAirtime.fulfilled, (state, action) => {
      // eslint-disable-next-line no-unused-vars
      const { payload } = action;
    });
    builder.addCase(buyAirtime.rejected, (state) => {
      state.status = states.ERROR;
    });
  }
});

export default billSlice.reducer;
export const { updateStatus, updateState, } = billSlice.actions;
