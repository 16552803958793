/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/react-in-jsx-scope */
import './InvestSubComponents.css';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconContext } from 'react-icons/lib';
import uuid from 'react-uuid';
import ButtonReuse from '../fragments/Button';
import { useIcon } from '../../hooks/useIcon';
import useRIBs from '../../hooks/useRIBs';
import H4 from '../textElements/H4/H4';
import Paragraph from '../textElements/Paragraph/Paragraph';
import AvailableBalanceBadge from '../fragments/AvailableBalanceBadge';
import InputGroupLabel from '../fragments/InputGroup/components/InputGroupLabel';
import AuthPinField from '../../features/auth/registration/view/components/PinField/AuthPinField';
import { buyFirstBag, resetRIBsInitialSetupErrorState, selectPurchaseStatus, setInitialInvestmentAmount, updateAmount, updateBagPurchaseCurrency } from '../../slices/ribsInitialSetupSlice';
import ImagePlaceholder from '../fragments/ImagePlaceholder';
import getIconUrl from '../../utils/getIconUrl';
import { formatNumber, getCurrency } from '../../utils/simpleActions';
import loadingStates from '../../utils/loadingStates';
import { showToast } from '../../slices/toast-slice';
import toastTypes from '../../utils/toast-types';
import GroupWithAvailableBalance from '../fragments/InputGroup/components/GroupWithAvailableBalance';
import CurrencyPriceField from '../fragments/CurrencyPriceField';
import ModalLayout from '../layouts/dashboard/ModalLayout';
import Select from '../fragments/Select';
import useCurrency from '../../hooks/useCurrency';

export function SelectFrequency({ onComplete }) {
  const { accordionAngleDown, investNewImg } = useIcon();
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [selectedRepeatOption, setRepeatOption] = useState('');
  const frequencyOptions = useMemo(() => [
    { text: 'Daily', value: 'daily' },
    { text: 'Weekly', value: 'weekly' },
    { text: 'Monthly', value: 'monthly' },
  ], []);
  const weeklyRepeatOptions = useMemo(() => [
    { text: 'Monday', value: 'moday' },
    { text: 'Tuesday', value: 'Tuesday' },
    { text: 'Wednesday', value: 'Wednesday' },
    { text: 'Thursday', value: 'Thursday' },
    { text: 'Friday', value: 'thursday' },
    { text: 'Saturday', value: 'saturday' },
    { text: 'Sunday', value: 'sunday' },
  ], []);
  const dailyRepeatOptions = useMemo(() => [
    { text: '12AM', value: '12am' },
    { text: '1AM', value: '1am' },
    { text: '2AM', value: '2am' },
    { text: '3AM', value: '3am' },
    { text: '4AM', value: '4am' },
    { text: '5AM', value: '5am' },
    { text: '6AM', value: '6am' },
    { text: '7AM', value: '7am' },
    { text: '8AM', value: '8am' },
    { text: '9AM', value: '9am' },
    { text: '10AM', value: '10pm' },
    { text: '11AM', value: '11pm' },
    { text: '12PM', value: '12pm' },
    { text: '1PM', value: '1pm' },
    { text: '2PM', value: '2pm' },
    { text: '3PM', value: '3pm' },
    { text: '4PM', value: '4pm' },
    { text: '5PM', value: '5pm' },
    { text: '6PM', value: '6pm' },
    { text: '7PM', value: '7pm' },
    { text: '8PM', value: '8pm' },
    { text: '9PM', value: '9pm' },
    { text: '10PM', value: '10pm' },
    { text: '11PM', value: '11pm' },
  ], []);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState((new Date()).getDate());

  function handleCompletion() {
    onComplete && onComplete();
  }

  function checkIfButtonIsDisabled(option) {
    return (option === '');
  }

  function completeDateSelection(day) {
    setSelectedDate(day);
    setShowDatePicker(false);
  }

  return (
    <div className="invest-freq">
      <img src={investNewImg} alt="invest" className="invest-ic" />
      <H4 classNames="invest-freq-title">How much are you willing to invest today?</H4>
      <Paragraph classNames="invest-freq-subtitle">
        Let’s test how risky you’re feeling today, be confident and input the amount below.
      </Paragraph>
      <Select
        options={frequencyOptions}
        value={selectedFrequency}
        onChange={setSelectedFrequency}
        hasLabel
        outsideLabel
        outsideLabelText="I want to purchase"
        placeholder="Select frequency"
        chevIcon={accordionAngleDown}
        doesChevIconHaveBackgroundColor={false}
      />
      <div style={{ height: '3.2rem' }} />
      {
        selectedFrequency === 'monthly' ? (
          <>
            <InputGroupLabel label="Repeats every" />
            <button
              className="modal-btn"
              onClick={() => setShowDatePicker(true)}
            >
              <p className="modal-btn-txt">{formatDayInWords(selectedDate)}</p>
            </button>
          </>
        ) : (
          <Select
            options={(selectedFrequency === 'weekly') ? weeklyRepeatOptions : dailyRepeatOptions}
            value={selectedRepeatOption}
            onChange={setRepeatOption}
            hasLabel
            outsideLabel
            outsideLabelText="I want to buy"
            placeholder="Select frequency"
            chevIcon={accordionAngleDown}
            doesChevIconHaveBackgroundColor={false}
          />
        )
      }
      <ButtonReuse
        text="Confirm"
        onClick={handleCompletion}
        btnStyle="proceed-btn"
        isDisabled={checkIfButtonIsDisabled(selectedFrequency)}
      />
      <DCARecurringDatePicker
        showDatePicker={showDatePicker}
        completeDateSelection={completeDateSelection}
        selectedDate={selectedDate}
      />
    </div>
  );
}

export function DCARecurringDatePicker({ showDatePicker, completeDateSelection, selectedDate }) {
  return (
    <ModalLayout
      visible={showDatePicker}
      showLine={false}
      noPadding
    >
      <FrequencyDatePicker
        onComplete={completeDateSelection}
        initialDate={selectedDate}
      />
    </ModalLayout>
  );
}

export function Amount({ onComplete }) {
  const { investNewImg } = useIcon();
  const { suggestions } = useRIBs();
  const [suggestion, setSuggestion] = useState('');
  const [amount, setAmount] = useState('');
  const dispatch = useDispatch();
  const [curr, setCurr] = useState();

  const { setSelectedCurrency, stringCurrencyBalance } = useCurrency();

  useEffect(() => {
    if (!suggestion || suggestion === 'Custom') {
      return;
    }
    setAmount(suggestion.toString());
  }, [suggestion]);

  function handleCompletion() {
    dispatch(setInitialInvestmentAmount(amount));
    onComplete && onComplete();
  }

  function handleChangeAmount(value) {
    setAmount(value);
  }

  function checkIfBtnIsDisabled(amt) {
    return (amt === '');
  }

  function changeCurrency(val) {
    setSelectedCurrency(val);
    setCurr(val);
    dispatch(updateBagPurchaseCurrency(val));
  }

  return (
    <div className="invest-amount">
      <img src={investNewImg} alt="invest" className="invest-ic" />
      <H4 classNames="invest-amount-title">How much are you willing to invest today?</H4>
      <Paragraph classNames="invest-amount-subtitle">
        Let’s test how risky you’re feeling today, be confident and input the amount below.
      </Paragraph>
      <GroupWithAvailableBalance>
        <CurrencyPriceField
          hasLabel
          labelText="Amount"
          canFormatNumber
          parentAmount={amount}
          setParentAmount={handleChangeAmount}
          placeholder="Enter Amount"
          onChange={changeCurrency}
          isError={false}
          errorMessage={undefined}
        />
        <AvailableBalanceBadge value={stringCurrencyBalance} />
      </GroupWithAvailableBalance>
      <div className="suggestions">
        {
          suggestions && suggestions.map((d) => (
            <ButtonReuse
              key={uuid()}
              onClick={() => setSuggestion(d)}
              btnStyle={`amount-suggestion default-background ${suggestion === d && 'active secondary-white-black-color3'}`}
            >
              {d !== 'Custom' && curr && getCurrency(curr).s}
              {d}
            </ButtonReuse>
          ))
        }
      </div>
      <Paragraph classNames="invest-amount-hint">
        Please note that a fee of 2% will be deducted from the amount you choose to invest.
      </Paragraph>
      <ButtonReuse
        text="Proceed"
        onClick={handleCompletion}
        btnStyle="proceed-btn"
        isDisabled={checkIfBtnIsDisabled(amount)}
      />
    </div>
  );
}

export function Summary({ onComplete }) {
  const { investSheet } = useIcon();
  const { amount, buyType, currency, selectedBag } = useSelector((state) => state.ribsInitialSetup);
  const { tx_charge } = useSelector((state) => state.auth.user.user_info);
  const { allTokens } = useSelector((state) => state.global);
  const { loadingState, error } = useSelector(selectPurchaseStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadingState === loadingStates.failed) {
      dispatch(showToast({
        type: toastTypes.error,
        desc: error,
      }));
      dispatch(resetRIBsInitialSetupErrorState());
    } else if (loadingState === loadingStates.success) {
      onComplete && onComplete();
    }
  }, [loadingState]);

  function handleCompletion() {
    const totalPrice = calulateTotal();
    dispatch(updateAmount(totalPrice));
    dispatch(buyFirstBag({ amount: totalPrice, reference: selectedBag.reference, currency, buyType }));
  }

  function calulateTotal() {
    const feeAmount = calculateRateWithPercentage(tx_charge);
    return amount + feeAmount;
  }

  function getColorForItem(symbol) {
    const matchingToken = allTokens.data.find((token) => token.symbol.toLowerCase() === symbol.toLowerCase());
    if (!matchingToken) return '#1FC5C9';
    const tokenColor = matchingToken.color;
    return `#${tokenColor}`;
  }

  function getTokenNameFromSymbol(symbol) {
    const matchingToken = allTokens.data.find((token) => token.symbol.toLowerCase() === symbol.toLowerCase());
    if (!matchingToken) return '#1FC5C9';
    return matchingToken.name;
  }

  function calculateRateWithPercentage(percentage) {
    return (percentage / 100) * amount;
  }

  function getCurrencyAbbreviation(curr) {
    return getCurrency(curr).c.toUpperCase();
  }

  function getCurrencySymbol(curr) {
    return getCurrency(curr).s.toUpperCase();
  }

  return (
    <div className="invest-summary">
      <img src={investSheet} alt="invest" className="invest-ic" />
      <H4 classNames="title">What a great choice. See your summary below</H4>
      <div className="table-caption-row">
        <Paragraph classNames="key">Amount invested</Paragraph>
        <Paragraph classNames="value">
          {`${getCurrencySymbol(currency)}${formatNumber(amount, 2)} ${getCurrencyAbbreviation(currency)}`}
        </Paragraph>
      </div>
      <div className="group-box">
        <h5 className="table-title">Roqqu large cap investment</h5>
        <div className="bag-distro-table">
          {
            selectedBag && selectedBag.items.map(({ ratio, symbol }) => (
              <div key={uuid()} className="bag-distro-table-row">
                <figure className="coin-icon">
                  <ImagePlaceholder width="16px" height="16px" src={getIconUrl(symbol)} />
                </figure>
                <div className="coin-name-box">
                  <p className="coin-name">
                    {getTokenNameFromSymbol(symbol)}
                    <span className="coin-symbol">{symbol}</span>
                  </p>
                </div>
                <div className="percentage-distro-box">
                  <p className="percentage-distro">
                    {ratio}
                    %
                  </p>
                </div>
                <div className="progress-bar-box">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${ratio}%`,
                      backgroundColor: getColorForItem(symbol),
                    }}
                  />
                </div>
                <div className="amount-box">
                  <p className="amount">
                    {`$${calculateRateWithPercentage(ratio)}`}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="group-box extra">
        <div className="fee-info">
          <p className="key">{`Fee(${tx_charge}%)`}</p>
          <p className="value">{`${getCurrencySymbol(currency)}${calculateRateWithPercentage(tx_charge)} ${getCurrencyAbbreviation(currency)}`}</p>
        </div>
        <hr />
        <div className="fee-info">
          <p className="key">What you will get</p>
          <p className="value">
            {`${getCurrencySymbol(currency)}${formatNumber(calulateTotal(), 2)} ${getCurrencyAbbreviation(currency)}`}
          </p>
        </div>
      </div>
      <ButtonReuse
        text="Proceed"
        onClick={handleCompletion}
        btnStyle="proceed-btn"
        loading={loadingState === loadingStates.pending}
      />
    </div>
  );
}

export function Confirm({ onComplete }) {
  const { investPinImg } = useIcon();
  const [pin, setPin] = useState('');
  const { amount, buyType, selectedBag } = useSelector((state) => state.ribsInitialSetup);
  const { error, loadingState } = useSelector(selectPurchaseStatus);
  const dispatch = useDispatch();

  function handleCompletion() {
    dispatch(buyFirstBag({ amount, reference: selectedBag.reference, currency: 'usd', pin, buyType }));
  }

  function checkIfBtnIsDisabled(val) {
    return (val.length !== 6);
  }

  useEffect(() => {
    if (loadingState === loadingStates.success) {
      onComplete && onComplete();
    } else if (loadingState === loadingStates.failed) {
      dispatch(showToast({
        type: toastTypes.error,
        desc: error ?? 'something went wrong',
      }));
    }
  }, [loadingState]);

  return (
    <div className="invest-confirm">
      <img src={investPinImg} alt="invest" className="invest-ic" />
      <H4 classNames="title">Input your pin to confirm this transaction</H4>
      <InputGroupLabel label="Enter Pin" />
      <form className="invest-confirm-form">
        <div className="pin-input-group">
          <AuthPinField pin={pin} setPin={setPin} />
        </div>
      </form>
      <ButtonReuse
        text="Confirm"
        onClick={handleCompletion}
        btnStyle="proceed-btn"
        isDisabled={checkIfBtnIsDisabled(pin)}
      />
    </div>
  );
}

export function Success({ onComplete }) {
  const { investSuccessImg } = useIcon();
  function handleCompletion() {
    onComplete && onComplete();
  }

  return (
    <div className="invest-success">
      <img src={investSuccessImg} alt="invest" className="invest-ic" />
      <h5 className="info">Roqqu large cap invested successfully</h5>
      <ButtonReuse
        text="View Investment"
        onClick={handleCompletion}
        btnStyle="proceed-btn"
      />
    </div>
  );
}

function FrequencyDatePicker({ onComplete, initialDate = 1 }) {
  const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const iconStyles = useMemo(() => ({ className: 'prevnext-btn-img' }), []);

  useEffect(() => {
    const arrayOfDays = populateArrayOfDays();
    setDaysInMonth(arrayOfDays);
    getWeekdayOfFirstDayOfTheMonth();
  }, []);

  useEffect(() => {
    const todaysDate = new Date();
    setSelectedDate(todaysDate.getDate());
  }, []);

  function handleClick() {
    onComplete && onComplete(selectedDate);
  }

  function handleDayClick(day) {
    if (day.canBeSelected) {
      setSelectedDate(day.date);
    }
  }

  return (
    <div className="freq-date-picker">
      <IconContext.Provider value={iconStyles}>
        <nav className="nav-section">
          <h6 className="title">Choose a date</h6>
        </nav>
      </IconContext.Provider>
      <div className="weekdays">
        {
          weekdays.map((weekday) => (
            <div key={uuid()} className="weekday">
              <span>{weekday}</span>
            </div>
          ))
        }
      </div>
      <div className="days-in-month">
        {
          daysInMonth.map((d) => (
            <button
              key={uuid()}
              className={`day-btn ${!d.canBeSelected && 'disabled'} ${d.date === selectedDate && 'selected'}`}
              onClick={() => handleDayClick(d)}
            >
              {d.date}
            </button>
          ))
        }
      </div>
      <section className="btn-section">
        <ButtonReuse
          onClick={handleClick}
          text="Proceed"
          btnStyle="proceed-btn"
        />
      </section>
    </div>
  );
}

function getNumberOfDaysInThisMonth() {
  const todayDate = new Date();
  return getNumberOfDaysInMonth(todayDate);
}

function getNumberOfDaysInPreviousMonth() {
  const lastMonth = new Date();
  lastMonth.setDate(0);
  return lastMonth.getDate();
}

function getNumberOfDaysInMonth(date) {
  const nextMonth = new Date();
  convertDateToFirstDayOfNextMonth(nextMonth);
  const numberOfDaysBetweenDates = convertMillisecondsToDays(getDifferenceBetweenDates(nextMonth, date));
  const numberOfDaysInMonth = date.getDate() + numberOfDaysBetweenDates;
  return numberOfDaysInMonth;
}

function convertDateToFirstDayOfNextMonth(date) {
  date.setMonth(date.getMonth() + 1);
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
}

function getDifferenceBetweenDates(whole, takeAway) {
  const wholeInMilliSeconds = whole.valueOf();
  const takeawayInMilliseconds = takeAway.valueOf();
  return wholeInMilliSeconds - takeawayInMilliseconds;
}

function convertMillisecondsToDays(milliseconds) {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  return Math.trunc(days);
}

function populateArrayOfDays() {
  const arrayOfDays = [];
  const firstWeekdayInMonth = getWeekdayOfFirstDayOfTheMonth();
  const numberOfDaysInLastMonth = getNumberOfDaysInPreviousMonth();
  const numberOfDaysInThisMonth = getNumberOfDaysInThisMonth();
  const todaysDate = (new Date()).getDate();
  for (let i = firstWeekdayInMonth; i > 0; i--) {
    const dayData = {
      canBeSelected: false,
      date: numberOfDaysInLastMonth - i + 1,
    };
    arrayOfDays.push(dayData);
  }
  for (let i = 1; i <= numberOfDaysInThisMonth; i++) {
    const dayData = {
      canBeSelected: i >= todaysDate,
      date: i,
    };
    arrayOfDays.push(dayData);
  }
  return arrayOfDays;
}

function getWeekdayOfFirstDayOfTheMonth() {
  const todayDate = new Date();
  todayDate.setDate(1);
  return todayDate.getDay();
}

export function formatDayInWords(day) {
  const stringDay = day.toString();
  let suffix = 'th';
  const firstDigitInDay = stringDay.substring(0, 1);
  const lastDigitInDay = stringDay.substring(stringDay.length - 1);
  if (stringDay.length === 2 && firstDigitInDay === '1') {
    suffix = 'th';
  } else if (lastDigitInDay === '1') {
    suffix = 'st';
  } else if (lastDigitInDay === '2') {
    suffix = 'nd';
  } else if (lastDigitInDay === '3') {
    suffix = 'rd';
  }
  return `${stringDay}${suffix}`;
}
