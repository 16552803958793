/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIcon } from '../../../hooks/useIcon';
import ModalLayout from '../../layouts/dashboard/ModalLayout';
import ButtonReuse from '../Button';
import '../../../styles/dashboard/wallet/SuccessFulTransactionModal.css';

function TransactionSuccessModal({
  boxItems = [],
  visible = false,
  goToDashboard = () => { },
  // goAgain = {
  //   text: null,
  //   func: () => { },
  // },
  // downloadReceipt = () => { },
  closeModal = () => { },
}) {
  const { t } = useTranslation();
  const { successWhiteGIF } = useIcon();

  return (
    <ModalLayout
      noPadding
      onClose={closeModal}
      visible={visible}
      width="46.8rem"
      // bottomPortal={(
      //   <div className="btn-wrap secondary-white-black-color">
      //     <ModalLayout.PortalButtons
      //       text={goAgain.text ? goAgain.text : t('dsh.makeAnotherTransfer')}
      //       onClick={() => goAgain.func()}
      //     />
      //     <ModalLayout.PortalButtons
      //       onClick={() => downloadReceipt()}
      //       text={t('dsh.downloadReceipt')}
      //     />
      //   </div>
      // )}
    >
      <div className="transaction-success-modal">
        {/* figure box */}
        <figure className="transaction-success-modal-img">
          <img src={successWhiteGIF} alt="" className="img icon-colo" />
        </figure>
        {/* figure box end */}
        <ModalLayout.BigText text={t('dsh.transactionSuccessful')} />
        <ModalLayout.SmallText text={`${t('dsh.transactionNote1')}\n${t('dsh.transactionNote2')}`} />
        {/* detail wrap start */}
        <div className="detail-box">
          {/* box start */}
          {boxItems.map((child) => {
            const { name, value } = child;

            return (
              <div className="box" key={name}>
                <p className="text secondary-white-black-color">{name}</p>
                <p className="value line-clamp-1">{value}</p>
              </div>
            );
          })}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="text secondary-white-black-color  ">{t('dsh.status.title')}</p>
            <p className="value">{t('dsh.status.success')}</p>
          </div>
          {/* box end */}
        </div>
        {/* detail wrap end */}
        {/* btn start */}
        <div className="btn-box">
          <ButtonReuse
            onClick={() => goToDashboard()}
            btnStyle="btn-reuse"
            text={t('dsh.backToDsh')}
          />
        </div>
        {/* btn end */}
      </div>
    </ModalLayout>
  );
}

export default TransactionSuccessModal;
