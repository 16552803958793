/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import LoginService from '../services/LoginService';

export const LoginStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  PIN_SET_FALSE: 'no_pin',
  UNVERIFIED: 'unverified',
  ERROR: 'failed',
};

const initialState = {
  status: LoginStates.BASE,
  data: {
    email: '',
    password: '',
    auth: '',
  },
  loginType: 'email',
  user: undefined,
  extraData: undefined,
  pinSet: undefined,
  pendingAuthorization: undefined,
  error: undefined,
  phone: '',
  dialCode: '',
  phoneLogin: {
    pendingAuthorization: undefined,
    status: LoginStates.BASE,
    error: undefined,
    pinSet: undefined,
  },
  reportAccount: {
    status: LoginStates.BASE,
    error: undefined,
  },
};

export const triggerLogin = createAsyncThunk('auth/login', async (_, thunkAPI) => {
  try {
    return await LoginService.login(thunkAPI.getState().login.data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const triggerGoogleLogin = createAsyncThunk(
  'auth/googleLogin',
  async ({ access_token, ...rest }, thunkAPI) => {
    try {
      return await LoginService.googleLogin({ access_token, ...rest });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

// Trigger Phone Number Login
export const triggerPhoneNumberLogin = createAsyncThunk(
  'auth/phoneNumberLogin',
  async ({ phone, ...rest }, { getState, rejectWithValue }) => {
    try {
      const { dialCode } = getState().login;
      return await LoginService.phoneNumberLogin({ ...rest, phone, dialCode });
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

// Trigger Phone Number Final Login
export const triggerPhoneNumberFinalLogin = createAsyncThunk(
  'auth/phoneNumberFinalLogin',
  async ({ email, password }, thunkAPI) => {
    try {
      return await LoginService.login({ email, password });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

// Trigger Report Account
export const triggerReportAccount = createAsyncThunk(
  'auth/reportAccount',
  async ({ phone, email, reason }, thunkAPI) => {
    try {
      return await LoginService.reportAccount({ phone, email, reason });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginType: (state, action) => {
      state.loginType = action.payload || 'email';
    },
    updateLoginData: (state, action) => {
      const { name, value } = action.payload;
      state.data[name] = value;
    },
    resetLoginData: (state) => {
      state.status = initialState.status;
      state.data = initialState.data;
      state.loginType = initialState.loginType;
      state.extraData = undefined;
      state.pinSet = undefined;
      state.pendingAuthorization = undefined;
      state.error = undefined;
      state.phoneLogin.status = initialState.phoneLogin.status;
      state.phoneLogin.error = undefined;
      state.phoneLogin.pendingAuthorization = undefined;
      state.phoneLogin.pinSet = undefined;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updateDialCode: (state, { payload }) => {
      state.dialCode = payload;
    },
  },
  extraReducers: (builder) => {
    // login reducer
    builder.addCase(triggerLogin.pending, (state) => {
      state.error = undefined;
      state.pinSet = undefined;
      state.pendingAuthorization = undefined;
      state.status = LoginStates.LOADING;
    });
    builder.addCase(triggerLogin.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload.status !== 'fail') {
        state.pinSet = payload.extra_data.pin_set;
        state.pendingAuthorization = (payload.status === 'pending_authorization');
        state.user = payload.extra_data;
        state.status = LoginStates.SUCCESSFUL;
      } else {
        state.error = payload.message;
        state.status = LoginStates.ERROR;
      }
    });
    builder.addCase(triggerLogin.rejected, (state, action) => {
      state.error = action.payload;
      state.status = LoginStates.ERROR;
    });

    // triggerGoogleLogin reducer
    builder.addCase(triggerGoogleLogin.pending, (state) => {
      state.error = undefined;
      state.pinSet = undefined;
      state.pendingAuthorization = undefined;
      state.status = LoginStates.LOADING;
    });
    builder.addCase(triggerGoogleLogin.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload.status === 'pending_authorization' || payload.status === 'pending_verification') {
        // state.phone.pinSet = payload?.extra_data?.pin_set;
        AuthService._saveEmail(payload.data);
        state.pinSet = payload?.extra_data?.pin_set;
        state.pendingAuthorization = true;
      } else if (payload.status === 'success') {
        // pending_authorization
        state.pinSet = payload?.extra_data?.pin_set;
        AuthService._saveToken(payload.data);
      }
      state.status = LoginStates.SUCCESSFUL;
    });
    builder.addCase(triggerGoogleLogin.rejected, (state, action) => {
      state.error = action.payload;
      state.status = LoginStates.ERROR;
    });

    // triggerPhoneNumberLogin reducer
    builder.addCase(triggerPhoneNumberLogin.pending, (state) => {
      state.phoneLogin.error = undefined;
      state.phoneLogin.pendingAuthorization = undefined;
      state.phoneLogin.status = LoginStates.LOADING;
    });
    builder.addCase(triggerPhoneNumberLogin.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload?.extra_data) {
        state.phoneLogin.pinSet = payload?.extra_data?.pin_set;
      } else {
        state.phoneLogin.pinSet = true;
      }
      if (payload.status === 'pending_authorization' || payload.status === 'pending_verification') {
        AuthService._saveEmail(payload.data);
        state.phoneLogin.pendingAuthorization = true;
      } else if (payload.status === 'success') {
        // pending_authorization
        AuthService._saveToken(payload.data);
      }
      state.phoneLogin.status = LoginStates.SUCCESSFUL;
    });
    builder.addCase(triggerPhoneNumberLogin.rejected, (state, action) => {
      state.phoneLogin.error = action.payload;
      state.phoneLogin.status = LoginStates.ERROR;
    });

    // trigger Phone Number Final Login
    builder.addCase(triggerPhoneNumberFinalLogin.pending, (state) => {
      state.error = undefined;
      state.pinSet = undefined;
      state.pendingAuthorization = undefined;
      state.status = LoginStates.LOADING;
    });
    builder.addCase(triggerPhoneNumberFinalLogin.fulfilled, (state, action) => {
      const { payload } = action;
      state.pinSet = payload.extra_data.pin_set;
      state.pendingAuthorization = (payload.status === 'pending_authorization');
      state.user = payload.extra_data;
      state.status = LoginStates.SUCCESSFUL;
    });
    builder.addCase(triggerPhoneNumberFinalLogin.rejected, (state, action) => {
      state.error = action.payload;
      state.status = LoginStates.ERROR;
    });

    // trigger Report Account
    builder.addCase(triggerReportAccount.pending, (state) => {
      state.reportAccount.error = undefined;
      state.reportAccount.status = LoginStates.LOADING;
    });
    builder.addCase(triggerReportAccount.fulfilled, (state) => {
      state.reportAccount.status = LoginStates.SUCCESSFUL;
    });
    builder.addCase(triggerReportAccount.rejected, (state, action) => {
      state.reportAccount.error = action.payload;
      state.reportAccount.status = LoginStates.ERROR;
    });
  },
});

export const selectEntireLoginState = (state) => state.login;
export const selectLoginData = (state) => state.login.data;
export const selectUserFromLogin = (state) => state.login.user;
export const selectEntirePhoneLogin = (state) => state.login.phoneLogin;
export const selectPhoneState = (state) => state.login.phone;
export const selectReportAccountState = (state) => state.login.reportAccount;

export default loginSlice.reducer;
export const {
  updateLoginData, resetLoginData, setLoginType, updatePhone, updateDialCode,
} = loginSlice.actions;
