/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { useIcon } from '../../../../hooks/useIcon';

export default function NotificationItem({ item, selectItem }) {
  const { seen, unseen } = useIcon();
  return (
    <div className="notification-item" onClick={() => selectItem()}>
      <figure>
        {item.isRead ? <img src={seen} alt="" /> : <img src={unseen} alt="" />}
      </figure>
      <div className="content">
        {/* <p className={`title ${item.isRead && 'secondary-white-black-color'}`}>
          {item.title}
        </p> */}
        <p className={`title ${item.isRead && 'secondary-white-black-color'}`}>
          {typeof item?.title === 'string' ? item?.title : 'Title here'}
        </p>
        <p className="body secondary-white-black-color">{item.body}</p>
        <p className="time secondary-white-black-color">
          {moment(item.created_at, 'YYYYMMDD').fromNow()}
        </p>
      </div>
    </div>
  );
}
