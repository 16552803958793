/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './TransferCurrencyModal.css';
import RoqqDialog from '../../RoqqDialog/RoqqDialog';
import RoqqPill from '../../RoqqPill/RoqqPill';
import RoqqSecondaryButton from '../../RoqqNegativeButton/RoqqNegativeButton';
import RoqqTextBox from '../../RoqqTextBox/RoqqTextBox';

function TransferCurrencyModal({ show, onClose }) {
  // eslint-disable-next-line no-unused-vars
  const { transferValue, setTransferValue } = useState('');
  return (
    <RoqqDialog
      show={show}
      onClose={onClose}
      showBackBtn
      buttonType="single"
      headerText="Transfer to Wallet"
      neutralBtnText="Proceed"
      isNeutralButtonDisabled={transferValue === ''}
    >
      <div className="transfer-modal-container">
        <section className="balance-wrapper roqq-diamond-tile">
          <div className="lottery-balance">
            <p className="amount">16usdt</p>
            <RoqqPill text="Lottery Balance" className="balance-label" />
          </div>
          <RoqqSecondaryButton
            className="use-max-btn"
            text="Use Max"
          />
        </section>

        <hr className="horz-rule" />

        <section className="transfer-amt-input">
          <p className="input-label">Amount you wish to transfer:</p>
          <RoqqTextBox
            type="number"
            className="amount-input"
          />
        </section>
      </div>
    </RoqqDialog>
  );
}

export default TransferCurrencyModal;
