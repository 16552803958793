/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchKYCCountries, fetchSelectedCountryData,
  fetchStates, resetCountriesStatus, resetCountryDataStatus,
  selectKYCCountries, selectKYCCountryLink,
} from '../../../../slices/KYCSlice';
import { selectVerifyyId, toggleVerifyIdDrawer } from '../../../../slices/UserSlice';
import * as toastType from '../../../../utils/toast-types';
import useThunkEffectCalls from '../../../../hooks/useThunkEffectCalls';
import ButtonReuse from '../../Button';
import DrawerLayout from '../../Drawer';
import LoadingPage from '../../LoadingPage';
import ShowSection from '../../ShowSection';
import CurrencySelectStage from './CurrencySelectStage';
import VerifyOptions from './VerifyOptions';
import DocumentVerification from './DocumentVerification/DocumentVerification';
import toastTypes from '../../../../utils/toast-types';
import SubFlow from '../../subFlow';
import { RETURN_TO_WALLET_PORTFOLIO_FLOW } from '../../../../utils/storageKeys';
import KYCScreenFlow from '../../../../features/kyc';
import { setToggleToast } from '../../../../slices/authSlice';

function VerifyDrawer() {
  const { t } = useTranslation();
  const { isOpen } = useSelector(selectVerifyyId);
  const dispatch = useDispatch();
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const currencyRef = useRef(null);
  const verifyOptionRef = useRef(null);
  const verificationStepsRef = useRef(null);
  const [loadingCurrencies, setLoadingCurrencies] = useState(true);
  const [details, setDetails] = useState({
    currency: false,
    verificationSteps: false,
  });

  const stages = [
    {
      id: 1,
      dataName: 'currency',
      name: 'Select Currency',
      ref: currencyRef,
    },
    {
      id: 2,
      dataName: 'verificationSteps',
      name: 'Few steps to take to start trading with Roqqu',
      ref: verificationStepsRef,
    },
    {
      id: 3,
      dataName: 'selectVerifyOption',
      name: 'Select Option to Verify',
      ref: verifyOptionRef,
    },
  ];

  function selectedStage() {
    return stages.find((s) => s.id === stage);
  }

  function fetchSelectedLink() {
    const { verificationSteps: { level }, currency: { country_code } } = details;
    dispatch(fetchSelectedCountryData({ country: country_code, level: `${level}` }));
  }

  function proceed() {
    setStage((prev) => prev + 1);
    // if (!details.currency) {
    //   setStage(1);
    //   return;
    // }

    // if (!details.verificationSteps) {
    //   setStage(2);
    // } else {
    //   // fetch link...

    //   setStage(3);
    // }
  }

  useEffect(() => {
    if (details.verificationSteps && details.currency) {
      setLoadingCurrencies(true);
      fetchSelectedLink();
    }
  }, [details.currency, details.verificationSteps]);

  function closeDrawer() {
    setLoadingCurrencies(false);
    setStage(1);
    setDetails({
      currency: false,
      verificationSteps: false,
    });
    dispatch(toggleVerifyIdDrawer());
  }

  async function submit() {
    const componentAction = selectedStage().ref.current;
    const stageResp = await componentAction?.submit();
    if (stageResp && stageResp?.name === 'currency') {
      if (stageResp?.data.status === 0 || !stageResp?.data.config) {
        dispatch(
          setToggleToast({
            type: toastTypes.warning,
            heading: 'Identity Verification Error',
            description: 'Identity Verification not available for selected currency.',
          }),
        );
        return;
      }
    }
    setDetails((prev) => ({ ...prev, [stageResp?.name]: stageResp?.data }));
    setDetails((prev) => {
      proceed();
      return prev;
    });
  }

  function proceedOptionSelect(_data) {
    setDetails((prev) => ({ ...prev, verificationSteps: _data }));
    setDetails((prev) => {
      proceed();
      return prev;
    });
  }

  function handleBackButtonPress() {
    if (stage === 1) {
      closeDrawer();
      // setLoadingCurrencies(false);
      // dispatch(toggleVerifyIdDrawer());
      sessionStorage.removeItem(RETURN_TO_WALLET_PORTFOLIO_FLOW);
    } else {
      setStage((prev) => prev - 1);
    }
  }

  const { data: countries, status: countriesStatus } = useSelector(selectKYCCountries);
  const {
    status: country_data_status,
    error: country_data_error,
  } = useSelector(selectKYCCountryLink);

  function getVerificationCurrencies() {
    dispatch(fetchKYCCountries());
  }

  useThunkEffectCalls(getVerificationCurrencies);

  useEffect(() => {
    if (countriesStatus === fetchStates.FETCHED || country_data_status === fetchStates.FETCHED) {
      setLoadingCurrencies(false);
    } else if (countriesStatus === fetchStates.FETCHING
      || country_data_status === fetchStates.FETCHING) {
      setLoadingCurrencies(true);
    } else if (countriesStatus === fetchStates.ERROR || country_data_status === fetchStates.ERROR) {
      // handle error
      if (country_data_status === fetchStates.ERROR) {
        setLoadingCurrencies(false);
        setStage(1);
        setDetails({
          currency: false,
          verificationSteps: false,
        });
        dispatch(
          setToggleToast({
            type: toastTypes.error,
            name: 'Verification Error',
            desc: country_data_error || 'Unable to get verification link',
          }),
        );
      }
    }
    dispatch(resetCountriesStatus());
    dispatch(resetCountryDataStatus());
  }, [countriesStatus, country_data_status, country_data_error]);

  return (
    <SubFlow
      onClose={() => closeDrawer()}
      isVisible={isOpen}
      title="Let’s get you verified"
      goBack
      goBackFunc={handleBackButtonPress}
    >
      <SubFlow.BodyBGWrapper>
        <div className="withdraw-wrapper">
          {loadingCurrencies ? (
            <LoadingPage fullscreen={false} />
          ) : (
            <>
              <ShowSection show={stage === 1}>
                <CurrencySelectStage
                  countries={countries}
                  setCanProceed={setCanProceed}
                  ref={currencyRef}
                  dataName="currency"
                />
              </ShowSection>

              <ShowSection show={stage === 2}>
                {/* <VerifyOptions
                data={details.currency}
                proceedData={(d) => proceedOptionSelect(d)}
              /> */}
                <KYCScreenFlow
                  data={details.currency}
                  proceedData={(d) => proceedOptionSelect(d)}
                />
              </ShowSection>

              {/* <ShowSection show={stage === 2}>
              <VerificationSteps
                selectedCurrency={details.currency}
                ref={verificationStepsRef}
              />
            </ShowSection> */}

              <ShowSection show={stage === 3}>
                <DocumentVerification
                  options={details}
                />
              </ShowSection>

              {(stage === 1) && (
              <div className="button-wrapper">
                <ButtonReuse
                  loading={loading}
                  isDisabled={!canProceed}
                  onClick={() => submit()}
                  btnStyle="btn-reuse"
                  text={t('dsh.proceed')}
                />
              </div>
              )}
            </>
          )}
        </div>
      </SubFlow.BodyBGWrapper>
    </SubFlow>
  );
}

export default VerifyDrawer;
