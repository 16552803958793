/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import WalletService from '../services/walletService';
import FiatTransferDataStore from '../services/FiatTransferDataStore';
import * as FiatTransferStates from '../states/FiatTransferStates';

const initialState = {
  modal: {
    isOpen: false,
  },
  userTransferData: {
    token: AuthService._getUserToken(),
    identifier: '',
    amount: '',
    currency: 'NGN',
    narration: '',
    pin: '',
  },
  fiatTransferState: FiatTransferStates.DEFAULT,
  toast: {
    isOpen: false,
    type: 'error',
    name: 'Error Message Here',
    desc: 'This is a descriptive error message here you can make it unique',
  },
  error: undefined,
};

export const initiateTransfer = createAsyncThunk('fiatTransfer/initiate', async (_, thunkAPI) => {
  try {
    const { userTransferData } = thunkAPI.getState().fiatTransfer;
    const response = await WalletService.transferFiat(userTransferData);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.toString());
  }
});

export const fiatTransferSlice = createSlice({
  name: 'fiatTransfer',
  initialState,
  reducers: {
    updateTransferData: (state, action) => {
      const { name, value } = action.payload;
      state.userTransferData = {
        ...state.userTransferData,
        [name]: value,
      };
    },
    updateTransferPin: (state, action) => {
      state.userTransferData.pin = action.payload;
    },
    proceedToConfirmPin: (state) => {
      FiatTransferDataStore.saveData(state.userTransferData);
      state.fiatTransferState = FiatTransferStates.FORM_VALIDATED;
    },
    resetTransferData: (state) => {
      state.userTransferData = {
        ...state.userTransferData,
        identifier: '',
        amount: '',
        narration: '',
        token: '',
      };
    },
    resetFiatTransferState: (state) => {
      state.fiatTransferState = FiatTransferStates.DEFAULT;
    },
    setShowToast: (state, action) => {
      state.toast.isOpen = !state.toast.isOpen;
      if (state.toast.isOpen && action.payload?.name && action.payload?.desc) {
        state.toast.name = action.payload?.name;
        if (action.payload.type) {
          state.toast.type = action.payload?.type;
        } else {
          state.toast.type = initialState.toast.type;
        }
        state.toast.desc = action.payload?.desc;
      } else {
        state.toast.name = initialState.toast.name;
        state.toast.desc = initialState.toast.desc;
      }
    },
    toggleTransferModalIsOpen: (state, action) => {
      state.modal.isOpen = !state.modal.isOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initiateTransfer.fulfilled, (state, action) => {
      FiatTransferDataStore.clearData();
      state.fiatTransferState = FiatTransferStates.COMPLETED;
    });
    builder.addCase(initiateTransfer.rejected, (state, action) => {
      FiatTransferDataStore.clearData();
      state.error = action.payload;
      state.fiatTransferState = FiatTransferStates.ERROR;
    });
  },
});

export const selectTransferModalState = (state) => state.fiatTransfer.modal;

export default fiatTransferSlice.reducer;
export const {
  updateTransferData,
  proceedToConfirmPin,
  resetFiatTransferState,
  updateTransferPin,
  setShowToast, toggleTransferModalIsOpen,
} = fiatTransferSlice.actions;
