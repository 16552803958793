/* eslint-disable */
import './TransactionCheckerDialog.css';
import SubFlow from '../subFlow';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideToolDialogs, toolDialogTypes } from '../../../slices/toolsSlice';
import ClipboardService from '../../../services/clipboardService';
import Select from '../Select';
import { useIcon } from '../../../hooks/useIcon';
import ButtonReuse from '../Button';
import InputGroup from '../InputGroup';
import ImagePlaceholder from '../ImagePlaceholder';
import getIconUrl from '../../../utils/getIconUrl';
import { setToggleToast } from '../../../slices/authSlice';
import useWindowSize from '../../../hooks/useWindowSize';

export default function TransactionCheckerDialog() {
  const dispatch = useDispatch();
  const { visibleToolDialog } = useSelector((state) => state.tools);
  const [currentStage, setCurrentStage] = useState(0);
  const { t } = useTranslation();

  function goBack() {
    if (currentStage == 0) {
      dispatch(hideToolDialogs())
    } else {
      setCurrentStage(0);
    }
  }

  return (
    <SubFlow
      title={t('dsh.txnChecker.txnChecker')}
      isVisible={visibleToolDialog === toolDialogTypes.transactionChecker}
      goBack
      goBackFunc={() => goBack()}
      onClose
    >
      <SubFlow.BodyBGWrapper>
        {currentStage === 0 ? (
          <TransactionInfoForm onComplete={() => setCurrentStage(1)} />
        ) : (
          <TransactionCheckResult />
        )}
      </SubFlow.BodyBGWrapper>
    </SubFlow>
  );
}

function TransactionInfoForm({ onComplete }) {
  const { chevronDown } = useIcon();
  const types = useMemo(() => [
    { text: 'Blockchain Hash', value: 'blockchain_hash' },
    { text: 'Roqqu ID', value: 'roqqu_id' }
  ], []);
  const chains = useMemo(() => [
    { text: 'TRC 20', value: 'trc_20' },
    { text: 'Bitcoin', value: 'bitcoin' },
    { text: 'Matic', value: 'matic' }
  ], []);
  const [txnHash, setTxnHash] = useState();
  const { t } = useTranslation();

  return (
    <div className="txn-info-form">
      <Select
        options={types}
        outsideLabel
        outsideLabelText={t('dsh.txnChecker.txnType')}
        placeholder={t('dsh.txnChecker.selectType')}
        textClassNames='placeholder'
        doesChevIconHaveBackgroundColor={false}
        chevIcon={chevronDown}
      />
      <div className="dropdown-spacing" />
      <Select
        options={chains}
        outsideLabel
        outsideLabelText={t('dsh.txnChecker.chain')}
        placeholder={t('dsh.txnChecker.selectChain')}
        textClassNames='placeholder'
        doesChevIconHaveBackgroundColor={false}
        chevIcon={chevronDown}
      />
      <div className="dropdown-spacing" />
      <InputGroup
        label={t('dsh.txnChecker.txnHash')}
        name="transactionHash"
        isTransparent
        placeholder={t('dsh.txnChecker.txnHash')}
        value={txnHash}
        onChange={(e) => setTxnHash(e.target.value)}
      />
      <ButtonReuse
        text={t('dsh.txnChecker.check')}
        onClick={onComplete}
        btnStyle="check-btn"
      />
    </div>
  );
}

function TransactionCheckResult() {
  const txnHash = '7rjKXhRuR7rjKXhRuRyaQboP1QSKryaQboP1QSKr';
  const [tabIndex, setTabIndex] = useState(0);
  const { mainCopyIcon } = useIcon();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  function copyHash(hash) {
    ClipboardService.writeToClipboard(hash).then(() => {
      dispatch(setToggleToast({
        name: 'Copied!',
        desc: 'Copied transaction hash successfully',
      }));
    }).catch((err) => {
      console.log("CopyingError:", err.message);
    });
  }

  function truncateHash(hash) {
    const lastIndex = hash.length - 1;
    const secondHalfStartingIndex = lastIndex - 9;
    return `${hash.substring(0, 9)}...${hash.substring(secondHalfStartingIndex)}`;
  }

  return (
    <div className="txn-check-result">
      <ImagePlaceholder src={getIconUrl('btc')} width='56px' height='56px' />
      <h3 className='txn-result-name'>{t('dsh.txnChecker.coinTxn', { coin: 'Bitcoin' })}</h3>
      <p className='txn-result-date'>{t('dsh.txnChecker.broadcastedOn', { date: '12 Mar 2024 05:51:53 GMT+1' })}</p>
      <section className="check-tab-btn-group">
        <button type="button" onClick={() => setTabIndex(0)} className={`check-tab-btn ${tabIndex === 0 && 'active'}`}>{t('dsh.txnChecker.overview')}</button>
        <button type="button" onClick={() => setTabIndex(1)} className={`check-tab-btn ${tabIndex === 1 && 'active'}`}>{t('dsh.txnChecker.advanced')}</button>
      </section>
      <section className="summary">
        <h4 className='summary-title'>{t('dsh.txnChecker.summary')}</h4>
        <div className='surface summary-surface'>
          <p className='summary-desc'>{t('dsh.txnChecker.summaryText', { time: 'March 12, 2024 at 05:03 AM GMT+1', status: 'unconfirmed', currency: '$', amount: '150.24' })}</p>
        </div>
      </section>
      <main className='surface txn-check-details'>
        <div className='item'>
          <h6 className='property'>{t('dsh.txnChecker.hash')}</h6>
          <div className='value-box row'>
            <p className='hash'>{isMobile ? truncateHash(txnHash) : txnHash}</p>
            <span className='bottom'>
              <img src={mainCopyIcon} alt='copy hash' className='copy-hash' onClick={() => copyHash(txnHash)} />
            </span>
          </div>
        </div>
        <div className='item'>
          <h6 className='property'>{t('dsh.txnChecker.amount')}</h6>
          <div className='value-box'>
            <p className='top'>0.00009003 BTC</p>
            <p className='bottom'>$150.5</p>
          </div>
        </div>
        <div className='item'>
          <h6 className='property'>{t('dsh.txnChecker.fee')}</h6>
          <div className='value-box'>
            <p className='top'>0.00009003 BTC</p>
            <p className='bottom'>$1.5</p>
          </div>
        </div>
        <div className='item'>
          <h6 className='property'>{t('dsh.txnChecker.from')}</h6>
          <div className='value-box'>
            <p className='top'>{isMobile ? truncateHash(txnHash) : txnHash}</p>
          </div>
        </div>
        <div className='item'>
          <h6 className='property'>{t('dsh.txnChecker.to')}</h6>
          <div className='value-box'>
            <p className='top'>{isMobile ? truncateHash(txnHash) : txnHash}</p>
          </div>
        </div>
        <div className='item'>
          <h6 className='property'>{t('dsh.txnChecker.status')}</h6>
          <div className='value-box'>
            <div className='status'>Successful</div>
          </div>
        </div>
        {
          tabIndex === 1 && (
            <div className='item'>
              <h6 className='property'>{t('dsh.txnChecker.blockId')}</h6>
              <div className='value-box'>
                <p className='top'>34,567,890</p>
              </div>
            </div>
          )
        }
      </main>
    </div>
  );
}
