/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import { useIcon } from '../../../hooks/useIcon';
import './Search.css';

const Search = ({
  // onLoad,
  // onChange,
  // iconClassNames,
  borderClassNames,
  textClassNames,
  showIcon = true,
  onChange,
  placeholder = 'Search',
  showTrailingIcon = false,
}) => {
  const { searchIcon } = useIcon();

  const handleChange = (evt) => {
    if (onChange) onChange(evt.target.value?.toLowerCase());
  };

  return (
    <div className={`search-component ${borderClassNames || ''}`}>
      {
        showIcon && (
          <figure className="search-icon-box">
            <img
              src={searchIcon}
              alt="search icon"
              className="search-icon"
            />
          </figure>
        )
      }
      <input
        type="text"
        name="searchQuery"
        id="search"
        placeholder={placeholder}
        className={`search-input ${textClassNames || ''}`}
        onChange={handleChange}
      />
      {
        showTrailingIcon && (
          <figure className="search-icon-box">
            <img
              src={searchIcon}
              alt="search icon"
              className="search-icon"
            />
          </figure>
        )
      }
    </div>
  );
};

export default Search;
