/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import './RoqqTextBox.css';

export default function RoqqTextBox({
  placeholder, name, type, value, onChange, className,
}) {
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState('');

  function handleChange(e) {
    setText(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <input
      className={`roqq-text-box ${className || ''}`}
      type={type || 'text'}
      placeholder={placeholder}
      onChange={handleChange}
      // value={text}
      value={value}
      name={name}
    />
  );
}
