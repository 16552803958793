/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonReuse from '../../../../../components/fragments/Button';
import InputGroup from '../../../../../components/fragments/InputGroup';
import LoaderContainer from '../../../../../components/fragments/LoaderContainer';
import WalletService from '../../../../../services/walletService';

const TransferRecipient = React.forwardRef(
  ({ dataName, setCanProceed, setShowProceedButton }, ref) => {
    const { t } = useTranslation();
    const initialDetails = { username: '', narration: '' };
    const [details, setDetails] = useState(initialDetails);
    const [showVerifyButton, setShowVerifyButton] = useState(true);

    const [nameLoading, setNameLoading] = useState(false);
    const [idName, setIdName] = useState();

    const handleChange = (e) => {
      const { name, value } = e.target;
      const data = { ...details, [name]: value };
      setDetails(data);
    };

    async function verifyCredentials() {
      setNameLoading(true);
      if (details.username) {
        const data = await WalletService.verifyRecipientDetails({
          identifier: details.username,
          provider: 'raven',
        });

        if (data !== false) {
          setIdName(data.data.data);
          setShowProceedButton(true);
          setShowVerifyButton(false);
        } else {
          // open toast here...
          setIdName({ fname: 'not', lname: 'found' });
        }
      }
      setNameLoading(false);
    }

    useEffect(() => {
      if (details.narration && idName?.fname) {
        setCanProceed(true);
      }
    }, [details.narration]);

    React.useImperativeHandle(ref, () => ({
      async submit() {
        // skip and pass data for the next screen
        return {
          name: dataName,
          ...idName,
          ...details,
        };
      },
    }));

    return (
      <div className="recipient-screen-container">
        <div className="input-section">
          <InputGroup
            label={t('dsh.wallet.enterUserUsername')}
            value={details.username}
            onChange={handleChange}
            onBlur={() => verifyCredentials()}
            name="username"
            placeholder={t('dsh.wallet.enterUsernameEmailOrPhoneNumber')}
          />

          {nameLoading && (
            <div style={{ marginTop: '1.6rem' }}>
              <LoaderContainer width={162} height={32}>
                <rect x="0" y="0" rx="18" ry="18" width="162" height="32" />
              </LoaderContainer>
            </div>
          )}
          {!nameLoading && idName?.fname && (
            <div className="id-name-tag container-color2">{`${idName?.fname} ${idName?.lname}`}</div>
          )}
        </div>

        <div className="input-section">
          <InputGroup
            label={t('dsh.wallet.enterNarration')}
            value={details.narration}
            onChange={handleChange}
            name="narration"
            placeholder={t('dsh.wallet.enterNarration')}
          />
        </div>

        {showVerifyButton && (
          <div className="button-wrapper">
            <ButtonReuse
              loading={nameLoading}
              isDisabled={!details.username}
              onClick={() => verifyCredentials()}
              btnStyle="btn-reuse"
              text={t('dsh.wallet.verifyDetails')}
            />
          </div>
        )}
      </div>
    );
  }
);

export default TransferRecipient;
