/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { useIcon } from '../../../hooks/useIcon';
import ButtonReuse from '../Button';

import './styles/index.css';

function NoInternet({
  name,
  retry = () => {},
  message = 'An Unknown Error occured while \nLoading all assets',
}) {
  const { notInternetConnection } = useIcon();
  return (
    <div className="no-connection">
      <div className="icon-container">
        <img className="icon-color" src={notInternetConnection} alt="nic" />
      </div>
      <div className="info-section">
        {name && <div className="name">{name}</div>}
        {message.split('\n').map((item, i) => (
          <React.Fragment key={i}>
            <p
              style={{
                marginBottom: i === message.split('\n').length - 1 && '0.5rem',
                marginTop: i !== 0 && '0',
              }}
              className="message secondary-white-black-color"
            >
              {item}
            </p>
          </React.Fragment>
        ))}
      </div>
      <div className="btn-container">
        <div className="btn-box">
          <ButtonReuse
            onClick={retry}
            btnStyle="btn-reuse retry-button"
            text="Retry"
          />
        </div>
      </div>
    </div>
  );
}

export default NoInternet;
