import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class LoginService {
  static async login(data) {
    const { response } = await post({
      url: apiRoutes.authenticate,
      data: {
        ...data,
        email: data.email.trim(),
      },
    });
    if (!response) {
      throw new Error('Failed to sign in. Check your internet connection or try again.');
    }

    if (response?.data?.status === 'fail') {
      throw new Error(response.data.message);
    }
    AuthService._saveEmail(data.email);
    // AuthService._saveUserPassword(data.password);
    // !please do not uncomment these as they are not needed and redundant.
    // const { email, extra_data } = response.data;
    // AuthService._saveUser({ email, ...extra_data });
    return response.data;
  }

  static async googleLogin(data) {
    const { response } = await post({
      url: apiRoutes.authenticateGoogle,
      data,
    });

    if (!response) {
      throw new Error('Failed to sign in. Check your internet connection or try again.');
    }

    if (response?.data?.status === 'fail') {
      throw new Error(response.data.message);
    }
    return response.data;
  }

  // Trigger Phone  Number Login
  static async phoneNumberLogin({ phone, dialCode }) {
    const wrongFormatNumberErrorMessage = 'phone number does not exist in our records';
    const { response } = await post({
      url: apiRoutes.phoneNumberLogin,
      data: { phone },
    });

    if (!response) {
      throw new Error('Failed to sign in. Check your Internet connection or try again.');
    }

    if (response?.data?.status === 'fail') {
      if (response.data.message === wrongFormatNumberErrorMessage) {
        const { response: retriedResponse } = await post({
          url: apiRoutes.phoneNumberLogin,
          data: {
            phone: `${dialCode}${phone.slice(1)}`,
          },
        });

        if (retriedResponse?.data?.status === 'fail') {
          throw new Error(retriedResponse.data.message);
        }
      } else {
        throw new Error(response.data.message);
      }
    }
    return response.data;
  }

  // Trigger Report Account
  static async reportAccount(data) {
    const { response } = await post({
      url: apiRoutes.reportAccount,
      data,
    });

    if (!response) {
      throw new Error('Failed to report account. Check your Internet connection or try again.');
    }

    if (response?.data?.status === 'fail') {
      throw new Error(response.data.message);
    }
    return response.data;
  }
}
