/* eslint-disable no-prototype-builtins */
import apiRoutes from '../http/apiRoutes';
import { get, post } from '../http/https';
import AuthService from './AuthService';

export default class BankingService {
  static async getMyBanks() {
    const { response } = await post({
      url: apiRoutes.getBanksAccounts,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async addABankService(data) {
    const response = await post({
      url: apiRoutes.addBankAccount,
      data: { ...data, token: AuthService._getUserToken() },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }

  static async deleteABankService(data) {
    const { response } = await post({
      url: apiRoutes.removeBankAccount,
      data,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async resolveBankAccountName(data) {
    const { response } = await post({
      url: apiRoutes.resolveBankAccountName,
      data: { ...data, token: AuthService._getUserToken() },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getAllBanks() {
    const { response } = await get({
      url: apiRoutes.getSupportedBanks,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async addHighRiskBank(data) {
    if (!data.hasOwnProperty('s_code') || !data.hasOwnProperty('e_code')) {
      throw new Error('something went wrong with the request');
    }
    const response = await post({
      url: apiRoutes.addBankAccount,
      data: { ...data, token: AuthService._getUserToken() },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    } else if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }

    return response.response;
  }

  static async sendPhoneCode() {
    const response = await post({
      url: apiRoutes.pushCode,
      data: { type: 'account_auth', route: 'sms', token: AuthService._getUserToken() },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    } else if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async sendEmailCode() {
    const response = await post({
      url: apiRoutes.pushCode,
      data: { type: 'account_auth', route: 'email', token: AuthService._getUserToken() },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    } else if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async checkEmailCode(code) {
    const response = await post({
      url: apiRoutes.checkCode,
      data: {
        type: 'account_auth',
        code,
        route: 'email',
        token: AuthService._getUserToken(),
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    } else if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async checkPhoneCode(code) {
    const response = await post({
      url: apiRoutes.checkCode,
      data: {
        type: 'account_auth',
        code,
        route: 'sms',
        token: AuthService._getUserToken(),
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    } else if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async getAccountConfiguration() {
    const response = await post({
      url: apiRoutes.accountConfiguration,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (!response.response) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }

  static async connectBank(currency) {
    const response = await post({
      url: apiRoutes.connectBank,
      data: {
        token: AuthService._getUserToken(),
        currency,
      },
    });

    if (!response.response) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }
}
