/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toolService from '../services/toolService';

export const toolDialogTypes = {
  rateChecker: 'rateChecker',
  networkChecker: 'networkChecker',
  transactionChecker: 'transactionChecker',
};

export const promiseStates = {
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

const initialState = {
  visibleToolDialog: null,
  rates: {
    loadingState: null,
    all: [
      {
        base: 'gbp',
        quote: 'ngn',
        baseName: 'British pounds',
        quoteName: 'Naira',
        quoteSym: '₦',
        rate: 1997.38,
        pnl: -0.61,
      },
      {
        base: 'gbp',
        quote: 'usd',
        baseName: 'British pounds',
        quoteName: 'US Dollar',
        quoteSym: '$',
        rate: 1.2636,
        pnl: -0.61,
      },
      {
        base: 'gbp',
        quote: 'ghs',
        baseName: 'British pounds',
        quoteName: 'Ghanaian Cedis',
        quoteSym: 'Ç',
        rate: 1.2636,
        pnl: -0.61,
      },
      {
        base: 'gbp',
        quote: 'ngn',
        baseName: 'British pounds',
        quoteName: 'Naira',
        quoteSym: '₦',
        rate: 1997.38,
        pnl: -0.61,
      },
      {
        base: 'gbp',
        quote: 'ngn',
        baseName: 'British pounds',
        quoteName: 'Naira',
        quoteSym: '₦',
        rate: 1997.38,
        pnl: -0.61,
      },
      {
        base: 'gbp',
        quote: 'ngn',
        baseName: 'British pounds',
        quoteName: 'Naira',
        quoteSym: '₦',
        rate: 1997.38,
        pnl: -0.61,
      },
    ],
  },
  network: {},
  transaction: {
    loadingState: null,
  },
};

export const getAllRates = createAsyncThunk(
  'tools/getAllRates',
  async (data, { rejectWithValue }) => {
    try {
      const res = await toolService.checkRates(data);
      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    showRateCheckerDialog: (state) => {
      state.visibleToolDialog = toolDialogTypes.rateChecker;
    },
    showNetworkCheckerDialog: (state) => {
      state.visibleToolDialog = toolDialogTypes.networkChecker;
    },
    showTransactionCheckerDialog: (state) => {
      state.visibleToolDialog = toolDialogTypes.transactionChecker;
    },
    hideToolDialogs: (state) => {
      state.visibleToolDialog = null;
    },
  },
});

export const selectRates = (state) => state.tools.rates;
export const selectTransactionChecker = (state) => state.tools.transaction;
export const {
  showRateCheckerDialog,
  showNetworkCheckerDialog,
  showTransactionCheckerDialog,
  hideToolDialogs,
} = toolsSlice.actions;
export default toolsSlice.reducer;
