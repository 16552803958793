import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import registrationRoutes from '../../navigation/auth/registrationRoutes';

function RedirectedSignup() {
  const [searchParams] = useSearchParams();
  return (
    <Navigate to={`${registrationRoutes.startRegistration}?ref=${searchParams.get('ref')}`} replace />
  );
}

export default RedirectedSignup;
