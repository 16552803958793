/* eslint-disable react/prop-types */
import React from 'react';
import './RoqqPill.css';

function RoqqPill({ text, className }) {
  return (
    <div className={`text-pill ${className || ''}`}>
      {text}
    </div>
  );
}

export default RoqqPill;
