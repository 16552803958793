import React from 'react';

/* eslint-disable import/prefer-default-export */
const Transactions = React.lazy(() => import('../../../pages/dashboard/transactions'));
const TransactionDetails = React.lazy(() => import('../../../pages/dashboard/transactions/TransactionDetails'));

export const Dashboard_transactions_route_group = [
  { path: '/dashboard-transactions', component: <Transactions /> },
  {
    path: '/dashboard-transactions-single-details',
    component: <TransactionDetails />,
  },
];
