import React, { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';

import userImg from '../../../../../../assets/user-profile-img.png';
import logoImg from '../../../../../../assets/roqqu-nav-logo.svg';
import ImagePlaceholder from '../../../../../../components/fragments/ImagePlaceholder';
import { getDummyUserImage } from '../../../../../../utils/getIconUrl';
import useUser from '../../../../../../hooks/useUser';
import './style.css';
import TooltipIcon from '../../../../../../components/fragments/TooltipIcon';
import { useIcon } from '../../../../../../hooks/useIcon';
import LanguageDropdown from '../../../../../../components/layouts/dashboard/LanguageDropdown';

function RoqqNavBar() {
  const { user } = useUser();

  const {
    globeWhiteIcon,
    signOutWhite,
  } = useIcon();

  // eslint-disable-next-line no-unused-vars
  const [openSignOut, setOpenSignOut] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);

  return (
    <div className="roqq-nav-container roqq-nav-container-wrapper">
      <div className="roqq-nav-shadow" />
      <div className="roqq-nav">
        <div className="roqq-nav-wrapper roqq-container-lg">
          <div className="brand-logo">
            <figure className="img-box">
              <img src={logoImg} alt="" className="img" />
            </figure>
          </div>
          <div className="profile-container header-nav-content">
            <div className="profile-badge-container">
              <ImagePlaceholder
                src={
                  user?.fname && user?.lname
                    ? getDummyUserImage({ fname: user?.fname, lname: user?.lname })
                    : userImg
                }
                fallback={userImg}
              />
              <div
                className="name-email-box line-clamp-1"
              >
                <p className="name">
                  {user?.fname}
                  {' '}
                  {user?.lname}
                </p>
                {/* <p className="email">ayenkeh@gmail.com</p> */}
              </div>
              <FaAngleRight
                style={{ marginRight: '0', height: 'fit-content' }}
                className="icon options-icon"
                // onClick={toggleDropdown}
              />
            </div>
            <div style={{ position: 'relative', zIndex: '99999' }}>
              {/* <div style={{ position: 'relative' }}> */}
              <TooltipIcon
                name="Language"
                image={globeWhiteIcon}
                // action={() => setLanguageDropdown(!languageDropdown)}
              />
              <LanguageDropdown
                visible={languageDropdown}
                onClose={() => setLanguageDropdown(false)}
              />
            </div>

            <TooltipIcon
              className="nav-logout"
              name="Logout"
              image={signOutWhite}
              action={() => setOpenSignOut(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoqqNavBar;
