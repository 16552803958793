/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

import './RoqqNegativeButton.css';

function RoqqSecondaryButton({ className, text = '', onClick = () => { } }) {
  return (
    <div onClick={onClick} className={`roqq-secondary-container ${className || ''}`}>
      <div className="roqq-secondary-wrapper-1">
        <div className="roqq-secondary-wrapper-2">
          {text}
        </div>
      </div>
    </div>
  );
}

export default RoqqSecondaryButton;
