/* eslint-disable max-len */

import React from 'react';

/* eslint-disable import/prefer-default-export */
const DashboardLoanIndex = React.lazy(() => import('../../../../pages/dashboard/home/loans'));
// import ConfirmTransactionLoans from '../../../../pages/dashboard/home/loans/ConfirmTransactionLoan';
// import DashboardLoanMain from '../../../../pages/dashboard/home/loans/DashboardLoanMain';

export const Dashboard_home_loan_route_group = [
  { path: '/dashboard-home-loans', component: <DashboardLoanIndex /> },
  // { path: '/dashboard-home-loans-confirm-transaction', component: ConfirmTransactionLoans },
  // { path: '/dashboard-home-loans-main', component: DashboardLoanMain },
];
