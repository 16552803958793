/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../services/UserServices';

export const fetchStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

export const CoinCreationStates = {
  BASE: 'base',
  CREATING: 'creating',
  ERROR: 'error',
  CREATED: 'created',
};

export const FavoritingStates = {
  BASE: 'base',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

const initialState = {
  token: '',
  authenticated: false,
  userBalance: {
    status: fetchStates.BASE,
    data: null,
    error: false,
  },
  coinCreationState: CoinCreationStates.BASE,
  verifyId: {
    isOpen: false,
    modalIsOpen: false,
  },
  favoriting: {
    state: FavoritingStates.BASE,
    coins: null,
  },
  unfavoriting: {
    state: FavoritingStates.BASE,
    coins: null,
  },
  userPreferences: {
    status: fetchStates.BASE,
    data: null,
    error: false,
  },
  favouriteCurrencies: {
    favoritingStatus: fetchStates.BASE,
    favoritingError: false,
    favoritingMessage: '',
    status: fetchStates.BASE,
    data: [],
    error: false,
  },
};

export const fetchUserBalance = createAsyncThunk(
  'user/fetchUserBalance',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UserService.getUserBalance();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const createNewCoinBalance = createAsyncThunk(
  'user/createNewCoinBalance',
  async (symbol, { rejectWithValue }) => {
    try {
      const { data } = await UserService.createNewCoinBalance(symbol);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const createNewCoinBalances = createAsyncThunk(
  'user/createNewCoinBalances',
  async (symbols, { rejectWithValue }) => {
    try {
      const data = await UserService.createNewCoinBalances(symbols);
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const favoriteCoins = createAsyncThunk(
  'user/favoriteCoin',
  async (symbols, { rejectWithValue }) => {
    try {
      const data = await UserService.favoriteCoins(symbols);
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const unfavoriteCoins = createAsyncThunk(
  'user/unfavoriteCoin',
  async (symbols, { rejectWithValue }) => {
    try {
      const data = await UserService.unfavoriteCoins(symbols);
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const saveUserPreference = createAsyncThunk(
  'user/saveUserPreference',
  async (_data, { rejectWithValue }) => {
    try {
      const data = await UserService.saveUserPreferences(_data);
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const fetchUserFavouriteCurencies = createAsyncThunk(
  'user/fetchUserFavouriteCurencies',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UserService.getUserFavouriteCurrencies();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const saveToFavouriteCurrencies = createAsyncThunk(
  'user/saveToFavouriteCurrencies',
  async (currency, { rejectWithValue }) => {
    try {
      const { data } = await UserService.saveToFavouriteCurrencies(currency);
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

/**
 * This slice will be used to manage the state of the user across the whole app
 * Other state data will be added as we progress with the project
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {},
    getToken: (state, action) => {},
    authenticate: (state, action) => { },
    toggleVerifyIdDrawer: (state) => {
      state.verifyId.isOpen = !state.verifyId.isOpen;
    },
    togglevVerifyIdModal: (state) => {
      state.modalIsOpen.isOpen = !state.modalIsOpen.isOpen;
    },
    resetUserSlice: (state) => {
      state.userBalance = initialState.userBalance;
    },
    updateGemBalance: (state) => {
      if (state.userBalance.data?.others) {
        const _others = [...state.userBalance.data.others];
        _others[0].available_bal = `${parseFloat(_others[0].available_bal, 10) - 10.0}`;
        state.userBalance.data.others = _others;
      }
    },
    resetUserPreferences: (state) => {
      state.userPreferences.status = initialState.userPreferences.status;
      state.userPreferences.error = initialState.userPreferences.error;
    },
    updateFiatBalance: (state, action) => {
      if (state.userBalance.data && state.userBalance.data?.fiat) {
        const changeCurrency = action.payload?.currency;
        const changeAmount = action.payload?.amount;
        if (changeCurrency && changeAmount) {
          const balances = { ...state.userBalance.data };
          const fiatIndex = balances.fiat.findIndex((d) => d.currency === changeCurrency);
          balances.fiat[fiatIndex].available_bal = `${changeAmount}`;
          state.userBalance.data = balances;
        }
      }
    },
    updateCryptoBalance: (state, action) => {
      if (state.userBalance.data && state.userBalance.data?.crypto) {
        const changeCurrency = action.payload?.currency;
        const changeAmount = action.payload?.amount;
        if (changeCurrency && changeAmount) {
          const balances = { ...state.userBalance.data };
          const cryptoIndex = balances.crypto.findIndex((d) => d.currency === changeCurrency);
          balances.crypto[cryptoIndex].available_bal = `${changeAmount}`;
          state.userBalance.data = balances;
        }
      }
    },
    resetFavouriteCurrency: (state, action) => {
      state.favouriteCurrencies.favoritingStatus = initialState
        .favouriteCurrencies
        .favoritingStatus;
      state.favouriteCurrencies.favoritingError = initialState.favouriteCurrencies.favoritingError;
      state.favouriteCurrencies.favoritingMessage = initialState
        .favouriteCurrencies
        .favoritingMessage;
      // state.favouriteCurrencies.error = initialState.favouriteCurrencies.error;
      // state.favouriteCurrencies.status = initialState.favouriteCurrencies.status;
    },
  },
  extraReducers: (builder) => {
    // fetchUserBalance thunk reducers...
    builder.addCase(fetchUserBalance.pending, (state, action) => {
      state.userBalance.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchUserBalance.fulfilled, (state, action) => {
      state.userBalance.data = action.payload;
      state.userBalance.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchUserBalance.rejected, (state) => {
      state.userBalance.status = fetchStates.ERROR;
    });

    // createNewCoinBalance thunk reducers...
    builder.addCase(createNewCoinBalance.pending, (state, action) => {
      state.coinCreationState = CoinCreationStates.CREATING;
    });
    builder.addCase(createNewCoinBalance.fulfilled, (state, action) => {
      state.coinCreationState = CoinCreationStates.CREATED;
    });
    builder.addCase(createNewCoinBalance.rejected, (state) => {
      state.coinCreationState = CoinCreationStates.ERROR;
    });

    // createNewCoinBalances thunk reducers...
    builder.addCase(createNewCoinBalances.pending, (state, action) => {
      state.coinCreationState = CoinCreationStates.CREATING;
    });
    builder.addCase(createNewCoinBalances.fulfilled, (state, action) => {
      state.coinCreationState = CoinCreationStates.CREATED;
    });
    builder.addCase(createNewCoinBalances.rejected, (state) => {
      state.coinCreationState = CoinCreationStates.ERROR;
    });

    // favoriteCoins reducers
    builder.addCase(favoriteCoins.pending, (state, action) => {
      state.favoriting.state = FavoritingStates.PENDING;
      state.favoriting.coins = action.payload;
    });
    builder.addCase(favoriteCoins.fulfilled, (state, action) => {
      state.favoriting.state = FavoritingStates.FULFILLED;
    });
    builder.addCase(favoriteCoins.rejected, (state, action) => {
      state.favoriting.coins = null;
      state.favoriting.state = FavoritingStates.REJECTED;
    });

    // unfavoriteCoins reducers
    builder.addCase(unfavoriteCoins.pending, (state, action) => {
      state.unfavoriting.state = FavoritingStates.PENDING;
    });
    builder.addCase(unfavoriteCoins.fulfilled, (state) => {
      state.unfavoriting.state = FavoritingStates.FULFILLED;
    });
    builder.addCase(unfavoriteCoins.rejected, (state) => {
      state.unfavoriting.coins = null;
      state.unfavoriting.state = FavoritingStates.REJECTED;
    });

    // saveUserPreference reducers
    builder.addCase(saveUserPreference.pending, (state, action) => {
      state.userPreferences.status = fetchStates.FETCHING;
    });
    builder.addCase(saveUserPreference.fulfilled, (state) => {
      state.userPreferences.status = fetchStates.FETCHED;
    });
    builder.addCase(saveUserPreference.rejected, (state, action) => {
      state.userPreferences.status = fetchStates.ERROR;
      state.userPreferences.error = action.payload;
    });

    // fetchUserFavouriteCurencies reducers
    builder.addCase(fetchUserFavouriteCurencies.pending, (state) => {
      state.favouriteCurrencies.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchUserFavouriteCurencies.fulfilled, (state, action) => {
      state.favouriteCurrencies.status = fetchStates.FETCHED;
      state.favouriteCurrencies.data = action.payload;
    });
    builder.addCase(fetchUserFavouriteCurencies.rejected, (state, action) => {
      state.favouriteCurrencies.status = fetchStates.ERROR;
      state.favouriteCurrencies.error = action.payload;
    });

    // saveToFavouriteCurrencies reducers
    builder.addCase(saveToFavouriteCurrencies.pending, (state) => {
      state.favouriteCurrencies.favoritingStatus = fetchStates.FETCHING;
    });
    builder.addCase(saveToFavouriteCurrencies.fulfilled, (state, action) => {
      state.favouriteCurrencies.favoritingStatus = fetchStates.FETCHED;
      state.favouriteCurrencies.favoritingMessage = action.payload.message;
      if (action.payload.data) {
        state.favouriteCurrencies.data = action.payload.data;
      }
    });
    builder.addCase(saveToFavouriteCurrencies.rejected, (state, action) => {
      state.favouriteCurrencies.favoritingStatus = fetchStates.ERROR;
      state.favouriteCurrencies.favoritingError = action.payload;
    });
  },
});

export const selectUserBalance = (state) => state.user.userBalance;
export const selectVerifyyId = (state) => state.user.verifyId;
export const selectCoinCreationState = (state) => state.user.coinCreationState;
export const selectUserPreferences = (state) => state.user.userPreferences;
export const selectFavouriteCurrencies = (state) => state.user.favouriteCurrencies;
export const selectFavoriting = (state) => ({
  favoriting: state.user.favoriting,
  unfavoriting: state.user.unfavoriting,
});

export default userSlice.reducer;
export const {
  setToken, getToken, authenticate, resetUserPreferences,
  resetUserSlice, toggleVerifyIdDrawer,
  togglevVerifyIdModal, updateGemBalance,
  updateFiatBalance, updateCryptoBalance,
  resetFavouriteCurrency,
} = userSlice.actions;
