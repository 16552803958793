/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';

export const THEME_MODES = {
  dark: 'dark',
  light: 'light',
  black: 'black',
};

// 'dark' | 'light' | 'black'
export const useDarkMode = (def) => {
  // const [color, set]
  const [theme, setTheme] = useState(def);

  const setMode = (mode) => {
    setTheme(mode);
    window.localStorage.setItem('theme', mode);
  };

  const toggleTheme = (_theme) => {
    if (_theme) {
      setMode(_theme);
    } else {
      setMode(THEME_MODES.black);
    }
    // theme === 'dark' ? setMode('light') : setMode('dark');
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');

    localTheme ? setTheme(localTheme) : setMode(THEME_MODES.black);
  }, [def]);

  return [theme, toggleTheme];
};

// We might need to revert to this if the dark/light glitching continues
// if (def === 'dark') {
//   document.body.style.background = '#252525'
// }
// if (def === 'light') {
//   document.body.style.background = '#fafaff';
// }
