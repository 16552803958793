const ChartHelper = {
  convertToLineChartSeries(data) {
    const result = data.map((item) => [Date.parse(item.created_at), item.price]);
    return result;
  },
  calculateAverageChange(data) {
    let totalChange = 0;
    let averageChange;
    data.forEach((item) => {
      totalChange += item._change;
    });
    if (totalChange === 0) {
      averageChange = 0;
    } else {
      averageChange = totalChange / data.length;
    }
    if (Number.isNaN(averageChange)) throw new Error('average change is not a number');
    return averageChange;
  },
  async fetchChartData(symbol) {
    const response = await fetch(`https://loader1.roqqu.com/stream1/history?symbol=${symbol}`);
    const responseJson = await response.json();
    return responseJson;
  },
};

export default ChartHelper;
