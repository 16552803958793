/* eslint-disable object-curly-newline */
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class WalletWithdrawalService {
  static async withdrawToBank({
    amount, account, currency, pin,
  }) {
    const res = await post({
      url: apiRoutes.withdrawFiat,
      data: {
        token: AuthService._getUserToken(),
        amount,
        account,
        currency,
        pin,
      },
    });

    if (res.response === false) {
      throw new Error('Unable to withdraw');
    }
    if (res.response.data.status === 'fail') {
      throw new Error(res.response.data.message);
    }
    return res.response.data;
  }

  static async withdrawToMomo({ mobile, amount }) {
    const res = await post({
      url: apiRoutes.withdrawToMomo,
      data: {
        token: AuthService._getUserToken(),
        mobile,
        amount,
      },
    });

    if (res.response === false) {
      throw new Error(res?.error?.response?.data?.message);
    }

    if (res.response.data.status === 'fail') {
      throw new Error(res.response.data.message);
    }
    return res.response.data;
  }

  static async withdrawToP2P({
    currency, route, provider, amount, recipient, sender, pin,
  }) {
    const response = await post({
      url: apiRoutes.initiateP2PWithdraw,
      data: {
        token: AuthService._getUserToken(),
        currency,
        provider,
        route,
        amount,
        recipient, // Identifier of user, email, phone number or username
        sender, // Phone number of the peer selected to process p2p payment
        pin,
      },
    });

    if (response.response === false) {
      throw new Error('Unable to withdraw');
    }
    if (response.response.data.status === 'fail' || response.response.data.success === 'false') {
      throw new Error(response.response.data.message);
    }

    return response.response.data;
  }

  static async getP2PMerchantDetails({ currency, route, provider, amount }) {
    const res = await post({
      url: apiRoutes.getWithdrawalP2PDetails,
      data: {
        token: AuthService._getUserToken(),
        currency,
        route,
        provider,
        amount,
      },
    });

    if (res.response === false) {
      throw new Error(res.error.response.data.message);
    }

    return res?.response?.data?.data;
  }
}
