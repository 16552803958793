const authRoutes = {
  getStarted: '/get-started',
  signIn: '/login',
  signInWithEmail: '/login/email',
  accountToLoginTo: '/login-with',
  reportAccount: '/report-account',
  verifyLoginAccount: '/verify-device',
  verifyAccount: '/verify-account',
  setYourPin: '/set-your-pin',
  confirmPin: '/confirm-pin',
  forgetPassword: '/forget-password',
  legacySetPassword: '/account/token/:passwordToken',
  setNewPassowrd: '/set-new-password/:passwordToken',
  signUpComplete: '/signup-complete',
  banned: '/banned',
};

export const getAuthRoute = (route) => `/auth/${route}`;

export default authRoutes;
