import React from 'react';

/* eslint-disable import/prefer-default-export */
const HelpAndSupport = React.lazy(() => import('../../../pages/dashboard/help&support'));
const HelpSupportCategory = React.lazy(() => import('../../../pages/dashboard/help&support/HelpSupportCategory'));
const HelpSupportArticle = React.lazy(() => import('../../../pages/dashboard/help&support/HelpSupportArticle'));

export const Help_support_route_group = [
  { path: '/dashboard-help-support', component: <HelpAndSupport /> },
  { path: '/dashboard-help-support-categories', component: <HelpSupportCategory /> },
  { path: '/dashboard-help-support-article/:title', component: <HelpSupportArticle /> },
];
