/* eslint-disable */
import uuid from 'react-uuid';
import ImagePlaceholder from '../../../../../components/fragments/ImagePlaceholder';
import getIconUrl from '../../../../../utils/getIconUrl';
import './RibsIconGroup.css';

function calulateLeftOffset(index) {
  return index * 1.4;
}

function calculateWidth(coinSymbols, iconSize) {
  const iconSizeAsFloat = parseFloat(iconSize.substring(0, (iconSize.length - 1)));
  const width = ((coinSymbols.length) * iconSizeAsFloat) - (coinSymbols.length * 0.33 * iconSizeAsFloat);
  return width;
}

export default function RibsIconGroup({ coinSymbols, iconSize="20px" }) {
  return (
    <div className="ribs-icon-group" style={{ width: `${calculateWidth(coinSymbols, iconSize)}px`, height: iconSize }}>
      <div className="inner" style={{ width: iconSize, height: iconSize }}>
        {
          coinSymbols.map((symbol, index) => (
            <div
              key={uuid()}
              style={{
                position: 'absolute',
                left: `${calulateLeftOffset(index)}rem`,
                height: '100%'
              }}
            >
              <RibIcon
                key={uuid()}
                coinSymbol={symbol}
                size={iconSize}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

function RibIcon({ coinSymbol, size }) {
  return (
    <div className="ribs-icon" style={{ width: size, height: size }}>
      <ImagePlaceholder height={size} width={size} src={getIconUrl(coinSymbol)} />
    </div>
  );
}
