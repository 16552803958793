const apiRoutes = {
  // registration
  register: '/user/register',
  googleRegister: '/user/register/google',
  verifyEmail: '/user/verify/email',
  resendVerEmail: '/user/resend-ver-email',
  entryVerify: '/verify-entry',
  sendSecurityCode: '/security/push-code',
  checkSecurityCode: '/security/check-code',

  // Authentification and password
  authenticate: '/user/authenticate',
  authenticateVerify: '/user/auth/verify',
  authenticateGoogle: '/user/authenticate/google',
  passwordReset: '/account/reset',
  passwordResetTokenValidate: '/account/verify',
  passwordResetFinal: '/account/update',
  signOut: '/user/signout',
  setUsername: '/user/set-username',
  phoneNumberLogin: '/user/authenticate/phone/init',
  phoneNumberVerify: '/user/authenticate/phone',
  reportAccount: '/user/authenticate/report',

  // Userinfo
  getUserInfo: '/user/get-info',
  getUserTransactions: '/user/transactions',
  getUserBalance: '/user/balances',

  // Banks and devices
  resolveBankAccountName: '/user/account-resolve',
  addBankAccount: '/user/add-account',
  getBanksAccounts: '/user/accounts',
  getSupportedBanks: '/banks',
  removeBankAccount: '/user/remove-account',
  getDevices: '/user/devices',
  removeDevice: '/user/remove-device',
  deactivateAccount: '/user/deactivate-account',
  connectBank: '/user/connect-account',
  accountConfiguration: '/user/account-configurations',
  // High-rish banks
  pushCode: '/user/security/push-code',
  checkCode: '/user/security/check-code',

  // Mobile Money
  getMomoProviders: '/user/fiat/momo-providers',
  addMomoAccount: '/user/fiat/add-momo',
  removeMomoAccount: '/user/fiat/remove-momo',
  withdrawToMomo: '/user/fiat/withdraw-momo',

  // 2FA
  enable2FA: '/user/enable-auth',
  disable2FA: '/user/disable-auth',
  setUp2FA: '/user/set-up-google-auth',
  confirm2FA: '/user/confirm-auth',
  reset2FA: '/user/reset-2fa',
  reset2FAConfirm: '/user/reset-2fa-confirm',

  // Account Config
  getUserSettings: '/user/account/settings',
  newsletterSubscribe: '/user/account/settings/newsletter/subscribe',
  newsletterUnsubscribe: '/user/account/settings/newsletter/unsubscribe',
  saveUserPreference: '/user/save-preferences',

  // Wallets
  getUserWallets: '/user/get-wallets',
  generateWallet: '/user/generate-wallet',
  deleteWallets: '/user/delete-wallet',

  // Withdrawal
  withdrawFiat: '/user/bank/withdraw-fiat',
  getPendingWithdrawals: '/user/bank/pending-withdrawals',
  cancelBankWithdrawal: '/user/bank/cancel-withdrawal',
  getCurrencyWithdrawRoutes: '/user/fiat/withdraw',
  getRouteWithdrawOptions: '/user/fiat/withdraw-options',
  verifyIdentifier: '/user/fiat/verify-identifier',
  getWithdrawalP2PDetails: '/user/fiat/withdraw-peers',
  initiateP2PWithdraw: '/user/fiat/withdraw-p2p',

  // Deposit
  getCurrencyDepositRoutes: '/user/fiat/deposit',
  getRouteDepositOptions: '/user/fiat/deposit-options',
  getDepositDetailsForPaymentLinks: '/user/fiat/deposit-payment-link',
  getRouteDepositDetailsP2P: '/user/fiat/deposit-p2p',
  getRouteDepositDetailsBankTransfer: '/user/fiat/deposit-bank',

  // Transfer
  verifyRecipient: '/user/user-verify',
  transferFiat: '/user/fiat-transfer',

  // coin
  getCoinPrice: '/price',
  getFees: '/fees',
  createTokenBalance: '/user/create-token-balance',
  favoriteCoin: '/user/favorite-token',
  coinMetaData: '/token-metadata/',

  // Coin Transfer
  detectNetworkOfAddress: '/user/wallet/detect-network',
  verifyUsername: '/user/get-username',
  getTransactionExplorerLink: '/user/wallet/networks',
  verifyAccount: '/user/wallet/verify-account',
  recentCoinTransfers: '/user/wallet/recent-transfers',
  coinTransfer: '/user/wallet/transfer',

  // Referrals
  getReferrals: '/user/referrals',

  // Prices and Conversions
  getTokenHistory: '/history?symbol=',
  getCurrencyRate: '/rate/usd-ngn', // 1 USD = x NGN
  getExchangeRate: '/rate/',
  getAllCurrencyRate: '/rates',
  getAllTokens: '/all-tokens',
  getPrices: '/prices',
  updatePrimaryCurrency: '/user/update-primary-currency',
  getSupportedCountries: '/countries',

  // Price alert
  getPriceAlerts: '/user/alerts',
  createPriceAlert: '/user/create-alert',
  deletePriceAlert: '/user/disable-alert',

  // Payment Link
  createPaymentLink: '/user/create-payment-link',
  uploadBrand: '/user/upload_brand',
  getAllPaymentLink: '/user/payment-links',
  disablePaymentLink: '/user/disable-link',
  getPaymentOrders: '/user/payment-orders',

  // savings
  getUserSaving: '/user/savings',
  getSavingRates: '/user/saving-rates',
  createNewUSDTSaving: '/user/save',
  withdrawUSDTSaving: '/user/saving-withdraw',

  // Bills
  getSubscription: '/user/bills/data-subscriptions',
  loadAirtime: '/user/bills/load-airtime',
  loadData: '/user/bills/load-data',

  // cards
  getAllCards: '/user/all-cards',
  getCard: '/user/get-card',
  getCardTransactions: '/user/card-transactions',
  createNewCard: '/user/create-card',
  fundCard: '/user/fund-card',
  withdrawFromCard: '/user/withdraw-card',

  // loans
  getUserOverdraft: '/user/overdrafts',
  applyOverdraft: '/user/apply-overdraft',
  paybackOverdraft: '/user/payback-overdraft',
  overdraftSettings: '/user/overdraft-settings',

  // trade
  buy: '/user/buy',
  sell: '/user/sell',
  swap: '/user/swap',
  swapCurrency: '/user/currency-swap',
  trade24hAnalytics: '/user/analytics/trade-summary',

  // Bill Payments
  rechargeAirtime: '/user/bills/load-airtime',
  getDataSubscriptions: '/user/bills/data-subscriptions',
  rechargeData: '/user/bills/load-data',
  verifyIUCnumber: '/user/bills/verify-iuc',
  getCableSubscriptions: '/user/bills/cable-subscriptions',
  rechargeCable: '/user/bills/load-cable',
  getElectricProvider: '/user/bills/electricity-providers',
  verifyMeter: '/user/bills/verify-meter',
  rechargeElectricityMeter: '/user/bills/load-electricity',

  // Reset Pin
  resetPin: '/user/reset-pin',
  resetPinConfirmation: '/user/reset-pin-confirm',

  // KYC
  getKYCCountries: '/user/kyc/get-available-countries',
  getSelectedKycLink: '/user/kyc/get-link',

  // Notification
  getNotifications: '/user/notifications',
  markAsRead: '/user/notifications/read',
  markAllAsRead: '/user/notifications/read-all',

  // News
  getAllNews: '/news/cmc',

  // Raffle
  getPoolPrize: '/lottery/get-poolprize',
  getUserRaffleEntries: '/lottery/get-user-entries',
  playRaffle: '/lottery/enter',
  testPlayRaffle: '/lottery/test-win',
  canPlayFreeRaffle: '/lottery/check',
  socialShareCheck: '/lottery/share',

  // Currencies
  getFavouriteCurrencies: '/user/currencies/favourites',
  addToFavouriteCurrencies: '/user/currencies/favourite',

  // RIBS
  getAllBags: '/bags',
  buyBag: '/bags/buy',
  getUserBags: '/bags/user',
  getSingleBag: '/bags/user/single',
  dumpSingleBag: '/bags/dump',
  // withdrawWholeBagPartially
  dumpBagPartially: '/bags/dump/partial',
};

export default apiRoutes;
