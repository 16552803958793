/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Fragment, useContext } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';

import './RIBsQuestions/stages/styles/index.css';
import { useIcon } from '../../../hooks/useIcon';
import AccordionStateContext from '../../../context/AccordionContext';
import { setSelectedBag } from '../../../features/ribs/application/ribsApplicationSlice';
import ImagePlaceholder from '../../fragments/ImagePlaceholder';
import getIconUrl from '../../../utils/getIconUrl';
import BagDropdown from './RIBsQuestions/stages/BagDropdown';
import ButtonReuse from '../../fragments/Button';

function BagItem({
  item, toggleActiveIndex, index,
  onSelectBag = () => null,
}) {
  const { chevronDown } = useIcon();
  const dispatch = useDispatch();
  const activeAccordionIndex = useContext(AccordionStateContext);

  function selectBag() {
    onSelectBag();
    dispatch(setSelectedBag(item));
  }

  return (
    <div className="bag-item-container">
      <div className="bag-item" onClick={() => toggleActiveIndex && toggleActiveIndex()}>
        <div className="decription-section">
          <div className="coin-preview">
            <div className="">
              {item.coins.slice(0, 5).map((d) => (
                <Fragment key={uuid()}>
                  <div className="coin-preview-item">
                    <ImagePlaceholder src={getIconUrl(d.symbol)} />
                  </div>
                </Fragment>
              ))}
            </div>
            {item.coins.length > 5 && (
            <p className="coin-preview-additional secondary-white-black-color3">
              +
              {item.coins.length - 5}
              {' '}
              more
            </p>
            )}
          </div>
          <p className="bag-title">
            {item.bagName}
          </p>
        </div>
        {toggleActiveIndex ? (
          <img src={chevronDown} width={16} alt="" />
        ) : (
          <div>
            <ButtonReuse
                  // onClick={() => setNewToCryptoAnswer('yes')}
              btnStyle="yes-no-btn secondary-white-black-color3"
              text="Inspect"
            />
          </div>
        )}
      </div>
      <BagDropdown
        onSelect={() => selectBag()}
        coins={item.coins}
        isOpen={index === activeAccordionIndex}
      />
    </div>
  );
}

export default BagItem;
