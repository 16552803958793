/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonReuse from '../../../../components/fragments/Button';
import SubFlowLayout from '../../../../components/fragments/subFlow';
import WelcomeScreen from './TransferWelcomeScreen';
import ChooseCurrency from '../withdraw/ChooseCurrency';
import ConfirmProcess from '../withdraw/ConfirmProcess';
import flowType from '../withdraw/flowType';
import transactionTypes from '../TransactionTypes';
import TransferRecipient from './TransferRecipient';
import ContinueToKYC from '../../../../components/fragments/ContinueToKYC';
// Find styles @AddCoinDrawer.css ln851

function Transfer({ onClose, isVisible, openTransferSuccessModal }) {
  const { t } = useTranslation();
  const initialDetails = {
    // intro: false,
    currency: false, // data after currency selection
    recipient: false, // data after entering recipient data
    confirmTransaction: false,
  };
  const [showProceedButton, setShowProceedButton] = useState(true);
  const [details, setDetails] = useState(initialDetails);
  const [stage, setStage] = useState(null);

  const heroRef = useRef(null);
  const currencyRef = useRef(null);
  const recipientRef = useRef(null);
  const confirmTransactionRef = useRef(null);
  const [showUpgradeLimit, setShowUpgradeLimit] = useState(false);

  const stages = [
    // {
    //   id: 1,
    //   dataName: 'intro',
    //   name: 'Select Route',
    //   ref: heroRef,
    // },
    {
      id: 1,
      dataName: 'currency',
      name: 'Select Currency',
      ref: currencyRef,
    },
    {
      id: 2,
      dataName: 'recipient',
      name: 'Select Currency',
      ref: recipientRef,
    },
    {
      id: 3,
      dataName: 'confirmTransaction',
      name: 'Confirm you Transaction',
      ref: confirmTransactionRef,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  function closeTransfer() {
    setDetails(initialDetails);
    setShowUpgradeLimit(false);
  }

  function closeSubFlow() {
    closeTransfer();
    onClose();
  }

  function finishAllProcess(successDetails) {
    closeTransfer();
    setStage(null);
    openTransferSuccessModal(successDetails?.successArray);
  }

  function proceed() {
    // if (!details.intro) {
    //   setCanProceed(true);
    //   setShowProceedButton(true);
    //   setStage(1);
    // }
    if (!details.currency) {
      setShowProceedButton(true);
      setStage(1);
    } else if (!details.recipient) {
      setShowProceedButton(false);
      setStage(2);
    } else if (!details.confirmTransaction) {
      setShowProceedButton(true);
      setStage(3);
    } else {
      // finish process then open modal...
      const successDetails = details.confirmTransaction;
      finishAllProcess(successDetails);
    }
  }

  function selectedStage() {
    return stages.find((s) => s.id === stage);
  }

  async function submit() {
    setLoading(true);
    try {
      const componentAction = selectedStage().ref.current;
      const stageResp = await componentAction.submit();
      if (stageResp?.status && stageResp.status === flowType.FINISH) {
        finishAllProcess(stageResp);
      } else if (stageResp === flowType.COMINGSOON) {
        // !open coming soon screen
        setLoading(false);
      } else if (stageResp === flowType.NOCHANGE) {
        setLoading(false);
      } else if (stageResp) {
        const data = { ...details, [stageResp.name]: stageResp };
        setDetails(data);
        setCanProceed(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function goBackHandler() {
    if (showUpgradeLimit) {
      setShowUpgradeLimit((prevState) => !prevState);
      return;
    }
    const currentViewName = stages[selectedStage().id - 1].dataName;
    const newData = { ...details, [currentViewName]: false };
    if (selectedStage().id >= 2) {
      const currentViewName2 = stages[selectedStage().id - 2].dataName;
      newData[currentViewName2] = false;
    }
    setShowProceedButton(true);
    setDetails(newData);
    setStage((prev) => prev - 1);
  }

  useEffect(() => {
    proceed();
    setLoading(false);
  }, [
    details.intro,
    details.currency,
    details.recipient,
    details.confirmTransaction,
  ]);

  function divertStage() {
    setShowUpgradeLimit(true);
  }

  return (
    <SubFlowLayout
      goBack={(stage > 1) || (showUpgradeLimit)}
      goBackFunc={() => goBackHandler()}
      onClose={() => closeSubFlow()}
      isVisible={isVisible}
      title={t('dsh.wallet.transferPageTitle')}
    >
      <SubFlowLayout.BodyBGWrapper>
        <div className="withdraw-wrapper">
          {/* Start of a stage */}
          {/* <ShowSection show={stage === 1}>
          <WelcomeScreen
            dataName="intro"
            setCanProceed={setCanProceed}
            setShowProceedButton={setShowProceedButton}
            ref={heroRef}
          />
        </ShowSection> */}
          {/* End of a stage */}
          {/* Start of a stage */}
          {
          stage === 1 && (
          <ShowSection show>
            { showUpgradeLimit
              ? <ContinueToKYC /> : (
                <ChooseCurrency
                  // notKYCRestricted
                  dataName="currency"
                  setCanProceed={setCanProceed}
                  title={transactionTypes.TRANSFER}
                  ref={currencyRef}
                  callback={divertStage}
                />
              )}
          </ShowSection>
          )
        }
          {/* End of a stage */}

          {/* Start of a stage */}
          {
          stage === 2 && (
          <ShowSection show>
            <TransferRecipient
              dataName="recipient"
              setCanProceed={setCanProceed}
              setShowProceedButton={(arg) => setShowProceedButton(arg)}
              ref={recipientRef}
            />
          </ShowSection>
          )
        }
          {/* End of a stage */}

          {/* Start of a stage */}
          {
          stage === 3 && (
          <ShowSection show>
            <ConfirmProcess
              dataName="confirmTransaction"
              currency={details.currency?.currency}
              amount={details.currency?.amount}
              title={transactionTypes.TRANSFER}
                  // seen as the route
              TransferDetails={details.recipient}
              setCanProceed={setCanProceed}
              ref={confirmTransactionRef}
            />
          </ShowSection>
          )
        }
          {/* End of a stage */}
          {(showProceedButton && !showUpgradeLimit) && (
          <div className="button-wrapper">
            <ButtonReuse
              loading={loading}
              isDisabled={!canProceed}
              onClick={() => submit()}
              btnStyle="btn-reuse"
              text={t('dsh.proceed')}
            />
          </div>
          )}
        </div>
      </SubFlowLayout.BodyBGWrapper>
    </SubFlowLayout>
  );
}

function ShowSection({ show = false, children }) {
  return (
    <div
      style={{
        display: `${show ? 'block' : 'none'}`,
      }}
    >
      {children}
    </div>
  );
}

export default Transfer;
