/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useWindowSize from '../../hooks/useWindowSize';
import '../../styles/dashboard/tables/TrendingAssetTable.css';
import TrendingAsset from './TrendingAsset';

function TrendingAssetTable({
  list,
  parentSelector = '#master-container',
  dontshowChart,
  dontshowTrade,
  dontshowSN,
  AddPadding,
}) {
  const { t } = useTranslation();
  const { windowSize, isMobile } = useWindowSize();

  const { data: prices } = useSelector((state) => state.global.prices);
  const [assetsToDisplay, setAssetsToDisplay] = useState([]);

  function showLessAssets() {
    const delimitedList = list.slice(0, 6);
    setAssetsToDisplay(delimitedList);
  }

  useEffect(() => {
    if (list) setAssetsToDisplay(list.slice(0, 10));
  }, [list]);

  useEffect(() => {
    if (!list) return;
    if (isMobile) {
      showLessAssets();
    }
  }, [windowSize, list]);

  // ---------------------------------------------------------------------
  // Custom Observer for a controlled fetch for asset detail in batches [start]
  // ---------------------------------------------------------------------
  const getNextAssetToDisplay = (position = assetsToDisplay?.length, batchSize = 10) => {
    if (list.slice(position, batchSize + position).length === 0) return;

    const updatedDisplayList = [...assetsToDisplay, ...list.slice(position, batchSize + position)];
    setAssetsToDisplay(updatedDisplayList);
  };

  useEffect(() => {
    let gettingNext = false;

    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 500) {
        if (!gettingNext) {
          gettingNext = true;
          getNextAssetToDisplay();
          setTimeout(() => {
            if (list?.length > assetsToDisplay?.length) {
              gettingNext = false;
            }
          }, 100);
        }
      }
    };

    const scrollParent = document.querySelector(parentSelector || '#master-container');
    scrollParent.addEventListener('scroll', handleScroll);

    return () => {
      scrollParent.removeEventListener('scroll', handleScroll);
    };
  }, [assetsToDisplay]);

  // ---------------------------------------------------------------------
  // Custom Observer for a controlled fetch for asset detail in batches  [end]
  // ---------------------------------------------------------------------
  return (
    <div className="trending-asset-table-wrap">
      <table
        cellSpacing="0"
        cellPadding="0"
        className={`trending-asset-table ${AddPadding && 'table-padding'}`}
      >
        <thead>
          <tr style={{ fontWeight: 300 }}>
            {!dontshowSN && (
              <th
                style={{ fontWeight: 300 }}
                className="desktop-only cell-serial-no"
              >
                #
              </th>
            )}
            <th style={{ fontWeight: 300 }} className="cell-name">
              {t('dsh.trendingAssetTable.name')}
            </th>
            <th style={{ fontWeight: 300 }} className="cell-price">
              {t('dsh.trendingAssetTable.price')}
            </th>
            <th
              style={{ fontWeight: 300 }}
              className="desktop-only cell-change"
            >
              {t('dsh.trendingAssetTable.h24Change')}
            </th>
            {!dontshowChart && (
              <th
                style={{ fontWeight: 300 }}
                className="desktop-only cell-chart"
              >
                {t('dsh.trendingAssetTable.chart')}
              </th>
            )}
            {dontshowTrade && (
              <th
                style={{ fontWeight: 300 }}
                className="desktop-only cell-trade"
              >
                {t('dsh.trendingAssetTable.trade')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {list &&
            assetsToDisplay &&
            assetsToDisplay.map((child, index) => {
              const { id, symbol } = child;
              return (
                <TrendingAsset
                  key={id}
                  index={index}
                  abr={symbol}
                  price={prices[symbol].price}
                  change={prices[symbol].change || 0}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(TrendingAssetTable);
