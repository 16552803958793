import React from 'react';

const DashboardGetStarted = React.lazy(() => import('../../../pages/dashboard/get-started'));
const NewsIndex = React.lazy(() => import('../../../pages/dashboard/news'));

/* eslint-disable import/prefer-default-export */
export const Dashboard_get_started_route_group = [
  { path: '/dashboard-get-started', component: <DashboardGetStarted /> },
  { path: '/dashboard-get-started/news', component: <NewsIndex /> },
];
