/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import ButtonReuse from '../../components/fragments/Button';
import { useIcon } from '../../hooks/useIcon';
import ErrorPageWrapper from './ErrorPageWrapper';

function Error500() {
  const { error500 } = useIcon();

  function goToDashboard() {
    window.location.reload();
    window.location.pathname = '/dashboard-home';
  }

  return (
    <ErrorPageWrapper>
      <div className="error-404-500">
        <figure className="illustration-img-box">
          <img src={error500} className="img" alt="" />
        </figure>
        <div className="error-description-box">
          <div className="error-description-text">
            Well, this is unexpected...
            <br />
            We’re not quite sure what went wrong. You can go back, or try
            looking on our Help Center if you need a hand.
          </div>
          <div className="btn-box">
            <ButtonReuse
              btnStyle="btn-reuse"
              onClick={() => goToDashboard()}
              text={
                <div className="btn-content">
                  <span>Go to your dashboard</span>
                  <FaChevronRight className="icon" style={{ color: 'white' }} />
                </div>
                }
            />
          </div>
        </div>
      </div>
    </ErrorPageWrapper>
  );
}

export default Error500;
