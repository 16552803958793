/* eslint-disable react/prop-types */
import React from 'react';

function ShowSection({
  show, styles = {}, displayType = 'block', children, hasDefaultStyles = true, classNames = '',
}) {
  const displayStyle = {
    display: `${show ? displayType : 'none'}`,
    flexDirection: 'column',
    ...styles,
  };

  return (
    <div className={classNames} style={hasDefaultStyles ? displayStyle : { ...styles }}>
      {children}
    </div>
  );
}

export default ShowSection;
