/* eslint-disable max-len */
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

const MobileMoneyService = {
  async getMomoProviders(currency) {
    const res = await post({
      url: apiRoutes.getMomoProviders,
      data: {
        currency,
        token: AuthService._getUserToken(),
      },
    });
    if (res.error) {
      throw new Error(res.error.response.data.message);
    }
    return res.response.data.data;
  },
  async createMomoAccount(data) {
    const res = await post({
      url: apiRoutes.addMomoAccount,
      data: {
        ...data,
        token: AuthService._getUserToken(),
      },
    });
    if (res.error) {
      throw new Error(res.error.response.data.message);
    }
    if (res.response.data.status === 'fail') {
      throw new Error(res.response.data.message);
    }
    return res.response.data.data;
  },
  async removeMomoAccount(mobile) {
    const res = await post({
      url: apiRoutes.removeMomoAccount,
      data: {
        mobile,
        token: AuthService._getUserToken(),
      },
    });
    if (res.error) {
      throw new Error(res.error.response.data.message);
    }
    if (res.response.data.status === 'fail') {
      throw new Error(res.response.data.message);
    }
    return res.response.data.data;
  },
  convertNumberToInternationalFormat(phoneNumber, prefix) {
    const intlFormatNumber = `${prefix}${phoneNumber}`;
    return intlFormatNumber;
  },
  // async withdrawToMomo() {},
};

export default MobileMoneyService;
