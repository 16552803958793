/* eslint-disable react/prop-types */
import React from 'react';
// import OutsideClickHandler from 'react-outside-click-handler';
import '../../../../styles/dashboard/layout/NotificationDropdown.css';
import { useTranslation } from 'react-i18next';
import NotificationWrapper from './NotificationsWrapper';
import SubFlow from '../../../fragments/subFlow';

export default function NotificationDropdown({ visible, onClose, openNotificationSelectModal }) {
  const { t } = useTranslation();
  return (
    // <OutsideClickHandler onOutsideClick={() => onClose()}>
    //   {visible && <NotificationWrapper onClose={onClose} />}
    // </OutsideClickHandler>
    <SubFlow
      onClose={() => onClose()}
      isVisible={visible}
      title={t('dsh.notification.notification')}
      goBack
      goBackFunc={() => onClose()}
    >
      <NotificationWrapper
        openNotificationSelectModal={openNotificationSelectModal}
        onClose={onClose}
      />
    </SubFlow>
  );
}
