/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable space-in-parens */
import './RateCheckerDialog.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import SubFlow from '../subFlow';
import { hideToolDialogs, selectRates, toolDialogTypes } from '../../../slices/toolsSlice';
import Search from '../Search';
import ImagePlaceholder from '../ImagePlaceholder';
import getIconUrl, { getCurrencyIconUrl } from '../../../utils/getIconUrl';
import CurrencySelectAlt from '../CurrencySelectAlt';
import { formatNumber } from '../../../utils/simpleActions';

export default function RateCheckerDialog() {
  const { visibleToolDialog } = useSelector((state) => state.tools);
  const { all } = useSelector(selectRates);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [searchResults, setSearchResults] = useState(all);
  const { t } = useTranslation();

  function search(query) {
    const q = query.toLowerCase();
    const results = all.filter(({ quote, quoteName, quoteSym, rate, pnl }) => quote.toLowerCase().includes(q) || quoteName.toLowerCase().includes(q) || quoteSym === q || rate.toString().includes(q) || Math.trunc(pnl).toString().includes(q) );
    setSearchResults(results);
  }

  return (
    <SubFlow
      title={t('dsh.rateChecker.rates')}
      isVisible={visibleToolDialog === toolDialogTypes.rateChecker}
      goBack
      goBackFunc={() => dispatch(hideToolDialogs())}
      onClose
    >
      <SubFlow.BodyBGWrapper>
        <div className="rate-checker">
          <section className="search-section">
            <Search borderClassNames="rate-search-border" onChange={(value) => search(value)} />
            <div className="select-rate-curr-wrap">
              <CurrencySelectAlt showIcon showTitle={false} containerClassNames="select-rate-curr" />
            </div>
          </section>
          <section className="check-type-btn-group">
            <button type="button" onClick={() => setTabIndex(0)} className={`check-type-btn ${tabIndex === 0 && 'active'}`}>{t('dsh.rateChecker.currencies')}</button>
            <button type="button" onClick={() => setTabIndex(1)} className={`check-type-btn ${tabIndex === 1 && 'active'}`}>{t('dsh.rateChecker.crypto')}</button>
          </section>
          <main className="check-results">
            {
              all && searchResults && searchResults.map(({ base, quote, baseName, quoteName, quoteSym, rate, pnl, type }) => (
                <RateTile
                  key={uuid()}
                  base={base}
                  quote={quote}
                  baseName={baseName}
                  quoteName={quoteName}
                  quoteSym={quoteSym}
                  rate={rate}
                  pnl={pnl}
                  type={tabIndex === 0 ? 'currencies' : 'crypto'}
                />
              ))
            }
          </main>
        </div>
      </SubFlow.BodyBGWrapper>
    </SubFlow>
  );
}

function RateTile({
  base, quote, baseName, quoteName, quoteSym, rate, pnl, type = 'currencies',
}) {
  const [iconUrls, setIconUrls] = useState([]);

  useEffect(() => {
    if (type === 'crypto') {
      setIconUrls([getIconUrl(base), getIconUrl(quote)]);
    } else {
      setIconUrls([getCurrencyIconUrl(base), getCurrencyIconUrl(quote)]);
    }
  }, [type]);

  return (
    <div className="rate-tile">
      <div className="rate-ic-group">
        <figure className="base-ic">
          <ImagePlaceholder src={iconUrls[0]} width="25px" height="25px" figureClassName="rate-curr-ic" />
        </figure>
        <figure className="quote-ic">
          <ImagePlaceholder src={iconUrls[1]} width="25px" height="25px" figureClassName="rate-curr-ic" />
        </figure>
      </div>
      <section className="currency-info">
        <h3 className="currency-pair">{`${base.toUpperCase()}/${quote.toUpperCase()}`}</h3>
        <p className="currency-pair-desc">{`${baseName} to ${quoteName}`}</p>
      </section>
      <section className="rate-info">
        <h6 className="rate-amount">{`${quoteSym}${formatNumber(rate, 2, true)}`}</h6>
        <p className={getClassNameFromPnL(pnl)}>{`${pnl}%`}</p>
      </section>
    </div>
  );
}

function getClassNameFromPnL(pnl) {
  if (pnl > 1) return 'rate-pnl profit';
  return 'rate-pnl loss';
}
