/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TransferDrawer from '.';
import TransactionSuccessModal from '../../../../components/fragments/TransactionSuccessModal';
import { selectTransferModalState, toggleTransferModalIsOpen } from '../../../../slices/FiatTransferSlice';

function TransferSuccessCombination() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openedAt, setOpenedAt] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const { isOpen: showTransferDrawer } = useSelector(selectTransferModalState);
  const [transferSuccessModalDetails, setTransferSuccessModalDetails] = useState(null);

  function toggleTransferDrawer() {
    dispatch(toggleTransferModalIsOpen());
  }

  function openTransferSuccessModal(successArray) {
    setSuccessModal(true);
    setTransferSuccessModalDetails(successArray);
  }

  function closeSuccessModal() {
    setSuccessModal(false);
    toggleTransferDrawer();
    setTransferSuccessModalDetails(null);
  }

  function transactionModalDashboardClose() {
    closeSuccessModal();
  }

  useEffect(() => {
    if (showTransferDrawer) {
      setOpenedAt(location.pathname);
    }
  }, [showTransferDrawer]);

  useEffect(() => {
    if ((location.pathname !== openedAt) && showTransferDrawer) {
      toggleTransferDrawer();
    }
  }, [location.pathname]);

  return (
    <>
      <TransferDrawer
        openTransferSuccessModal={openTransferSuccessModal}
        onClose={toggleTransferDrawer}
        isVisible={showTransferDrawer}
      />
      <TransactionSuccessModal
        closeModal={closeSuccessModal}
        visible={successModal}
        // goAgain={{
        //   text: 'Cancel',
        //   func: () => setSuccessModal(false),
        // }}
        // downloadReceipt={() => { }}
        goToDashboard={() => transactionModalDashboardClose()}
        boxItems={
          transferSuccessModalDetails || []
        }
      />
    </>
  );
}

export default TransferSuccessCombination;
