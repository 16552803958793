/* eslint-disable */
import './InvestNewOneOff.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SubFlow from '../fragments/subFlow';
import DashboardLayout from '../layouts/dashboard';
import InvestProgressIndicators from '../InvestProgressIndicators/InvestProgressIndicators';
import { Amount, Success, Summary } from '../InvestSubComponents/InvestSubComponents';
import { resetRIBsInitialSetupState, selectPurchaseStatus } from '../../slices/ribsInitialSetupSlice';

export default function InvestNewOneOff() {
  const maxPageIndex = 2;
  const openRoute = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { responseData } = useSelector(selectPurchaseStatus);

  function returnToPrevPage() {
    if (currentPageIndex === 0) {
      openRoute(-1);
    } else if (currentPageIndex === maxPageIndex) {
      openRoute(-4);
    } else {
      setCurrentPageIndex((current) => current - 1);
    }
  }

  function proceed() {
    const bagReference = responseData[0]
    dispatch(resetRIBsInitialSetupState());
    openRoute('/dashboard-wallet-ribs', {
      replace: true,
      state: {
        reference: bagReference,
      }
    });
  }

  function goToNextPage() {
    if (currentPageIndex >= maxPageIndex) {
      proceed();
    } else {
      setCurrentPageIndex((current) => current + 1);
    }
  }

  return (
    <div className="invest-one-off">
      <DashboardLayout pageTitle="New One-off Investment">
        <SubFlow
          title="Invest"
          isVisible
          goBack
          goBackFunc={returnToPrevPage}
          onClose={returnToPrevPage}
        >
          <SubFlow.BodyBGWrapper>
            {
              currentPageIndex !== maxPageIndex && (
                <>
                  <p className="step-info">
                    {`Step ${currentPageIndex + 1}`}
                    <span className={(currentPageIndex === (maxPageIndex - 1)) ? 'complete' : ''}>{`/${maxPageIndex}`}</span>
                  </p>
                  <InvestProgressIndicators numberOfBars={3} currentIndex={currentPageIndex} />
                </>
              )
            }
            {
              currentPageIndex === 0 ? (
                <Amount onComplete={goToNextPage} />
              ) : currentPageIndex === 1 ? (
                <Summary onComplete={goToNextPage} />
              ) : currentPageIndex === 2 ? (
                <Success onComplete={goToNextPage} />
              ) : <div/>
            }
          </SubFlow.BodyBGWrapper>
        </SubFlow>
      </DashboardLayout>
    </div>
  );
}
