/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as states from '../states/ComponentStates';
import WalletService from '../services/walletService';

const initialState = {
  fiat: {
    status: states.BASE,
    data: [],
    error: false,
  },
  withdrawDetails: null,
  pendingWithdrawal: {
    pending: [],
    pendingExist: false,
  },
};

export const withdrawFiat = createAsyncThunk('withdraw/withdrawFiat', async ({
  token, amount, account, currency, pin,
}, { rejectWithValue }) => {
  try {
    const response = await WalletService.withdrawFiat({
      token, amount, account, currency, pin,
    });

    return response.response.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getPendingWithdrawal = createAsyncThunk('withdraw/getPendingWithdrawal', async (_, { rejectWithValue }) => {
  try {
    const response = await WalletService.getPendingWithdrawals();

    return response.response.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    saveWithdrawDetails: (state, action) => {
      if (action.payload) {
        state.withdrawDetails = action.payload;
      } else {
        state.withdrawDetails = initialState.withdrawDetails;
      }
    },
    updateWithdrawals(state, action) {
      state.pendingWithdrawal.pending = action.payload;
      state.pendingWithdrawal.pendingExist = action.payload.length > 0;
    },
    resetPendingWithdrawal(state) {
      state.pendingWithdrawal.pending = initialState.pendingWithdrawal.pending;
      state.pendingWithdrawal.pendingExist = initialState.pendingWithdrawal.pendingExist;
    },
  },
  extraReducers: (builder) => {
    // extraReducers for withdrawFiat
    builder.addCase(withdrawFiat.pending, (state) => {
      state.fiat.status = states.FETCHING;
    });
    builder.addCase(withdrawFiat.fulfilled, (state, action) => {
      const { data, status } = action.payload;
      state.fiat.data = data;
      if (status !== 'fail') {
        state.fiat.status = states.FETCHED;
      } else {
        state.fiat.status = states.ERROR;
      }
    });
    builder.addCase(withdrawFiat.rejected, (state) => {
      state.fiat.status = states.ERROR;
    });

    // extraReducers for getPendingWithdrawal
    builder.addCase(getPendingWithdrawal.pending, () => {
    });
    builder.addCase(getPendingWithdrawal.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.pendingWithdrawal.pending = data;
      state.pendingWithdrawal.pendingExist = !!data;
    });
    builder.addCase(getPendingWithdrawal.rejected, () => {
    });
  },
});

export const selectWithdrawal = (state) => state.withdraw.fiat;
export const selectPendingWithdrawal = (state) => state.withdraw.pendingWithdrawal;
export const selectWithdrawalDetails = (state) => state.withdraw.withdrawDetails;

export default withdrawSlice.reducer;
export const {
  saveWithdrawDetails, resetPendingWithdrawal, updateWithdrawals,
} = withdrawSlice.actions;
