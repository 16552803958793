/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PriceAlertService from '../services/priceAlertService';

export const fetchStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

const initialState = {
  data: [],
  error: false,
  deleteError: false,
  status: fetchStates.BASE,
  deleteStatus: fetchStates.BASE,
  createStatus: fetchStates.BASE,
};

export const fetchPriceAlerts = createAsyncThunk(
  'priceAlerts/fetchPriceAlerts',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await PriceAlertService.getPriceAlerts(token);
      const priceAlerts = data.data?.filter((d) => d.status !== 0);
      // return data.data;
      return priceAlerts;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const createPriceAlert = createAsyncThunk(
  'priceAlerts/createPriceAlert',
  async (params, { rejectWithValue }) => {
    try {
      await PriceAlertService.createPriceAlertService(params);
      return params;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const deletePriceAlert = createAsyncThunk(
  'priceAlerts/deletePriceAlert',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await PriceAlertService.deletePriceAlertService(params);
      return { data, params };
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const priceAlertSlice = createSlice({
  name: 'priceAlerts',
  initialState,
  reducers: {
    resetCreateStatus: (state) => {
      state.status = initialState.status;
    },
    resetDeleteStatus: (state) => {
      state.deleteStatus = initialState.deleteStatus;
    },
  },
  extraReducers: (builder) => {
    // fetchPriceAlerts reducers...
    builder.addCase(fetchPriceAlerts.pending, (state) => {
      state.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchPriceAlerts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchPriceAlerts.rejected, (state) => {
      state.status = fetchStates.ERROR;
    });

    // createPriceAlert reducers...
    builder.addCase(createPriceAlert.pending, (state) => {
      state.createStatus = fetchStates.FETCHING;
    });
    builder.addCase(createPriceAlert.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.createStatus = fetchStates.FETCHED;
    });
    builder.addCase(createPriceAlert.rejected, (state) => {
      state.createStatus = fetchStates.ERROR;
    });

    // deletePriceAlert reducers...
    builder.addCase(deletePriceAlert.pending, (state) => {
      state.deleteStatus = fetchStates.FETCHING;
    });
    builder.addCase(deletePriceAlert.fulfilled, (state, action) => {
      const { data, params } = action.payload;
      if (data.status === 'fail') {
        state.deleteStatus = fetchStates.ERROR;
        state.deleteError = data.message;
      } else {
        state.deleteStatus = fetchStates.FETCHED;
        state.data = state.data.filter((d) => d.id !== params.id);
      }
    });
    builder.addCase(deletePriceAlert.rejected, (state) => {
      state.deleteStatus = fetchStates.ERROR;
    });
  },
});

// Accessors
export const selectPriceAlert = (state) => state.priceAlerts;

export default priceAlertSlice.reducer;
export const { resetCreateStatus, resetDeleteStatus } = priceAlertSlice.actions;
