/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import SelectCurrency from '../../SelectCurrency';
import { getCountryFlag } from '../../../../utils/getStaticAssets';

const CurrencySelectStage = React.forwardRef(({ setCanProceed, countries, dataName = 'currency' }, ref) => {
  const [selectedCurrency, setSelectedCurrency] = useState('');

  useEffect(() => {
    setCanProceed(true);
  }, []);

  function getSelectedCurrency() {
    return countries.find((d) => d.country_code === selectedCurrency);
  }

  React.useImperativeHandle(ref, () => ({
    submit() {
      return {
        name: dataName,
        data: getSelectedCurrency(),
      };
    },
  }));
  return (
    <>
      {/*  TODO: Translate */}
      <div className="section-title">
        Select document issuing country
      </div>
      <div className="section-sub-title secondary-white-black-color">
        Choose your document issuing country for accurate identity verification.
      </div>
      <SelectCurrency
        parentData={countries.map((d) => ({
          icon: getCountryFlag(d.country_code),
          code: d.country_code,
          name: d.country,
        }))}
        onChange={setSelectedCurrency}
        label=""
        showCountryName
      />
    </>
  );
});

export default CurrencySelectStage;
