/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import toastTypes from '../utils/toast-types';

const initialState = {
  toastIsOpen: false,
  info: 'Info here',
  text: 'Text here',
  type: toastTypes.success,
};

const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    toggleClipboardToast: (state, action) => {
      state.toastIsOpen = action.payload;
      // toast about to be closed...
      if (!action.payload) {
        state.info = initialState.info;
        state.text = initialState.text;
      }
    },
    setClipboardToast: (state, action) => {
      state.info = action.payload.info;
      state.text = action.payload.text;
      state.type = action.payload.type;
    },
  },
});

export const selectClipboard = (state) => state.clipboard;

export default clipboardSlice.reducer;
export const { toggleClipboardToast, setClipboardToast } = clipboardSlice.actions;
