/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/react-in-jsx-scope */
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SubFlow from '../fragments/subFlow';
import DashboardLayout from '../layouts/dashboard';
import './InvestGetStarted.css';
import { useIcon } from '../../hooks/useIcon';
import { setBuyType } from '../../slices/ribsInitialSetupSlice';

export default function InvestGetStarted() {
  const openRoute = useNavigate();
  const {
    investmentReturn,
    breadReturn,
    investOneOffImg,
    investRecurringImg,
  } = useIcon();
  const dispatch = useDispatch();

  function returnToPrevPage() {
    openRoute(-1);
  }

  function proceed(investmentType) {
    dispatch(setBuyType(investmentType.toLowerCase()));
    openRoute(`/dashboard-home-ribs-new-investment/${investmentType}`);
  }

  return (
    <DashboardLayout pageTitle="Get Started">
      <SubFlow
        title="Invest"
        isVisible
        goBack
        goBackFunc={returnToPrevPage}
        onClose={returnToPrevPage}
      >
        <SubFlow.BodyBGWrapper>
          <h6 className="invest-intro-h6 invest-get-started-title">Welcome Jaykay, let’s get you started.</h6>
          <p className="invest-intro-paragraph invest-get-started-subtitle">Select how you would like to invest today</p>
          <button
            className="investment-type-btn one-off"
            onClick={() => proceed('one-off')}
          >
            <figure className="invest-img-box">
              <img src={investOneOffImg} alt="" />
            </figure>
            <div className="texts">
              <h6 className="investment-type-title">One off</h6>
              <h6 className="investment-type-subtitle">Make a one off purchase by investing in an entire portfolio of coins.</h6>
            </div>
            <img src={investmentReturn} alt="proceed to one-off investment" className="forward-icon" />
          </button>
          <button
            className="investment-type-btn recurring"
            onClick={() => proceed('recurring')}
          >
            <figure className="invest-img-box">
              <img src={investRecurringImg} alt="" />
            </figure>
            <div className="texts">
              <h6 className="investment-type-title">Recurring</h6>
              <p className="investment-type-subtitle">Make a recurring purchase by investing in an entire portfolio of coins.</p>
            </div>
            <img src={breadReturn} alt="proceed to one-off investment" className="forward-icon" />
          </button>
        </SubFlow.BodyBGWrapper>
      </SubFlow>
    </DashboardLayout>
  );
}
