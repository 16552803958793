/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import ActionContext from '../context/ActionContext';

// HeaderNavContent icons
import closeChillBlue from '../assets/close-chill-blue.svg';
import newEllipsis from '../assets/new-ellipsis.svg';
import searchBlueLight from '../assets/search-icon-blue-light.svg';
import searchBlueDark from '../assets/search-icon-blue-dark.svg';
import globeIcon from '../assets/globe.svg';
import globeWhiteIcon from '../assets/globeWhite.svg';
import bellIcon from '../assets/notification.svg';
import unseen from '../assets/roqqu-logo.svg';
import seen from '../assets/roqqu-grey-logo.svg';
import seenLight from '../assets/roqqu-grey-logo-light.svg';
import logoImg from '../assets/roqqu-nav-logo.svg';

// HeaderNavContent Icons
// import englishIcon from '../assets/english.png';
import englishIcon from '../assets/languages/english.png';
import frenchIcon from '../assets/languages/french.png';
import danishIcon from '../assets/languages/danish.png';
import swedishIcon from '../assets/languages/swedish.png';
import italianIcon from '../assets/languages/italian.png';
import spanishIcon from '../assets/languages/spanish.png';
import germanIcon from '../assets/languages/german.png';
import igbo from '../assets/languages/igbo.svg';
import yoruba from '../assets/languages/yoruba.svg';
import hausa from '../assets/languages/hausa.svg';
import englishLocal from '../assets/languages/english-local.svg';

// Icons for sidebar
// import logoImg from '../assets/roqqu-white-logo.svg';
import colorLogoImg from '../assets/roqqu-color-logo.svg';
import smallLogoImg from '../assets/roqqu-eclipse-logo.svg';
import smallColorLogoImg from '../assets/roqqu-color-eclipse-logo.svg';
import dashboardIcon from '../assets/dashboard-side-icon.svg';
import walletIcon from '../assets/wallet-side-icon.svg';
import moneyIcon from '../assets/money-bill.svg';
import transactionIcon from '../assets/transaction-side-icon.svg';
import exchangeIcon from '../assets/exchange-icon.svg';
import verificationImg from '../assets/verification-side-icon.svg';
import settingImg from '../assets/settings-side-icon.svg';
import roqquHubImg from '../assets/hub-icon.svg';
import helpImg from '../assets/help-side-icon.svg';
import feeImg from '../assets/fees-side-icon.svg';
import nftImg from '../assets/nft-icon.svg';
import nftIcon from '../assets/myNFT-icon-copy.svg';
import getStartedIcon from '../assets/rocket.svg';

// Icons for DashboardGetstarted Page
import contactThumbnailImg from '../assets/contact-thumbnail-img.svg';
import downloadRoqquAppImg from '../assets/download-roqqu-img.svg';
import appStoreDownloadImg from '../assets/app_store_download_btn.svg';
import appStoreDownloadLightImg from '../assets/app_store_download_btn_light.svg';
import playStoreDownloadImg from '../assets/play_store_download_btn.svg';
import playStoreDownloadLightImg from '../assets/play_store_download_btn_light.svg';
import article1Img from '../assets/article1.svg';
import article2Img from '../assets/article2.svg';
import article3Img from '../assets/article3.svg';

// Icons for DashboardHome Page
import investImg from '../assets/invest-icon.svg';
import buyAssetImg from '../assets/buy-assets-icon.svg';
import buyAssetSingleImg from '../assets/buy-assets-single-icon.svg';
import buyAssetSingleImgLight from '../assets/buy-assets-single-icon-light.svg';
import sellAssetImg from '../assets/sell-assets-icon.svg';
import sellAssetSingleImg from '../assets/sell-assets-single-icon.svg';
import sellAssetSingleImgLight from '../assets/sell-assets-single-icon-light.svg';
import swapCoinImg from '../assets/swap-coin-icon.svg';
import swapCoinSingleImg from '../assets/swap-coin-single-icon.svg';
import swapCoinSingleImgLight from '../assets/swap-coin-single-icon-light.svg';
import roqqnrollSingleImg from '../assets/roqqnroll-single-icon.svg';
import roqqnrollSingleImgLight from '../assets/roqqnroll-single-icon-light.svg';
import convertSingleImg from '../assets/coin-swap-single-icon.svg';
import convertSingleImgLight from '../assets/coin-swap-single-icon-light.svg';
import payBillImg from '../assets/pay-bill-icon.svg';
import payBillSingleImg from '../assets/pay-bill-single-icon.svg';
import payBillSingleImgLight from '../assets/pay-bill-single-icon-light.svg';
import transactionSingleWhiteImg from '../assets/transaction-side-icon-white.svg';
import transactionSingleDarkImg from '../assets/transaction-side-icon-dark.svg';
import savingsUSDTImg from '../assets/usdt-savings.svg';
import savingsUSDTImgLight from '../assets/usdt-savings-light.svg';
import loansActionImg from '../assets/loans-action-img.svg';
import loansActionImgLight from '../assets/loans-action-img-light.svg';
import cardsActionImg from '../assets/cards-action-img.svg';
import cardsActionImgLight from '../assets/cards-action-img-light.svg';
import newsActionImg from '../assets/news-action-img.svg';
import newsActionImgLight from '../assets/news-action-img-light.svg';
import paymentLinkActionImg from '../assets/payment-link-action-img.svg';
import paymentLinkActionImgLight from '../assets/payment-link-action-img-light.svg';
import nftActionImg from '../assets/nft-action-img.svg';
import nftActionImgLight from '../assets/nft-action-img-light.svg';
import stakeActionImg from '../assets/stake-action-img.svg';
import stakeActionImgLight from '../assets/stake-action-img-light.svg';
import riseIcon from '../assets/rise.svg';
import fallIcon from '../assets/fall.svg';
import checkLight from '../assets/lightmode-icons/tick-light.svg';
import checkDark from '../assets/tick-dark.svg';
import blackberryLoader from '../assets/loading-blackberry.svg';
import savingsWelcome from '../assets/savings-welcome.svg';
import paperJet from '../assets/paper-jet.svg';
import completeIconBlue from '../assets/complete-icon-blue.svg';
import arrowDownDark from '../assets/arrow-down.svg';
import eyeIcon from '../assets/eye.svg';

// import nftImg from '../assets/nft-icon.svg';
import paymentLinkImg from '../assets/payment-link-icon.svg';
import searchIcon from '../assets/search-icon.svg';

// Icons for SingleAsset Page
import PriceAlertimg from '../assets/price-alert-icon.svg';
import sendCoinImg from '../assets/upload.svg';
import receiveCoinImg from '../assets/download.svg';

import tipsImg from '../assets/tips.svg';
import add from '../assets/add.svg';
import addLight from '../assets/lightmode-icons/wallet-dashboard/add.svg';
import binIcon from '../assets/delete.svg';

// Icons for PayBill Page
import airtimeIcon from '../assets/airtime-icon.svg';
import cableIcon from '../assets/cable-icon.svg';
import electricityIcon from '../assets/electricity-icon.svg';
import dataIcon from '../assets/data-icon.svg';
import airtimeIconLight from '../assets/lightmode-icons/paybills-page/airtime-icon.svg';
import dataIconLight from '../assets/lightmode-icons/paybills-page/data-icon.svg';
import cableIconLight from '../assets/lightmode-icons/paybills-page/cable-icon.svg';
import electricityIconLight from '../assets/lightmode-icons/paybills-page/electricity-icon.svg';

// Network Providers
import mtn from '../assets/network providers/mtn.svg';
import airtel from '../assets/network providers/airtel.svg';
import etisalat from '../assets/network providers/etisalat.svg';
import glo from '../assets/network providers/glo.svg';

// Icons for CableBill page
import dstvImg from '../assets/dstv-icon.svg';
import gotvImg from '../assets/gotv-icon.svg';
import tstvImg from '../assets/tstv-icon.svg';
import startimeImg from '../assets/startime-icon.svg';

// Icons for Savings page
import kenyaIcon from '../assets/kenya-shillings-icon.svg';
import lockImg from '../assets/saving-lock-icon.svg';

// Icons for DashboardWallet Page
import depositIcon from '../assets/wallet-deposit-icon.svg';
import withdrawIcon from '../assets/wallet-withdraw-icon.svg';
import transferIcon from '../assets/wallet-transfer-icon.svg';
import depositIconLight from '../assets/lightmode-icons/wallet-dashboard/wallet-deposit-icon.svg';
import withdrawIconLight from '../assets/lightmode-icons/wallet-dashboard/wallet-withdraw-icon.svg';
import transferIconLight from '../assets/lightmode-icons/wallet-dashboard/wallet-transfer-icon.svg';
import greenAlert from '../assets/green-alert.svg';
import BankTransferHeader from '../assets/Bank_Transfer_Header.svg';

import star from '../assets/star-blue-filled.svg';
import unStar from '../assets/star-blue-outlined.svg';
import starLight from '../assets/lightmode-icons/wallet-dashboard/star-blue-filled-light.svg';
import unStarLight from '../assets/lightmode-icons/wallet-dashboard/star-blue-outlined-light.svg';
import info2 from '../assets/info2.svg';
import currenciesIcon from '../assets/currency-imgs.svg';
import cancelIcon from '../assets/cancel.svg';
import mainCopyIcon from '../assets/mainCopyIcon.svg';
import mainCopyIconLight from '../assets/mainCopyIconLight.svg';
import chevLeft from '../assets/chevLeft.svg';
import chevRight from '../assets/chevRight.svg';
import chevDown from '../assets/chevDown.svg';
import swapModalCloseIcon from '../assets/swap-modal-close-icon.svg';
// import XrpImg from '../assets/xrp-icon.svg';
// import BitcoinImg from '../assets/btc-icon.svg';
// import EtheriumImg from '../assets/eth-icon.svg';

// Icons for DepositConfirmTransaction Page
import copyIcon from '../assets/copy-icon.svg';
import copyIconLight from '../assets/copy-icon-light.svg';
import ravenIcon from '../assets/raveb-transfer-icon.svg';
import clockIcon from '../assets/clock-icon.svg';

// Icons for DepositConfirmTransaction Page
import currencyIncreImg from '../assets/currency-increase-icon.svg';
import currencydecreaImg from '../assets/currency-decrease-icon.svg';
import headphones from '../assets/headphone-icon.svg';

// Icons for Settings Page
import plusIcon from '../assets/plus-icon.svg';
import pencilIcon from '../assets/pencil-icon.svg';
import xRoundedIcon from '../assets/x-rounded-icon.svg';
import pcIcon from '../assets/pc-icon.svg';
import iphoneDeviceIcon from '../assets/iphone-device-icon.svg';
import chevronDown from '../assets/chevron-down.svg';
import separator from '../assets/seperator.svg';

// Icons for Help&Support page
import helpIcon from '../assets/help&support/help-icon.svg';
import accountIcon from '../assets/help&support/account-icon.svg';
import tradingIcon from '../assets/help&support/trading-icon.svg';
import privacyIcon from '../assets/help&support/privacy-icon.svg';
import productsIcon from '../assets/help&support/products-icon.svg';
import optionsIcon from '../assets/help&support/options-icon.svg';
import vectorIcon from '../assets/help&support/vector-icon.svg';
import doubleChevron from '../assets/help&support/double-chevron-right-dark.svg';
import doubleChevronLight from '../assets/help&support/double-chevron-right-light.svg';
import carouselRight from '../assets/right-catousel.svg';
import carouselLeft from '../assets/left-carousel.svg';

// Icons for RIBs
import chevronRightBlunt from '../assets/ribs/chevrons-right.svg';
import chevronRightBluntLight from '../assets/ribs/chevrons-right-light.svg';
import RIBSCloseIcon from '../assets/ribs/close-distribution.svg';
import ribsFilterIcon from '../assets/ribs/filter-icon.svg';
import moreCircleIcon from '../assets/ribs/more-circle.svg';
import whitepaperIcon from '../assets/ribs/whitepaper-icon.svg';
import coinGlobalIcon from '../assets/ribs/coin-global.svg';
import infoManIllustrationFaded from '../assets/ribs/init-man.png';
import infoManIllustrationFadedLight from '../assets/ribs/init-man-light.svg';
import initInfoBg1Light from '../assets/ribs/init-info-bg-1.svg';
import initInfoBg2Light from '../assets/ribs/init-info-bg-2.svg';
import initInfoBg1 from '../assets/ribs/init-info-bg-1-dark.svg';
import initInfoBg2 from '../assets/ribs/init-info-bg-2-dark.svg';
import dummyAvatar from '../assets/ribs/dummy-avatar.svg';
import ribsTxns from '../assets/ribs/ribs-txns.svg';
import trendDown from '../assets/ribs/trending-down.svg';
import trendUp from '../assets/ribs/trending-up.svg';
import RIBSCancelIcon from '../assets/ribs/close-icon.svg';
import goBck from '../assets/ribs/goBck.svg';
import pieChart from '../assets/ribs/pieChart.svg';
import pieChartActive from '../assets/ribs/pieChartActive.svg';
import listView from '../assets/ribs/listView.svg';
import listViewActive from '../assets/ribs/listViewActive.svg';
import dumpIcon from '../assets/ribs/dump-bag/dumpIcon.png';
import castleImg from '../assets/ribs/castle.png';
import trainImg from '../assets/ribs/train.png';
import statsIcon from '../assets/roqqnroll/statsIcon.svg';
import statsBorderTopIcon from '../assets/roqqnroll/statsBorderTopBling.svg';
import statsBorderBottomIcon from '../assets/roqqnroll/statsBorderBotmBling.svg';

// Icons for DashboardHome Page
import verifyIdentityIcon from '../assets/verify-identity-icon.svg';
import usernameIcon from '../assets/username-identify-icon.svg';

// Coin icons
import btcIcon from '../assets/btc-icon.svg';
import ethIcon from '../assets/eth-icon.svg';
import xrpIcon from '../assets/xrp-icon.svg';

// Auth Toast Icons
import toastErrorIcon from '../assets/toast-error-icon.svg';
import toastWarningIcon from '../assets/toast-warning-icon.svg';
import toastSuccessIcon from '../assets/toast-success-icon.svg';
import xIcon from '../assets/x-icon.svg';

import notInternetConnection from '../assets/no-connection-icon.svg';
import chevronRight from '../assets/chevron-right.svg';
import chevronRightLight from '../assets/chevron-right-light.svg';
import bellAlert from '../assets/bell-alert.svg';
import trash from '../assets/trash.svg';
import fileIcon from '../assets/file-icon.svg';
import shareIcon from '../assets/share-icon.svg';
import deleteIcon from '../assets/delete-icon.svg';
import deleteIconRed from '../assets/delete-red.svg';
// import paymentLinkIllustration from '../assets/link-ellipse.svg';
import imagePlaceholderIcon from '../assets/image-placeholder.svg';
import successWhiteTick from '../assets/successful-icon.svg';
import successWhiteGIF from '../assets/gif/success.gif';
import bell3DImg from '../assets/bell-3d-img.png';
import arrowsIcon from '../assets/arrow-down-up-icon.svg';
import arrowHeadDownIcon from '../assets/arrow-head-down.svg';
import sidewaysArrowsIcon from '../assets/arrow-swap-horizontal.svg';
import plusCircleIcon from '../assets/plus-circle.svg';
import checkboxCheckedIcon from '../assets/checkbox-checked-icon.svg';
import reminderBell1Icon from '../assets/reminder-bell-1-icon.svg';
import documentIcon1 from '../assets/document-icon-1.svg';
import accordionAngleDown from '../assets/arccordion-angle-down.svg';
import accordionAngleDownLight from '../assets/arccordion-angle-down-light.svg';
import accordionTickCircleInactive from '../assets/accordion-tick-circle-inactive.svg';
import accordionTickCircleActive from '../assets/accordion-tick-circle-active.svg';
import newSuccessIcon from '../assets/new-success-icon.svg';

// Price Alert
import priceAbove from '../assets/price-above.svg';
import priceBelow from '../assets/price-below.svg';

// User Nft Profile Tabs
import activityIcon from '../assets/activities-icon.svg';
import collectionsIcon from '../assets/collections-icon.svg';
import draftIcon from '../assets/drafts-icon.svg';
import insightIcon from '../assets/insight-icon.svg';
import myNftIcon from '../assets/myNFT-icon.svg';
import loadingCircle from '../assets/loader-icon.svg';

// Nft Home Tabs
import creators from '../assets/creators.svg';
import curCollections from '../assets/curCollections.svg';
import openBids from '../assets/openBids.svg';

// Error fallbacks
import error404 from '../assets/404-svg-img.svg';
import error500 from '../assets/500-svg-img.svg';

// Referrals
import facebookIcon from '../assets/facebook-icon.svg';
import facebookLightIcon from '../assets/facebook-dark-icon.svg';
import twitterIcon from '../assets/twitter-icon.svg';
import twitterLightIcon from '../assets/twitter-light-icon.svg';
import userPlaceholderIcon from '../assets/user-placeholder-icon.svg';
import progressIcon from '../assets/progress-icon.svg';
import completeIcon from '../assets/complete-icon.svg';

// Withdrawal
import instantTransferRouteIcon from '../assets/instant-transfer-route-icon.svg';
import bankRouteIcon from '../assets/bank-route-icon.svg';
import cardRouteIcon from '../assets/card-route-icon.svg';
import cardRouteIconDisabled from '../assets/cardtopup.svg';
import peerRouteIcon from '../assets/peer-route-icon.svg';
import momoRouteIcon from '../assets/momo-route-icon.svg';
import momoRouteIconDisabled from '../assets/mobile_money.svg';
import ravenBankIcon from '../assets/raven-bank-icon.svg';
import barterBankIcon from '../assets/barter-bank-icon.svg';
import kudaBankIcon from '../assets/kuda-bank-icon.svg';
import opayBankIcon from '../assets/opay-bank-icon.svg';
import tingBankIcon from '../assets/ting-transfer-icon.svg';
import carbonIcon from '../assets/carbon-bank-img.png';
import walletAfricaIcon from '../assets/wallet-africa-img.webp';
import linkRouteIcon from '../assets/payment_link.svg';
import paymentLinkRouteIcon from '../assets/payment_links.svg';
import copyRounded from '../assets/copy_rounded.svg';
import depositImage from '../assets/Deposit.svg';
import bankImg from '../assets/bank-img.svg';

import close from '../assets/Close-blue.svg';
import closeLight from '../assets/close-light.svg';
import investmentClose from '../assets/ribs/add-money/close-icon.svg';
import investmentReturn from '../assets/ribs/add-money/return.svg';

import backIcon from '../assets/back-icon.svg';
import backIconLight from '../assets/back-icon-light.svg';

import closeIcon from '../assets/close-icon.svg';
import closeIconLight from '../assets/close-icon-light.svg';

// Transfer
import bulletpointCheck from '../assets/bulletpoint-check.svg';
import bulletpointCheckLight from '../assets/bulletpoint-check-light.svg';

import errorExclamationIcon from '../assets/error-exclamation-icon.svg';
import warningExclamationIcon from '../assets/warning-exclamation-icon.svg';
import neutralExclamationIconDark from '../assets/neutral-exclamation-icon-dark.svg';
import neutralExclamationIconLight from '../assets/neutral-exclamation-icon-light.svg';
import tipsIconDark from '../assets/tips-icon-dark.svg';
import tipsIconWarning from '../assets/tipsIconWarning.svg';
import visibilityIconDark from '../assets/password-visibility.svg';
import securityShieldIcon from '../assets/security-shield-1.svg';
import resetPinIllustrationIcon from '../assets/reset-pin-illustration.svg';
import pinShield from '../assets/verify-lock.svg';
import signOut from '../assets/sign-out.svg';
import signOutWhite from '../assets/sign-out-white.svg';
import warnIcon from '../assets/warning-icon.svg';
import warnYellowIcon from '../assets/warning-icon-yellow.svg';
import notFound from '../assets/not-found-icon.svg';
import notFoundFileIcon from '../assets/notfound.svg';

// Flags
import ngnFlagIcon from '../assets/country-flags/ngn-flag.svg';
import usdFlagIcon from '../assets/country-flags/usd-flag.svg';
import gbpFlagIcon from '../assets/country-flags/gbp-flag.svg';
import eurFlagIcon from '../assets/country-flags/eur-flag.svg';
import ghsFlagIcon from '../assets/country-flags/ghs-flag.svg';
import filledChevDown from '../assets/country-flags/filled-chev-down.svg';
import filledChevDownLight from '../assets/country-flags/filled-chev-down-light.svg';

// User verification icons
import arrowRightCircledLight from '../assets/arrow-right-circled-white.svg';
import arrowRightCircledDark from '../assets/arrow-right-circled-black.svg';

import waveImg from '../assets/wave-hand.svg';
import profileIcon from '../assets/profile-mail-icon.svg';

// Banned Account Icon
import bannedAccountIllustration from '../assets/banned-for-dark.svg';
import discardedAccountIllustration from '../assets/discarded-for-dark.svg';

import calendarDarkModeIcon from '../assets/calendar.svg';
import calendarLightModeIcon from '../assets/calendar-black.svg';

import filterDarkModeIcon from '../assets/filter-ic.svg';
import filterLightModeIcon from '../assets/filter-ic-black.svg';

import swap from '../assets/swap.svg';

import reportedAccount from '../assets/reported-account-done.svg';

// Profile dropdown
import myAccountpd from '../assets/profile-dropdown/my_account.svg';
import helppd from '../assets/profile-dropdown/help.svg';
import notificationpd from '../assets/profile-dropdown/notification-2-line 1.svg';

// BreadCrumbs
import breadReturn from '../assets/Icons/breadcrumb_return.svg';
import breadReturnLight from '../assets/Icons/breadcrumb_return-light.svg';

// Send Coin
import qrWalletIcon from '../assets/send_coin/qr_wallet_icon.svg';
import username from '../assets/send_coin/username.svg';

// New Toast Icons
import newToastWarning from '../assets/toast-new/toast-new-warning.svg';
import newToastSuccess from '../assets/toast-new/toast-new-success.svg';
import newToastError from '../assets/toast-new/toast-new-error.svg';
import newToastPending from '../assets/toast-new/toast-new-pending.svg';
import newToastClose from '../assets/toast-new/toast-new-close.svg';

// Payment Link Revamp
import empty from '../assets/payment-link/empty.svg';
import linkVector from '../assets/payment-link/link-vector.svg';
import copyVector from '../assets/payment-link/copy-vector.svg';
import ellipse from '../assets/payment-link/ellipse-vector.svg';
import trashIcon from '../assets/payment-link/trash.svg';
import viewIcon from '../assets/payment-link/view.svg';
import share from '../assets/payment-link/share.svg';
import paymentLinkIllustration from '../assets/payment-link/warningImg.png';

// Raffle Draw
import raffleVideoCircle from '../assets/raffle/video-circle.svg';
import raffleVideoCircleLight from '../assets/raffle/video-circle-light.svg';
import rubbiesDiamond from '../assets/raffle/raffle-rubbies-diamond.svg';
import x1Rubbies from '../assets/raffle/x1-rubies.svg';
import x1InLoveRubbies from '../assets/raffle/InLove.svg';
import x10Rubbies from '../assets/raffle/x10-rubies.svg';
import x10LikeRubbies from '../assets/raffle/Like.svg';
import raffleSmiley from '../assets/raffle/smiley.svg';
import wheelSpin from '../assets/raffle/whee-spin.png';
import wheel2Spin from '../assets/raffle/wheel-spin-2.png';
import spinEllipse from '../assets/raffle/spin-ellipse.svg';
import spinResultRibbon from '../assets/raffle/spin-result-ribbon.png';
import spinResultRibbonLarge from '../assets/raffle/spin-result-ribbon-dsk.png';
import instagramIcon from '../assets/raffle/Instagram.svg';
import twitterIconBlue from '../assets/raffle/Twitter.svg';
import facebookIconRaffle from '../assets/raffle/Facebook.svg';
import whatsappIconRaffle from '../assets/raffle/Whatsapp.svg';
import raffleFailIllustration from '../assets/raffle/fail-illustration.svg';
import socialFindGif from '../assets/raffle/social-find.svg';
import scatteredCoin from '../assets/raffle/scattered-coin.png';
import raffleDummyProfileImage from '../assets/raffle/dummy-image.svg';
import raffleDummy2ProfileImage from '../assets/raffle/dummy-image2.svg';
import raffleWheelDecor1 from '../assets/raffle/wheel-decor-1.png';
import raffleWheelDecor2 from '../assets/raffle/wheel-decor-2.png';
import raffleWheelDecor3 from '../assets/raffle/wheel-decor-3.png';

// Bottom Toast
import toastGood from '../assets/toast/toast-good-check.svg';
import toastWarning from '../assets/toast/toast-warning-check.svg';
import toastDanger from '../assets/toast/toast-danger-check.svg';

// KYC
import kycIllustration from '../assets/kyc/kyc-illustration.svg';
import kycInactiveIllustration from '../assets/kyc/kyc-inactive-illustration.svg';
import backIconDarkMode from '../assets/kyc/back-dark-mode.svg';
import backIconLightMode from '../assets/kyc/back-light-mode.svg';
import kycCheckDarkMode from '../assets/kyc/check-dark-mode.svg';
import kycCheckLightMode from '../assets/kyc/check-light-mode.svg';
import kycBuyCryptoIllustrationDarkMode from '../assets/kyc/buy-crypto-dark-mode.svg';
import kycBuyCryptoIllustrationLightMode from '../assets/kyc/buy-crypto-light-mode.svg';
import kycSwapCryptoIllustrationDarkMode from '../assets/kyc/swap-crypto-dark-mode.svg';
import kycSwapCryptoIllustrationLightMode from '../assets/kyc/swap-crypto-light-mode.svg';
import kycTransferIllustrationDarkMode from '../assets/kyc/transfer-limit-dark-mode.svg';
import kycTransferIllustrationLightMode from '../assets/kyc/transfer-limit-light-mode.svg';
import kycInfoIllustration from '../assets/kyc/info-illustration.svg';
import kycVerifiedTickIllustration from '../assets/kyc/good-check.svg';

// Settings payment methods
import settingsBankPaymentIcon from '../assets/settings-payment-methods/settings-bank-payment-icon.svg';
import settingsBankPaymentTrashIcon from '../assets/settings-payment-methods/settings-bank-payment-trash-icon.svg';
import settingPaymentMethodRightArrow from '../assets/settings-payment-methods/settings-payment-method-right-arrow.svg';

import InfoWarningIcon from '../assets/info-circle.svg';
import InfoSuccessIcon from '../assets/info-circle-success.svg';

import filledCheckLight from '../assets/filled-checked.svg';
import filledCheckDark from '../assets/filled-checked-dark.svg';
import { THEME_MODES } from '../utils/themes/useDarkMode';

import investMascotImg from '../assets/invest-mascot.png';
import investOneOffImg from '../assets/invest-oneoff-img.png';
import investRecurringImg from '../assets/invest-recurring-img.png';
import investNewImg from '../assets/invest-new-img.svg';
import investSheet from '../assets/invest-sheet.svg';
import investPinImg from '../assets/invest-pin.png';
import investSuccessImg from '../assets/invest-success.svg';

import roqqDiamond from '../assets/roqqnroll/diamond-green.svg';
import roqqDialogHeader from '../assets/roqqnroll/roqq-dialog-header.svg';
import roqqCloseBtn from '../assets/roqqnroll/roqq-close-btn.svg';
import roqqDialogBackBtn from '../assets/roqqnroll/roqq-dialog-back.svg';
import roqqDepositIcon from '../assets/roqqnroll/roqq-deposit.svg';
import roqqWithdrawIcon from '../assets/roqqnroll/roqq-withdraw.svg';
import roqqSwapIcon from '../assets/roqqnroll/roqq-swap.svg';
import roqqPaybillIcon from '../assets/roqqnroll/roqq-paybill.svg';
import roqqRuby from '../assets/roqqnroll/roqq-ruby.svg';
import roqqArrow from '../assets/roqqnroll/roqq-arrow.svg';
import roqqClaimCoin from '../assets/roqqnroll/claim-success-coin.svg';
import needHelpIcon from '../assets/roqqnroll/needHelp.svg';
import hourGlassIcon from '../assets/roqqnroll/hourGlass.png';
import stat1Icon from '../assets/roqqnroll/stat1.svg';
import stat2Icon from '../assets/roqqnroll/stat2.svg';
import errorIcon from '../assets/roqqnroll/errorIcon.svg';
import rubbyIcon from '../assets/roqqnroll/roqqRuby.svg';
import shadowedPlayIcon from '../assets/roqqnroll/shadow-play-icon.svg';
import roqqVerfifyIdentityIcon from '../assets/roqqnroll/verify-identity.svg';

const commonIcons = {
  InfoWarningIcon,
  InfoSuccessIcon,
  // Raffle
  rubbiesDiamond,
  x1Rubbies,
  x1InLoveRubbies,
  x10Rubbies,
  x10LikeRubbies,
  wheelSpin,
  wheel2Spin,
  spinEllipse,
  spinResultRibbon,
  spinResultRibbonLarge,
  instagramIcon,
  twitterIconBlue,
  facebookIconRaffle,
  whatsappIconRaffle,
  raffleFailIllustration,
  socialFindGif,
  scatteredCoin,
  tipsIconWarning,
  raffleDummyProfileImage,
  raffleDummy2ProfileImage,
  raffleWheelDecor1,
  raffleWheelDecor2,
  raffleWheelDecor3,
  raffleSmiley,

  // Profile dropdown
  myAccountpd,
  helppd,
  notificationpd,

  dummyAvatar,

  bannedAccountIllustration,
  discardedAccountIllustration,

  // HeaderNavContent icons
  closeChillBlue,
  newEllipsis,
  globeIcon,
  globeWhiteIcon,
  bellIcon,
  unseen,
  englishIcon,
  frenchIcon,
  italianIcon,
  swedishIcon,
  spanishIcon,
  germanIcon,
  danishIcon,

  englishLocal,
  igbo,
  yoruba,
  hausa,

  // SideNav icons
  dashboardIcon,
  walletIcon,
  moneyIcon,
  transactionIcon,
  exchangeIcon,
  verificationImg,
  settingImg,
  roqquHubImg,
  helpImg,
  feeImg,
  nftImg,
  nftIcon,
  getStartedIcon,

  //  Dashboard icons
  investImg,
  buyAssetImg,
  sellAssetImg,
  swapCoinImg,
  payBillImg,
  riseIcon,
  fallIcon,
  contactThumbnailImg,
  downloadRoqquAppImg,
  arrowDownDark,

  article1Img,
  article2Img,
  article3Img,
  savingsWelcome,

  paperJet,
  completeIconBlue,
  closeDark: closeLight,

  // Getstarted
  verifyIdentityIcon,
  usernameIcon,

  // nftImg,
  paymentLinkImg,
  searchIcon,
  blackberryLoader,

  //  Single asset page icons
  PriceAlertimg,
  sendCoinImg,
  receiveCoinImg,

  binIcon,

  //  CableBill page icons
  dstvImg,
  gotvImg,
  tstvImg,
  startimeImg,

  // Network Providers
  glo,
  mtn,
  airtel,
  etisalat,

  //  Single asset page icons
  kenyaIcon,
  lockImg,
  tipsImg,

  // DashboardWallet icons
  currenciesIcon,
  greenAlert,
  BankTransferHeader,
  copyRounded,
  depositImage,
  info2,
  cancelIcon,
  // XrpImg,
  // BitcoinImg,
  // EtheriumImg,

  // DepositConfirmTransaction icons
  ravenIcon,
  clockIcon,

  // TransactionTable icons
  currencyIncreImg,
  currencydecreaImg,
  headphones,

  // Settings icons
  plusIcon,
  pencilIcon,
  xRoundedIcon,
  pcIcon,
  iphoneDeviceIcon,
  chevronDown,
  separator,

  // Help & Support icons
  helpIcon,
  accountIcon,
  tradingIcon,
  privacyIcon,
  productsIcon,
  optionsIcon,
  vectorIcon,
  carouselRight,
  carouselLeft,

  // Coins
  btcIcon,
  ethIcon,
  xrpIcon,

  // Toast
  toastErrorIcon,
  toastWarningIcon,
  toastSuccessIcon,
  xIcon,

  notInternetConnection,
  bellAlert,
  fileIcon,
  shareIcon,
  deleteIcon,
  deleteIconRed,
  paymentLinkIllustration,
  imagePlaceholderIcon,
  successWhiteTick,
  successWhiteGIF,
  bell3DImg,
  arrowsIcon,
  arrowHeadDownIcon,
  sidewaysArrowsIcon,
  plusCircleIcon,
  checkboxCheckedIcon,
  reminderBell1Icon,
  documentIcon1,
  securityShieldIcon,
  resetPinIllustrationIcon,
  accordionTickCircleInactive,
  accordionTickCircleActive,
  signOut,
  signOutWhite,
  warnIcon,
  warnYellowIcon,
  notFound,
  notFoundFileIcon,
  newSuccessIcon,

  // Price Alert
  priceAbove,
  priceBelow,
  trash,

  // My NFT Profile Tab
  activityIcon,
  collectionsIcon,
  draftIcon,
  insightIcon,
  myNftIcon,
  loadingCircle,

  // My NFT Profile Icons
  creators,
  curCollections,
  openBids,

  // Error fallbacks
  error404,
  error500,

  // Referrals
  userPlaceholderIcon,
  progressIcon,
  completeIcon,

  // Withdrawal
  instantTransferRouteIcon,
  bankRouteIcon,
  cardRouteIcon,
  cardRouteIconDisabled,
  peerRouteIcon,
  momoRouteIcon,
  momoRouteIconDisabled,
  ravenBankIcon,
  barterBankIcon,
  kudaBankIcon,
  opayBankIcon,
  tingBankIcon,
  carbonIcon,
  walletAfricaIcon,
  pinShield,
  linkRouteIcon,
  paymentLinkRouteIcon,
  bankImg,

  errorExclamationIcon,
  warningExclamationIcon,
  tipsIcon: tipsIconDark,
  visibilityIcon: visibilityIconDark,

  // Flags
  ngnFlagIcon,
  usdFlagIcon,
  gbpFlagIcon,
  eurFlagIcon,
  ghsFlagIcon,

  waveImg,
  profileIcon,

  swap,

  reportedAccount,
  qrWalletIcon,
  username,
  eyeIcon,

  // NEW TOAST ICONS
  newToastWarning,
  newToastSuccess,
  newToastError,
  newToastPending,
  newToastClose,

  // Payment-link Revamp
  empty,
  linkVector,
  copyVector,
  ellipse,
  trashIcon,
  viewIcon,
  share,

  // RIBs
  whitepaperIcon,
  coinGlobalIcon,
  investmentClose,
  investmentReturn,
  RIBSCancelIcon,

  toastGood,
  toastWarning,
  toastDanger,
  ribsTxns,
  trendDown,
  trendUp,

  kycIllustration,
  kycInactiveIllustration,
  kycInfoIllustration,
  kycVerifiedTickIllustration,

  chevDown,
  swapModalCloseIcon,
  RIBSCloseIcon,
  goBck,
  listView,
  pieChart,
  pieChartActive,
  listViewActive,
  dumpIcon,

  trainImg,
  castleImg,

  investMascotImg,
  investOneOffImg,
  investRecurringImg,
  investNewImg,
  investSheet,
  investPinImg,
  investSuccessImg,

  // Roqq n Roll Icons
  roqqDiamond,
  roqqDialogHeader,
  roqqCloseBtn,
  roqqDialogBackBtn,
  roqqDepositIcon,
  roqqWithdrawIcon,
  roqqSwapIcon,
  roqqPaybillIcon,
  roqqRuby,
  roqqArrow,
  roqqClaimCoin,
  statsIcon,
  statsBorderTopIcon,
  statsBorderBottomIcon,
  needHelpIcon,
  hourGlassIcon,
  stat1Icon,
  stat2Icon,
  errorIcon,
  rubbyIcon,
  shadowedPlayIcon,
  roqqVerfifyIdentityIcon,
};

const darkModeIcons = {
  // quick actions
  buyAssetSingleImg,
  transactionSingleImg: transactionSingleWhiteImg,
  sellAssetSingleImg,
  swapCoinSingleImg,
  roqqnrollSingleImg,
  convertSingleImg,
  payBillSingleImg,
  loansActionImg,
  paymentLinkActionImg,
  cardsActionImg,
  nftActionImg,
  stakeActionImg,
  savingsUSDTImg,
  newsActionImg,
  chevronRight,

  // Bank Payment Methods
  settingsBankPaymentIcon,
  settingsBankPaymentTrashIcon,
  settingPaymentMethodRightArrow,

  // Raffle
  raffleVideoCircle,
  infoManIllustrationFaded,
  initInfoBg1,
  initInfoBg2,

  backIcon,
  closeIcon,

  filledCheck: filledCheckDark,
  logoImg,
  smallLogoImg,
  searchBlue: searchBlueDark,

  //  PayBill page darkmode icons
  airtimeIcon,
  cableIcon,
  electricityIcon,
  dataIcon,

  // DashboardWallet icons
  depositIcon,
  withdrawIcon,
  transferIcon,
  star,
  unStar,

  copyIcon,
  add,
  checkIcon: checkDark,
  mainCopyIcon,

  // Referrals
  facebookIcon,
  twitterIcon,

  // Transfer
  bulletpointCheck,
  close,
  filledChevDown,

  neutralExclamationIcon: neutralExclamationIconDark,
  accordionAngleDown,

  // User Verification Icons
  arrowRightCircled: arrowRightCircledLight,

  appStoreDownloadImg,
  playStoreDownloadImg,

  // Help & Support
  doubleChevron,
  calendar: calendarDarkModeIcon,
  filter: filterDarkModeIcon,

  // RIBs
  chevronRightBlunt,
  ribsFilterIcon,
  moreCircleIcon,

  seen,

  // BreadCrumbs
  breadReturn,

  // Send Coin
  chevLeftIcon: chevLeft,
  chevRightIcon: chevRight,
  kycBackIcon: backIconDarkMode,
  kycCheck: kycCheckDarkMode,
  kycBuyCrypto: kycBuyCryptoIllustrationDarkMode,
  kycSwapCrypto: kycSwapCryptoIllustrationDarkMode,
  kycTransfer: kycTransferIllustrationDarkMode,
};

const lightModeIcons = {
  // quick actions
  buyAssetSingleImg: buyAssetSingleImgLight,
  transactionSingleImg: transactionSingleDarkImg,
  sellAssetSingleImg: sellAssetSingleImgLight,
  swapCoinSingleImg: swapCoinSingleImgLight,
  roqqnrollSingleImg: roqqnrollSingleImgLight,
  convertSingleImg: convertSingleImgLight,
  payBillSingleImg: payBillSingleImgLight,
  loansActionImg: loansActionImgLight,
  paymentLinkActionImg: paymentLinkActionImgLight,
  cardsActionImg: cardsActionImgLight,
  nftActionImg: nftActionImgLight,
  stakeActionImg: stakeActionImgLight,
  savingsUSDTImg: savingsUSDTImgLight,
  newsActionImg: newsActionImgLight,
  chevronRight: chevronRightLight,

  // Bank Payment Methods
  settingsBankPaymentIcon,
  settingsBankPaymentTrashIcon,
  settingPaymentMethodRightArrow,

  // Raffle
  raffleVideoCircle: raffleVideoCircleLight,
  infoManIllustrationFaded: infoManIllustrationFadedLight,
  initInfoBg1: initInfoBg1Light,
  initInfoBg2: initInfoBg2Light,

  backIcon: backIconLight,
  closeIcon: closeIconLight,

  filledCheck: filledCheckLight,
  logoImg: colorLogoImg,
  smallLogoImg: smallColorLogoImg,
  searchBlue: searchBlueLight,

  //  PayBill page lightmode icons
  airtimeIcon: airtimeIconLight,
  cableIcon: cableIconLight,
  electricityIcon: electricityIconLight,
  dataIcon: dataIconLight,

  // DashboardWallet icons
  depositIcon: depositIconLight,
  withdrawIcon: withdrawIconLight,
  transferIcon: transferIconLight,
  star: starLight,
  unStar: unStarLight,
  mainCopyIcon: mainCopyIconLight,

  copyIcon: copyIconLight,
  add: addLight,
  checkIcon: checkLight,

  // Referrals
  facebookIcon: facebookLightIcon,
  twitterIcon: twitterLightIcon,

  // Transfer
  bulletpointCheck: bulletpointCheckLight,
  close: closeLight,
  filledChevDown: filledChevDownLight,
  neutralExclamationIcon: neutralExclamationIconLight,
  accordionAngleDown: accordionAngleDownLight,

  // User Verification Icons
  arrowRightCircled: arrowRightCircledDark,

  appStoreDownloadImg: appStoreDownloadLightImg,
  playStoreDownloadImg: playStoreDownloadLightImg,

  // Help & Support
  doubleChevron: doubleChevronLight,
  calendar: calendarLightModeIcon,
  filter: filterLightModeIcon,

  // RIBs
  chevronRightBlunt: chevronRightBluntLight,
  ribsFilterIcon,
  moreCircleIcon,

  seen: seenLight,

  // BreadCrumbs
  breadReturn: breadReturnLight,
  chevLeftIcon: chevLeft,
  chevRightIcon: chevRight,
  kycBackIcon: backIconLightMode,
  kycCheck: kycCheckLightMode,
  kycBuyCrypto: kycBuyCryptoIllustrationLightMode,
  kycSwapCrypto: kycSwapCryptoIllustrationLightMode,
  kycTransfer: kycTransferIllustrationLightMode,
};

export function useIcon() {
  const actionCtx = useContext(ActionContext);

  let icons = {};

  switch (actionCtx.theme) {
    case THEME_MODES.dark:
      icons = { ...commonIcons, ...darkModeIcons };
      break;
    case THEME_MODES.black:
      icons = { ...commonIcons, ...darkModeIcons };
      break;
    case THEME_MODES.light:
      icons = { ...commonIcons, ...lightModeIcons };
      break;
    default:
      break;
  }

  return icons;
}

// This hook imports all icons used within the application
// wiith the exception of icons from reactIcons
// and exports them depending on the current theme
