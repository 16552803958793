/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useIcon } from '../../../hooks/useIcon';
import Portal from '../../widgets/Portal';
import '../../../styles/fragments/AddCoinDrawer.css';

function DrawerLayout({
  onClose = () => {},
  isVisible = true,
  width = '32%',
  children,
  title,
  goBack,
  goBackFunc = () => {},
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showChild, setShowChild] = useState(false);
  const { close } = useIcon();

  function dismissHandler() {
    setIsCollapsed(true);
    if (isVisible) {
      setShowChild(false);
      // I'm using a delay of 200ms here because it takes 200ms for the animation that
      // closes the side nav drawer to run. If you use lesss than 2ms, the animation
      // won't be smooth and if you use more than that, the app might look slow
      setTimeout(() => {
        setIsCollapsed(false);
        onClose();
      }, 200);
    }
  }

  useEffect(() => {
    if (isVisible) {
      // open child content after drawer is open
      // this is to prevent child squash
      setTimeout(() => {
        setShowChild(true);
      }, 200);
    }
  }, [isVisible]);
  return (
    <>
      <Portal selector="#modal">
        {isVisible && (
          <div
            onClick={(event) => event.stopPropagation()}
            className="end-nav-drawer-wrap"
          >
            <div
              className="end-nav-drawer-content"
              style={{ '--drawer-width': width }}
            >
              <div className="dismiss-area" onClick={dismissHandler} />
              <div
                className={`end-nav-drawer-main ${isCollapsed && 'collapse'}`}
              >
                <div className="line" />
                <div className="header">
                  <div className="title-section">
                    {goBack && (
                      <FaAngleLeft
                        onClick={() => goBackFunc()}
                        className="icon toggle-blue-color"
                      />
                    )}
                    <h1 className="title">{title}</h1>
                  </div>
                  <img
                    src={close}
                    alt="dismiss side nav"
                    className="close-drawer"
                    onClick={() => dismissHandler()}
                  />
                </div>
                {showChild && <div className="child-container">{children}</div>}
              </div>
            </div>
          </div>
        )}
      </Portal>
    </>
  );
}

export default DrawerLayout;
