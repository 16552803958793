import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class KYCService {
  static async getKYCCountries() {
    const { response, error } = await post({
      url: apiRoutes.getKYCCountries,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error(error.response.data);
    }
    return response;
  }

  static async getKYCSelectedLink({ country, level }) {
    const { response, error } = await post({
      url: apiRoutes.getSelectedKycLink,
      data: {
        token: AuthService._getUserToken(),
        country,
        level,
      },
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response;
  }
}
