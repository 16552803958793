/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const LoadingStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  ERROR: 'error',
};

const initialState = {
  newToCrypto: '',
  investAmount: '',
  selectedBag: null,
};

const ribsApplicationSlice = createSlice({
  name: 'ribsApplication',
  initialState,
  reducers: {
    setNewToCrypto(state, action) {
      state.newToCrypto = action.payload;
    },
    setInvestAmount(state, action) {
      state.investAmount = action.payload;
    },
    setSelectedBag(state, action) {
      state.selectedBag = action.payload;
    },
    resetAll(state) {
      state.selectedBag = initialState.selectedBag;
      state.investAmount = initialState.investAmount;
    },
  },
});

export const selectRIBsApplication = (state) => state.ribsApplication;

export const {
  setNewToCrypto,
  setInvestAmount,
  setSelectedBag,
  resetAll,
} = ribsApplicationSlice.actions;
export default ribsApplicationSlice.reducer;
