/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import './ReelProgressIndicators.css';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import {
  goToNextReelStage,
  incrementReelProgressPercentage,
  resetReelState,
} from '../../slices/reelIndicatorsSlice';

export default function ReelProgressIndicators({ numberOfStages, onStageChange, onComplete }) {
  const dispatch = useDispatch();
  const [mappingArray, setMappingArray] = useState([]);
  const intervalRef = useRef(null);
  const {
    finalStageIndex,
    currentStageIndex,
    currentStageProgress,
  } = useSelector((state) => state.reels);

  // eslint-disable-next-line no-unused-vars

  function playReels() {
    dispatch(incrementReelProgressPercentage());
  }

  useLayoutEffect(() => {
    dispatch(resetReelState(numberOfStages));
    const newArr = createAndPopulateArray(numberOfStages);
    setMappingArray(newArr);
  }, []);

  useEffect(() => {
    bindCallbackToIntervalRef(intervalRef, playReels, 40);
    onStageChange && onStageChange(0);

    return () => {
      clearInterval(intervalRef.current);
      dispatch(resetReelState(numberOfStages));
    };
  }, []);

  useEffect(() => {
    if (currentStageProgress === 100) {
      if (currentStageIndex === finalStageIndex) {
        clearInterval(intervalRef.current);
        onComplete && onComplete();
      } else {
        onStageChange && onStageChange(currentStageIndex + 1);
        dispatch(goToNextReelStage());
      }
    }
  }, [currentStageProgress]);

  return (
    <div className="reel-progress-indicators">
      {
        mappingArray.map((_, index) => (
          <div
            key={uuid()}
            id={`reel-inicator-${index}`}
            className="reel-indicator"
          >
            <div
              className="reel-indicator-inner-bar"
              style={(index === currentStageIndex) ? {
                width: `${currentStageProgress}%`,
              } : (index < currentStageIndex) ? {
                width: '100%',
              } : {
                width: '0%',
              }}
            />
          </div>
        ))
      }
    </div>
  );
}

function createAndPopulateArray(length) {
  const newArr = [];
  for (let i = 0; i < length; i++) {
    newArr.push({
      index: i,
      width: '0%',
    });
  }
  return newArr;
}

function bindCallbackToIntervalRef(ref, callback, intervalMilliseconds) {
  ref.current = setInterval(callback, intervalMilliseconds);
}
