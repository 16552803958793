/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import './InvestIntro.css';
import {
  useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import SubFlow from '../fragments/subFlow';
import DashboardLayout from '../layouts/dashboard';
import { useIcon } from '../../hooks/useIcon';
import ButtonReuse from '../fragments/Button';
import ReelProgressIndicators from '../ReelProgressIndicators/ReelProgressIndicators';

function H6({ children }) {
  return (
    <h4 className="invest-intro-h6">{children}</h4>
  );
}

function Paragraph({ children }) {
  return (
    <p className="invest-intro-paragraph">{children}</p>
  );
}

function Page({ data }) {
  const { investMascotImg } = useIcon();
  return (
    <section className="invest-intro-page">
      <H6>{data.title}</H6>
      <div className="text-gap" />
      <Paragraph>{data.subtitle}</Paragraph>
      <figure className="mascot-box">
        <img src={data.img} alt="invest mascot" className="mascot" />
      </figure>
    </section>
  );
}

// TODO: Add this page to the flow. It may be undoable because there may not be data to rank the different bags
// according to their performances
// function SelectInvestmentType() {}

export default function InvestIntro() {
  const { investMascotImg, trainImg, castleImg } = useIcon();
  const pageData = useMemo(() => [
    {
      title: 'Stress-free investing',
      subtitle: 'Instead of always trying to time the market, Auto invest buys crypto for you over time at set intervals.',
      img: investMascotImg,
    },
    {
      title: 'Your crypto autopilot',
      subtitle: 'Select a bag of coins and set your plan to buy daily, weekly or monthly while you relax and spend less time on the charts.',
      img: trainImg,
    },
    {
      title: 'Build a long-term plan',
      subtitle: 'Split a single fund across multiple timelines to ensure you make the most out of a crypto project.',
      img: castleImg,
    },
  ], []);
  const openRoute = useNavigate();
  const [routeIndex, setRouteIndex] = useState(0);

  function returnToPrevPage() {
    openRoute(-1);
  }

  function onStageChange(newStageIndex) {
    setRouteIndex(newStageIndex);
  }

  function openRIBs() {
    openRoute('/dashboard-home-ribs-pick-new-bag');
  }

  function skipIntro() {
    openRIBs();
  }

  function onComplete() {
    openRIBs();
  }

  return (
    <DashboardLayout pageTitle="Invest">
      <div className="invest-intro">
        <SubFlow
          title="Invest"
          isVisible
          goBack
          goBackFunc={returnToPrevPage}
          onClose={returnToPrevPage}
        >
          <SubFlow.BodyBGWrapper>
            <ReelProgressIndicators
              numberOfStages={3}
              onStageChange={onStageChange}
              onComplete={onComplete}
            />
            <main className="content">
              {
                routeIndex === 0 ? (
                  <Page data={pageData[0]} />
                ) : routeIndex === 1 ? (
                  <Page data={pageData[1]} />
                ) : routeIndex === 2 ? (
                  <Page data={pageData[2]} />
                ) : <div />
              }
              <ButtonReuse
                text="Start Investing"
                onClick={skipIntro}
                btnStyle="invest-intro-btn"
              />
            </main>
          </SubFlow.BodyBGWrapper>
        </SubFlow>
      </div>
    </DashboardLayout>
  );
}
