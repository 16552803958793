import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class ReferralService {
  static async getReferrals() {
    const { response } = await post({
      url: apiRoutes.getReferrals,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }
}
