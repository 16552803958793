/* eslint-disable react/prop-types */
import React from 'react';
import { useIcon } from '../../../../hooks/useIcon';

function InputErrorMessage({ errorMessage, isWarning, warningMessage }) {
  const { errorExclamationIcon, warningExclamationIcon } = useIcon();
  return (
    <>
      <figure className="img-box error-img-box">
        {isWarning ? (
          <img src={warningExclamationIcon} className="img" alt="" />
        ) : (
          <img src={errorExclamationIcon} className="img" alt="" />
        )}
      </figure>
      <div className={`${isWarning ? 'warning-message' : 'error-message'} error`}>
        {isWarning ? warningMessage : errorMessage}
      </div>
    </>
  );
}

export default InputErrorMessage;
