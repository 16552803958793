import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

const UsernameService = {
  async setUsername(username) {
    const { response, error } = await post({
      url: apiRoutes.setUsername,
      data: {
        username,
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response;
  },
};

// const sampleSuccessResponse = {
//   error: false,
//   response: {
//     data: {
//       data: null,
//       message: 'username set successfully',
//       status: 'success',
//     },
//   },
// };

export default UsernameService;
