/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useRef } from 'react';
import '../../../styles/dashboard/layout/DashboardLayout.css';
import { use100vh } from '../../../hooks/use100vh';
import ActionContext from '../../../context/ActionContext';
import VerifyDrawer from '../../fragments/VerifyDetails/VerifyDrawer';
import VerifyModal from '../../fragments/VerifyDetails/VerifyModal';
import CurrencyConvertDrawer from '../../fragments/CurrencyConvert/CurrencyConvertDrawer';
import UsernameDrawer from '../../../pages/dashboard/get-started/UsernameDrawer/UsernameDrawer';
import ToolTip from '../../../pages/dashboard/home/tool-tip/ToolTip';
import TransferSuccessCombination from '../../../pages/dashboard/wallet-portfolio/transfer/TransferSuccessCombination';
import FAB from './FAB';
import Copy from '../../fragments/Copy';
import RateCheckerDialog from '../../fragments/ToolDialogs/RateCheckerDialog';
import TransactionCheckerDialog from '../../fragments/ToolDialogs/TransactionCheckerDialog';

const DashboardLayout = React.forwardRef(
  ({
    children, pageTitle, goBack, goBackFunc,
  }, dashboardRef) => {
    const actionCtx = useContext(ActionContext);

    const scrollBodyRef = useRef(null);

    React.useImperativeHandle(dashboardRef, () => ({
      scrollToTop() {
        if (scrollBodyRef) {
          scrollBodyRef?.current?.scrollTo(0, 0);
        }
      },
      scrollBodyRef,
    }));

    const height = use100vh();
    return (
      <>
        <VerifyDrawer />
        <VerifyModal />
        <CurrencyConvertDrawer />
        <UsernameDrawer />
        <ToolTip />
        <TransferSuccessCombination />
        <RateCheckerDialog />
        <TransactionCheckerDialog />
        <FAB />
        <div
          className="dashboard-layout-wrap scroll-design"
          id="master-container"
          role="presentation"
          ref={scrollBodyRef}
          style={{
            minHeight: height ? `${height}px` : '100vh',
          }}
        >
          {/* <div
            className={`side-bar ${actionCtx?.isSidebarReduce && 'side-bar-reduced'} ${
              actionCtx?.isSlideBarOpen && 'translateX'
            }`}
          >
            <Sidebar theme={actionCtx?.theme} toggleTheme={actionCtx?.toggleTheme} />
          </div> */}
          <main
            className="main white-black-bg default-background main-reduced"
            onClick={() => actionCtx?.slideBarFunc('close')}
          >
            <div className="main-container">{children}</div>
          </main>
        </div>
      </>
    );
  },
);

export default DashboardLayout;
