/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import WalletService from '../services/walletService';
import WalletWithdrawalService from '../services/walletWithdrawalService';

export const LoadingStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  ERROR: 'error',
};

const initialState = {
  currency: null,
  amount: null,
  withdrawalRoutesState: LoadingStates.BASE,
  withdrawalRoutes: null,
  withdrawalRoutesError: null,
  route: null,

  // BANK WITHDRAWAL DATA START
  bankAccountName: null,
  bankAccountNumber: null,
  bankName: null,
  // ----- BANK WITHDRAWAL DATA END -----

  // P2P WITHDRAWAL DATA START
  p2pProvider: null,
  p2pBankAccountNumber: null,
  // p2pName is the name of the user that is initiating the withdrawal
  p2pIdentifier: null,
  p2pMerchant: {
    name: null,
    phoneNumber: null,
    loadingState: LoadingStates.BASE,
    error: null,
  },

  // MOMO WITHDRAWAL DATA
  selectedOption: null,
  momoAccountName: null,
  momoNumber: null,
  momoProviderName: null,
  momoProviderKey: null,
  // MOMO WITHDRAWAL DATA END

  // GBP & EUR WITHDRAWAL DATA
  bank: null,
  logo: null,
  recipientName: null,
  sortCode: null,
  accountNumber: null,
  narration: null,
  // GBP & EUR WITHDRAWAL DATA END

  withdrawalRouteOptions: {
    loadingState: LoadingStates.BASE,
    routes: null,
    error: null,
  },
  bankResponseData: {
    loadingState: LoadingStates.BASE,
    data: null,
    error: null,
  },
  momoResponseData: {
    loadingState: LoadingStates.BASE,
    data: null,
    error: null,
  },
  p2pResponseData: {
    loadingState: LoadingStates.BASE,
    data: null,
    error: null,
  },
  foreignWithdrawalResponseData: {
    loadingState: LoadingStates.BASE,
    data: null,
    error: null,
  },
};

const walletWithdrawalSlice = createSlice({
  name: 'walletWithdrawal',
  initialState,
  reducers: {
    resetState: (state) => {
      state.currency = initialState.currency;
      state.amount = initialState.amount;
    },
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
    setAmount: (state, { payload }) => {
      state.amount = payload.slice(1);
    },
    setRoute: (state, { payload }) => {
      state.route = payload;
    },
    setOption: (state, { payload }) => {
      state.selectedOption = payload;
    },
    setMomoData: (state, { payload }) => {
      state.momoNumber = payload.momoNumber;
      state.momoProviderName = payload.providerName;
      state.momoProviderKey = payload.providerKey;
      state.momoAccountName = payload.name;
    },
    setForeignBank: (state, { payload }) => {
      state.bank = payload.bankName;
      state.logo = payload.logo;
    },
    setForeignData: (state, { payload }) => {
      state.recipientName = payload.recipientName;
      state.sortCode = payload.sortCode;
      state.accountNumber = payload.accountNumber;
      state.narration = payload.narration;
    },
    setBankData: (state, { payload }) => {
      state.bankAccountNumber = payload.accountNumber;
      state.bankSortCode = payload.sortCode || ' ';
      state.bankAccountName = payload.accountName;
      state.bankName = payload.bankName;
    },
    setP2PIdentifier: (state, { payload }) => {
      state.p2pIdentifier = payload;
    },
    setP2PProvider: (state, { payload }) => {
      state.p2pProvider = payload;
    },
    resetWithdrawalResponses: (state) => {
      state.bankResponseData = initialState.bankResponseData;
      state.momoResponseData = initialState.momoResponseData;
      state.p2pResponseData = initialState.p2pResponseData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWithdrawalRoutes.pending, (state) => {
      state.withdrawalRoutesState = LoadingStates.LOADING;
    });
    builder.addCase(getWithdrawalRoutes.fulfilled, (state, { payload }) => {
      state.withdrawalRoutes = payload || {
        status: 0,
        card: 0,
        bank: 0,
        p2p: 0,
        momo: 0,
        payment_links: 0,
      };
      // UNDO: leave empty routes to null

      delete state.withdrawalRoutes.currency;
      delete state.withdrawalRoutes.status;
      delete state.withdrawalRoutes.payment_links;

      state.withdrawalRoutesError = null;
      state.withdrawalRoutesState = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(getWithdrawalRoutes.rejected, (state, { payload }) => {
      state.withdrawalRoutesError = payload;
      state.withdrawalRoutes = null;
      state.withdrawalRoutesState = LoadingStates.ERROR;
    });
    builder.addCase(getWithdrawalOptionsForRoute.pending, (state) => {
      state.withdrawalRouteOptions.loadingState = LoadingStates.LOADING;
    });
    builder.addCase(getWithdrawalOptionsForRoute.fulfilled, (state, { payload }) => {
      state.withdrawalRouteOptions.routes = payload;
      state.withdrawalRouteOptions.error = null;
      state.withdrawalRouteOptions.loadingState = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(getWithdrawalOptionsForRoute.rejected, (state, { payload }) => {
      state.withdrawalRouteOptions.error = payload;
      state.withdrawalRouteOptions.routes = null;
      state.withdrawalRouteOptions.loadingState = LoadingStates.ERROR;
    });
    builder.addCase(withdrawToBank.pending, (state) => {
      state.bankResponseData.loadingState = LoadingStates.LOADING;
    });
    builder.addCase(withdrawToBank.fulfilled, (state, { payload }) => {
      state.bankResponseData.data = payload;
      state.bankResponseData.error = null;
      state.bankResponseData.loadingState = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(withdrawToBank.rejected, (state, { payload }) => {
      state.bankResponseData.error = payload;
      state.bankResponseData.data = null;
      state.bankResponseData.loadingState = LoadingStates.ERROR;
    });
    builder.addCase(withdrawToRaven.pending, (state) => {
      state.p2pResponseData.loadingState = LoadingStates.LOADING;
    });
    builder.addCase(withdrawToRaven.fulfilled, (state, { payload }) => {
      if (payload.message === 'incorrect pin for user') {
        state.p2pResponseData.error = 'incorrect pin for user';
        state.p2pResponseData.data = null;
        state.p2pResponseData.loadingState = LoadingStates.ERROR;
      } else {
        state.p2pResponseData.data = payload;
        state.p2pResponseData.error = null;
        state.p2pResponseData.loadingState = LoadingStates.SUCCESSFUL;
      }
    });
    builder.addCase(withdrawToRaven.rejected, (state, { payload }) => {
      state.p2pResponseData.error = payload;
      state.p2pResponseData.data = null;
      state.p2pResponseData.loadingState = LoadingStates.ERROR;
    });
    builder.addCase(getP2PMerchantDetails.pending, (state) => {
      state.p2pMerchant.loadingState = LoadingStates.LOADING;
    });
    builder.addCase(getP2PMerchantDetails.fulfilled, (state, { payload }) => {
      state.p2pMerchant.name = payload.fullname;
      state.p2pMerchant.phoneNumber = payload.phone_number;
      state.p2pMerchant.error = null;
      state.p2pMerchant.loadingState = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(getP2PMerchantDetails.rejected, (state, { payload }) => {
      state.p2pMerchant.error = payload;
      state.p2pMerchant.name = null;
      state.p2pMerchant.phoneNumber = null;
      state.p2pMerchant.loadingState = LoadingStates.ERROR;
    });

    builder.addCase(withdrawToMomo.pending, (state) => {
      state.momoResponseData.loadingState = LoadingStates.LOADING;
    });
    builder.addCase(withdrawToMomo.fulfilled, (state, { payload }) => {
      state.momoResponseData.data = payload;
      state.momoResponseData.error = null;
      state.momoResponseData.loadingState = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(withdrawToMomo.rejected, (state, { payload }) => {
      state.momoResponseData.error = payload;
      state.momoResponseData.data = null;
      state.momoResponseData.loadingState = LoadingStates.ERROR;
    });
  },
});

export const getWithdrawalRoutes = createAsyncThunk(
  'walletWithdrawal/getWithdrawalRoutes',
  async (currency, { rejectWithValue }) => {
    try {
      const response = await WalletService.getCurrencyWithdrawRoutes(currency);
      return response.data.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const getWithdrawalOptionsForRoute = createAsyncThunk(
  'walletWithdrawal/getWithdrawalOptions',
  async ({ currency, route }, { rejectWithValue }) => {
    try {
      const response = await WalletService.getRouteWithdrawOptions({ currency, route });
      return response.data.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

/**
 * This thunk's extraReducers have not been implemented yet. They will be implemented
 * after the call tomorrow
 */
export const withdrawToBank = createAsyncThunk(
  'walletWithdrawal/withdrawToBank',
  async (pin, { getState, rejectWithValue }) => {
    try {
      const { amount, currency, bankAccountNumber, iban } = getState().walletWithdrawal;
      const _amount = amount.replace(/[^0-9.]/g, '');
      const data = await WalletWithdrawalService.withdrawToBank({
        amount: _amount,
        account: bankAccountNumber || iban,
        currency,
        pin,
      });
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const withdrawToRaven = createAsyncThunk(
  'walletWithdrawal/withdrawToRaven',
  async (pin, { getState, rejectWithValue }) => {
    try {
      const {
        currency,
        route,
        amount,
        p2pIdentifier: { identifier },
        p2pMerchant: { phoneNumber },
      } = getState().walletWithdrawal;
      const _amount = amount.replace(/[^0-9.]/g, '');
      const response = await WalletWithdrawalService.withdrawToP2P({
        currency,
        route,
        amount: _amount,
        provider: 'raven',
        recipient: identifier,
        sender: phoneNumber,
        pin,
      });
      return response;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const getP2PMerchantDetails = createAsyncThunk(
  'walletWithdrawal/getRavenMerchantDetails',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { currency, route, p2pProvider: provider, amount } = getState().walletWithdrawal;
      const _amount = amount.replace(/[^0-9.]/g, '');
      const data = await WalletWithdrawalService.getP2PMerchantDetails({
        currency,
        route,
        amount: _amount,
        provider,
      });
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const withdrawToMomo = createAsyncThunk(
  'walletWithdrawal/withdrawToMomo',
  async (pin, { getState, rejectWithValue }) => {
    try {
      const { amount, momoNumber } = getState().walletWithdrawal;
      const data = await WalletWithdrawalService.withdrawToMomo({
        mobile: momoNumber,
        amount,
        pin,
      });
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export default walletWithdrawalSlice.reducer;
export const {
  resetState: resetWalletWithdrawalState,
  setCurrency: setWalletWithdrawalCurrency,
  setAmount: setWalletWithdrawalAmount,
  setRoute: setWalletWithdrawalRoute,
  setOption: setWalletWithdrawalRouteOption,
  setMomoData,
  setBankData: setWalletWithdrawalBankData,
  setP2PIdentifier: setWalletWithdrawalP2PIdentifier,
  setP2PProvider: setWalletWithdrawalP2PProvider,
  resetWithdrawalResponses,
  setForeignBank,
  setForeignData,
} = walletWithdrawalSlice.actions;
export const getWithdrawalRoutesState = (state) => state.walletWithdrawal.withdrawalRouteOptions;
export const getBankWithdrawalResponseState = (state) => state.walletWithdrawal.bankResponseData;
export const getMomoWithdrawalResponseState = (state) => state.walletWithdrawal.momoResponseData;
export const getP2PWithdrawalResponseState = (state) => state.walletWithdrawal.p2pResponseData;
