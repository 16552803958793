/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  Fragment, useEffect, useMemo, useRef, useState,
} from 'react';

import './SwapInputs.css';
import uuid from 'react-uuid';
import { useIcon } from '../../../hooks/useIcon';
import ImagePlaceholder from '../ImagePlaceholder';
import getIconUrl from '../../../utils/getIconUrl';
import ModalLayout from '../../layouts/dashboard/ModalLayout';
// eslint-disable-next-line object-curly-newline
import { checkForReallySmallNumbers, formatCurrencyField, formatNumber, removeAlpha } from '../../../utils/simpleActions';

function SwapInputs({
  canFormatNumber = true,
  swapPositions = () => { },
  assets = [], // assets => { label, symbol, icon, price? }
  // fromDetails => { fromAssetCurrency, fromAssetBalance, fromAssetCurrencyIcon, fromAmount, onChangeFromCurrency, isError, errorMessage, dontChange, }
  fromDetails = {},
  // toDetails => { toAssetCurrency, toAmount, toAssetBalanceAfter, toAssetCurrencyIcon, setToAmount, onChangeToCurrency, isError, errorMessage, dontChange, }
  toDetails = {},
  amountDecimalPlaces = 2,
}) {
  const inputRef = useRef(null);
  const toInputRef = useRef(null);

  const {
    arrowHeadDownIcon, chevDown, swapModalCloseIcon, searchIcon,
  } = useIcon();

  const [fromLabel, setFromLabel] = useState({});
  const [_fromAmount, set_FromAmount] = React.useState(fromDetails?.fromAmount);

  const [toLabel, setToLabel] = useState({});
  const [_toAmount, set_toAmount] = React.useState(toDetails?.toAmount);

  useEffect(() => {
    let _newAmount = checkForReallySmallNumbers(fromDetails?.fromAmount);
    // setParentAmount(removeAlpha(_newAmount));
    if (canFormatNumber) {
      _newAmount = formatCurrencyField(_newAmount, inputRef, amountDecimalPlaces);
    }
    set_FromAmount(_newAmount);
  }, [fromDetails?.fromAmount]);

  useEffect(() => {
    let _newAmount = checkForReallySmallNumbers(toDetails?.toAmount);
    // setParentAmount(removeAlpha(_newAmount));
    if (canFormatNumber) {
      _newAmount = formatCurrencyField(_newAmount, toInputRef, amountDecimalPlaces);
    }
    set_toAmount(_newAmount);
  }, [toDetails?.toAmount]);

  function handleChange(e) {
    let _value = checkForReallySmallNumbers(e.target.value);
    fromDetails?.setFromAmount(removeAlpha(_value));
    if (canFormatNumber) {
      _value = formatCurrencyField(_value, inputRef, amountDecimalPlaces);
    }
    set_FromAmount(_value);
  }

  function handleToAmountChange(e) {
    let _value = checkForReallySmallNumbers(e.target.value);
    toDetails?.setToAmount(removeAlpha(_value));
    if (canFormatNumber) {
      _value = formatCurrencyField(_value, inputRef, amountDecimalPlaces);
    }
    set_toAmount(_value);
  }

  useEffect(() => {
    if (fromDetails?.fromAssetCurrency) {
      const _asset = assets?.find((d) => d.symbol === fromDetails?.fromAssetCurrency);
      if (_asset) {
        setFromLabel(_asset);
      }
    }
  }, [fromDetails?.fromAssetCurrency]);

  useEffect(() => {
    if (toDetails?.toAssetCurrency) {
      const _asset = assets?.find((d) => d.symbol === toDetails?.toAssetCurrency);
      if (_asset) {
        setToLabel(_asset);
      }
    }
  }, [toDetails?.toAssetCurrency]);

  // MODAL
  const [coinSelectionModalOpen, setCoinSelectionModalOpen] = useState(false);
  const [currentSelectionFor, setCurrentSelectionFor] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const modalAssets = useMemo(() => {
    let _assets = assets;
    if (currentSelectionFor === 'from') {
      _assets = assets.filter((d) => d.symbol !== toDetails?.toAssetCurrency);
    } else if (currentSelectionFor === 'to') {
      _assets = assets.filter((d) => d.symbol !== fromDetails?.fromAssetCurrency);
    }
    return _assets;
  }, [assets, currentSelectionFor, fromDetails?.fromAssetCurrency]);

  function handleCoinSelectionModalOpen(_for) {
    setCoinSelectionModalOpen(true);
    setCurrentSelectionFor(_for);
  }

  function handleCoinSelectionModalClose() {
    setCoinSelectionModalOpen(false);
    setCurrentSelectionFor('');
  }

  function clearSearchQuery() {
    setSearchQuery('');
  }

  function selectAssetsCurrency(_currency) {
    if (currentSelectionFor === 'from') {
      fromDetails?.onChangeFromCurrency(_currency);
    } else if (currentSelectionFor === 'to') {
      toDetails?.onChangeToCurrency(_currency);
    }
    handleCoinSelectionModalClose();
  }

  useEffect(() => {
    const results = modalAssets.filter(
      (coin) => coin.label?.toLowerCase().includes(searchQuery?.toLowerCase())
        || coin.symbol?.toLowerCase().includes(searchQuery?.toLowerCase()),
    );
    setSearchResults(results);
  }, [searchQuery, modalAssets]);

  return (
    <>
      <div className="swap-inputs-wrapper">
        {/* SWAP FROM SECTION */}
        <div className={`swap-from-section ${fromDetails?.isError ? 'error' : ''}`}>
          <div className="swap-section">
            <div className="section-row">
              <div className="section-label">
                Amount to swap
              </div>

              <div className="" style={{ display: 'flex', columnGap: '0.4rem' }}>
                <span className="section-label-balance section-label">
                  Balance:
                </span>
                {' '}
                <span className="section-label-balance-price">{fromDetails?.fromAssetBalance}</span>
              </div>
            </div>

            <div className="section-row">
              <div className="asset-section">
                <ImagePlaceholder
                  src={fromDetails?.fromAssetCurrencyIcon}
                />

                <div className="asset-dp-section" onClick={() => !fromDetails?.dontChange && handleCoinSelectionModalOpen('from')}>
                  <span className="asset-symbol-figure">{fromDetails?.fromAssetCurrency?.toUpperCase()}</span>
                  {!fromDetails?.dontChange && (
                  <figure className="img-box">
                    <img src={chevDown} alt="" className="img icon-color" />
                  </figure>
                  )}
                </div>
              </div>

              <div className="input-wrapper">
                <input
                  inputMode="numeric"
                  autoComplete="off"
                  onChange={handleChange}
                  value={_fromAmount}
                  type="text"
                  name="amount"
                  id="amount"
                  ref={inputRef}
                  className="asset-symbol-figure white-black-color"
                />
              </div>
            </div>

            <div className="section-row">
              <div className="section-label">
                {fromLabel?.label}
              </div>

              {fromLabel?.price && (
              <div className="section-label">
                {fromLabel?.price}
              </div>
              )}
            </div>
          </div>

          <div className="swap-pointer-controller" onClick={() => swapPositions()}>
            <figure className="img-box">
              <img src={arrowHeadDownIcon} alt="" className="icon-color img" />
            </figure>
          </div>
        </div>
        {/* SWAP FROM SECTION */}
        {/* SWAP TO SECTION */}
        <div className="swap-to-section swap-section">
          <div className="section-row">
            <div className="section-label">
              You get
            </div>

            <div className="" style={{ display: 'flex', columnGap: '0.4rem' }}>
              <span className="section-label-balance section-label">
                Balance After:
              </span>
              {' '}
              <span className="section-label-balance-price">{toDetails?.toAssetBalanceAfter}</span>
            </div>
          </div>

          <div className="section-row">
            <div className="asset-section">
              <ImagePlaceholder
                src={toDetails?.toAssetCurrencyIcon}
              />

              <div className="asset-dp-section" onClick={() => !toDetails?.dontChange && handleCoinSelectionModalOpen('to')}>
                <span className="asset-symbol-figure">{toDetails?.toAssetCurrency?.toUpperCase()}</span>
                {!toDetails?.dontChange && (
                <figure className="img-box">
                  <img src={chevDown} alt="" className="img icon-color" />
                </figure>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <input
                inputMode="numeric"
                autoComplete="off"
                onChange={handleToAmountChange}
                value={_toAmount}
                type="text"
                name="to_amount"
                id="to_amount"
                ref={toInputRef}
                className="asset-symbol-figure white-black-color"
              />
            </div>
          </div>

          <div className="section-row">
            <div className="section-label">
              {toLabel?.label}
            </div>

            {toLabel?.price && (
              <div className="section-label">
                {toLabel?.price}
              </div>
            )}
          </div>
        </div>
        {/* SWAP TO SECTION */}
        {fromDetails?.errorMessage && (
          <div className="error-message-section">
            {fromDetails?.errorMessage}
          </div>
        )}
      </div>
      <ModalLayout
        auth
        width="46rem"
        visible={coinSelectionModalOpen}
        onClose={() => handleCoinSelectionModalClose()}
      >
        <div className="modal-primary-background swap-input-select-modal">
          <figure className="img-box close-icon" onClick={() => handleCoinSelectionModalClose()}>
            <img src={swapModalCloseIcon} alt="" className="img" />
          </figure>

          <div className="modal-title">
            Select Item
          </div>

          {modalAssets.length > 10 && (
          <div className="search-box">
            <figure className="search-icon-box">
              <img
                className="search-icon"
                src={searchIcon}
                alt="search for crypto assets"
              />
            </figure>
            <input
              type="text"
              className="search-query-input secondary-white-black-color"
              placeholder="Search"
              value={searchQuery}
              onInput={(e) => setSearchQuery(e.target.value)}
            />
            <figure className="clear-icon-box" onClick={clearSearchQuery}>
              <img
                className="clear-icon icon-color"
                src={swapModalCloseIcon}
                alt="clear search field"
              />
            </figure>
          </div>
          )}

          <div className="item-list-section">
            {modalAssets && searchResults && searchResults.map((d) => (
              <Fragment key={uuid()}>
                <div className="list-item" onClick={() => selectAssetsCurrency(d?.symbol)}>
                  <ImagePlaceholder
                    src={d?.icon}
                  />

                  <div>
                    <div className="name">{d?.label}</div>
                    <div className="symbol secondary-white-black-color3">{d?.symbol?.toUpperCase()}</div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </ModalLayout>
    </>
  );
}

export default SwapInputs;
