/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';
// import Modal from 'react-awesome-modal';
import { getUser } from '../../../slices/authSlice';
import { useIcon } from '../../../hooks/useIcon';
// import Portal from '../../widgets/Portal';
import './WhatsNewDialog.css';
import ModalLayout from '../../layouts/dashboard/ModalLayout';

// eslint-disable-next-line no-unused-vars
export default function WhatsNewDialog({ visible, onClickClose }) {
  const user = useSelector(getUser);
  const [videoID, setVideoID] = useState(null);
  const { closeDark, paperJet } = useIcon();
  const playerRef = useRef(null);

  useEffect(() => {
    if (user && user.app_management && user.app_management.whatsnew && user.app_management.whatsnew.video) {
      const videoURL = user.app_management.whatsnew.video_url;
      const video_id = videoURL.slice(videoURL.indexOf('?') + 3);
      setVideoID(video_id);
    }
  }, [user]);

  const closeVideoModal = () => {
    onClickClose();
    setVideoID(null);
  };

  return user && user.app_management && user.app_management.whatsnew && (
  // <Portal>
  <section id="whats-new-dialog-parent">
    <ModalLayout
      auth
      width="101.5rem"
      onClose={() => closeVideoModal()}
      visible={visible}
    >
      <main className="whats-new-dialog">
        <button onClick={() => closeVideoModal()} className="close-btn">
          <img src={closeDark} alt="Close what's new dialog" />
        </button>
        <aside className="desktop-banner">
          <img src={paperJet} alt="what's new in this release" className="paper-jet" />
          <h2 className="heading">Here's what's new in this update</h2>
          <p className="content">We are thrilled to announce some fantastic new features and enhancements to the Roqqu app, designed to elevate your financial experience to new heights.</p>
        </aside>
        <main className="whats-new-content">
          {
                  user.app_management.whatsnew.video && (
                  <section className="video-box">
                    {
                            videoID && (
                            <iframe
                              ref={playerRef}
                              width="100%"
                              height="100%"
                              src={`https://www.youtube.com/embed/${videoID}?playlist=${videoID}&loop=1`}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            />
                            )
                        }
                  </section>
                  )
              }
          <section className="mobile-banner">
            <h2 className="heading">What's new in this update?</h2>
            <p className="content">We are thrilled to announce some fantastic new features and enhancements to the Roqqu app, designed to elevate your financial experience to new heights.</p>
          </section>
          <section className="new-features">
            <h3 className="new-features-header">New experiences we introduced</h3>
            <main className="key-highlights">
              {
                    user?.app_management?.whatsnew?.key_highlights?.map((highlight) => (
                      <Highlight key={uuid()} text={highlight} />
                    ))
                  }
            </main>
          </section>
        </main>
      </main>
    </ModalLayout>
  </section>
  // </Portal>
  );
}

function Highlight({ text }) {
  const { completeIconBlue } = useIcon();

  return (
    <div className="highlight">
      <div>
        <img src={completeIconBlue} alt={text} className="tick-img" />
      </div>
      <p className="text">{text}</p>
    </div>
  );
}
