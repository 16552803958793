/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { selectInspectBagURL, selectRecommendedRIBs, selectUserRIBs } from '../features/ribs/global/ribsGlobalSlice';

export function getDaysInMonthFormatted(day) {
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = 8;
  // const daysInMonth = new Date(year, month, 0).getDate();

  // for (let day = 1; day <= daysInMonth; day += 1) {
  let suffix;

  const lastDigit = day % 10;

  if (day > 10 && day < 20) {
    suffix = 'th';
  } else if (lastDigit === 1) {
    suffix = 'st';
  } else if (lastDigit === 2) {
    suffix = 'nd';
  } else if (lastDigit === 3) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }

  const daysFormatted = `${day}${suffix}`;
  // }

  return daysFormatted;
}

function getHoursFormatted() {
  const hoursFormatted = [];

  for (let hour = 0; hour < 24; hour += 1) {
    const hourString = hour.toString().padStart(2, '0');
    hoursFormatted.push({ text: `${hourString}:00`, value: `${hourString}:00` });
  }

  return hoursFormatted;
}

function useRIBs() {
  const history = useNavigate();

  const [isBeginnerMode, setIsBeginnerMode] = useState(true);
  const [isPartOfMyRIBs] = useState(true);
  const [isRecurring] = useState(true);

  const periodToSelect = useMemo(() => ([
    {
      id: 0,
      value: 5,
      period: 'yr',
    },
    {
      id: 1,
      value: 3,
      period: 'yr',
    },
    {
      id: 2,
      value: 1,
      period: 'yr',
    },
    {
      id: 3,
      value: 1,
      period: 'm',
    },
    {
      id: 4,
      value: 7,
      period: 'd',
    },
  ]), []);

  const suggestions = useMemo(() => ([
    '100',
    '200',
    '300',
    'Custom',
  ]), []);

  const { myRIBs } = useSelector(selectUserRIBs);
  const inspectURL = useSelector(selectInspectBagURL);
  const { RIBs: recommendedBags } = useSelector(selectRecommendedRIBs);

  function inspectBag(slug = 'a-rib') {
    history(`${inspectURL}/${slug}`);
  }

  function goToRIBsAgreementTerms() {

  }

  function continuewAsBeginner() {
    setIsBeginnerMode(true);
  }

  /* Recurring Frequency functionalities Start */

  const repeatOptionValues = useMemo(() => [
    { text: 'Daily', value: 'daily' },
    { text: 'Weekly', value: 'weekly' },
    // { text: 'Bi-Weekly', value: 'biweekly' },
    { text: 'Monthly', value: 'monthly' },
  ], []);
  const [repeatsEvery, setRepeatsEvery] = useState([]);

  const [repeatOptionValue, setRepeatOptionValue] = useState(repeatOptionValues[0]?.value);
  const [repeatEveryValue, setRepeatEveryValue] = useState('');

  const repeatWeekDays = useMemo(() => [
    { text: 'Monday', value: 'monday' },
    { text: 'Tuesday', value: 'tuesday' },
    { text: 'Wednesday', value: 'wednesday' },
    { text: 'Thursday', value: 'thursday' },
    { text: 'Friday', value: 'friday' },
    { text: 'Saturday', value: 'saturday' },
    { text: 'Sunday', value: 'sunday' },
  ], []);

  useEffect(() => {
    setRepeatEveryValue('');
    if (repeatOptionValue === 'weekly' || repeatOptionValue === 'biweekly') {
      setRepeatsEvery(repeatWeekDays);
    } else if (repeatOptionValue === 'monthly') {
      setRepeatsEvery([]); // the calendar handles it
    } else if (repeatOptionValue === 'daily') {
      setRepeatsEvery(getHoursFormatted());
    }
  }, [repeatOptionValue]);

  /* Recurring Frequency functionalities End */

  return {
    periodToSelect,
    isPartOfMyRIBs,
    userBags: myRIBs,
    recommendedBags,
    suggestions,
    handleInspectBag: inspectBag,

    isBeginnerMode,
    goToRIBsAgreementTerms,
    continuewAsBeginner,

    // Recurring Frequency functionalities
    setRepeatOptionValue,
    setRepeatEveryValue,
    repeatOptionValues,
    repeatOptionValue,
    repeatEveryValue,
    repeatsEvery,
  };
}

export default useRIBs;
