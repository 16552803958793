import { useEffect, useState } from 'react';
import { formatNumber } from '../utils/simpleActions';
import useCurrency from './useCurrency';

export const checkForTypes = {
  ALL: 'all-checks', // balance, min & max tradable
  BALANCE: 'balance',
  TRADABLE: 'tradable',
};

/**
 * This hook calculates the amount check against user balance
 * @param {Object} param0 Contains amount and currency of amount
 * @returns An object that tells if amount is valid and error message
 */
const useAmountAgaintBalance = ({ amount, currencyFor, checkFor = checkForTypes.ALL }) => {
  const { currencies, userBalance } = useCurrency();
  const [amountError, setAmountError] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  function getCurrencyFor(currency) {
    // returns a currency
    return currencies?.find((d) => d.symbol === currency);
  }

  function checkAll(_amount, _currentCurrency, _balance) {
    if ((parseFloat(_amount || 0) < _currentCurrency.min_tradeable)
        || (parseFloat(_amount || 0) > _currentCurrency.max_tradeable)) {
      if (parseFloat(_amount || 0) < _currentCurrency.min_tradeable) {
        setAmountError(`Minimum ${currencyFor?.toUpperCase()} tradable is ${formatNumber(_currentCurrency.min_tradeable)}`);
      } else {
        setAmountError(`Maximum ${currencyFor?.toUpperCase()} tradable is ${formatNumber(_currentCurrency.max_tradeable)}`);
      }
      setCanProceed(false);
    } else if (_amount <= parseFloat(_balance || 1)) {
      setCanProceed(_amount > 0);
    } else {
      setAmountError(
        `Insufficient ${currencyFor?.toUpperCase()} balance`,
      );
      setCanProceed(false);
    }
  }

  function checkBalance(_amount, _balance) {
    if (_amount <= parseFloat(_balance || 1)) {
      setCanProceed(_amount > 0);
    } else {
      setAmountError(
        `Insufficient ${currencyFor?.toUpperCase()} balance`,
      );
      setCanProceed(false);
    }
  }

  function checkTradable(_amount, _currentCurrency) {
    if ((parseFloat(_amount || 0) < _currentCurrency.min_tradeable)
      || (parseFloat(_amount || 0) > _currentCurrency.max_tradeable)) {
      if (parseFloat(_amount || 0) < _currentCurrency.min_tradeable) {
        setAmountError(`Minimum ${currencyFor?.toUpperCase()} tradable is ${formatNumber(_currentCurrency.min_tradeable)}`);
      } else {
        setAmountError(`Maximum ${currencyFor?.toUpperCase()} tradable is ${formatNumber(_currentCurrency.max_tradeable)}`);
      }
      setCanProceed(false);
    } else {
      setCanProceed(_amount > 0);
    }
  }

  useEffect(() => {
    setAmountError(false);
    const _amount = parseFloat(amount || 0);
    const _balance = !userBalance?.fiat
      ? 0
      : userBalance?.fiat.find((d) => d.currency === currencyFor)?.available_bal;
    const _currentCurrency = getCurrencyFor(currencyFor);
    if (_amount && _amount !== '') {
      if (checkFor === checkForTypes.ALL) {
        checkAll(_amount, _currentCurrency, _balance);
      } else if (checkFor === checkForTypes.BALANCE) {
        checkBalance(_amount, _balance);
      } else if (checkFor === checkForTypes.TRADABLE) {
        checkTradable(_amount, _currentCurrency);
      }
    }
  }, [amount, currencyFor, userBalance]);

  return {
    canProceed,
    amountErrorMessage: amountError,
  };
};

export default useAmountAgaintBalance;
