export const PASSWORD = 'pwd';
export const TOKEN = 'rjt';
export const USER = 'user';
export const PRIMARYCURRENCY = 'primary_currency';
export const EMAIL_TEMP = 'email_temp';
export const FIAT_TF_DATA = 'fiat_transfer_data';
export const FIRST_TIME_USER = 'ftu';
export const TOOLTIP = 'ttp';
export const RETURN_TO_WITHDRAWAL_FLOW = 'returnFromMomoAccountCreationToWithdraw';
export const RETURN_TO_DEPOSIT_EUR_FLOW = 'hshbclwabc';
export const RETURN_TO_WALLET_PORTFOLIO_FLOW = 'returnFromUpgradeLimitFlowToWalletPortfolio';
export const TOOLTIP_PLAYED = 'ttppld';
export const WHATS_NEW_VIEWED = 'wnvwd';
export const CACHED_USER_BALANCE_DATA = 'cchdba';

export const bankStorageKeys = {
  savedHighRiskBankData: 'savedhighriskbankdata',
};
