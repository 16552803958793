/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RaffleService from '../services/RaffleService';

export const raffleFetchStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

const initialState = {
  poolPrize: {
    status: raffleFetchStates.BASE,
    data: null,
    error: false,
  },
  raffleEntries: {
    status: raffleFetchStates.BASE,
    pendingEntriesAmount: 0,
    data: null,
    error: false,
  },
  playSpin: {
    status: raffleFetchStates.BASE,
    data: null,
    error: false,
  },
  canPlayFree: {
    status: raffleFetchStates.BASE,
    data: null,
    error: false,
  },
  socialCheck: {
    status: raffleFetchStates.BASE,
    data: null,
    error: false,
  },
};

export const getPoolPrize = createAsyncThunk(
  'raffle/getPoolPrize',
  async (_, { rejectWithValue }) => {
    try {
      const data = await RaffleService.getPoolPrize();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getRaffleEntries = createAsyncThunk(
  'raffle/getRaffleEntries',
  async (_, { rejectWithValue }) => {
    try {
      const data = await RaffleService.getUserRaffleEntries();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const playRaffle = createAsyncThunk(
  'raffle/playRaffle',
  async (mode, { rejectWithValue }) => {
    try {
      const data = await RaffleService.playRaffle(mode);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const canPlayFreeRaffle = createAsyncThunk(
  'raffle/canPlayFreeRaffle',
  async (mode, { rejectWithValue }) => {
    try {
      const data = await RaffleService.canPlayFreeRaffle(mode);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const socialShareCheck = createAsyncThunk(
  'raffle/socialShareCheck',
  async ({
    social_media_link,
    timestamp,
    refid,
  }, { rejectWithValue }) => {
    try {
      const data = await RaffleService.socialShareCheck({
        social_media_link,
        timestamp,
        refid,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const raffleSlice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    resetRafflePlaySpin: (state) => {
      state.playSpin.data = null;
      state.playSpin.status = initialState.playSpin.status;
      state.playSpin.error = initialState.playSpin.error;
    },
    resetRaffleLastSpinTimeNow: (state) => {
      if (state.canPlayFree) {
        state.canPlayFree.lastPlayed = new Date().toISOString();
        state.canPlayFree.canEnter = false;
      }
    },
    resetShareStatus: (state) => {
      state.socialCheck.data = null;
      state.socialCheck.status = initialState.socialCheck.status;
      state.socialCheck.error = initialState.socialCheck.error;
    },
  },
  extraReducers: (builder) => {
    // getPoolPrize
    builder.addCase(getPoolPrize.pending, (state) => {
      state.poolPrize.status = raffleFetchStates.FETCHING;
    });
    builder.addCase(getPoolPrize.fulfilled, (state, action) => {
      state.poolPrize.data = action.payload.data;
      state.poolPrize.status = raffleFetchStates.FETCHED;
    });
    builder.addCase(getPoolPrize.rejected, (state, action) => {
      state.poolPrize.error = action.payload;
      state.poolPrize.status = raffleFetchStates.ERROR;
    });

    // getRaffleEntries
    builder.addCase(getRaffleEntries.pending, (state) => {
      state.raffleEntries.status = raffleFetchStates.FETCHING;
    });
    builder.addCase(getRaffleEntries.fulfilled, (state, action) => {
      const _allEntries = action.payload.data;
      const _pendingEntriesAmount = _allEntries.reduce(
        (accumulator, item) => accumulator + item.prize,
        0,
      );
      state.raffleEntries.pendingEntriesAmount = _pendingEntriesAmount;
      state.raffleEntries.data = action.payload.data.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      );
      state.raffleEntries.status = raffleFetchStates.FETCHED;
    });
    builder.addCase(getRaffleEntries.rejected, (state, action) => {
      state.raffleEntries.error = action.payload;
      state.raffleEntries.status = raffleFetchStates.ERROR;
    });

    // playRaffle
    builder.addCase(playRaffle.pending, (state) => {
      state.playSpin.status = raffleFetchStates.FETCHING;
    });
    builder.addCase(playRaffle.fulfilled, (state, action) => {
      state.playSpin.data = action.payload.data;
      state.playSpin.status = raffleFetchStates.FETCHED;
    });
    builder.addCase(playRaffle.rejected, (state, action) => {
      state.playSpin.error = action.payload;
      state.playSpin.status = raffleFetchStates.ERROR;
    });

    // canPlayFreeRaffle
    builder.addCase(canPlayFreeRaffle.pending, (state) => {
      state.canPlayFree.status = raffleFetchStates.FETCHING;
    });
    builder.addCase(canPlayFreeRaffle.fulfilled, (state, action) => {
      state.canPlayFree.data = action.payload.data;
      state.canPlayFree.status = raffleFetchStates.FETCHED;
    });
    builder.addCase(canPlayFreeRaffle.rejected, (state, action) => {
      state.canPlayFree.error = action.payload;
      state.canPlayFree.status = raffleFetchStates.ERROR;
    });

    // socialShareCheck
    builder.addCase(socialShareCheck.pending, (state) => {
      state.socialCheck.status = raffleFetchStates.FETCHING;
    });
    builder.addCase(socialShareCheck.fulfilled, (state, action) => {
      state.socialCheck.data = action.payload.data;
      state.socialCheck.status = raffleFetchStates.FETCHED;
    });
    builder.addCase(socialShareCheck.rejected, (state, action) => {
      state.socialCheck.error = action.payload;
      state.socialCheck.status = raffleFetchStates.ERROR;
    });
  },
});

export const selectPoolPrize = (state) => state.raffle.poolPrize;
export const selectPlayRaffle = (state) => state.raffle.playSpin;
export const selectCanPlayFree = (state) => state.raffle.canPlayFree;
export const selectSocialCheck = (state) => state.raffle.socialCheck;
export const selectUserRaffleEntries = (state) => state.raffle.raffleEntries;

export default raffleSlice.reducer;
export const {
  resetRafflePlaySpin, resetRaffleLastSpinTimeNow, resetShareStatus,
} = raffleSlice.actions;
