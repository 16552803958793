/* eslint-disable */
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import SubFlow from '../fragments/subFlow';
import DashboardLayout from '../layouts/dashboard';
import './InvestmentBagPicker.css';
import H4 from '../textElements/H4/H4';
import Paragraph from '../textElements/Paragraph/Paragraph';
import RibsIconGroup from '../../features/ribs/views/components/ribs_icon_group/RibsIconGroup';
import { useIcon } from '../../hooks/useIcon';
import useWindowSize from '../../hooks/useWindowSize';
import { getAllBags, selectBag } from '../../slices/ribsInitialSetupSlice';
import useThunkEffectCalls from '../../hooks/useThunkEffectCalls';
import loadingStates from '../../utils/loadingStates';
import { formatNumber } from '../../utils/simpleActions';
import { useEffect } from 'react';

export default function InvestmentBagPicker() {
  const openRoute = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const tabData = useMemo(
    () => [
      {
        name: 'Top Performing',
        index: 0,
      },
      {
        name: 'Highly Rated',
        index: 1,
      },
      {
        name: 'Popular Bags',
        index: 2,
      },
    ],
    []
  );
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { loadingState } = useSelector((state) => state.ribsInitialSetup);

  function returnToPrevPage() {
    openRoute(-1);
  }

  useThunkEffectCalls(() => dispatch(getAllBags()));

  return (
    <div className="investment-bag-picker">
      <DashboardLayout pageTitle="Choose how to invest">
        <SubFlow
          title="Invest"
          isVisible
          goBack
          goBackFunc={returnToPrevPage}
          onClose={returnToPrevPage}
        >
          <SubFlow.BodyBGWrapper noPadding>
            <div className="common-header">
              <H4 classNames="invest-pick-title">
                How would you like to invest?
              </H4>
              <Paragraph classNames="invest-pick-subtitle">
                Please pick an option of how you want to invest. Buy multiple
                coins in one single purchase
              </Paragraph>
            </div>
            {isMobile ? (
              <InvestmentTypesMobile />
            ) : (
              <>
                <section className="tab-header">
                  {tabData.map(({ name, index }) => (
                    <button
                      onClick={() => setTabIndex(index)}
                      key={uuid()}
                      className={`tab-header-btn ${
                        tabIndex === index && 'active'
                      }`}
                    >
                      {name}
                    </button>
                  ))}
                </section>
                <section className="tab-body">
                  {loadingState == loadingStates.success && (
                    <InvestmentTypeTable />
                  )}
                </section>
              </>
            )}
          </SubFlow.BodyBGWrapper>
        </SubFlow>
      </DashboardLayout>
    </div>
  );
}

function InvestmentTypeTable() {
  const openRoute = useNavigate();
  const columnTitles = ['Assets', 'PNL', 'Deposit', 'Risk level', ''];
  const { trendUp } = useIcon();
  const { allBags } = useSelector((state) => state.ribsInitialSetup);
  const dispatch = useDispatch();

  function proceedWithBag(bag) {
    dispatch(selectBag(bag));
    openRoute('/dashboard-home-ribs-get-started');
  }

  function getClassNameFromIndex(title) {
    if (title === '') {
      return 'action-box';
    }
    let name = title.toLowerCase();
    name = name.replace(' ', '-');
    name += '-box';
    return name;
  }

  return (
    <div className="investment-type-table">
      <nav className="column-titles">
        {columnTitles.map((title) => (
          <div
            key={uuid()}
            className={`title-box ${getClassNameFromIndex(title)}`}
          >
            <h6 className="title">{title}</h6>
          </div>
        ))}
      </nav>
      {allBags.map((bag) => (
        <button key={uuid()} onClick={() => proceedWithBag(bag)} className="investment-item">
          <div className="assets-box">
            <div className="asset-icons">
              <RibsIconGroup
                coinSymbols={JSON.parse(bag.items).map(({ symbol }) => symbol)}
                iconSize="24px"
              />
              <p className="name">{bag.name}</p>
            </div>
          </div>
          <div className="pnl-box">
            <img
              src={trendUp}
              alt="change indicator"
              className="change-indicator"
            />
            <p className="change">0.42%</p>
          </div>
          <div className="deposit-box">
            <p className="deposit">{`\$${formatNumber(bag.minimum_buy, 2)}`}</p>
          </div>
          <div className="risk-level-box">
            <div
              className={`risk-chip ${isHighRiskBag(bag.category) && 'high-risk'}`}
            >
              <p>{bag.category}</p>
            </div>
          </div>
          <div className="action-box">
            <div className="invest-chip">
              <p>Invest</p>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

function InvestmentTypesMobile() {
  const openRoute = useNavigate();
  const { trendUp } = useIcon();
  const { allBags, loadingState } = useSelector(
    (state) => state.ribsInitialSetup
  );
  const dispatch = useDispatch();

  function proceedWithBag(bag) {
    dispatch(selectBag(bag));
    openRoute('/dashboard-home-ribs-get-started', {
      state: {
        bagId: bag.id,
        bagRef: bag.reference,
      }
    });
  }

  return (
    <div className="investment-types-mobile">
      <h5 className="category-name">Top Performing</h5>
      <section className="top-performers category-list">
        {loadingState === loadingStates.populated &&
          allBags.map((bag) => (
            <figure key={uuid()} className="top-performer" onClick={() => proceedWithBag(bag)}>
              <div className="top-row">
                <div className="info">
                  <RibsIconGroup
                    coinSymbols={JSON.parse(bag.items).map(({ symbol }) => symbol)}
                    iconSize="24px"
                  />
                  <div className="numbers">
                    <h6 className="bag-name">{bag.name}</h6>
                    <div className="change-box">
                      <img
                        src={trendUp}
                        alt="change indicator"
                        className="change-indicator"
                      />
                      <p className="change">0.42%</p>
                    </div>
                  </div>
                </div>
                <div className={`risk-chip ${isHighRiskBag(bag.category) && 'high-risk'}`}>
                  {bag.category}
                </div>
              </div>
              <div className="meta-row">
                <div className="meta">
                  <p className="min-deposit">Minimum deposit</p>
                  <p className="min-deposit-amt">{`\$${formatNumber(
                    bag.minimum_buy,
                    2
                  )}`}</p>
                </div>
                <button className="action">Invest</button>
              </div>
            </figure>
          ))}
      </section>
      <h5 className="category-name">Highly Rated</h5>
      <div className="highly-rated category-list">
        {allBags.map((bag) => (
          <MobileBagCard key={uuid()} bag={bag} onClick={() => proceedWithBag(bag)} />
        ))}
      </div>
      <h5 className="category-name">Popular bags</h5>
      <div className="popular-bags category-list">
        {allBags.map((bag) => (
          <MobileBagCard key={uuid()} bag={bag} onClick={() => proceedWithBag(bag)} />
        ))}
      </div>
    </div>
  );
}

function MobileBagCard({ bag, onClick }) {
  const { trendUp } = useIcon();

  return (
    <figure onClick={onClick} className="mobile-bag-card">
      <RibsIconGroup
        coinSymbols={JSON.parse(bag.items).map(({ symbol }) => symbol)}
        iconSize="24px"
      />
      <h6 className="bag-name">{bag.name}</h6>
      <div className="change-box">
        <img
          src={trendUp}
          alt="change indicator"
          className="change-indicator"
        />
        <p className="change">0.42%</p>
      </div>
      <p className="min-deposit">Minimum deposit</p>
      <p className="min-deposit-amt">{`\$${formatNumber(
        bag.minimum_buy,
        2
      )}`}</p>
      <button onClick={onClick} className="action">
        Invest
      </button>
    </figure>
  );
}

function isHighRiskBag(categ) {
  return categ.toLowerCase() === 'high risk';
}
