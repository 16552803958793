/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useMemo, useState } from 'react';

import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { useIcon } from '../../../../hooks/useIcon';
import { toggleTransferModalIsOpen } from '../../../../slices/FiatTransferSlice';

import './index.css';
import '../../../../features/gamefi/roqqnroll/styles/index.css';
import {
  showNetworkCheckerDialog,
  showRateCheckerDialog,
  showTransactionCheckerDialog,
} from '../../../../slices/toolsSlice';

// eslint-disable-next-line react/prop-types, no-unused-vars
function FloatingNav({ theme = 'default' }) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const {
    dashboardIcon,
    walletIcon,
    transactionIcon,
    roqquHubImg,
    getStartedIcon,
    buyAssetSingleImg,
    transactionSingleImg,
    sellAssetSingleImg,
    swapCoinSingleImg,
    payBillSingleImg,
    savingsUSDTImg,
    loansActionImg,
    cardsActionImg,
    newsActionImg,
    paymentLinkActionImg,
    nftActionImg,
    stakeActionImg,
    roqqnrollSingleImg,
  } = useIcon();

  const nav_items = useMemo(
    () => [
      !(
        user.user_info.nin_verified
        && user.user_info.bvn_verified
        && typeof user.user_info.username === 'string'
      )
        ? {
          name: t('tooltip.getStarted'),
          link: '/dashboard-get-started',
          icon: getStartedIcon,
          active: location.pathname.includes('/dashboard-get-started'),
        }
        : {},
      {
        name: 'Dashboard',
        link: '/dashboard-home',
        hideOnMobile: false,
        icon: dashboardIcon,
        active: location.pathname.includes('/dashboard-home'),
      },
      {
        name: 'Wallet',
        link: '/dashboard-wallet',
        hideOnMobile: false,
        icon: walletIcon,
        active: location.pathname.includes('/dashboard-wallet'),
      },
      {
        name: t('dsh.transactions.title'),
        link: '/dashboard-transactions',
        hideOnMobile: true,
        icon: transactionIcon,
        active: location.pathname.includes('/dashboard-transactions'),
      },
      // {
      //   name: t('dsh.settings.navName'),
      //   link: '/dashboard-setting',
      //   icon: settingImg,
      //   active: location.pathname.includes('/dashboard-setting'),
      // },
    ],
    [location.pathname, user],
  );

  const quickActionList = useMemo(
    () => [
      {
        name: t('dsh.actions.buyAssets'),
        img: buyAssetSingleImg,
        link: '/dashboard-home-buy-asset',
        description: t('dsh.actions.buyCrypto'),
      },
      {
        name: t('dsh.actions.sellAssets'),
        img: sellAssetSingleImg,
        link: '/dashboard-home-sell-asset',
        description: t('dsh.actions.sellCrypto'),
      },
      {
        name: t('dsh.actions.swapCoin'),
        img: swapCoinSingleImg,
        link: '/dashboard-home-swap-asset',
        description: t('dsh.actions.exchngeCrypto'),
      },
      {
        name: t('dsh.actions.fiat'),
        img: payBillSingleImg,
        link: null,
        func: () => dispatch(toggleTransferModalIsOpen()),
        description: t('dsh.actions.exchngeFiat'),
      },
      {
        name: t('dsh.actions.savings'),
        img: savingsUSDTImg,
        link: '/dashboard-home-savings',
        description: t('dsh.actions.setAside'),
      },
      {
        name: 'RoqqNRoll',
        img: roqqnrollSingleImg,
        link: '/dashboard-earn-raffle',
        description: t('dsh.actions.roqqnroll'),
      },
      {
        name: t('dsh.actions.payBills'),
        img: payBillSingleImg,
        link: '/dashboard-home-pay-bills',
        description: t('dsh.actions.payments'),
      },
      {
        name: t('dsh.actions.loans'),
        img: loansActionImg,
        link: '/dashboard-home-loans',
        description: t('dsh.actions.borrow'),
      },
      {
        name: t('dsh.actions.paymentLinks'),
        img: paymentLinkActionImg,
        link: '/payment-links',
        description: t('dsh.actions.receivePayments'),
      },
      {
        name: t('dsh.actions.cards'),
        img: cardsActionImg,
        link: '/dashboard-cards',
        description: t('dsh.actions.secureTx'),
      },
      {
        name: t('dsh.transactions.title'),
        link: '/dashboard-transactions',
        img: transactionSingleImg,
        hideOnDesktop: true,
        description: t('dsh.actions.secureTx'),
      },
      {
        name: t('dsh.actions.news'),
        img: newsActionImg,
        link: '/dashboard-get-started/news',
        description: t('dsh.actions.ltstNews'),
      },
      {
        name: 'NFTs',
        img: nftActionImg,
        link: '',
        description: t('dsh.actions.buyNfts'),
        comingSoon: true,
      },
      {
        name: t('dsh.actions.staking'),
        img: stakeActionImg,
        link: '',
        description: t('dsh.actions.stake'),
        comingSoon: true,
      },
      {
        name: t('dsh.actions.rateChecker'),
        img: payBillSingleImg,
        link: null,
        func: () => dispatch(showRateCheckerDialog()),
        description: t('dsh.actions.rateCheckerDesc'),
      },
      {
        name: t('dsh.actions.networkChecker'),
        img: payBillSingleImg,
        link: null,
        func: () => dispatch(showNetworkCheckerDialog()),
        description: t('dsh.actions.networkCheckerDesc'),
      },
      {
        name: t('dsh.actions.txnChecker'),
        img: payBillSingleImg,
        link: null,
        func: () => dispatch(showTransactionCheckerDialog()),
        description: t('dsh.actions.txnCheckerDesc'),
      },
    ],
    [
      buyAssetSingleImg,
      sellAssetSingleImg,
      swapCoinSingleImg,
      payBillSingleImg,
      savingsUSDTImg,
      loansActionImg,
      paymentLinkActionImg,
      cardsActionImg,
      newsActionImg,
      payBillSingleImg,
      nftActionImg,
      stakeActionImg,
    ],
  );

  const [floatingNavPanelOpen, setFloatingNavPanelOpen] = useState();

  function openNavPanel() {
    setFloatingNavPanelOpen(true);
  }

  function closeNavPanel() {
    setFloatingNavPanelOpen(false);
  }

  function onNavActionClick() {
    closeNavPanel();
  }

  function callPanelFunction(cb) {
    if (cb) {
      cb();
    }
    closeNavPanel(false);
  }

  return (
    <div className={`floating-nav--contianer ${theme}`}>
      <div className="floating-nav">
        {nav_items.map((child) => {
          const {
            name, link, icon, active, hideOnMobile,
          } = child;
          return (
            name
            && link && (
              <Fragment key={uuid()}>
                <Link
                  className={`
                    nav-item ${
                      active ? 'active' : 'secondary-white-black-color3'
                    }
                    ${
                      !(
                        user.user_info.nin_verified
                        && user.user_info.bvn_verified
                        && typeof user.user_info.username === 'string'
                      ) && hideOnMobile
                        ? 'settings'
                        : ''
                    }
                    `}
                  to={link}
                >
                  <figure className="img-box">
                    <img src={icon} alt="" className="img" />
                  </figure>
                  <span className={`${active ? 'white-black-color' : ''}`}>
                    {name}
                  </span>
                </Link>
              </Fragment>
            )
          );
        })}
        <div
          onClick={() => openNavPanel()}
          className="nav-item secondary-white-black-color3"
          // to="/dashboard-setting"
        >
          <figure className="img-box">
            <img src={roqquHubImg} alt="" className="img" />
          </figure>
          <span
            className={`${
              location.pathname.includes('/dashboard-setting')
                ? 'white-black-color'
                : ''
            }`}
          >
            More
          </span>
        </div>
        {floatingNavPanelOpen && (
          <div className="floating-nav-panel default-background">
            <OutsideClickHandler onOutsideClick={() => closeNavPanel(false)}>
              <div className="floating-nav-panel-container">
                {quickActionList.map((d) => {
                  const Tag = d?.comingSoon || !d?.link ? 'div' : Link;

                  return (
                    <Fragment key={uuid()}>
                      <Tag
                        {...(d?.comingSoon ? {} : { to: d.link })}
                        className={`nav-panel-item ${
                          d?.comingSoon ? 'disabled-panel-item' : ''
                        }
                        ${
                          d?.hideOnDesktop
                          && !(
                            user.user_info.nin_verified
                            && user.user_info.bvn_verified
                            && typeof user.user_info.username === 'string'
                          )
                            ? 'hidden'
                            : ''
                        }
                          ${
                            d?.hideOnDesktop
                            && user.user_info.nin_verified
                            && user.user_info.bvn_verified
                            && typeof user.user_info.username === 'string'
                              ? 'hide-item'
                              : ''
                          }
                        `}
                        onClick={() => !d?.comingSoon
                          && (d?.link
                            ? onNavActionClick(d.link)
                            : callPanelFunction(d?.func))}
                      >
                        <figure className="nav-panel-item-figure container-wrap">
                          <img src={d.img} alt="" />
                        </figure>

                        <div className="details-section">
                          <div className="details-title-section">
                            <h6 className="white-black-color">{d.name}</h6>
                            {d?.comingSoon && (
                              <div className="details-comingsoon-tag blue-color">
                                Coming Soon
                              </div>
                            )}
                          </div>
                          <p className="secondary-white-black-color3">
                            {d.description}
                          </p>
                        </div>
                      </Tag>
                    </Fragment>
                  );
                })}
              </div>
            </OutsideClickHandler>
          </div>
        )}
      </div>
    </div>
  );
}

export default FloatingNav;
