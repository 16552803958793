/* eslint-disable */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import ribsService from '../services/ribsService';
import loadingStates from '../utils/loadingStates';

export const BASE = 'base';
export const FETCHING = 'fetching';
export const ERROR = 'error';
export const FETCHED = 'fetched';
export const POPULATING = 'populating';
export const POPULATED = 'populated';

const initialState = {
  allBags: [],
  selectedBag: null,
  loadingState: loadingStates.base,
  buyType: '',
  currency: '',
  amount: null,
  frequency: null,
  error: '',
  purchaseStatus: {
    responseData: null,
    loadingState: loadingStates.base,
    error: null,
  }
};

export const getAllBags = createAsyncThunk(
  'ribsSetup/getExistingBags',
  async (_, { rejectWihValue }) => {
    try {
      const res = await ribsService.getAllBags();
      return res.data.data;
    } catch (err) {
      return rejectWihValue(err.toString());
    }
  }
);

export const buyFirstBag = createAsyncThunk(
  'ribsSetup/buyBag',
  async ({ amount, reference, currency, buyType }, { rejectWithValue }) => {
    try {
      const res = await ribsService.buyABag({ amount, bag_reference: reference, buy_type: buyType, currency });
      return res.data.data;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

const ribsInitialSetupSlice = createSlice({
  name: 'ribsSetup',
  initialState,
  reducers: {
    selectBag: (state, { payload }) => {
      const oldItems = payload.items;
      const newPayload = { ...payload, items: JSON.parse(oldItems) };
      state.selectedBag = newPayload;
    },
    setInitialInvestmentAmount: (state, { payload }) => {
      state.amount = parseFloat(payload);
    },
    setBuyType: (state, { payload }) => {
      state.buyType = payload;
    },
    setFrequency: (state, { payload }) => {
      state.frequency = payload;
    },
    updateAmount: (state, { payload }) => {
      state.amount = payload;
    },
    updateBagPurchaseCurrency: (state, { payload }) => {
      state.currency = payload;
    },
    resetRIBsInitialSetupState: (state) => {
      state.selectedBag = initialState.selectedBag;
      state.loadingState = initialState.loadingState
      state.buyType = initialState.buyType;
      state.currency = initialState.currency;
      state.amount = initialState.amount;
      state.frequency = initialState.frequency;
      state.error = initialState.error;
      state.purchaseStatus = initialState.purchaseStatus;
    },
    resetRIBsInitialSetupErrorState: (state) => {
      state.loadingState = loadingStates.base;
      state.error = initialState.error;
      state.purchaseStatus = initialState.purchaseStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBags.pending, (state) => {
      state.error = null;
      state.loadingState = loadingStates.pending;
    });
    builder.addCase(getAllBags.fulfilled, (state, { payload }) => {
      state.allBags = payload;
      state.loadingState = loadingStates.success;
    });
    builder.addCase(getAllBags.rejected, (state, { payload }) => {
      state.error = payload;
      state.loadingState = loadingStates.failed;
    });

    builder.addCase(buyFirstBag.pending, (state) => {
      state.purchaseStatus.error = null;
      state.purchaseStatus.loadingState = loadingStates.pending;
    });
    builder.addCase(buyFirstBag.fulfilled, (state, { payload }) => {
      state.purchaseStatus.responseData = payload;
      state.purchaseStatus.loadingState = loadingStates.success;
    });
    builder.addCase(buyFirstBag.rejected, (state, { payload }) => {
      state.purchaseStatus.loadingState = loadingStates.failed;
      state.purchaseStatus.error = payload;
    });
  },
});

export const selectPurchaseStatus = (state) => state.ribsInitialSetup.purchaseStatus;
export const {
  resetRIBsInitialSetupState,
  resetRIBsInitialSetupErrorState,
  selectBag,
  setBuyType,
  setFrequency,
  setInitialInvestmentAmount,
  updateAmount,
  updateBagPurchaseCurrency,
} = ribsInitialSetupSlice.actions;

export default ribsInitialSetupSlice.reducer;
