/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useReducer, useState } from 'react';

import '../../../../styles/dashboard/earn/RIBs.css';
import { useNavigate } from 'react-router-dom';
import getStartedIllustration from '../../../../assets/ribs/getstarted-illustration.svg';
import DashboardLayout from '../../../../components/layouts/dashboard';
import ButtonReuse from '../../../../components/fragments/Button';
import RIBsFAQ from '../../../../components/fragments/RIBs/FAQ';
import RIBsApplyDrawer from '../../../../components/fragments/RIBs/RIBsApplyDrawer';
import { RIBsApplicationNavigationHandlerContext, RIBsApplicationNavigationTreeContext } from '../../../../context/RIBsApplicationNavigationContext';
import RIBsNavigationStack from '../../../../components/flows/RIBsSetupFlow/RIBsNavigationStack';
import { RIBsQuestionNavigationHandlerContext, RIBsQuestionNavigationTreeContext } from '../../../../components/flows/RIBsSetupFlow/RIBsQuestions/RIBsQuestionStackContext';
import RIBsQuestionStack from '../../../../components/flows/RIBsSetupFlow/RIBsQuestions/RIBsQuestionStack';
import NewToCrypto from '../../../../components/flows/RIBsSetupFlow/RIBsQuestions/stages/NewToCrypto';
import useDrawerNavigationStack from '../../../../hooks/useDrawerNavigationStack';
import InitStage from '../../../../components/flows/RIBsSetupFlow/InitStage';
import useRIBs from '../../../../hooks/useRIBs';
import { useIcon } from '../../../../hooks/useIcon';

function RIBs() {
  const { raffleVideoCircle } = useIcon();
  const { isBeginnerMode } = useRIBs();
  const history = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);

  function openGetstartedDrawer() {
    setShowDrawer(true);
  }

  function goToBeginnerFlow() {
    history('/dashboard-earn-ribs-buy');
  }

  function getStarted() {
    if (isBeginnerMode) {
      goToBeginnerFlow();
    } else {
      openGetstartedDrawer();
    }
  }

  const { state: ribsNavState, dispatchNavigation: dispatchRibsNavAction } = useDrawerNavigationStack({ title: 'RIBs', initialItem: <InitStage /> });
  const { state: RIBsQuestionsNavState, dispatchNavigation: dispatchRIBsQuestionsNavAction } = useDrawerNavigationStack({
    title: 'RIBs',
    initialItem: <NewToCrypto />,
    otherData: {
      stages: 5,
    },
  });

  return (
    <DashboardLayout pageTitle="Invest - Intro">
      <RIBsApplicationNavigationTreeContext.Provider value={ribsNavState}>
        <RIBsApplicationNavigationHandlerContext.Provider value={dispatchRibsNavAction}>

          <RIBsQuestionNavigationTreeContext.Provider value={RIBsQuestionsNavState}>
            <RIBsQuestionNavigationHandlerContext.Provider value={dispatchRIBsQuestionsNavAction}>

              <RIBsApplyDrawer
                isVisible={showDrawer}
                onClose={() => setShowDrawer(false)}
              />

            </RIBsQuestionNavigationHandlerContext.Provider>
          </RIBsQuestionNavigationTreeContext.Provider>

        </RIBsApplicationNavigationHandlerContext.Provider>
      </RIBsApplicationNavigationTreeContext.Provider>

      <div className={!isBeginnerMode && 'ribs-wrapper'} style={{ paddingBottom: '0' }}>
        <div className={!isBeginnerMode ? 'getstarted-banner' : 'ribs-getstarted-banner feature-getstarted-banner'}>
          <div className="content-section">
            <div className="title">
              Take control of your crypto with our easy to use investment bags
            </div>
            <p className="desc">
              Seize the Benefits of Auto-Investing: Earn passive income effortlessly.
              Withdraw funds conveniently at any time. Experience financial freedom with our
              streamlined investment plan.
            </p>
            <p className="desc">
              Start creating your own auto investment plan: Choose a coin you want to invest in,
              decide your investment amount and schedule a recurring plan.
            </p>
            <div className="cta-btn-section">
              <ButtonReuse onClick={() => getStarted()} btnStyle="getstarted-btn">Get Started</ButtonReuse>
            </div>
          </div>
          <img src={getStartedIllustration} alt="" className="getstarted-illustration" />
        </div>

      </div>
      <div className="ribs-wrapper" style={{ paddingTop: 0 }}>
        <RIBsFAQ />
      </div>
    </DashboardLayout>
  );
}

export default RIBs;
