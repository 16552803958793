/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  numberOfStages: 0,
  finalStageIndex: 0,
  currentStageIndex: 0,
  currentStageProgress: 0,
};

const reelIndicatorsSlice = createSlice({
  name: 'reelIndicators',
  initialState,
  reducers: {
    // The payload of this function is the number of stages the reel indicators should have
    // this action must be dispatched after mounting any ReelProgressIndicators component
    resetReelState: (state, { payload }) => {
      state.numberOfStages = payload;
      state.finalStageIndex = payload - 1;
      state.currentStageProgress = 0;
      state.currentStageIndex = 0;
    },
    goToNextReelStage: (state) => {
      state.currentStageIndex += 1;
      state.currentStageProgress = 0;
    },
    incrementReelProgressPercentage: (state) => {
      state.currentStageProgress += 1;
    },
  },
});

export const {
  resetReelState,
  goToNextReelStage,
  incrementReelProgressPercentage,
} = reelIndicatorsSlice.actions;
export default reelIndicatorsSlice.reducer;
