/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import uuid from 'react-uuid';
import './InvestProgressIndicators.css';
import { useEffect, useState } from 'react';

export default function InvestProgressIndicators({ numberOfBars, currentIndex }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const newArray = [];
    for (let i = 0; i < numberOfBars; i++) {
      newArray.push(i);
    }
    setItems(newArray);
  }, [numberOfBars]);

  return (
    <div className="progress-indicators">
      {
        items.map((_, index) => (
          <div
            key={uuid()}
            className={`indicator ${index <= currentIndex && 'active'}`}
          />
        ))
      }
    </div>
  );
}
