/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ReferralService from '../services/referralsService';
import * as states from '../utils/strings';

const initialState = {
  allReferrals: {
    status: states.BASE,
    data: [],
    error: false,
  },
};

export const fetchReferrals = createAsyncThunk(
  'referrals/fetchReferrals',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ReferralService.getReferrals();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReferrals.pending, (state) => {
      state.allReferrals.status = states.FETCHING;
    });
    builder.addCase(fetchReferrals.fulfilled, (state, action) => {
      state.allReferrals.data = action.payload;
      state.allReferrals.status = states.FETCHED;
    });
    builder.addCase(fetchReferrals.rejected, (state) => {
      state.allReferrals.status = states.ERROR;
    });
  },
});

export const selectReferals = (state) => state.referrals.allReferrals;

export default referralsSlice.reducer;
// export const {} = referralsSlice.actions;
