/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/react-in-jsx-scope */
import './InvestNewRecurring.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SubFlow from '../fragments/subFlow';
import DashboardLayout from '../layouts/dashboard';
import InvestProgressIndicators from '../InvestProgressIndicators/InvestProgressIndicators';
import { Amount, SelectFrequency, Success, Summary } from '../InvestSubComponents/InvestSubComponents';
import { resetRIBsInitialSetupState, selectPurchaseStatus } from '../../slices/ribsInitialSetupSlice';

export default function InvestNewRecurring() {
  const maxPageIndex = 3;
  const openRoute = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { responseData } = useSelector(selectPurchaseStatus);

  function returnToPrevPage() {
    if (currentPageIndex === 0) {
      openRoute(-1);
    } else if (currentPageIndex === maxPageIndex) {
      openRoute(-4);
    } else {
      setCurrentPageIndex((current) => current - 1);
    }
  }

  function proceed() {
    const bagReference = responseData[0];
    dispatch(resetRIBsInitialSetupState());
    openRoute('/dashboard-wallet-ribs', {
      replace: true,
      state: {
        reference: bagReference,
      },
    });
  }

  // eslint-disable-next-line no-unused-vars
  function goToNextPage() {
    if (currentPageIndex >= maxPageIndex) {
      proceed();
    } else {
      setCurrentPageIndex((current) => current + 1);
    }
  }

  return (
    <div className="invest-one-off">
      <DashboardLayout pageTitle="New Recurring Investment">
        <SubFlow
          title="Invest"
          isVisible
          goBack
          goBackFunc={returnToPrevPage}
          onClose={returnToPrevPage}
        >
          <SubFlow.BodyBGWrapper>
            {
              currentPageIndex < (maxPageIndex - 1) && (
                <>
                  <p className="step-info">
                    {`Step ${currentPageIndex + 1}`}
                    <span className={(currentPageIndex === (maxPageIndex - 2)) ? 'complete' : ''}>{`/${maxPageIndex - 1}`}</span>
                  </p>
                  <InvestProgressIndicators numberOfBars={3} currentIndex={currentPageIndex} />
                </>
              )
            }
            {
              currentPageIndex === 0 ? (
                <SelectFrequency onComplete={goToNextPage} />
              ) : currentPageIndex === 1 ? (
                <Amount onComplete={goToNextPage} />
              ) : currentPageIndex === 2 ? (
                <Summary onComplete={goToNextPage} />
              ) : currentPageIndex === 3 ? (
                <Success onComplete={goToNextPage} />
              ) : <div />
            }
          </SubFlow.BodyBGWrapper>
          {
            currentPageIndex === 0 && <div style={{ width: '100%', height: '20vh' }} />
          }
        </SubFlow>
      </DashboardLayout>
    </div>
  );
}
