/* eslint-disable object-curly-newline */
/* eslint-disable no-unsafe-optional-chaining */
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class WalletService {
  static async getUserWallets({ symbol }) {
    const response = await post({
      url: apiRoutes.getUserWallets,
      data: {
        token: AuthService._getUserToken(),
        symbol,
      },
    });
    /**
     * [[ INSTRUCTION PERTAINING TO THE IF BLOCK STARTING ON THE NEXT LINE ]]
     * I'm checking if response.response === false because a failed request will return a
     * response object that has a property response which has a value of false. This code
     * below is therefore, strictly dependent on the structure of the data being returned
     * from the server and if it changes at some point, we'll need to update this code
     */
    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async generateWallet({ network }) {
    const response = await post({
      url: apiRoutes.generateWallet,
      data: {
        token: AuthService._getUserToken(),
        network,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async deleteWallet({ network, address }) {
    const response = await post({
      url: apiRoutes.deleteWallets,
      data: {
        token: AuthService._getUserToken(),
        network,
        address,
      },
    });

    if (response.response === false) {
      return response;
      // throw new Error('failed to get user wallet');
    }
    return response;
  }

  // Start Withdraw
  static async withdrawFiat({
    amount, account, currency, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.withdrawFiat,
      data: {
        token: AuthService._getUserToken(),
        amount,
        account,
        currency,
        ...pin,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async getPendingWithdrawals() {
    const response = await post({
      url: apiRoutes.getPendingWithdrawals,
      data: { token: AuthService._getUserToken() },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async cancelBankWithdrawal({ id, pin }) {
    const { response } = await post({
      url: apiRoutes.cancelBankWithdrawal,
      data: { token: AuthService._getUserToken(), id, pin },
    });

    if (response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async getCurrencyWithdrawRoutes(currency) {
    const { response } = await post({
      url: apiRoutes.getCurrencyWithdrawRoutes,
      data: {
        token: AuthService._getUserToken(),
        currency,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async getRouteWithdrawOptions({ currency, route }) {
    const { response } = await post({
      url: apiRoutes.getRouteWithdrawOptions,
      data: {
        token: AuthService._getUserToken(),
        currency,
        route,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async verifyIdentifier({ identifier, provider }) {
    const { response } = await post({
      url: apiRoutes.verifyIdentifier,
      data: {
        token: AuthService._getUserToken(),
        identifier,
        provider,
      },
    });
    if (response.response === false || !response) {
      throw new Error('failed to get user wallet');
    }
    if (response.data.status === 'fail') {
      throw new Error(response.data.message);
    }
    return response;
  }

  static async getWithdrawalP2PDetails({
    currency, route, provider, amount,
  }) {
    const { response } = await post({
      url: apiRoutes.getWithdrawalP2PDetails,
      data: {
        token: AuthService._getUserToken(),
        currency,
        provider,
        route,
        amount,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async initiateP2PWithdraw({
    currency, route, provider, amount, recipient, sender, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.initiateP2PWithdraw,
      data: {
        token: AuthService._getUserToken(),
        currency,
        provider,
        route,
        amount,
        recipient, // Identifier of user, email, phone number or username
        sender, // Phone number of the peer selected to process p2p payment
        ...pin,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }
  // End Withdraw

  // Start Deposit
  static async getCurrencyDepositRoutes(currency) {
    const { response } = await post({
      url: apiRoutes.getCurrencyDepositRoutes,
      data: {
        token: AuthService._getUserToken(),
        currency,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get deposit routes');
    }
    return response;
  }

  static async getRouteDepositOptions({ currency, route }) {
    const { response } = await post({
      url: apiRoutes.getRouteDepositOptions,
      data: {
        token: AuthService._getUserToken(),
        currency,
        route,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get deposit route options');
    }
    return response;
  }

  static async getDepositPaymentLink({ provider, currency, amount, connected }) {
    const { response } = await post({
      url: apiRoutes.getDepositDetailsForPaymentLinks,
      data: {
        token: AuthService._getUserToken(),
        currency,
        provider,
        amount,
        connected,
      },
    });
    if (response?.data?.status === 'fail') {
      throw new Error(response.data.message);
    }
    return response;
  }

  static async getRouteDepositDetailsP2P({ currency, route, provider }) {
    const { response } = await post({
      url: apiRoutes.getRouteDepositDetailsP2P,
      data: {
        token: AuthService._getUserToken(),
        currency,
        route,
        provider,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get deposit route options');
    }
    return response;
  }

  static async getRouteDepositDetailsBankTransfer({ currency, amount }) {
    const { response } = await post({
      url: apiRoutes.getRouteDepositDetailsBankTransfer,
      data: {
        token: AuthService._getUserToken(),
        currency,
        amount,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get deposit route options');
    }
    return response;
  }
  // End Deposit

  // Start Transfer
  static async verifyRecipientDetails({ identifier }) {
    const { response } = await post({
      url: apiRoutes.verifyRecipient,
      data: {
        token: AuthService._getUserToken(),
        identifier,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user');
    }
    return response;
  }

  static async transferFiat({ identifier, currency, amount, narration, pin }) {
    const { response } = await post({
      url: apiRoutes.transferFiat,
      data: {
        token: AuthService._getUserToken(),
        identifier,
        currency,
        amount,
        narration,
        pin,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  // End Transfer

  static async createNewCoinBalance(symbol) {
    const response = await post({
      url: apiRoutes.createTokenBalance,
      data: {
        token: AuthService._getUserToken(),
        symbol,
      },
    });
    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message ?? 'failed to create new token balance');
    }
    return response;
  }
}
