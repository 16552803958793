/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NotificationService from '../services/notificationService';

export const notificationStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

const initialState = {
  status: notificationStates.BASE,
  data: [],
  unread: 0,
  error: false,
};

// Get user notification
export const fetchUserNotification = createAsyncThunk(
  'notification/fetch',
  async (_, { rejectWithValue }) => {
    try {
      // const { token } = data;
      const response = await NotificationService.getUserNotifications();

      return response.response.data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

// Mark all notification as read
export const readAll = createAsyncThunk('notification/read-all', async (_, { rejectWithValue }) => {
  try {
    // const { token } = data;
    const response = await NotificationService.markAllAsRead();

    return response.response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // extraReducers for fetchUserNotification
    builder.addCase(fetchUserNotification.pending, (state) => {
      state.status = notificationStates.LOADING;
    });

    builder.addCase(fetchUserNotification.fulfilled, (state, action) => {
      const { notifications, unreadNotificationsCount } = action.payload;
      state.data = notifications;
      state.unread = unreadNotificationsCount;

      state.status = notificationStates.SUCCESSFUL;
    });

    builder.addCase(fetchUserNotification.rejected, (state) => {
      state.status = notificationSlice.ERROR;
    });

    // extraReducers for readAll
    builder.addCase(readAll.pending, () => {
      // state.status = notificationStates.LOADING;
    });

    builder.addCase(readAll.fulfilled, (state) => {
      const allNotification = [...state.data];
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const notification of allNotification) {
        notification.isRead = 1;
      }

      state.data = allNotification;
      state.status = notificationStates.SUCCESSFUL;
    });

    builder.addCase(readAll.rejected, (state) => {
      state.status = notificationSlice.ERROR;
    });
  },
});

export default notificationSlice.reducer;
