/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, createContext } from 'react';
import { useDarkMode } from '../utils/themes/useDarkMode';

const ActionContext = createContext({
  isSidebarReduce: false,
  sidebarReduceFunc: (param) => {},
  isSidebarDropDownOpen: {},
  sidebarDropDownOpenFunc: (param) => {},
  isSlideBarOpen: false,
  slideBarFunc: (param) => {},
  theme: 'dark',
  toggleTheme: (param) => {},
  handleDropDown: false,
  showDropDown: (param) => {},
});

export function ActionContextProvider({ children }) {
  const [isSidebarReduceVal, setIsSidebarReduceVal] = useState(false);
  const [slideBarOpenValue, setSlideBarOpenValue] = useState(false);
  const [sidebarDropDown, setSidebarDropdown] = useState({
    one: false,
    two: false,
  });
  const [currentTheme, setTheme] = useDarkMode(localStorage.getItem('theme'));
  const [showDropDown, setShowDropDown] = useState(false);

  function switchTheme(_theme) {
    setTheme(_theme);
  }

  function sidebarReduceFunction(param) {
    if (param === 'any') {
      setIsSidebarReduceVal(!isSidebarReduceVal);
      setSidebarDropdown((prev) => ({ ...prev, one: false, two: false }));
    }
  }
  function sidebarDropDownOpenFunction(param) {
    if (param === 'one') {
      setSidebarDropdown((prev) => ({ ...prev, one: !sidebarDropDown.one }));
    }
    if (param === 'two') {
      setSidebarDropdown((prev) => ({ ...prev, two: !sidebarDropDown.two }));
    }
    if (param === 'close') {
      setSidebarDropdown((prev) => ({ ...prev, one: false, two: false }));
    }
  }
  function slideBarFunction(param) {
    if (param === 'open') {
      setSlideBarOpenValue(true);
    }
    if (param === 'close') {
      setSlideBarOpenValue(false);
    }
  }
  const handleDropDown = (classList) => {
    if (
      classList.contains('dropdown-container') || classList.contains('dropdown-icon')
    ) {
      setShowDropDown(!showDropDown);
    } else {
      setShowDropDown(false);
    }
  };

  const context = {
    isSidebarReduce: isSidebarReduceVal,
    sidebarReduceFunc: sidebarReduceFunction,
    isSidebarDropDownOpen: sidebarDropDown,
    sidebarDropDownOpenFunc: sidebarDropDownOpenFunction,
    isSlideBarOpen: slideBarOpenValue,
    slideBarFunc: slideBarFunction,
    theme: currentTheme,
    toggleTheme: switchTheme,
    handleDropDown,
    showDropDown,
  };

  return (
    <ActionContext.Provider value={context}>
      {children}
    </ActionContext.Provider>
  );
}

export default ActionContext;
