/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';

import './styles/BagDropdown.css';
import _ from 'lodash';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import ImagePlaceholder from '../../../../fragments/ImagePlaceholder';
import getIconUrl from '../../../../../utils/getIconUrl';
import ButtonReuse from '../../../../fragments/Button';

function BagDropdown({
  isOpen = true, label = 'Crypto in this bag',
  showCardDescription = true, coins = [],
  onSelect = () => null, forInspect = false,
  onSell,
  type = 'distibution', // 'distribution' | 'pandl'
}) {
  const { t } = useTranslation();
  const styles = {
    box: {
      display: 'grid',
      gridTemplateColumns: '100%',
      transition: 'grid-template-rows 300ms ease-out',
      marginTop: isOpen && !forInspect && '1.6rem',
    },
    open: {
    },
    close: {
      display: 'grid',
      gridTemplateRows: '0fr',
      gridTemplateColumns: '100%',
      transition: 'grid-template-rows 300ms ease-out',
    },
  };

  function handleOnSelect() {
    onSelect();
  }

  return (

    <div className={`ribs-bag-dropdown ${forInspect ? 'for-inspect' : ''}`} style={{ ...styles.box, gridTemplateRows: isOpen ? '1fr' : '0fr' }}>
      <div style={{
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        // alignItems: 'flex-start',
        // justifyContent: 'space-between',
      }}
      >
        <div className={`dropdown-body ${!forInspect && 'default-background'}`}>
          <div className="ribs-header-section">
            <p>{label}</p>
          </div>

          <div className="table-section">
            {type !== 'pandl' ? (
              <div className="row ribs-table-header-section">
                <div className="row-item secondary-white-black-color3">
                  <p>{t('dsh.ribs.sellBag.coins')}</p>
                </div>
                <div className="row-item secondary-white-black-color3">
                  <p>{t('dsh.ribs.sellBag.distribution')}</p>
                </div>
                <div className="row-item secondary-white-black-color3">
                  <p>{t('dsh.ribs.sellBag.24hchange')}</p>
                </div>
              </div>
            ) : (
              <div className="row ribs-table-header-section pandl" style={{ padding: '1.3rem 3.6rem 1.3rem 2.4rem' }}>
                <div className="row-item secondary-white-black-color3">
                  <p>{t('dsh.ribs.sellBag.coins')}</p>
                </div>
                <div className="row-item secondary-white-black-color3">
                  <p>{t('dsh.ribs.sellBag.gainLoss')}</p>
                </div>
                <div className="row-item pandl secondary-white-black-color3">
                  <p>{t('dsh.ribs.sellBag.action')}</p>
                </div>
              </div>
            )}

            {coins.map((d) => (
              <Fragment key={uuid()}>
                <div className="row ribs-table-body-items">
                  <div className="row-item">
                    <ImagePlaceholder width="2.4rem" src={getIconUrl(d.symbol)} />
                    <div className="name-symbol">
                      <div className="name">{d.name}</div>
                      <div className="symbol secondary-white-black-color3">{_.upperCase(d.symbol)}</div>
                    </div>
                  </div>

                  {type !== 'pandl' ? (
                    <>
                      <div className="row-item">
                        <div className="name-symbol">
                          <div className="name">$3.60 (14%)</div>
                          <div className="symbol secondary-white-black-color3">BTC 0.0002</div>
                        </div>
                      </div>

                      <div className="row-item">
                        <div className="name-symbol">
                          <div className="name">$29,000</div>
                          <div className="symbol negative">-$50.12  (-1.04%)</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row-item">
                        <div className="name-symbol">
                          <div className="symbol negative">$5000.12 ≈ 0.254700</div>
                          <div className="symbol negative">(-4.85%)</div>
                        </div>
                      </div>

                      <div className="row-item pandl">
                        <ButtonReuse onClick={() => onSell(d)} btnStyle="bag-coin-action-btn secondary-white-black-color3">
                          {t('dsh.sell_zero')}
                        </ButtonReuse>
                      </div>
                    </>
                  )}
                </div>
              </Fragment>
            ))}
          </div>

          {showCardDescription && (
          <div className="ribs-bag-info-section">
            <div className="bag-note-section secondary-white-black-color3">
              {t('dsh.ribs.sellBag.bagDistributionDetails')}
            </div>
            <div className="">
              <ButtonReuse onClick={() => handleOnSelect()} text={t('dsh.ribs.sellBag.select')} btnStyle="select-button" />
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BagDropdown;
