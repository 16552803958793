/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LoadingPage from './components/fragments/LoadingPage';
import { ActionContextProvider } from './context/ActionContext';
import AppRouter from './navigation/router';
import ErrorBoundary from './pages/error/ErrorBoundary';
import store from './store';
import ToastV2 from './components/toastV2/ToastV2';

function App() {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingPage />}>
        <Provider store={store}>
          <ToastV2 />
          <BrowserRouter>
            {/* <React.StrictMode> */}
            <ActionContextProvider>
              <AppRouter />
            </ActionContextProvider>
            {/* </React.StrictMode> */}
          </BrowserRouter>
        </Provider>
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;
