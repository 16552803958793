/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../../styles/dashboard/tables/TrendingAsset.css';
import Chart from 'react-apexcharts';
import { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import TrendingAssetDataSource from '../../services/TrendingAssetDataSource';
import {
  lineChartOptionsFall,
  lineChartOptionsRise,
} from '../../utils/chartOptions';
import getIconUrl from '../../utils/getIconUrl';
import ChartHelper from '../../utils/ChartHelper';
import { formatNumber } from '../../utils/simpleActions';
import ImagePlaceholder from '../fragments/ImagePlaceholder';
import useWindowSize from '../../hooks/useWindowSize';

export default function TrendingAsset({
  index,
  clickHandler,
  abr,
  price,
  change,
}) {
  const empty = '';
  const targetRef = useRef(null);
  const history = useNavigate();
  const allTokens = useSelector((state) => state.global.allTokens.data);
  const [series, setSeries] = useState([]);
  const [coinName, setCoinName] = useState('');
  const [iconUrl, setIconUrl] = useState();
  const { isMobile } = useWindowSize();
  const rise = change >= 0;
  const noChange = change === 0;

  function onItemClick() {
    history(`/dashboard-home-single-asset/${abr?.toLowerCase()}`);
    if (clickHandler) clickHandler();
  }

  function onBuyButtonClick(e) {
    e.stopPropagation();
    openBuyAssetPage();
  }

  function openBuyAssetPage() {
    // history({
    //   pathname: '/dashboard-home-buy-asset',
    //   state: {
    //     coinSymbol: abr?.toLowerCase(),
    //     coinName,
    //   },
    // });
    history('/dashboard-home-buy-asset', {
      state: {
        coinSymbol: abr?.toLowerCase(),
        coinName,
      }
    });
  }

  function getCoinName() {
    const currentCoin = allTokens.find(
      (item) => item.symbol?.toLowerCase() === abr?.toLowerCase()
    );
    setCoinName(currentCoin?.name || '');
  }

  const fetchData = async (symbol) => {
    try {
      const priceHistory = await new TrendingAssetDataSource().getPriceHistoryWithInterval(symbol, '1D');
      const iconPath = getIconUrl(abr?.toLowerCase());
      const data = ChartHelper.convertToLineChartSeries(priceHistory);
      setSeries([
        {
          name: symbol,
          data,
        },
      ]);
      setIconUrl(iconPath);
    } catch (err) {
      // null
    }
  };

  const fetchDataForMobile = async () => {
    try {
      const iconPath = getIconUrl(abr?.toLowerCase());
      setIconUrl(iconPath);
    } catch (err) {
      // null
    }
  };

  useEffect(() => {
    getCoinName();
    if (isMobile === true) {
      fetchDataForMobile();
    } else if (isMobile === false) {
      fetchData(abr);
    }
  }, [isMobile]);

  return (
    <tr ref={targetRef} id={`trending-asset${index}`} className="trending-asset" onClick={onItemClick}>
      <td className="trending-asset-bg">{empty}</td>
      <td className="desktop-only cell-serial-no">{index + 1}</td>
      <td className="cell-name">
        <ImagePlaceholder
          src={iconUrl}
          width="4rem"
          figureClassName="figure"
          alt={abr?.toLowerCase()}
        />
        <span className="name trending-asset-name">{coinName}</span>
        <span className="abr secondary-white-black-color">
          {abr?.toUpperCase()}
        </span>
      </td>
      <td className="cell-price">{`$${formatNumber(
        parseFloat(price),
        2,
        true
      )}`}</td>
      <td
        className={`desktop-only cell-change ${
          noChange ? 'no-change' : rise ? 'rise' : 'fall'
        }`}
      >
        <p>
          {!noChange && rise && <span>+</span>}
          {`${formatNumber(change, 2)}%`}
        </p>
      </td>
      <td className="desktop-only cell-chart">
        {/* <LoaderContainer width={1000} height={1000}>
          <rect x="0" y="0" rx="23" ry="23" width="1000" height="1000" />
        </LoaderContainer> */}
        {series.length === 0 && (
        <div className="shimmer-wrapper">
          <div className="shimmer-graph">
            <ContentLoader
              speed={2}
              width={200}
              height={200}
              // style={{ maxWidth: '100%', maxHeight: '100%' }}
              viewBox="0 0 200 200"
              backgroundColor="#f5f5f51a"
              foregroundColor="#f5f5f53d"
              className="content-loader"
            >
              <rect x="0" y="0" rx="23" ry="23" width="200" height="200" />
            </ContentLoader>
          </div>
        </div>
        )}

        {series.length > 0 && (
          <Chart
            options={rise ? lineChartOptionsRise : lineChartOptionsFall}
            series={series}
            height="100%"
          />
        )}
      </td>
      <td className="desktop-only cell-trade">
        <span className="trending-asset-button" onClick={onBuyButtonClick}>
          Buy
        </span>
      </td>
    </tr>
  );
}
