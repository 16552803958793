/* eslint-disable object-curly-newline */
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class SavingService {
  // Get user saving
  static async getUserSavings() {
    const response = await post({
      url: apiRoutes.getUserSaving,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    /**
     * [[ INSTRUCTION PERTAINING TO THE IF BLOCK STARTING ON THE NEXT LINE ]]
     * I'm checking if response.response === false because a failed request will return a
     * response object that has a property response which has a value of false. This code
     * below is therefore, strictly dependent on the structure of the data being returned
     * from the server and if it changes at some point, we'll need to update this code
     */
    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  // Get saving rate - have been removed from slice to the component where the data is needed

  // Create new USDT saving
  static async createNewUSDTSaving({ ref, amount, pin }) {
    const { response } = await post({
      url: apiRoutes.createNewUSDTSaving,
      data: {
        token: AuthService._getUserToken(),
        ref,
        amount,
        ...pin,
      },
    });

    if (response === false) {
      throw new Error('failed to create user saving');
    }
    return response;
  }

  // Withdraw USDT saving
  static async withdrawUSDTSaving({ save_id, amount, pin }) {
    const { response } = await post({
      url: apiRoutes.withdrawUSDTSaving,
      data: {
        token: AuthService._getUserToken(),
        save_id,
        amount,
        ...pin,
      },
    });

    if (response === false) {
      throw new Error('failed to withdraw user saving');
    }
    return response;
  }
}
