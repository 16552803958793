/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrency } from '../utils/simpleActions';
import CoreAppService from '../services/CoreService';
import naira from '../assets/country-flags/ngn-flag.svg';
import dollar from '../assets/country-flags/usd-flag.svg';
import euro from '../assets/country-flags/eur-flag.svg';
import pound from '../assets/country-flags/gbp-flag.svg';
// import Helpers from '../../utils/Helpers';

export const fetchStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

const initialState = {
  allRates: {
    data: null,
    status: fetchStates.BASE,
    error: false,
    currencies: [],
  },
  usdngnRate: {
    data: null,
    status: fetchStates.BASE,
    error: false,
  },
  prices: {
    data: null,
    status: fetchStates.BASE,
    error: false,
  },
  toast: {
    isOpen: false,
    type: 'error',
    name: 'Error Message Here',
    desc: 'This is a descriptive error message here you can make it unique',
  },
  allTokens: {
    data: null,
    status: fetchStates.BASE,
    error: false,
  },
  tokenHistory: {
    data: null,
    error: null,
    oneHourChange: null,
    status: fetchStates.BASE,
    individual: {},
  },
  news: {
    data: [],
    error: null,
    status: fetchStates.BASE,
    start: 1,
    limit: 100,
    hasMore: true,
    addNews: {
      status: fetchStates.BASE,
      error: null,
    },
  },
  countries: {
    data: null,
    error: null,
    status: fetchStates.BASE,
  },
  whatsNew: {
    canShow: false,
  },
};

export const fetchAllCurrencyRates = createAsyncThunk(
  'global/fetchAllCurrencyRates',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CoreAppService.getAllCurrencyRates();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchCurrencyRate = createAsyncThunk(
  'global/fetchCurrencyRate',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CoreAppService.getCurrencyRate();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchPrices = createAsyncThunk(
  'global/fetchPrices',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CoreAppService.getPrices();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchTokenHistory = createAsyncThunk(
  'global/fetchTokenHistory',
  async ({ symbol, interval = '1H' }, { rejectWithValue }) => {
    try {
      const { data } = await CoreAppService.getTokenHistory(symbol, interval);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchIndividualTokenHistory = createAsyncThunk(
  'global/fetchIndividualTokenHistory',
  async ({ symbol, interval = '1H' }, { rejectWithValue }) => {
    try {
      const { data } = await CoreAppService.getTokenHistory(symbol, interval);
      return {
        data: data.data,
        symbol,
        interval,
      };
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const updatePrimaryCurrency = createAsyncThunk(
  'global/updatePrimaryCurrency',
  async (symbol, { rejectWithValue }) => {
    try {
      const { data } = await CoreAppService.updatePrimaryCurrency(symbol);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchAllTokens = createAsyncThunk(
  'global/fetchAllTokens',
  async (_, { rejectWithValue }) => {
    try {
      const data = await CoreAppService.getAllTokens();
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchAllNews = createAsyncThunk(
  'global/fetchAllNews',
  async ({
    start = 1, limit = 100, slug = '', symbol = '', language = 'en',
  }, { rejectWithValue }) => {
    try {
      const response = await CoreAppService.getAllNews(start, limit, slug, symbol, language);
      return response.response.data.data.filter((item) => item.subtitle.length !== '');
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const addNews = createAsyncThunk(
  'global/addNews',
  async ({
    start = 1, limit = 100, slug = '', symbol = '', language = 'en',
  }, { getState, rejectWithValue }) => {
    try {
      const response = await CoreAppService.getAllNews(start, limit, slug, symbol, language);
      const currentData = getState().global.news.data;
      const newData = response?.response?.data?.data.filter((item) => item.subtitle.length !== '');
      return currentData.concat(newData);
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);
export const fetchSupportedCountries = createAsyncThunk(
  'global/fetchSupportedCountries',
  async (_, { rejectWithValue }) => {
    try {
      const data = await CoreAppService.getSupportedCountries();
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);
const dropdownItems = [
  { icon: naira, text: 'Naira(NGN)', value: 'ngn' },
  { icon: dollar, text: 'Dollar($)', value: 'usd' },
  { icon: pound, text: 'Pounds(GBP)', value: 'gbp' },
  { icon: euro, text: 'Euro(€)', value: 'eur' },
];

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleSnackbar: (state, action) => {
      state.toast.isOpen = !state.toast.isOpen;
      if (state.toast.isOpen && action.payload?.name && action.payload?.desc) {
        state.toast.name = action.payload?.name;
        if (action.payload.type) {
          state.toast.type = action.payload?.type;
        } else {
          state.toast.type = initialState.toast.type;
        }
        state.toast.desc = action.payload?.desc;
      } else {
        state.toast.name = initialState.toast.name;
        state.toast.desc = initialState.toast.desc;
      }
    },
    enableWhatsNew: (state) => {
      state.whatsNew.canShow = true;
    },
    disableWhatsNew: (state) => {
      state.whatsNew.canShow = false;
    },
  },
  extraReducers: (builder) => {
    // Get all currency rates
    builder.addCase(fetchAllCurrencyRates.pending, (state) => {
      state.allRates.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchAllCurrencyRates.fulfilled, (state, action) => {
      state.allRates.data = action.payload;
      const newArr = [];
      action.payload.forEach((d) => {
        const assetIcon = dropdownItems.find((f) => f.value === d.symbol);
        if (d.status === 1) {
          const _local_currencies = getCurrency(d.symbol);
          newArr.push({
            ...d,
            ep_icon: d.icon, // endpoint (ep) icon
            ..._local_currencies,
            s: _local_currencies?.s || d.icon,
            n: d.title,
            c: d.symbol,
            text: assetIcon ? assetIcon.text : '',
            icon: assetIcon ? assetIcon.icon : '',
          });
        }
      });
      state.allRates.currencies = newArr;
      state.allRates.min_tradeable = action.payload?.min_tradeable;
      state.allRates.max_tradeable = action.payload?.max_tradeable;
      state.allRates.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchAllCurrencyRates.rejected, (state) => {
      state.allRates.status = fetchStates.ERROR;
    });

    // Get usd-ngn rate
    builder.addCase(fetchCurrencyRate.pending, (state) => {
      state.usdngnRate.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchCurrencyRate.fulfilled, (state, action) => {
      state.usdngnRate.data = action.payload;
      state.usdngnRate.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchCurrencyRate.rejected, (state) => {
      state.usdngnRate.status = fetchStates.ERROR;
    });

    // Prices
    builder.addCase(fetchPrices.pending, (state) => {
      state.prices.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.prices.data = action.payload;
      state.prices.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchPrices.rejected, (state) => {
      state.prices.status = fetchStates.ERROR;
    });

    // Token history
    builder.addCase(fetchTokenHistory.pending, (state) => {
      state.tokenHistory.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchTokenHistory.fulfilled, (state, action) => {
      state.tokenHistory.data = action.payload;
      state.tokenHistory.oneHourChange = computeValueOfChange(action.payload);
      state.tokenHistory.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchTokenHistory.rejected, (state, action) => {
      state.tokenHistory.error = action.payload;
      state.tokenHistory.status = fetchStates.ERROR;
    });

    // Individual Token history
    builder.addCase(fetchIndividualTokenHistory.pending, (state) => {
      state.tokenHistory.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchIndividualTokenHistory.fulfilled, (state, action) => {
      const { data, symbol, interval } = action.payload;
      state.tokenHistory.individual[symbol] = { data, symbol, interval };
      state.tokenHistory.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchIndividualTokenHistory.rejected, (state, action) => {
      state.tokenHistory.error = action.payload;
      state.tokenHistory.status = fetchStates.ERROR;
    });

    // All Tokens
    builder.addCase(fetchAllTokens.pending, (state) => {
      state.allTokens.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchAllTokens.fulfilled, (state, action) => {
      state.allTokens.data = action.payload;
      state.allTokens.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchAllTokens.rejected, (state) => {
      state.allTokens.status = fetchStates.ERROR;
    });

    // Fetch News
    builder.addCase(fetchAllNews.pending, (state) => {
      state.news.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchAllNews.fulfilled, (state, action) => {
      state.news.data = action.payload;
      state.news.start += state.news.limit;
      state.news.hasMore = action.payload.length === state.news.limit;
      state.news.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchAllNews.rejected, (state) => {
      state.news.status = fetchStates.ERROR;
    });

    // Add News
    builder.addCase(addNews.pending, (state) => {
      state.news.addNews.status = fetchStates.FETCHING;
    });
    builder.addCase(addNews.fulfilled, (state, action) => {
      state.news.data = action.payload;
      state.news.start += state.news.limit;
      state.news.hasMore = action.payload.length === state.news.limit;
      state.news.addNews.status = fetchStates.FETCHED;
    });
    builder.addCase(addNews.rejected, (state) => {
      state.news.addNews.status = fetchStates.ERROR;
    });

    // All Countries
    builder.addCase(fetchSupportedCountries.pending, (state) => {
      state.countries.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchSupportedCountries.fulfilled, (state, action) => {
      state.countries.data = action.payload;
      state.countries.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchSupportedCountries.rejected, (state) => {
      state.countries.status = fetchStates.ERROR;
    });
  },
});

const computeValueOfChange = (array) => {
  const initialPrice = array[0]?.price;
  const finalPrice = array[array.length - 1]?.price;
  const percentChange = ((finalPrice - initialPrice) / initialPrice) * 100;
  return Number.isNaN(percentChange) ? 0 : percentChange;
};

// Accessors
export const selectPrices = (state) => state.global.prices;
export const selectAllRates = (state) => state.global.allRates;
export const selectUsdNgnRate = (state) => state.global.usdngnRate;
export const selectAllTokens = (state) => state.global.allTokens;
export const selectTokenHistory = (state) => state.global.tokenHistory;
export const selectAllNews = (state) => state.global.news;
export const selectAllCountries = (state) => state.global.countries;

export default globalSlice.reducer;
export const { toggleSnackbar, enableWhatsNew, disableWhatsNew } = globalSlice.actions;
