/* eslint-disable class-methods-use-this */
import { axiosInstance } from '../http/https';

export default class TrendingAssetDataSource {
  async getPriceHistory(symbol) {
    try {
      const response = await axiosInstance.get(`/history?symbol=${symbol}`);
      return response.data.data;
    } catch (e) {
      return [];
    }
  }

  async getPriceHistoryWithInterval(symbol, interval) {
    try {
      const response = await axiosInstance.get(`/history?symbol=${symbol}&interval=${interval}`);
      return response.data.data;
    } catch (e) {
      return [];
    }
  }
}
