/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

import './style.css';
import LoaderContainer from '../LoaderContainer';

function ImagePlaceholder({
  src = '', width = '', height, alt = 'image-pld',
  figureClassName = '', imgClassName = '', fallback = '',
}) {
  const figureStyles = {
    width,
    minWidth: width,
    height: height || width,
    minHeight: height || width,
  };
  const [stateSRC, setStateSRC] = useState(src);

  useEffect(() => {
    setStateSRC(src);
  }, [src]);

  // const imageLoadedRef = useRef(false)
  const [imageLoaded, setImageLoaded] = useState(false);
  function handleLoad() {
    setImageLoaded(true);
    // imageLoadedRef.current = true;
  }

  function changeToPlaceholderImage() {
    setStateSRC(fallback);
  }

  // // when the src changes show placeholder until image loads
  // useEffect(() => {
  //   setImageLoaded(false);
  // }, [src]);

  return (
    <figure className={`img-box ${figureClassName} image-placeholder-figure`} style={figureStyles}>
      {!imageLoaded && (
      <div className="image-placeholder-loader" style={figureStyles}>
        <LoaderContainer width={1000} height={1000}>
          <rect x="0" y="0" rx="50%" ry="50%" width="100%" height="100%" />
        </LoaderContainer>
      </div>
      )}
      {/* <img
        src="/roqqu-color.svg"
        style={{ display: `${!imageLoaded ? 'block' : 'none'}` }}
        className={`img ${imgClassName}`}
        alt={alt}
      /> */}
      <img
        src={stateSRC}
        className={`img ${imgClassName}`}
        style={{ display: `${imageLoaded ? 'block' : 'none'}` }}
        onError={() => changeToPlaceholderImage()}
        onLoad={() => handleLoad()}
        alt={alt}
      />
    </figure>
  );
}

export default ImagePlaceholder;
