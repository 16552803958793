/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, {
  Fragment, useContext, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import RIBsInfoNoteIllustration from '../../../../../assets/ribs/ribs-how-much-invest.svg';
import GroupWithAvailableBalance from '../../../../fragments/InputGroup/components/GroupWithAvailableBalance';
import InputGroup from '../../../../fragments/InputGroup';
import AvailableBalanceBadge from '../../../../fragments/AvailableBalanceBadge';
import ButtonReuse from '../../../../fragments/Button';
import { selectRIBsApplication, setInvestAmount } from '../../../../../features/ribs/application/ribsApplicationSlice';
import { RIBsQuestionNavigationHandlerContext } from '../RIBsQuestionStackContext';
import RecommendedBags from './RecommendedBags';
import useRIBs from '../../../../../hooks/useRIBs';

function WillinToInvest() {
  const dispatch = useDispatch();
  const dispatchRIBsQuestionStack = useContext(RIBsQuestionNavigationHandlerContext);

  const { suggestions } = useRIBs();

  const [selectedSuggestion, setSelectedSUggestion] = useState('Custom');
  const { investAmount } = useSelector(selectRIBsApplication);

  function updateAmount({ target: { value } }, suggestion) {
    dispatch(setInvestAmount(value));
    if (!suggestion) {
      selectSuggestion('Custom');
    }
  }

  function selectSuggestion(value) {
    setSelectedSUggestion(value);
    if (value === 'Custom') {
      return;
    }
    updateAmount({ target: { value } }, true);
  }

  function proceed() {
    dispatchRIBsQuestionStack({
      type: 'push',
      payload: <RecommendedBags />,
    });
  }

  return (
    <div className="ribs-questions-container">

      <div className="note-illustration-container">
        <img src={RIBsInfoNoteIllustration} alt="" />
      </div>

      <p className="note-title">
        How much are you willing to invest?
      </p>

      <p className="note-desc secondary-white-black-color3">
        Lorem ipsum dolor sit amet consectetur.
        Elementum euismod dolor sem donec sed pretium. Quis nunc sem dolor lectus.
      </p>

      <div className="">
        <GroupWithAvailableBalance>
          <InputGroup
            canFormatNumber
            label="Amount"
            value={investAmount}
            onChange={updateAmount}
            name="amount"
            placeholder="Enter Amount"
            // isError={!!amountError}
            // errorMessage={amountError}
          />
          <AvailableBalanceBadge />
        </GroupWithAvailableBalance>

        <div className="ribs-amount-suggestions">
          {suggestions.map((d) => (
            <Fragment key={d}>
              <ButtonReuse
                onClick={() => selectSuggestion(d)}
                btnStyle={`amount-suggestion default-background ${selectedSuggestion === d && 'active secondary-white-black-color3'}`}
              >
                {typeof d !== 'string' && '$'}
                {d}
              </ButtonReuse>
            </Fragment>
          ))}
        </div>

        <ButtonReuse onClick={() => proceed()} text="Proceed" btnStyle="btn-reuse" style={{ width: '100%' }} />
      </div>
    </div>
  );
}

export default WillinToInvest;
