/* eslint-disable import/no-extraneous-dependencies */
import * as nsfwjs from 'nsfwjs';
import apiRoutes from '../http/apiRoutes';
import { get } from '../http/https';

const Helpers = {

  assignIDs(array) {
    return array.map((item, index) => ({
      id: index,
      ...item,
    }));
  },

  assignProperties(array, propertyName, valueGenerator) {
    if (typeof valueGenerator === 'string') {
      return array.map((item) => ({
        ...item,
        [propertyName]: valueGenerator,
      }));
    }
    if (typeof valueGenerator === 'function') {
      return array.map((item) => ({
        ...item,
        [propertyName]: valueGenerator(item),
      }));
    }
    return array;
  },

  async convertToPrimaryCurrency(amountUSD, primaryCurrency) {
    const response = await get({
      url: `${apiRoutes.getExchangeRate}usd-${primaryCurrency}`,
    });
    const rate = response.response.data.data;
    const equivalentAmount = rate * amountUSD;
    return equivalentAmount;
  },

  async convertCryptoAmountToFiat(balance, symbol, fiatCurrency) {
    const response = await get({
      url: `${apiRoutes.getCoinPrice}?symbol=${symbol}`,
    });

    const usdValue = response.response.data.data;

    const primaryCurrencyValue = await this.convertToPrimaryCurrency(usdValue, fiatCurrency);

    return (primaryCurrencyValue * balance);
  },

  // I'm using snake case in this function because the variable names are long and snake case
  // is easier to read than camel case
  async convertFiatCurrencyPriceToCrypto(amount, cryptoSymbol, fiatCurrency) {
    const { response } = await get({
      url: `${apiRoutes.getCoinPrice}?symbol=${cryptoSymbol}`,
    });

    const usd_equivalent = response.data.data;

    const usd_rate = await get({
      url: `${apiRoutes.getExchangeRate}usd-${fiatCurrency}`,
    });

    const one_unit_of_USD_to_primary_currency = usd_rate.response.data.data;

    const crypto_equivalent = usd_equivalent * one_unit_of_USD_to_primary_currency;

    return (amount / crypto_equivalent);
  },

  showMinutes(time) {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    } else {
      minutes = `${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    } else {
      seconds = `${seconds}`;
    }
    return `${minutes}:${seconds}`;
  },

  showTime(time) {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = time % 60;
    let full_time = '';

    if (hours < 10) {
      hours = `0${hours}`;
    } else {
      hours = `${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    } else {
      minutes = `${minutes}`;
    }

    if (seconds < 10) {
      seconds = `0${seconds}`;
    } else {
      seconds = `${seconds}`;
    }

    if (hours === '00') {
      full_time = `${minutes}:${seconds}`;
    }
    full_time = `${hours}:${minutes}:${seconds}`;

    return {
      full_time,
      hours,
      minutes,
      seconds,
    };
  },

  async nsfwjsCheck(img) {
    const model = await nsfwjs.load();

    // Classify the image
    const predictions = await model.classify(img);
    return ('Predictions: ', predictions);
  },

  // Filter News Array
  filterNewsArray(arr, limit) {
    const filteredArray = arr.filter((obj) => obj.subtitle !== '');
    const shuffledArray = filteredArray.sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, limit);
  },

  supportedAreaCodes: ['233'],

  /**
   * If an area code starts with +, it uses the area code without the + sign to do the calculations
   * required to remove the area code
   */
  removeAreaCodeFromPhoneNumber(intlPhoneNumber, areaCode) {
    let phoneNumberWithoutAreaCode;
    let lengthOfAreaCode;
    if (!areaCode) {
      let areaCodeRemoved = false;
      for (let i = 0; i <= this.supportedAreaCodes.length; i += 1) {
        if (intlPhoneNumber.startsWith(this.supportedAreaCodes[i])) {
          lengthOfAreaCode = this.supportedAreaCodes[i].length;
          phoneNumberWithoutAreaCode = intlPhoneNumber.slice(lengthOfAreaCode);
          areaCodeRemoved = true;
          break;
        }
      }
      if (!areaCodeRemoved) {
        return intlPhoneNumber;
      }
    } else {
      const areaCodeWithoutPlusSign = areaCode.slice(1);
      lengthOfAreaCode = areaCodeWithoutPlusSign.length;
      phoneNumberWithoutAreaCode = intlPhoneNumber.slice(lengthOfAreaCode);
    }
    return phoneNumberWithoutAreaCode;
  },

  replaceZeroWithAreaCode(phoneNumber, areaCode) {
    if (phoneNumber[0] !== '0') {
      return phoneNumber;
    }
    return `${areaCode}${phoneNumber.slice(1)}`;
  },

  convertTimeStampToHumanReadableFormat(timestamp) {
    const timestampDate = new Date(timestamp);
    const month = timestampDate.toLocaleString('default', { month: 'short' }); // 26/23
    const dayAndYear = timestampDate.toLocaleString('en-US', { day: 'numeric', year: '2-digit' }).split(' ').join('/'); // 26/23
    const time = timestampDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }); // 9:40 AM

    return `${month} ${dayAndYear} at ${time}`;
  },

  stripCommas(value) {
    const stringWithoutCommas = value.replace(/,/g, '');
    const number = parseFloat(stringWithoutCommas);
    return number;
  },
  getSymbolFromCoinName(coinName) {
    const symbolForCoinWithName = {
      bitcoin: 'btc',
      ethereum: 'eth',
      eos: 'eos',
      steem: 'steem',
      hive: 'hive',
      doge: 'doge',
      shibainu: 'shib',
    };
    return symbolForCoinWithName[coinName.toLowerCase()] ?? '';
  },

};

export default Helpers;
