export const STATIC_ASSETS_BASE_URL = 'https://roqqu.com/static/media';

export function getBankLogo(code) {
  return `${STATIC_ASSETS_BASE_URL}/banks/${code}.png`;
}

export function getBankApp(app_key) {
  return `${STATIC_ASSETS_BASE_URL}/apps/${app_key}.png`;
}

export function getMobileProvider(provider_key) {
  return `${STATIC_ASSETS_BASE_URL}/mobile/${provider_key}.png`;
}

export function getElectricProvider(_key_) {
  return `${STATIC_ASSETS_BASE_URL}/electricity/${_key_}.png`;
}

export function getCableProvider(_key_) {
  return `${STATIC_ASSETS_BASE_URL}/cable/${_key_}.png`;
}

export function getCurrencyIcon(currency_symbol) {
  return `${STATIC_ASSETS_BASE_URL}/currencies/${currency_symbol}.png`;
}

export function getCountryFlag(country_code) {
  return `${STATIC_ASSETS_BASE_URL}/countries/${country_code}.png`;
}
