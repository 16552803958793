export default function getIconUrl(symbol) {
  if (!symbol) return '';

  return `https://roqqu.com/static/media/tokens/${symbol}.png`;
}

export function getCurrencyIconUrl(currencySymbol) {
  if (!currencySymbol) return '';
  return `https://roqqu.com/static/media/currencies/${currencySymbol}.png`;
}

export function getDummyUserImage({ fname, lname }) {
  return `https://api.dicebear.com/7.x/initials/svg?seed=${fname || ''}_${lname || ''}`;
}

export function getMomoIconUrl(symbol) {
  if (!symbol) return '';
  return `https://roqqu.com/static/media/momo/${symbol}.png`;
}

export function resolveMomoIconUrl(key) {
  if (!key) return '';
  const words = key.split('_');
  return `https://roqqu.com/static/media/momo/${words[0]}.png`;
}
