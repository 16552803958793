import { decrypt, encrypt } from '../utils/simpleActions';
import AuthService from './AuthService';

const userInfoCacheService = {
  storageKey: 'uinfcchsrvc',
  encryptAndCacheUserInfo(userInfo) {
    const userInfoString = JSON.stringify(userInfo);
    const encryptedUser = encrypt(userInfoString);
    localStorage.setItem(this.storageKey, encryptedUser);
  },
  fetchAndDecryptUser() {
    const encryptedUserFromStore = localStorage.getItem(this.storageKey);
    if (!encryptedUserFromStore) return undefined;
    const decryptedUserJson = JSON.parse(decrypt(encryptedUserFromStore));
    AuthService._savePrimaryCurrency(
      JSON.parse(decryptedUserJson.user_info.primary_currency),
    );
    return decryptedUserJson;
  },
};

export default userInfoCacheService;
