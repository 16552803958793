/* eslint-disable object-curly-newline */
import apiRoutes from '../http/apiRoutes';
import { get } from '../http/https';

export default class CoinService {
  static async getPrice({ symbol }) {
    const response = await get({
      url: `${apiRoutes.getCoinPrice}?symbol=${symbol}`,
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async getFees() {
    const response = await get({
      url: apiRoutes.getFees,
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }

  static async getCoinMetaData(symbol) {
    const { response } = await get({
      url: `${apiRoutes.coinMetaData}${symbol}`,
    });

    if (response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }
}
