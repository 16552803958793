/* eslint-disable import/prefer-default-export */
import React from 'react';

import InvestIntro from '../../../../components/InvestIntro/InvestIntro';
import Ribs from '../../../../pages/dashboard/earn/ribs';
import InvestGetStarted from '../../../../components/InvestGetStarted/InvestGetStarted';
import InvestNewOneOff from '../../../../components/InvestNewOneOff/InvestNewOneOff';
import InvestNewRecurring from '../../../../components/InvestNewRecurring/InvestNewRecurring';
import InvestmentBagPicker from '../../../../components/InvestmentBagPicker/InvestmentBagPicker';

export const Dashboard_invest_route_group = [
  { path: '/dashboard-home-invest', component: <InvestIntro /> },
  { path: '/dashboard-home-ribs-get-started', component: <InvestGetStarted /> },
  { path: '/dashboard-home-ribs-beginner', component: <Ribs /> },
  { path: '/dashboard-home-ribs-new-investment/one-off', component: <InvestNewOneOff /> },
  { path: '/dashboard-home-ribs-new-investment/recurring', component: <InvestNewRecurring /> },
  { path: '/dashboard-home-ribs-pick-new-bag', component: <InvestmentBagPicker /> },
];
