/* eslint-disable react/prop-types */
import React from 'react';

import './CurrencyConvertDrawer.css';
import ImagePlaceholder from '../ImagePlaceholder';
import { useIcon } from '../../../hooks/useIcon';
import ButtonReuse from '../Button';
import { getCurrencyIcon } from '../../../utils/getStaticAssets';

function ConvertSummary({ data, onSwap = () => { }, loading }) {
  const { sidewaysArrowsIcon } = useIcon();
  return (
    <div className="convert-drawer-container convert-summary">
      <div className="from-to-container change-bg">
        <div className="from-to-section">
          <ImagePlaceholder
            imgClassName="coin-img"
            figureClassName="coin-img-box"
            src={getCurrencyIcon(data?.from?.symbol)}
            alt="coin"
          />
          <div className="label-container">
            <div className="label secondary-white-black-color3">From</div>
            <div className="coin-symbol">{data?.from?.symbol?.toUpperCase()}</div>
          </div>
        </div>
        <div className="summary-convert-illustration">
          <figure className="img-box">
            <img src={sidewaysArrowsIcon} alt="" className="icon-color img" />
          </figure>
        </div>
        <div className="from-to-section">
          <ImagePlaceholder
            imgClassName="coin-img"
            figureClassName="coin-img-box"
            src={getCurrencyIcon(data?.to?.symbol)}
            alt="coin"
          />
          <div className="label-container">
            <div className="label secondary-white-black-color3">To</div>
            <div className="coin-symbol">{data?.to?.symbol?.toUpperCase()}</div>
          </div>
        </div>
      </div>

      <div className="convert-summary-section">
        <div className="convert-summary-item">
          <div className="convert-summary-item-label secondary-white-black-color3">Source Amount</div>
          <div className="convert-summary-item-value">{data?.from?.value}</div>
        </div>
        <div className="convert-summary-item">
          <div className="convert-summary-item-label secondary-white-black-color3">Destination Amount</div>
          <div className="convert-summary-item-value">{data?.to?.value}</div>
        </div>
        {/* <div className="convert-summary-item">
          <div className="convert-summary-item-label secondary-white-black-color3">
            Service Charge
          </div>
          <div className="convert-summary-item-value">0.100 LTC</div>
        </div> */}
      </div>

      <div className="button-wrapper summary">
        <ButtonReuse
          loading={loading}
          // isDisabled={!canProceed}
          onClick={() => onSwap()}
          btnStyle="btn-reuse"
          text="Confirm swap"
        />
      </div>
    </div>
  );
}

export default ConvertSummary;
