import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import * as Keys from '../utils/storageKeys';
import AuthService from './AuthService';

export default class LoginVerificationService {
  static async submitPin(data) {
    const dataAppendedWithEmail = {
      ...data,
      email: LoginVerificationService.getStoredEmail(),
    };
    const { response, error } = await post({
      url: apiRoutes.authenticateVerify,
      data: dataAppendedWithEmail,
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response.data;
  }

  static async resendCode() {
    // !this endpoint requires token which is not
    // !provided until verification is successful
    // eslint-disable-next-line no-unused-vars
    const { response, error } = await post({
      url: apiRoutes.resendVerEmail,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error('An error occured, try again later.');
    }
    return response;
  }

  static getStoredEmail() {
    return sessionStorage.getItem(Keys.EMAIL_TEMP);
  }

  static async submitPhonePin(data) {
    const { response, error } = await post({
      url: apiRoutes.phoneNumberVerify,
      data,
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response.data;
  }
}
