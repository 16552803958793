import Helpers from './Helpers';
import { formatNumber } from './simpleActions';

const GREEN_CHART_STROKE = '#58BD7D';
const RED_CHART_STROKE = '#FF6838';
const GREEN_CHART_AREA = '#45B26B';
const GREY = '#A7B1BC';
const DATA_POINT_MARKERS_CLASSNAME = 'apexcharts-data-point-marker';

// Usages: [ TrendingAsset.jsx, UserAsset.jsx ]
export const lineChartOptions = {
  chart: {
    type: 'line',
    id: 'line-datetime',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: [GREEN_CHART_STROKE],
    width: 1,
  },
  tooltip: {
    enabled: false,
  },
};

// Usages: [ TrendingAsset.jsx ]
export const lineChartOptionsRise = {
  chart: {
    type: 'line',
    id: 'line-datetime',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: [GREEN_CHART_STROKE],
    width: 2,
  },
  tooltip: {
    enabled: false,
  },
};

// Usages: [ TrendingAsset.jsx ]
export const lineChartOptionsFall = {
  chart: {
    type: 'line',
    id: 'line-datetime',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: [RED_CHART_STROKE],
    width: 2,
  },
  tooltip: {
    enabled: false,
  },
};

export const gainerChartOptions = {
  chart: {
    type: 'line',
    id: 'line-datetime',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
    height: '100%',
    sparkline: {
      enabled: true,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: [GREEN_CHART_STROKE],
    width: 1,
  },
  tooltip: {
    enabled: false,
  },
};

export const loserChartOptions = {
  chart: {
    type: 'line',
    id: 'line-datetime',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
    height: '100%',
    sparkline: {
      enabled: true,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: [RED_CHART_STROKE],
    width: 1,
  },
  tooltip: {
    enabled: false,
  },
};

// Usages: [ SingleAsset.jsx ]
export const singleAssetChartOptions = {
  chart: {
    type: 'area',
    id: 'area-datetime',
    foreColor: GREY,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    style: {
      textTransform: 'capitalize',
    },
    custom({
      // eslint-disable-next-line no-unused-vars
      series, seriesIndex, dataPointIndex, w,
    }) {
      return '<div class="chart_arrow_box">'
      + `<span class="price_figure white-black-color">$${formatNumber(series[seriesIndex][dataPointIndex])}</span>`
      // + `<span class="percentage_figure">${series[seriesIndex][dataPointIndex]}</span>`
      + '</div>';
    },
  },
  xaxis: {
    tickAmount: 4,
    labels: {
      formatter: (value) => {
        const dateString = new Date(value).toString();
        let formattedDate = dateString.split(' ');
        formattedDate = `${formattedDate[1]} ${formattedDate[2]}`;
        return formattedDate;
      },
    },
  },
  yaxis: {
    decimalsInFloat: 2,
    tickAmount: 5,
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  colors: [GREEN_CHART_AREA],
  fill: {
    colors: [GREEN_CHART_AREA],
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  stroke: {
    colors: [GREEN_CHART_AREA, GREEN_CHART_AREA],
    width: 1,
    curve: 'smooth',
  },
};

// Usages: [ ChartLoadingStateUI.jsx ]
export const ChartLoaderOptions = {
  chart: {
    type: 'area',
    id: 'area-datetime2',
    foreColor: 'transparent',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
    fillSeriesColor: false,
    style: {
      textTransform: 'capitalize',
    },
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  colors: ['#ffffff'],
  fill: {
    colors: ['#ffffff'],
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  stroke: {
    colors: ['#ffffff', '#ffffffee'],
    width: 0.2,
    curve: 'smooth',
  },
};

export const candlestickChartOptions = {};

export const RIBSChartOptions = {
  chart: {
    type: 'area',
    id: 'area-datetime',
    foreColor: GREY,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    theme: false,
    custom({ w, seriesIndex, dataPointIndex }) {
      const { series: mainSeriesData } = w.config;
      const { points } = w.config.annotations;
      const currentData = mainSeriesData[seriesIndex].data[dataPointIndex];
      const [xAxisData, yAxisData] = currentData;
      let toolTipComponent = `<div class="ribs-charts-tooltip">
            <div>${Helpers.convertTimeStampToHumanReadableFormat(xAxisData)} </div>
            <div>Price: $1500</div>
          </div>`;

      points.forEach((point) => {
        if (point.x === xAxisData && point.y === +Number(yAxisData).toFixed(2)) {
          toolTipComponent = `<div class="ribs-charts-tooltip ribs-charts-tooltip-main">
            <div>Purchased ${Helpers.convertTimeStampToHumanReadableFormat(xAxisData)} </div>
            <div>Amount: $1500.00 <span> +7.46% </span></div>
          </div>`;
        }
      });

      return toolTipComponent;
    },
  },
  xaxis: {
    tickAmount: 4,
    labels: {
      formatter: (value) => {
        const dateString = new Date(value).toString();
        let formattedDate = dateString.split(' ');
        formattedDate = `${formattedDate[1]} ${formattedDate[2]}`;
        return formattedDate;
      },
    },
  },
  yaxis: {
    decimalsInFloat: 2,
    tickAmount: 5,
  },
  forecastDatPoints: {
    count: 0,
    dashArray: 0,
  },
  grid: {
    show: false,
  },
  colors: [GREEN_CHART_AREA],
  fill: {
    colors: [GREEN_CHART_AREA],
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  stroke: {
    colors: [GREEN_CHART_AREA, GREEN_CHART_AREA],
    width: 1,
    curve: 'smooth',
  },
  annotations: {
    points: [
      {
        x: new Date(1680161163000).getTime(),
        y: 28600.38,
        marker: {
          size: 8,
          fillColor: '#fff',
          strokeColor: GREEN_CHART_STROKE,
          strokeWidth: 5,
          cssClass: DATA_POINT_MARKERS_CLASSNAME,
        },
      },
      {
        x: new Date(1680161762000).getTime(),
        y: 28753.46,
        marker: {
          size: 8,
          fillColor: '#fff',
          strokeColor: GREEN_CHART_STROKE,
          strokeWidth: 5,
          cssClass: DATA_POINT_MARKERS_CLASSNAME,
        },
      },
      {
        x: new Date(1680163502000).getTime(),
        y: 28745.71,
        marker: {
          size: 8,
          fillColor: '#fff',
          strokeColor: GREEN_CHART_STROKE,
          strokeWidth: 5,
          cssClass: DATA_POINT_MARKERS_CLASSNAME,
        },
      },
    ],
  },
};
