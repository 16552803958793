/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TradeService from '../../../services/tradeService';
import { selectCurrencySwap, toggleConvertDrawer } from '../../../slices/tradeSlice';
import useCurrency from '../../../hooks/useCurrency';
import { formatNumber, getCurrency } from '../../../utils/simpleActions';
import AvailableBalanceBadge from '../AvailableBalanceBadge';
import CurrencyPriceField from '../CurrencyPriceField';
import DrawerLayout from '../Drawer';
import ButtonReuse from '../Button';
import TransactionSuccessModal from '../TransactionSuccessModal';
import { fetchUserBalance } from '../../../slices/UserSlice';
import useAmountAgaintBalance, { checkForTypes } from '../../../hooks/useAmountAgaintBalance';
import ClickableSwapIcon from '../ClickableSwapIcon';
import toastTypes from '../../../utils/toast-types';
import SubFlowLayout from '../subFlow';

import './CurrencyConvertDrawer.css';
import ConvertSummary from './ConvertSummary';
import { setToggleToast } from '../../../slices/authSlice';
import SwapInputs from '../SwapInputs/SwapInputs';
import { getCurrencyIcon } from '../../../utils/getStaticAssets';

function CurrencyConvertDrawer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector(selectCurrencySwap);
  const {
    setSelectedCurrency,
    stringCurrencyBalance,
    userBalance, currencies, balance,
    selectedCurrency, primaryCurrency,
  } = useCurrency();

  const [showConvertSummary, setConvertSummary] = useState(false);
  const [swapFromCurrency, setSwapFromCurrency] = useState(primaryCurrency);
  const [swapToCurrency, setSwapToCurrency] = useState('');

  const [youPayValue, setYouPayValue] = useState('');
  const [youGetValue, setYouGetValue] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successDetails, setSuccessDetails] = useState([]);

  const [details, setDetails] = useState({
    amount: '0',
    calculatedAmount: '0',
    peggedCurrency: '',
    currentChange: '',
  });

  function getCurrencyFor(currency) {
    // returns a currency
    return currencies?.find((d) => d.symbol === currency);
  }

  function calculateCurrencyAmountFrom(
    amount, // from field value
    fromCurrency = swapFromCurrency,
    toCurrency = swapToCurrency,
  ) {
    let toUSDEquivalent = '';
    if (amount && amount !== '') {
      const from = getCurrencyFor(fromCurrency);
      const to = getCurrencyFor(toCurrency);

      // all rates are reference to usd
      // convert `from` to usd equivalent
      const fromInputValue = parseFloat(amount);
      const usdPriceOfFrom = parseFloat(from?.rate || 1);
      const usdEquivalent = fromInputValue / usdPriceOfFrom;

      // convert usd equivalent to `to` equivalent
      const usdPriceOfTo = parseFloat(to?.rate || 0);
      toUSDEquivalent = usdPriceOfTo * usdEquivalent;
    }
    return { calculatedAmount: toUSDEquivalent, amount };
  }

  function calculateCurrencyAmountTo(
    value, // to field value
    toCurrency = swapToCurrency,
    fromCurrency = swapFromCurrency,
  ) {
    return {
      amount: calculateCurrencyAmountFrom(
        value,
        toCurrency,
        fromCurrency,
      ).calculatedAmount,
      calculatedAmount: value,
    };
  }

  function calculateGetValue(
    value,
    forW,
    fromCurrency = swapFromCurrency,
    toCurrency = swapToCurrency,
  ) {
    if (currencies) {
      let toValue = parseFloat(userBalance?.fiat.find(
        (d) => d.currency === toCurrency,
      )?.available_bal);

      let newDetails = {};

      if (forW === 'from') {
        newDetails = calculateCurrencyAmountFrom(value, fromCurrency, toCurrency);
        toValue += newDetails.calculatedAmount;
        setYouGetValue(toValue);
      } else if (forW === 'to') {
        newDetails = calculateCurrencyAmountTo(value, toCurrency, fromCurrency);
        setYouGetValue(toValue + parseFloat(value || 0));
      }

      setDetails((prev) => ({ ...prev, ...newDetails }));
    }
  }

  const onChangeSwapFrom = (value) => {
    if (swapFromCurrency === details.peggedCurrency) {
      setDetails((prev) => ({ ...prev, peggedCurrency: value }));
    }
    setSelectedCurrency(value);
    setSwapFromCurrency(value);
  };

  function onChangeSwapTo(currency) {
    if (swapToCurrency === details.peggedCurrency) {
      setDetails((prev) => ({ ...prev, peggedCurrency: currency }));
    }
    setSwapToCurrency(currency);
    setYouGetValue(userBalance?.fiat.find((d) => d.currency === currency)?.available_bal);
  }

  useEffect(() => {
    if (userBalance?.fiat) {
      setYouGetValue(
        userBalance?.fiat.find((d) => d.currency === swapToCurrency)?.available_bal,
      );
    }
  }, [userBalance, userBalance?.fiat]);

  useEffect(() => {
    if (details.amount && details.amount !== '') {
      const data = {
        ...details,
        peggedCurrency: swapFromCurrency,
      };
      setDetails(data);
      calculateGetValue(details.amount, 'from');
    }
  }, [swapFromCurrency]);

  useEffect(() => {
    if (details.calculatedAmount && details.calculatedAmount !== '') {
      const data = {
        ...details,
        peggedCurrency: swapToCurrency,
      };
      setDetails(data);
      calculateGetValue(details.calculatedAmount, 'to');
    }
  }, [swapToCurrency]);

  // forW -> 'from' or 'to'
  const onChangeAmount = ({ name, value, forW }, currency) => {
    const data = {
      ...details,
      [name]: value,
      peggedCurrency: currency,
      currentChange: forW,
    };

    setDetails(data);
    calculateGetValue(value, forW);
  };

  function closeDrawer() {
    dispatch(toggleConvertDrawer());
  }

  function openSuccessModal(det) {
    setSuccessModal(true);
    setSuccessDetails(det);
    closeDrawer();
  }

  function closeSuccessModal() {
    setSuccessModal(false);
  }

  function fetchBalance() {
    dispatch(fetchUserBalance());
  }

  async function swapCurrency() {
    try {
      setLoading(true);
      const { data } = await TradeService.swapCurrencyService({
        amount: details.amount,
        from: swapFromCurrency,
        to: swapToCurrency,
        pegged: details.peggedCurrency,
      }, ({ error }) => {
        if (error) {
          const afterData = error.response.data;

          if (afterData.status === 'fail') {
            dispatch(
              setToggleToast({
                type: toastTypes.warning,
                heading: 'Currency Swap Error',
                description: afterData.message,
              }),
            );
          }
        }
      });

      if (data.status !== 'fail') {
        // open success modal...
        fetchBalance();
        openSuccessModal([
          {
            name: 'From',
            value: `${swapFromCurrency?.toUpperCase()} ${formatNumber(details.amount)}`,
          },
          {
            name: 'To',
            value: `${swapToCurrency?.toUpperCase()} ${formatNumber(details.calculatedAmount)}`,
          },
        ]);
      } else {
        dispatch(
          setToggleToast({
            type: toastTypes.warning,
            heading: 'Currency Swap Error',
            description: data.message,
          }),
        );
      }
    } catch (error) {
      // open taosts...
    } finally {
      setLoading(false);
    }
  }

  const {
    canProceed: amountCanProceed, amountErrorMessage,
  } = useAmountAgaintBalance({
    amount: details.amount,
    currencyFor: swapFromCurrency,
  });

  const {
    canProceed: calculatedAmountCanProceed,
    amountErrorMessage: calculatedAmountErrorMessage,
  } = useAmountAgaintBalance({
    amount: details.calculatedAmount,
    currencyFor: swapToCurrency,
    checkFor: checkForTypes.TRADABLE,
  });

  useEffect(() => {
    setCanProceed(amountCanProceed && calculatedAmountCanProceed);
  }, [amountCanProceed, calculatedAmountCanProceed]);

  useEffect(() => {
    if (currencies) {
      setSwapToCurrency(
        currencies?.filter((d) => d.c !== primaryCurrency)[0]?.c,
      );
    }
  }, [currencies]);

  function fillWithMaxAmount() {
    onChangeAmount({ name: 'amount', value: balance, forW: 'from' }, swapFromCurrency);
  }

  function swapCurrencyPosition() {
    const _swapFromCurrency = swapFromCurrency;
    const _swapToCurrency = swapToCurrency;
    setSwapFromCurrency(_swapToCurrency);
    setSelectedCurrency(_swapToCurrency);
    onChangeSwapTo(_swapFromCurrency);
    setSwapToCurrency(_swapFromCurrency);
  }

  function goToConvertSummary() {
    setConvertSummary(true);
  }

  function goBack() {
    if (showConvertSummary) {
      setConvertSummary(false);
    } else {
      closeDrawer();
    }
  }

  const fromDetails = useMemo(() => ({
    fromAssetCurrency: swapFromCurrency,
    fromAmount: details?.amount || '',
    fromAssetBalance: stringCurrencyBalance,
    fromAssetCurrencyIcon: getCurrencyIcon(swapFromCurrency),
    onChangeFromCurrency: onChangeSwapFrom,
    setFromAmount: (value) => onChangeAmount({ name: 'amount', value, forW: 'from' }, swapFromCurrency),
    isError: !!amountErrorMessage,
    errorMessage: amountErrorMessage,
  }), [swapFromCurrency, onChangeSwapFrom,
    stringCurrencyBalance, amountErrorMessage,
    details?.amount, onChangeAmount]);

  const toDetails = useMemo(() => ({
    toAssetCurrency: swapToCurrency,
    toAmount: details?.calculatedAmount || '',
    toAssetBalanceAfter: `${swapToCurrency?.toUpperCase()} ${formatNumber(youGetValue || 0)}`,
    toAssetCurrencyIcon: getCurrencyIcon(swapToCurrency),
    onChangeToCurrency: (value) => onChangeSwapTo(value),
    setToAmount: (value) => onChangeAmount({ name: 'calculatedAmount', value, forW: 'to' }, swapToCurrency),
    isError: !!calculatedAmountErrorMessage,
    errorMessage: calculatedAmountErrorMessage,
  }), [swapToCurrency, calculatedAmountErrorMessage, details?.calculatedAmount, youGetValue]);

  return (
    <>
      <SubFlowLayout
        goBack
        goBackFunc={() => goBack()}
        onClose={() => closeDrawer()}
        isVisible={drawerOpen}
        title={showConvertSummary ? 'Confirm this transaction' : 'Convert money from one currency to another'}
      >
        {!showConvertSummary && (
          <div className="withdraw-wrapper convert-drawer-container">
            <SwapInputs
              toDetails={toDetails}
              fromDetails={fromDetails}
              swapPositions={() => swapCurrencyPosition()}
              assets={
                currencies.map(
                  (d) => ({ label: d.title, symbol: d.symbol, icon: getCurrencyIcon(d.symbol) }),
                )
              }
            />
            {/* <div className="amount-field-label secondary-white-black-color3">
              You Pay
            </div>
            <CurrencyPriceField
              canFormatNumber
              placeholder="Enter Amount"
              parentAmount={details.amount || ''}
              setParentAmount={(value) => onChangeAmount({ name: 'amount', value, forW: 'from' }, swapFromCurrency)}
              defaultCurrency={swapFromCurrency}
              onChange={onChangeSwapFrom}
              changeOnMount={false}
              excludedCurrency={swapToCurrency}
              isError={!!amountErrorMessage}
              errorMessage={amountErrorMessage}
            />
            <AvailableBalanceBadge
              value={stringCurrencyBalance}
              fillWithBalance={() => fillWithMaxAmount()}
            />
            <div
              className="swap-icon"
              style={{
                display: 'flex',
                margin: '1.5rem 0',
                justifyContent: 'center',
              }}
            >
              <ClickableSwapIcon swap={() => swapCurrencyPosition()} />
            </div>
            {swapToCurrency && (
            <>
              <div className="amount-field-label secondary-white-black-color3">
                You Get
              </div>
              <CurrencyPriceField
                canFormatNumber
                parentAmount={details.calculatedAmount || ''}
                setParentAmount={(value) => onChangeAmount({ name: 'calculatedAmount', value, forW: 'to' }, swapToCurrency)}
              // value={}
                defaultCurrency={swapToCurrency}
                onChange={(value) => onChangeSwapTo(value)}
                placeholder="Amount is prefilled"
                changeOnMount={false}
                excludedCurrency={swapFromCurrency}
                isError={!!calculatedAmountErrorMessage}
                errorMessage={calculatedAmountErrorMessage}
              />
              <AvailableBalanceBadge
                text="Balance After"
                value={`${getCurrency(swapToCurrency)?.c?.toUpperCase()} ${formatNumber(youGetValue || 0)}`}
              />
            </>
            )} */}

            <div className="button-wrapper">
              <ButtonReuse
                loading={!swapToCurrency || loading}
                isDisabled={!canProceed}
              // onClick={() => swapCurrency()}
                onClick={() => goToConvertSummary()}
                btnStyle="btn-reuse"
                text={t('dsh.proceed')}
              />
            </div>
          </div>
        )}

        {showConvertSummary && (
          <ConvertSummary
            onSwap={() => swapCurrency()}
            loading={!swapToCurrency || loading}
            data={{
              from: {
                name: 'From',
                symbol: swapFromCurrency,
                value: `${swapFromCurrency?.toUpperCase()} ${formatNumber(details.amount)}`,
              },
              to: {
                name: 'To',
                symbol: swapToCurrency,
                value: `${swapToCurrency?.toUpperCase()} ${formatNumber(details.calculatedAmount)}`,
              },
            }}
          />
        )}
      </SubFlowLayout>
      <TransactionSuccessModal
        closeModal={() => closeSuccessModal()}
        visible={successModal}
        goToDashboard={() => closeSuccessModal()}
        boxItems={successDetails}
      />
    </>
  );
}

export default CurrencyConvertDrawer;
