/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import './RoqqDialog.css';
import Modal from 'react-awesome-modal';
import { useIcon } from '../../../../../../hooks/useIcon';
import RoqqPrimaryButton from '../RoqqPrimaryButton/RoqqPrimaryButton';
import RoqqSecondaryButton from '../RoqqNegativeButton/RoqqNegativeButton';
import Portal from '../../../../../../components/widgets/Portal';
import ModalLayout from '../../../../../../components/layouts/dashboard/ModalLayout';

/**
 * @typedef RoqqDialogProps
 * @property {'single' | 'double'} buttonType
 * @property {boolean} show
 * @property {boolean} isNeutralButtonDisabled
 * @property {string} headerText
 * @property {string} neutralBtnText
 * @property {() => {}} onClose
 * @property {() => {}} onClickBackBtn
 * @property {() => {}} onClickPositiveBtn
 * @property {() => {}} onClickNegativeBtn
 * @property {() => {}} onClickNeutralBtn
 * @property {boolean} showBackBtn
 * @property {string} positiveBtnText
 * @property {string} negativeBtnText
 * @property {React.ReactNode} children
 */

/**
 *
 * @param {RoqqDialogProps} param0
 * @returns
 */
export default function RoqqDialog({
  show,
  headerText,
  onClose,
  onClickBackBtn,
  showBackBtn,
  positiveBtnText,
  negativeBtnText,
  onClickPositiveBtn,
  onClickNegativeBtn,
  children,
  buttonType = 'double', // can be 'single', 'double' or 'none
  neutralBtnText,
  onClickNeutralBtn,
  isNeutralButtonDisabled,
}) {
  return (
    <ModalLayout visible={show} showLine={false} width="631px" noPadding>
      <div className="roqq-dialog">
        <section className="roqq-dialog-content-wrap">
          <section className="top">
            <section className="top-content">
              <RoqqDialog.BackBtn onClick={onClickBackBtn} showBackBtn={showBackBtn} />
              <div className="roqq-dialog-header">
                <h2 className="header-text">{headerText}</h2>
              </div>
              <RoqqDialog.CloseBtn onClick={onClose} />
            </section>
          </section>
          <main className="roqq-dialog-content">
            {children}
          </main>
          {buttonType !== 'none' && (
          <div className="action-btns-wrapper">
            {buttonType === 'double' ? (
              <section className="action-btns">
                <RoqqSecondaryButton className="roqq-action-btn" text={negativeBtnText} onClick={onClickNegativeBtn} />
                <RoqqPrimaryButton className="roqq-action-btn" text={positiveBtnText} onClick={onClickPositiveBtn} />
              </section>
            ) : (
              <section className="action-btn-single">
                <RoqqPrimaryButton className="roqq-action-btn" isDisabled={isNeutralButtonDisabled} text={neutralBtnText} onClick={onClickNeutralBtn} />
              </section>
            ) }
          </div>
          )}

        </section>
      </div>
    </ModalLayout>
  );
}

RoqqDialog.BackBtn = function RoqqDialogBackBtn({ onClick, showBackBtn, className }) {
  const { roqqDialogBackBtn } = useIcon();
  return (
    <button type="button" className={`roqq-dialog-back-btn ${className} ${!showBackBtn && 'hidden'}`} onClick={onClick}>
      <img src={roqqDialogBackBtn} alt="close dialog" />
    </button>
  );
};

RoqqDialog.CloseBtn = function RoqqDialogCloseBtn({ onClick }) {
  const { roqqCloseBtn } = useIcon();
  return (
    <button type="button" className="roqq-dialog-close-btn" onClick={onClick}>
      <img src={roqqCloseBtn} alt="close dialog" />
    </button>
  );
};
