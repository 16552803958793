/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import PersonalDetailsIcon from '../assets/auth-register/personal-details.svg';
import CreatePINIcon from '../assets/auth-register/create-pin.svg';
import VerifyIdentityIcon from '../assets/auth-register/verify-identity.svg';
import AddBankIcon from '../assets/auth-register/add-bank.svg';

export const SignupStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

export const RegistrationContinentSlug = {
  african: 'african',
  nonAfrican: 'non-african'
};

const initialState = {
  status: SignupStates.BASE,
  token: '',
  data: {
    username: '',
    email: '',
    password: '',
    fname: '',
    lname: '',
    phone: '',
  },
  error: '',
  showPassword: false,

  // New Registration from here
  registrationStages: [
    {
      title: 'Your personal details',
      description: 'We’d use this to personalise the app for you',
      icon: PersonalDetailsIcon,
      completed: false,
      active: true,
    },
    {
      title: 'Create PIN',
      description: 'This will serve as an extra layer of security for your app and transactions',
      icon: CreatePINIcon,
      completed: false,
      active: false,
    },
    {
      title: 'Verify Identity',
      description: 'We’d check to make sure you and your ID match',
      icon: VerifyIdentityIcon,
      completed: false,
      active: false,
    },
    {
      title: 'Preferences & Usage',
      description: 'Share referrals, add banking details, and select your app usage preferences.',
      icon: AddBankIcon,
      completed: false,
      active: false,
    },
  ],
  isPinValid: false,

  registrationPersonalDetails: {
    ref: '', // referrer identifier
    country: '',
    flowContinent: RegistrationContinentSlug.african,
    // Details
    firstName: '',
    lastName: '',
    emailAddress: '',
    dob: '',
    phone: '',
    password: '',
    // Address
    post_code: '',
    street: '',
    flat_number: '',
    city: '',
    africanAddress: '',
    africanState: '',

    username: '',
  },
};

export const register = createAsyncThunk(
  'signup/register',
  async (_, thunkAPI) => {
    try {
      const _state = thunkAPI.getState().signup.registrationPersonalDetails;
      const address = _state.flowContinent === RegistrationContinentSlug.african
        ? { address: _state.africanAddress, city: _state.city, state: _state.africanState }
        : {
          postCode: _state.post_code,
          street: _state.street,
          flat: _state.flat_number,
          city: _state.city
        };

      const _data = {
        email: _state.emailAddress,
        username: _state.username,
        fname: _state.firstName,
        lname: _state.lastName,
        phone: _state.phone.replaceAll(' ', ''),
        password: _state.password,
        ref: _state.ref,
        country: _state.country,
        dob: _state.dob,
        address: JSON.stringify(address)
      };

      const data = await AuthService.signUp(_data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

const registrationSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    togglePasswordVisibility: (state) => {
      state.showPassword = !state.showPassword;
    },
    updateData: (state, action) => {
      const { name, value } = action.payload;
      state.data = {
        ...state.data,
        [name]: value,
      };
    },
    resetError: (state) => {
      state.error = '';
    },
    updatePersonalDetailsData: (state, action) => {
      state.registrationPersonalDetails[action.payload.key] = action.payload.value;
    },
    resetPersonalDetailsData: (state) => {
      state.registrationPersonalDetails = initialState.registrationPersonalDetails;
    },
    completeRegistrationStage: (state, action) => {
      state.registrationStages[action.payload].completed = true;
      // state.registrationPersonalDetails = initialState.registrationPersonalDetails;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.status = SignupStates.LOADING;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      const { data, message: error, status } = action.payload;
      if (data && status !== 'fail') {
        AuthService._saveToken(data);
        state.token = data;
        state.status = SignupStates.SUCCESSFUL;
      } else {
        state.error = error;
        state.status = SignupStates.FAILED;
      }
    });
    builder.addCase(register.rejected, (state, action) => {
      state.status = SignupStates.FAILED;
      state.error = action.payload;
    });
  },
});

export const selectsignUp = (state) => state.signup;
export const selectRegistrationStages = (state) => state.signup.registrationStages;
export const selectRgistrationPersonalDetails = (state) => state.signup.registrationPersonalDetails;
export const selectRgistrationContinent =
  (state) => state.signup.registrationPersonalDetails.flowContinent;

export default registrationSlice.reducer;
export const {
  togglePasswordVisibility, updateData, resetError, updatePersonalDetailsData,
  completeRegistrationStage, resetPersonalDetailsData
} =
  registrationSlice.actions;
