/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
import { useIcon } from '../../hooks/useIcon';
import '../../styles/dashboard/Button.css';

function ButtonReuse({
  text,
  onClick,
  loading = false,
  bgColor,
  btnStyle = '',
  style = {},
  children,
  isDisabled,
  ...rest
}) {
  // Proposed btnStyle = {
  //   default,
  //   read: for the read-box on homedashboard,
  //   small: used in settings,
  //    FAB,
  //    icon
  //    btn-reuse
  //    brand, btn-reuse but based on revamp
  //    FAQ
  // }
  const { loadingCircle } = useIcon();

  const buttonDisabled = loading || isDisabled;
  return (
    <>
      <button
        style={{ backgroundColor: bgColor || '', ...style }}
        className={`reuseable-btn-wrap ${btnStyle} ${buttonDisabled ? 'disabled' : ''}`}
        onClick={onClick}
        disabled={buttonDisabled}
        {...rest}
      >
        {loading ? (
          <img src={loadingCircle} className="spin-loader" alt="" />
        ) : (
          <>
            {children}
            <span>{text === null ? 'submit' : text}</span>
          </>
        )}
      </button>
    </>
  );
}

export default ButtonReuse;
