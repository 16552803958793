/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import signupReducer from '../slices/registrationSlice';
import verificationReducer from '../slices/verificationSlice';
import transactionsReducer from '../slices/transactionsSlice';
import settingsReducer from '../slices/settingsSlice';
import banksReducer from '../slices/banksSlice';
import referralsReducer from '../slices/referralsSlice';
import userReducer from '../slices/UserSlice';
import walletReducer from '../slices/walletSlice';
import coinReducer from '../slices/coinSlice';
import pinReducer from '../slices/pinSlice';
import globalReducer from '../slices/globalSlice';
import paymentLinksReducer from '../slices/paymentLinkSlice';
import savingReducer from '../slices/savingSlice';
import billReducer from '../slices/billSlice';
import loginVerificationReducer from '../slices/LoginVerificationSlice';
import withdrawReducer from '../slices/withdrawSlice';
import cardReducer from '../slices/cardSlice';
import twoFAReducer from '../slices/twoFASlice';
import loanReducer from '../slices/loanSlice';
import priceAlertReducer from '../slices/priceAlertSlice';
import fiatTransferReducer from '../slices/FiatTransferSlice';
import tradeReducer from '../slices/tradeSlice';
import loginReducer from '../slices/LoginSlice';
import clipboardReducer from '../slices/clipboardSlice';
import kycReducer from '../slices/KYCSlice';
import usernameReducer from '../slices/UsernameSlice';
import notificationReducer from '../slices/notificationSlice';
import momoReducer from '../slices/mobileMoneySlice';
import walletWithdrawalReducer from '../slices/walletWithdrawalSlice';
import toastReducer from '../slices/toast-slice';
import ribsApplicationReducer from '../slices/ribsApplicationSlice';
import ribsGlobalReducer from '../slices/ribsGlobalSlice';
import raffleReducer from '../slices/RaffleSlice';
import ribsWithdrawSlice from '../slices/ribsWithdrawSlice';
import reelIndicatorsSlice from '../slices/reelIndicatorsSlice';
import ribsInitialSetupSlice from '../slices/ribsInitialSetupSlice';
import toolsReducer from '../slices/toolsSlice';

// I've removed all the loggers because you can now use chrome devtools to view actions in real time

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    auth: authReducer,
    transactions: transactionsReducer,
    settings: settingsReducer,
    banks: banksReducer,
    referrals: referralsReducer,
    signup: signupReducer,
    verification: verificationReducer,
    user: userReducer,
    coin: coinReducer,
    wallet: walletReducer,
    pin: pinReducer,
    global: globalReducer,
    paymentLinks: paymentLinksReducer,
    saving: savingReducer,
    bills: billReducer,
    withdraw: withdrawReducer,
    card: cardReducer,
    loginVerification: loginVerificationReducer,
    twoFA: twoFAReducer,
    loan: loanReducer,
    priceAlerts: priceAlertReducer,
    fiatTransfer: fiatTransferReducer,
    trade: tradeReducer,
    login: loginReducer,
    clipboard: clipboardReducer,
    kyc: kycReducer,
    username: usernameReducer,
    notification: notificationReducer,
    mobileMoney: momoReducer,
    walletWithdrawal: walletWithdrawalReducer,
    toastV2: toastReducer,
    ribsApplication: ribsApplicationReducer,
    ribsGlobal: ribsGlobalReducer,
    raffle: raffleReducer,
    ribsWithdrawMoney: ribsWithdrawSlice,
    reels: reelIndicatorsSlice,
    ribsInitialSetup: ribsInitialSetupSlice,
    tools: toolsReducer,
  },
});

export default store;
