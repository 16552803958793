/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React from 'react';
// RIBs Imports
// import RIBs from '../../../pages/dashboard/earn/ribs';
// import ARIBsFaq from '../../../pages/dashboard/earn/ribs/ARIBsFaq';
// import AllRIBsFaq from '../../../pages/dashboard/earn/ribs/AllRIBsFaq';
// // import CoinInfoPage from '../../../pages/dashboard/earn/ribs/CoinInfoPage';
// import HasRIBs from '../../../pages/dashboard/earn/ribs/HasRIBs';
// import InspectRIBs from '../../../pages/dashboard/earn/ribs/InspectRIBs';
// import RIBsBeginnerIndex from '../../../pages/dashboard/earn/ribs/beginnerFlow';

// Raffle Draw Imports
const RaffleDraw = React.lazy(() => import('../../../pages/dashboard/earn/raffle'));
const Spin = React.lazy(() => import('../../../pages/dashboard/earn/raffle/Spin'));
// import RIBsTransactions from '../../../pages/dashboard/earn/ribs/RIBsTransactions';
// import CancelInvestmentConfirmation from '../../../pages/dashboard/earn/ribs/CancelInvestmentConfirmation';

export const Dashboard_earn_route_group = [
  // RIBS Routes
  // { path: '/dashboard-earn-ribs', component: RIBs },
  // { path: '/dashboard-earn-ribs-with', component: HasRIBs },
  // { path: '/dashboard-earn-ribs-faq', component: AllRIBsFaq },
  // { path: '/dashboard-earn-ribs-faq/:slug', component: ARIBsFaq },
  // { path: '/dashboard-earn-ribs-inspect/:slug', component: InspectRIBs },
  // { path: '/dashboard-earn-ribs-inspect-transactions/:slug', component: RIBsTransactions },
  // {
  //   path: '/dashboard-earn-ribs-cancel-investment/:slug',
  //   component: CancelInvestmentConfirmation,
  // },
  // { path: '/dashboard-earn-ribs-coininfo/:slug', component: CoinInfoPage },

  // Beginner flow
  // {
  //   path: '/dashboard-earn-ribs-buy',
  //   component: RIBsBeginnerIndex,
  // }, // For initial buy for a bag

  // Raffle Draw Routes
  { path: '/dashboard-earn-raffle', component: <RaffleDraw /> },
  { path: '/dashboard-earn-raffle-spin', component: <Spin /> },
];
