import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class CardService {
  static async getAllCards(token) {
    const { response } = await post({
      url: apiRoutes.getAllCards,
      data: {
        token,
      },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getCard({ token, card_id }) {
    const { response } = await post({
      url: apiRoutes.getCard,
      data: { token, card_id },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getCardTransactions({ token, card_id }) {
    const { response } = await post({
      url: apiRoutes.getCardTransactions,
      data: { token, card_id },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async createNewCard({
    currency, amount, label, skin, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.createNewCard,
      data: {
        token: AuthService._getUserToken(),
        currency,
        amount,
        label,
        skin,
        ...pin,
      },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async fundCard({
    card_id, amount, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.fundCard,
      data: {
        token: AuthService._getUserToken(),
        card_id,
        amount,
        ...pin,
      },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async withdrawFromCard({
    token, card_id, amount, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.withdrawFromCard,
      data: {
        token, card_id, amount, pin,
      },
    });
    if (response.response === false) {
      throw new Error('some error');
    }
    return response;
  }
}
