/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TradeService from '../services/tradeService';

export const tradeStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

export const TradeType = {
  BUY: 'buy',
  SELL: 'sell',
  SWAP: 'swap',
  SWAP_COIN: 'swapCoin',
  SWAP_CURRENCY: 'swapCurrency',
};

const initialState = {
  status: tradeStates.BASE,
  data: null,
  error: false,
  tradeDetails: null,
  convert: {
    drawerOpen: false,
  },
  buy: {
    amount: null,
    coin: null,
    currency: null,
    pegged: null,
  },
  sell: {
    amount: null,
    coin: null,
    currency: null,
    pegged: null,
  },
  swapCoin: {
    amount: null,
    from: null,
    to: null,
    pegged: null,
  },
  tradeAnalytics: {
    data: [],
    status: tradeStates.BASE,
    error: null,
  },
  swapCurrency: {
    amount: null,
    from: null,
    to: null,
    pegged: null,
  },
};

export const buyThunk = createAsyncThunk(
  'trade/buyThunk',
  async ({
    amount, coin, currency, pegged,
  }, { rejectWithValue }) => {
    try {
      const { data } = await TradeService.buyService({
        amount, coin, currency, pegged,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const sellThunk = createAsyncThunk(
  'trade/sellThunk',
  async ({
    amount, coin, currency, pegged,
  }, { rejectWithValue }) => {
    try {
      const { data } = await TradeService.sellService({
        amount, coin, currency, pegged,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const swapThunk = createAsyncThunk(
  'trade/swapThunk',
  async ({
    amount, from, to, pegged,
  }, { rejectWithValue }) => {
    try {
      const { data } = await TradeService.swapService({
        amount, from, to, pegged,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const trade24hAnalytics = createAsyncThunk(
  'trade/trade24hAnalytics',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await TradeService.get24hTradeAnalytics();
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const newBuyAsset = createAsyncThunk('trade/buy_asset', async (_, thunkAPI) => {
  try {
    const data = await TradeService.buyCoin(thunkAPI.getState().trade.tradeDetails);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const newSellAsset = createAsyncThunk('trade/sell_asset', async (_, thunkAPI) => {
  try {
    const data = await TradeService.sellCoin(thunkAPI.getState().trade.tradeDetails);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const newSwapAsset = createAsyncThunk('trade/swap_asset', async (_, thunkAPI) => {
  try {
    const data = await TradeService.swapCoin(thunkAPI.getState().trade.tradeDetails);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

// export const newSellAsset = createAsyncThunk(
//   'trade/sell_asset',
//   async ({
//     amount, coin, currency, pegged,
//   }, { rejectWithValue }) => {
//     try {
//       const { data } = await TradeService.sellService({
//         amount, coin, currency, pegged,
//       });
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.toString());
//     }
//   },
// );

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    saveTradeDetails: (state, action) => {
      if (action.payload) {
        state.tradeDetails = action.payload;
      } else {
        state.tradeDetails = initialState.tradeDetails;
      }
    },
    resetTradeStatus: (state) => {
      state.error = initialState.error;
      state.status = initialState.status;
      state.tradeDetails = initialState.tradeDetails;
      state.buy = initialState.buy;
      state.sell = initialState.sell;
      state.swapCoin = initialState.swapCoin;
      state.swapCurrency = initialState.swapCurrency;
      state.tradeAnalytics.status = initialState.tradeAnalytics.status;
    },
    toggleConvertDrawer: (state) => {
      state.convert.drawerOpen = !state.convert.drawerOpen;
    },
    updateTradeData: (state, action) => {
      const { type } = action.payload;
      state = {
        ...state,
        [type]: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // buyThunk thunk reducers...
    builder.addCase(buyThunk.pending, (state) => {
      state.status = tradeStates.FETCHING;
    });
    builder.addCase(buyThunk.fulfilled, (state, action) => {
      if (action.payload?.status !== 'fail') {
        state.data = action.payload.data;
        state.status = tradeStates.FETCHED;
      } else {
        state.status = tradeStates.ERROR;
      }
    });
    builder.addCase(buyThunk.rejected, (state) => {
      state.status = tradeStates.ERROR;
    });

    // sellThunk thunk reducers...
    builder.addCase(sellThunk.pending, (state) => {
      state.status = tradeStates.FETCHING;
    });
    builder.addCase(sellThunk.fulfilled, (state, action) => {
      if (action.payload?.status !== 'fail') {
        state.data = action.payload.data;
        state.status = tradeStates.FETCHED;
      } else {
        state.status = tradeStates.ERROR;
      }
    });
    builder.addCase(sellThunk.rejected, (state) => {
      state.status = tradeStates.ERROR;
    });

    // swapThunk thunk reducers...
    builder.addCase(swapThunk.pending, (state) => {
      state.status = tradeStates.FETCHING;
    });
    builder.addCase(swapThunk.fulfilled, (state, action) => {
      if (action.payload?.status !== 'fail') {
        state.data = action.payload.data;
        state.status = tradeStates.FETCHED;
      } else {
        state.status = tradeStates.ERROR;
      }
    });
    builder.addCase(swapThunk.rejected, (state) => {
      state.status = tradeStates.ERROR;
    });

    // trade24hAnalytics reducers
    builder.addCase(trade24hAnalytics.pending, (state) => {
      state.tradeAnalytics.status = tradeStates.FETCHING;
    });
    builder.addCase(trade24hAnalytics.fulfilled, (state, action) => {
      if (action.payload?.status !== 'fail') {
        state.tradeAnalytics.data = action.payload.data;
        state.tradeAnalytics.status = tradeStates.FETCHED;
      } else {
        state.tradeAnalytics.error = action.payload.data.message;
        state.tradeAnalytics.status = tradeStates.ERROR;
      }
    });
    builder.addCase(trade24hAnalytics.rejected, (state) => {
      state.tradeAnalytics.status = tradeStates.ERROR;
    });

    // newBuyAsset thunk reducers
    builder.addCase(newBuyAsset.pending, (state) => {
      state.status = tradeStates.FETCHING;
    });
    builder.addCase(newBuyAsset.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.status = tradeStates.FETCHED;
    });
    builder.addCase(newBuyAsset.rejected, (state, action) => {
      state.status = tradeStates.ERROR;
      state.error = action.payload;
    });

    // newSellAsset thunk reducers
    builder.addCase(newSellAsset.pending, (state) => {
      state.status = tradeStates.FETCHING;
    });
    builder.addCase(newSellAsset.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.status = tradeStates.FETCHED;
    });
    builder.addCase(newSellAsset.rejected, (state, action) => {
      state.status = tradeStates.ERROR;
      state.error = action.payload;
    });

    // newSwapAsset thunk reducers
    builder.addCase(newSwapAsset.pending, (state) => {
      state.status = tradeStates.FETCHING;
    });
    builder.addCase(newSwapAsset.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.status = tradeStates.FETCHED;
    });
    builder.addCase(newSwapAsset.rejected, (state, action) => {
      state.status = tradeStates.ERROR;
      state.error = action.payload;
    });
  },
});

export const selectTrade24hAnalytics = (state) => state.trade.tradeAnalytics;
export const selectTradeDetails = (state) => state.trade.tradeDetails;
export const selectCurrencySwap = (state) => state.trade.convert;
export const selectTrade = (state) => state.trade;

export default tradeSlice.reducer;
export const {
  saveTradeDetails,
  toggleConvertDrawer,
  resetTradeStatus,
  updateTradeData,
} = tradeSlice.actions;
