import React, { useContext } from 'react';
import ReactPinField from 'react-pin-field';

import './styles/ConfirmPin.css';
import ButtonReuse from '../../../../fragments/Button';
import InputGroupLabel from '../../../../fragments/InputGroup/components/InputGroupLabel';
import { RIBsApplicationNavigationHandlerContext } from '../../../../../context/RIBsApplicationNavigationContext';
import SuccessApplication from '../../SuccessApplication';

function ConfirmPin() {
  const dispatchNavAction = useContext(RIBsApplicationNavigationHandlerContext);

  function goToQuestions() {
    dispatchNavAction({
      type: 'push',
      payload: <SuccessApplication />,
    });
  }

  return (
    <div className="ribs-questions-container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <p className="note-title">
        Input your pin to confirm this transaction
      </p>

      <div className="pin-container">
        <div className="">
          <InputGroupLabel label="Enter Pin" />
          <form className="">
            <div className="pin-input-group">
              <ReactPinField
                length={6}
                pattern="\d*"
                // type={pinVisible ? 'number' : 'password'}
                className="pin-field white-black-color white-black-bg"
                // onChange={(num) => setPinHandler(num)}
                  //   onComplete={() => handleSubmit()}
                format={(k) => k?.toUpperCase()}
                  //  disabled={showTime}
                validate="0123456789"
                  // autoFocus
                // ref={pinRef}
              />
            </div>
          </form>
        </div>

        <div style={{ marginTop: 'auto' }}>
          <ButtonReuse onClick={() => goToQuestions()} text="Proceed" btnStyle="btn-reuse" style={{ width: '100%' }} />
        </div>
      </div>
    </div>
  );
}

export default ConfirmPin;
