/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './ContinueToKYC.css';
import { useNavigate } from 'react-router-dom';
import verifyAccount from '../../../assets/verifiy_account.svg';
import ButtonReuse from '../Button';
import { RETURN_TO_WALLET_PORTFOLIO_FLOW } from '../../../utils/storageKeys';

function ContinueToKYC() {
  const push = useNavigate();

  function handleRoutingToUpgradeScreen() {
    sessionStorage.setItem(RETURN_TO_WALLET_PORTFOLIO_FLOW, true);
    push('/dashboard-setting');
  }
  return (

  // TODO: Translate All and add to translation file
    <div className="continue-to-kyc">
      <figure>
        <img src={verifyAccount} alt="continue to kyc" />
      </figure>
      <h2>Upgrade your account</h2>
      <p>
        It seems like the amount you entered exceeded your current limit.
        To proceed, kindly increase your limit by completing your verification
      </p>
      <aside>
        Don't worry, we've got you covered!
      </aside>
      <ButtonReuse
        onClick={() => handleRoutingToUpgradeScreen()}
        btnStyle="continue-to-kyc-proceed-btn"
        text="Continue to KYC"
        isDisabled={false}
      />
    </div>
  );
}
export default ContinueToKYC;
