/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import AccordionStateContext, { AccordionStateToggleContext } from '../../../../../context/AccordionContext';

const withBagsAccordionContext = (Component) => function BagsAccordionContext(props) {
  const { accordionCount } = props;
  const [activeIndex, setActiveIndex] = useState(accordionCount);

  const toggleAccordion = useCallback((index) => {
    setActiveIndex((prev) => ((prev === index) ? accordionCount : index));
  }, []);

  return (
    <AccordionStateContext.Provider value={activeIndex}>
      <AccordionStateToggleContext.Provider value={toggleAccordion}>
        <Component {...props} />
      </AccordionStateToggleContext.Provider>
    </AccordionStateContext.Provider>
  );
};

export default withBagsAccordionContext;
