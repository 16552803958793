const faqs = [
  {
    slug: 'how-does-spin-to-win-work',
    title: 'How does spin to win work?',
    description: 'The daily spin game allows you to spin a wheel every six hours for a chance to win the entire pot. If you win, the full pot amount is credited to your account. If no one claims the pot within the hour, it is distributed among all participants who spun the wheel during that hour.',
    blocks: [
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
    ],
  },
  {
    slug: 'how-is-the-pot-amount-generated',
    title: 'How is the pot amount generated?',
    description: 'The pot amount is generated by adding 0.1% of all trades made within each hour to the pot. This means that for every trade executed on the platform during the hour, a small percentage of that trade\'s value is contributed to the pot. As more trades occur, the pot grows incrementally.',
    blocks: [
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
    ],
  },
  {
    slug: 'how-does-spin-to-win-work',
    title: 'How does spin to win work?',
    description: 'The daily spin game allows you to spin a wheel every six hours for a chance to win the entire pot. If you win, the full pot amount is credited to your account. If no one claims the pot within the hour, it is distributed among all participants who spun the wheel during that hour.',
    blocks: [
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
    ],
  },
  {
    slug: 'how-is-the-pot-amount-generated',
    title: 'How is the pot amount generated?',
    description: 'The pot amount is generated by adding 0.1% of all trades made within each hour to the pot. This means that for every trade executed on the platform during the hour, a small percentage of that trade\'s value is contributed to the pot. As more trades occur, the pot grows incrementally.',
    blocks: [
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
      {
        title: null,
        body: `Lorem ipsum dolor sit amet consectetur. Odio massa semper venenatis adipiscing vitae sed semper. 
        Bibendum dignissim pulvinar fames pretium libero senectus. Et orci massa nulla ultricies egestas pretium eget.
        Tempor urna commodo sed dolor non ullamcorper. Mattis integer viverra adipiscing aliquet dui nulla fusce. Pulvinar
        in varius diam interdum purus. Non aliquet amet accumsan lacus. Tempus id venenatis dictum sollicitudin ipsum metus
        quam enim. Amet nullam sed hendrerit quis scelerisque.`,
      },
    ],
  },
];

export default faqs;
