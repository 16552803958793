/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import './styles/index.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RIBsInfoNoteIllustration from '../../../../../assets/ribs/advanced-new-to-crypto.svg';
import { RIBsQuestionNavigationHandlerContext, RIBsQuestionNavigationTreeContext } from '../RIBsQuestionStackContext';
import ButtonReuse from '../../../../fragments/Button';
import { setNewToCrypto } from '../../../../../features/ribs/application/ribsApplicationSlice';
import WillinToInvest from './WillinToInvest';
import useRIBs from '../../../../../hooks/useRIBs';
import { RIBsDrawerCloserContext } from '../../../../../context/RIBsApplicationNavigationContext';

function NewToCrypto() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const closerDrawer = useContext(RIBsDrawerCloserContext);
  const dispatchRIBsQuestionStack = useContext(RIBsQuestionNavigationHandlerContext);
  const ribsQuestionState = useContext(RIBsQuestionNavigationTreeContext);
  const { continuewAsBeginner } = useRIBs();

  function setNewToCryptoAnswer(_y_n) {
    if (_y_n) {
      continuewAsBeginner();
      history('/dashboard-earn-ribs-buy');
      closerDrawer();
    }
    dispatch(setNewToCrypto(_y_n ? 'yes' : 'no'));
    dispatchRIBsQuestionStack({
      type: 'push',
      payload: <WillinToInvest />,
    });
  }

  return (
    <div className="ribs-questions-container">

      <div className="note-illustration-container">
        <img src={RIBsInfoNoteIllustration} alt="" />
      </div>

      <p className="note-title">
        Are you new to crypto?
      </p>

      <p className="note-desc secondary-white-black-color3">
        Lorem ipsum dolor sit amet consectetur.
        Elementum euismod dolor sem donec sed pretium. Quis nunc sem dolor lectus.
      </p>

      <div className="yes-no-container">
        <ButtonReuse onClick={() => setNewToCryptoAnswer(true)} btnStyle="yes-no-btn secondary-white-black-color3" text="Beginner mode" />
        <ButtonReuse onClick={() => setNewToCryptoAnswer(false)} btnStyle="yes-no-btn secondary-white-black-color3" text="Advanced mode" />
      </div>
    </div>
  );
}

export default NewToCrypto;
