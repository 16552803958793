import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class PriceAlertService {
  static async getPriceAlerts() {
    const { response } = await post({
      url: apiRoutes.getPriceAlerts,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async createPriceAlertService(data) {
    const { response } = await post({
      url: apiRoutes.createPriceAlert,
      data,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async deletePriceAlertService(data) {
    const { response } = await post({
      url: apiRoutes.deletePriceAlert,
      data,
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }
}
