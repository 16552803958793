/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';

const initialState = {
  verificationComplete: false,
  verificationError: '',
  didResendCode: false,
  showTimer: true,
  loading: false,
  error: '',
  s_code: '',
};

export const submitCode = createAsyncThunk(
  'verification/submitcode',
  async (code, { rejectWithValue }) => {
    try {
      const email = AuthService._getEmail();
      const message = await AuthService.submitVerificationToken({
        code,
        email,
      });
      return message;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  'verification/resend',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.resendToken();
      return data.message;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const sendRegistrationPhoneCode = createAsyncThunk(
  'signup/sendRegistrationPhoneCode',
  async (phone_number, { rejectWithValue }) => {
    try {
      const data = await AuthService.sendSecurityCode({ type: 'registration', destination: phone_number });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const checkRegistrationPhoneCode = createAsyncThunk(
  'signup/checkRegistrationPhoneCode',
  async ({ phone_number, code }, { rejectWithValue }) => {
    try {
      const data = await AuthService.checkSecurityCode({ type: 'registration', destination: phone_number, code });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    restartSequence: (state) => {
      state.didResendCode = false;
      state.showTimer = true;
    },
    hideTimer: (state) => {
      state.showTimer = false;
    },
    resetError: (state) => {
      state.error = '';
      state.verificationError = '';
    },
    resetVerificationComplete: (state) => {
      state.verificationComplete = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(submitCode.fulfilled, (state, action) => {
      const { status, message: error } = action.payload;
      state.loading = false;
      if (status !== 'fail') {
        state.verificationComplete = true;
        AuthService._clearEmail();
      } else {
        state.error = error;
      }
    });
    builder.addCase(submitCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitCode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(resendVerificationCode.pending, (state) => {
      state.showTimer = false;
      state.loading = true;
    });

    builder.addCase(resendVerificationCode.fulfilled, (state) => {
      state.didResendCode = true;
      state.showTimer = true;
      state.loading = false;
    });

    builder.addCase(resendVerificationCode.rejected, (state, action) => {
      state.didResendCode = false;
      state.error = action.payload;
      state.loading = false;
    });

    // sendRegistrationPhoneCode
    builder.addCase(sendRegistrationPhoneCode.pending, (state) => {
      state.showTimer = false;
      state.loading = true;
    });
    builder.addCase(sendRegistrationPhoneCode.fulfilled, (state, action) => {
      const { data, message: error } = action.payload;
      if (data) {
        // state.s_code = data.data.code;
        state.didResendCode = true;
        state.showTimer = true;
        state.loading = false;
      } else {
        state.error = error;
      }
    });
    builder.addCase(sendRegistrationPhoneCode.rejected, (state, action) => {
      state.didResendCode = false;
      state.error = action.payload;
      state.loading = false;
    });

    // checkRegistrationPhoneCode
    builder.addCase(checkRegistrationPhoneCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkRegistrationPhoneCode.fulfilled, (state, action) => {
      const { status, message: error } = action.payload;
      state.loading = false;
      if (status !== 'fail') {
        state.verificationComplete = true;
        // AuthService._clearEmail();
      } else {
        state.verificationError = error;
      }
    });
    builder.addCase(checkRegistrationPhoneCode.rejected, (state, action) => {
      state.loading = false;
      state.verificationError = action.payload;
    });
  },
});

export const selectVerification = (state) => state.verification;

export default verificationSlice.reducer;
export const {
  restartSequence, hideTimer,
  resetError: resetVerificationError, resetVerificationComplete
} = verificationSlice.actions;
