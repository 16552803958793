/* eslint-disable import/prefer-default-export */
import { Dashboard_home_route_group } from './home';
import { Dashboard_wallet_route_group } from './wallet';
import { Help_support_route_group } from './help&support';
import { Dashboard_settings_route_group } from './setting';
import { Dashboard_transactions_route_group } from './transactions';
import { Dashboard_cards_route_group } from './cards';
import { Dashboard_payment_link_route_group } from './payment-link';
import { Dashboard_nfts_route_group } from './nfts';
import { Dashboard_get_started_route_group } from './get-started';
import { Dashboard_exchange_route_group } from './exchange';
import { Dashboard_hub_route_group } from './hub';
import { Dashboard_earn_route_group } from './earn';

export const Dashboard_route_group = [
  ...Dashboard_home_route_group,
  ...Dashboard_wallet_route_group,
  ...Dashboard_settings_route_group,
  ...Dashboard_transactions_route_group,
  ...Help_support_route_group,
  ...Dashboard_cards_route_group,
  ...Dashboard_payment_link_route_group,
  ...Dashboard_nfts_route_group,
  ...Dashboard_get_started_route_group,
  ...Dashboard_exchange_route_group,
  ...Dashboard_hub_route_group,
  ...Dashboard_earn_route_group,
];
