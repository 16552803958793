/* eslint-disable react/react-in-jsx-scope */
import RoqqDialogDivider from '../ui/RoqqDialogDivider/RoqqDialogDivider';
import RoqqTextBox from '../ui/RoqqTextBox/RoqqTextBox';
import RoqqTextTile from '../ui/RoqqTextTile/RoqqTextTile';
import './ClaimYourPrize.css';

export default function ClaimYourPrize() {
  return (
    <div className="claim-your-prize">
      <RoqqTextTile text="Sit back and relax, we are verifying your social media share. Your 20 USDT will be credited to you in a flash after verification." />
      <RoqqDialogDivider margin="24px 0 16.66px" />
      <p className="txt-label">Url Link</p>
      <RoqqTextBox placeholder="Enter link" />
    </div>
  );
}
