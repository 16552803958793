/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import './RoqqNRollHome.css';
import { useState } from 'react';
import RoqqDialog from '../ui/RoqqDialog/RoqqDialog';
import RoqqPrimaryButton from '../ui/RoqqPrimaryButton/RoqqPrimaryButton';
import EarnRubies from '../EarnRubies/EarnRubies';
import InsufficientRubies from '../InsufficientRubies/InsufficientRubies';
import ClaimYourPrize from '../ClaimYourPrize/ClaimYourPrize';

import BalanceModal from '../ui/modals/balance-modal/BalanceModal';
import ClaimSuccessfulModal from '../ui/modals/claim-succesful-modal/ClaimSuccessfulModal';
import TransferCurrencyModal from '../ui/modals/transfer-currency-modal/TransferCurrencyModal';
import ErrorModal from '../ui/modals/error-modal/ErrorModal';
import RoqqDiamondTile from '../ui/RoqqDiamondTile/RoqqDiamondTile';
import CountdownTimer from '../ui/CountdownTimer/CountdownTimer';
import RoqqDialogDivider from '../ui/RoqqDialogDivider/RoqqDialogDivider';
import WheelRefuel from '../ui/modals/wheel-refuel/WheelRefuel';

export default function RoqqNRollHome() {
  const [showModal, setShowmodal] = useState(false);

  function toggleModal() {
    setShowmodal((current) => !current);
  }

  return (
    <div className="roqq-n-roll-home">
      <RoqqDialog
        show={showModal}
        onClose={toggleModal}
        headerText="Insufficient Rubies"
        showBackBtn
        positiveBtnText="Play On"
        negativeBtnText="Go To Dashboard"
      >
        <InsufficientRubies />
      </RoqqDialog>

      <ClaimYourPrize />
      <RoqqPrimaryButton text="toggle dialog" onClick={toggleModal} />

      <ClaimSuccessfulModal />
      <BalanceModal />
      <TransferCurrencyModal />
      <ErrorModal />
      {/* <WheelRefuel onClose={toggleModal} show={showModal} /> */}
    </div>
  );
}
