/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/dashboard/Select.css';
// import angleDownIcon from '../../../assets/angle-down-icon.svg';
import SelectOptions from './Options';
import Portal from '../../widgets/Portal';
import { useIcon } from '../../../hooks/useIcon';
import useWindowSize from '../../../hooks/useWindowSize';
import InputGroupLabel from '../InputGroup/components/InputGroupLabel';

function Select({
  options = [],
  // eslint-disable-next-line no-unused-vars
  value,
  onChange = () => {},
  canSearch = false,
  placeholder = 'Select Item',
  searchPlaceholder = '',
  emptyMsg = 'No Item Found',
  fullscreen = false,
  // Classnames that affect the placeholder and emptyMsg
  textClassNames = '',
  outsideLabelText,
  labelText = '',
  // For label inside button start
  hasLabel = false,
  // For label inside button end
  // For label outside button start
  outsideLabel = false,
  // For label outside button end
  darkBg = false,
  hasOptionImage = false,
  optionImageKey = 'img',
  // Added props for styling
  classNames,
  iconClassNames,
  icon,
  // These props below allow the parent to change the button JSX
  customDropdownBody = false,
  customDropdownBodyJSX = null,
  // Position of the options container 'left' | 'right'
  optionsHorizontalPosition = 'left',
  chevIcon,
  doesChevIconHaveBackgroundColor = true,
  selectedTextClassNames = ''
}) {
  const { filledChevDown } = useIcon();
  const [optionsOpen, setOptionsOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selected, setSelected] = React.useState(
    // eslint-disable-next-line comma-dangle
    // options.find((it) => it.value === value)
    ''
  );

  useEffect(() => {
    setSelected(value ? options?.find((it) => it.value === value) || '' : '');
  }, [options]);

  const closeSelectoptions = () => {
    setSearchQuery('');
    setOptionsOpen(false);
  };

  const onSelectChange = (vlu) => {
    setSearchQuery('');
    setSelected(options.find((d) => d.value === vlu));
    onChange(vlu);
    closeSelectoptions();
  };

  let heightStyles = {};

  if (options.length < 10) {
    heightStyles = {
      maxHeight: `${(options.length * 56) / 10 + 0.9}rem`,
      minHeight: `${(options.length * 56) / 10 + 0.9}rem`,
    };
  }

  const blurHandler = () => {
    closeSelectoptions();
    setSearchQuery('');
  };

  const onSearchHandler = (allOptions) => {
    if (searchQuery) {
      const _searchQuery = searchQuery?.toLowerCase();
      const _allOptions = allOptions?.filter(
        (option) =>
          option.text?.toLowerCase().includes(_searchQuery) ||
          option.value?.toLowerCase().includes(_searchQuery)
      );
      return _allOptions;
    }

    return allOptions;
  };

  useEffect(() => {
    onSearchHandler();
  }, [searchQuery]);

  const buttonRef = useRef(null);

  const [buttonCoordinates, setButtonCoordinates] = useState(null);

  const { windowSize } = useWindowSize();

  useEffect(() => {
    setButtonCoordinates(buttonRef?.current?.getBoundingClientRect());
  }, [buttonRef, windowSize]);

  return (
    <div className="select default-color">
      {outsideLabel && (
        <InputGroupLabel
          onClick={() => setOptionsOpen(!optionsOpen)}
          label={outsideLabelText || labelText}
        />
      )}
      {!customDropdownBody ? (
        <button
          ref={buttonRef}
          className={`select-button ${
            darkBg ? 'input-box-in-container-color' : ''
          } ${hasLabel ? 'with-label' : ''} ${outsideLabel ? 'with-out-label' : ''} ${classNames}`}
          onClick={() => setOptionsOpen(!optionsOpen)}
          type="button"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <p className="option-label-box">
            {
            hasLabel && (
            <span className="label secondary-white-black-color">
              {labelText}
            </span>
            )
          }
            <span className={`selected-option white-black-color ${selected?.text ? selectedTextClassNames : textClassNames}`}>
              {selected?.text
                ? selected?.text
                : options.length === 0
                ? emptyMsg
                : placeholder}
            </span>
          </p>
          <figure style={{ backgroundColor: !doesChevIconHaveBackgroundColor ? 'unset' : '' }} className={`icon ${iconClassNames}`}>
            {
            icon ?? <img src={chevIcon || filledChevDown} alt="" />
            }
          </figure>
        </button>
      ) : (
        customDropdownBodyJSX({
          ref: buttonRef,
          onClick: () => setOptionsOpen(!optionsOpen)
        })
      )}
      {!fullscreen ? (
        <SelectOptions
          hasOptionImage={hasOptionImage}
          optionImageKey={optionImageKey}
          darkBg={darkBg}
          fullscreen={fullscreen}
          optionsOpen={optionsOpen}
          heightStyles={heightStyles}
          canSearch={canSearch}
          placeholder={searchPlaceholder}
          setSearchQuery={setSearchQuery}
          blurHandler={blurHandler}
          onSearchHandler={onSearchHandler}
          options={options}
          selected={selected}
          onSelectChange={onSelectChange}
          closeSelectoptions={closeSelectoptions}
          horizontalPosition={optionsHorizontalPosition}
          customDropdownBody={customDropdownBody}
        />
        ) : (
          <Portal selector="#select-options">
            <SelectOptions
              hasOptionImage={hasOptionImage}
              optionImageKey={optionImageKey}
              darkBg={darkBg}
              coordinates={buttonCoordinates}
              fullscreen={fullscreen}
              optionsOpen={optionsOpen}
              heightStyles={heightStyles}
              canSearch={canSearch}
              placeholder={searchPlaceholder}
              setSearchQuery={setSearchQuery}
              blurHandler={blurHandler}
              onSearchHandler={onSearchHandler}
              options={options}
              selected={selected}
              onSelectChange={onSelectChange}
              closeSelectoptions={closeSelectoptions}
              horizontalPosition={optionsHorizontalPosition}
              customDropdownBody={customDropdownBody}
            />
          </Portal>
        )}
    </div>
  );
}

export default Select;
