/* eslint-disable import/prefer-default-export */

import React from 'react';

const NftHomePage = React.lazy(() => import('../../../pages/dashboard/nfts'));
const NftDetailsPage = React.lazy(() => import('../../../pages/dashboard/nfts/NftDetails'));
const UserProfile = React.lazy(() => import('../../../pages/dashboard/nfts/UserProfile'));
const Confirmation = React.lazy(() => import('../../../pages/dashboard/nfts/Confirmation'));
const ConfirmationCreateNFTs = React.lazy(() => import('../../../pages/dashboard/nfts/ConfirmationCreateNFTs'));
const SearchNft = React.lazy(() => import('../../../pages/dashboard/nfts/SearchNft'));
const CreateSingleNFTsAndNFTCollections = React.lazy(() => import('../../../pages/dashboard/nfts/CreateSingleNFTsAndNFTCollections'));
const Collection = React.lazy(() => import('../../../pages/dashboard/nfts/Collection'));
const CreatorsProfile = React.lazy(() => import('../../../pages/dashboard/nfts/CreatorsProfile'));
const EnterNftDetail = React.lazy(() => import('../../../pages/dashboard/nfts/EnterNftDetails'));

export const Dashboard_nfts_route_group = [
  { path: '/dashboard-nfts', component: <NftHomePage /> },
  { path: '/dashboard-nfts-details', component: <NftDetailsPage /> },
  { path: '/dashboard-nfts-user-profile', component: <UserProfile /> },
  { path: '/dashboard-nfts-bid-confirmation', component: <Confirmation /> },
  {
    path: '/dashboard-nfts-create-nfts-confirmation',
    component: <ConfirmationCreateNFTs />,
  },
  { path: '/dashboard-nfts-search-results', component: <SearchNft /> },
  { path: '/dashboard-nfts-collections', component: <Collection /> },
  { path: '/dashboard-nfts-creators-profile', component: <CreatorsProfile /> },
  {
    path: '/dashboard-nfts-create-nfts-single-collections',
    component: <CreateSingleNFTsAndNFTCollections />,
  },
  {
    path: '/dashboard-nfts-enter-nft-details-upload',
    component: <EnterNftDetail />,
  },
];
