/* eslint-disable max-len */
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class BillPaymentService {
  // Cable Bill services start here
  static async getCablePlans(providerName, country) {
    const response = await post({
      url: apiRoutes.getCableSubscriptions,
      data: {
        token: AuthService._getUserToken(),
        provider: providerName,
        country,
      },
    });
    return response.response.data.data;
  }

  static async verifyIUCnumber(cardNumber, providerName, country) {
    try {
      const response = await post({
        url: apiRoutes.verifyIUCnumber,
        data: {
          token: AuthService._getUserToken(),
          provider: providerName,
          iuc: cardNumber,
          country,
        },
      });
      return response.response.data;
    } catch (e) {
      return e.toString();
    }
  }

  /**
   * @param data
   * @type object
   * @interface { provider: string, country: string, sub_plan: string, currency: string, phone: string, iuc: string }
   */

  static async rechargeCable(data) {
    const response = await post({
      url: apiRoutes.rechargeCable,
      data: {
        ...data,
        token: AuthService._getUserToken(),
      },
    });
    if (response.response === false) {
      return response;
    }
    return response;
  }

  // Electricity Bill services start here

  static async getElectricityProviders({ country }) {
    const response = await post({
      url: apiRoutes.getElectricProvider,
      data: {
        token: AuthService._getUserToken(),
        country,
      },
    });
    if (!response.response && response.error) {
      throw new Error(response.error.response.data.message);
    }
    return response;
  }

  /**
   * @param param0
   * @type object
   * @interface { provider: string, country: string, providerName: string, meterType: string, meterNumber: string }
   */

  static async verifyElectricMeter({
    meterNumber, meterType, country, providerName,
  }) {
    try {
      const response = await post({
        url: apiRoutes.verifyMeter,
        data: {
          token: AuthService._getUserToken(),
          provider: providerName,
          country,
          meter_no: meterNumber,
          meter_type: meterType,
        },
      });
      if (response.error === true) {
        return 'error';
      }
      return response.response.data;
    } catch (e) {
      return e.toString();
    }
  }

  /**
   * @param param0
   * @type object
   * @interface { provider: string, country: string, providerName: string, meterType: string, meterNumber: string }
   */
  static async rechargeElectricMeter({
    provider, meterNumber, meterType, amount, phone, currency, country, pin,
  }) {
    const response = await post({
      url: apiRoutes.rechargeElectricityMeter,
      data: {
        provider,
        amount,
        phone,
        currency,
        token: AuthService._getUserToken(),
        country,
        meter_no: meterNumber,
        meter_type: meterType,
        ...pin,
      },
    });

    if (response.response === false) {
      return response;
    }
    return response;
  }

  // Data Bill service starts here

  /**
   * @param param0
   * @type object
   * @interface { provider: string, currency: string, country: string, phone: string, sub_plan: string }
   */
  static async buyData({
    currency, phone, provider, sub_plan, country, pin,
  }) {
    const response = await post({
      url: apiRoutes.loadData,
      data: {
        token: AuthService._getUserToken(),
        provider,
        currency,
        country,
        phone,
        sub_plan,
        ...pin,
      },
    });

    if (response.response === false) {
      // throw new Error('failed to get user wallet');
      return response;
    }
    return response;
  }

  // Airtime Bill service starts here

  /**
   * @param param0
   * @type object
   * @interface {  phone: string, provider: string, amount: string, currency: string, country: string }
   */
  static async buyAirtime({
    currency, phone, amount, country, provider, pin,
  }) {
    const response = await post({
      url: apiRoutes.loadAirtime,
      data: {
        token: AuthService._getUserToken(),
        phone,
        provider,
        amount,
        currency,
        country,
        ...pin,
      },
    });

    if (response.response === false) {
      throw new Error('failed to get user wallet');
    }
    return response;
  }
}
