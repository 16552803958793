/* eslint-disable react/prop-types */
import React from 'react';

function InputMessage({ inputMessage }) {
  return (
    <div className="error-message message secondary-white-black-color">
      {inputMessage}
    </div>
  );
}

export default InputMessage;
