/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Helpers from '../utils/Helpers';
import ribsService from '../services/ribsService';
import { LoadingStates } from './ribsGlobalSlice';

const initialState = {
  withdrawalAmount: '',
  bag_reference: null,
  data: null,
  error: false,
  status: LoadingStates.BASE,
};

// eslint-disable-next-line no-console

export const dumpBagPartially = createAsyncThunk(
  'user/dumpBagPartially',
  async (_data, { getState, rejectWithValue }) => {
    try {
      const { data } = await ribsService.dumpBagPartially(_data);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const dumpBagSingleToken = createAsyncThunk(
  'user/dumpBagSingleToken',
  async (_data, { getState, rejectWithValue }) => {
    try {
      const { data } = await ribsService.dumpBagSingleToken(_data);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const ribsWithdrawSlice = createSlice({
  name: 'ribsWithdrawMoney',
  initialState,
  reducers: {
    setWithdrawalAmount(state, { payload }) {
      if (`${payload}`.length < 1) {
        state.withdrawalAmount = 0;
        return;
      }
      const amount = Helpers.stripCommas(`${payload}`);
      state.withdrawalAmount = amount;
    },
    setBagReference(state, { payload }) {
      state.bag_reference = payload;
    },
    resetAll(state) {
      state.withdrawalAmount = '';
      state.bag_reference = null;
      state.pin = null;
    },
    resetAllBagWithdrawalState(state) {
      state.withdrawalAmount = '';
      state.bag_reference = null;
      state.pin = null;
      state.data = null;
      state.error = false;
      state.status = LoadingStates.BASE;
    },
  },
  extraReducers: (builder) => {
    // dumpBagPartially reducer
    builder.addCase(dumpBagPartially.pending, (state) => {
      state.status = LoadingStates.LOADING;
    });
    builder.addCase(dumpBagPartially.fulfilled, (state, action) => {
      state.status = LoadingStates.SUCCESSFUL;
      state.data = action.payload;
    });
    builder.addCase(dumpBagPartially.rejected, (state, action) => {
      state.status = LoadingStates.ERROR;
      state.error = action.payload;
    });
    // dumpBagSingleToken reducer
    builder.addCase(dumpBagSingleToken.pending, (state) => {
      state.status = LoadingStates.LOADING;
    });
    builder.addCase(dumpBagSingleToken.fulfilled, (state, action) => {
      state.status = LoadingStates.SUCCESSFUL;
      state.data = action.payload;
    });
    builder.addCase(dumpBagSingleToken.rejected, (state, action) => {
      state.status = LoadingStates.ERROR;
      state.error = action.payload;
    });
  },
});

export const RIBsWithdrawMoney = (state) => state.ribsWithdrawMoney;

export const {
  setWithdrawalAmount,
  setBagReference,
  resetAll, resetAllBagWithdrawalState,
} = ribsWithdrawSlice.actions;
export default ribsWithdrawSlice.reducer;
