/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

import './RoqqPrimaryButton.css';

function RoqqPrimaryButton({
  className, text = '', onClick = () => { }, isDisabled = false,
}) {
  return (
    <div onClick={onClick} className={`roqq-primary-container ${isDisabled ? 'isdisabled' : ''} ${className || ''}`}>
      <div className="roqq-primary-wrapper-1">
        <div className="roqq-primary-wrapper-2">
          {text}
        </div>
      </div>
    </div>
  );
}

export default RoqqPrimaryButton;
