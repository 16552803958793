/* eslint-disable import/prefer-default-export */

import React from 'react';

/* eslint-disable-next-line max-len */
const DashboardWallet = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio'));
const DepositConfirmTransaction = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/deposit/DepositConfirmTransaction'));
const WalletDepositPage = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/deposit/WalletDeposit'));
const WalletTransferBank = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/transfer/WalletTranferBank'));
const WalletTransfer = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/transfer/WalletTransfer'));
const WalletTransferYourself = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/transfer/WalletTransferYourself'));
const ConfirmTransaction = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/withdraw/ConfirmTransaction'));
const ConfirmTransfer = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/withdraw/ConfirmTransfer'));
const WalletWithdraw = React.lazy(() => import('../../../pages/dashboard/wallet-portfolio/withdraw/WalletWithdraw'));
const WalletRibs = React.lazy(() => import('../../../pages/dashboard/ribs/index'));

export const Dashboard_wallet_route_group = [
  { path: '/dashboard-wallet', component: <DashboardWallet /> },
  { path: '/dashboard-wallet-deposit', component: <WalletDepositPage /> },
  { path: '/dashboard-wallet-deposit-confirm-transaction', component: <DepositConfirmTransaction /> },
  { path: '/dashboard-wallet-withdraw', component: <WalletWithdraw /> },
  { path: '/dashboard-wallet-withdraw-confirm-transaction', component: <ConfirmTransaction /> },
  { path: '/dashboard-wallet-transfer', component: <WalletTransfer /> },
  { path: '/dashboard-wallet-transfer-yourself', component: <WalletTransferYourself /> },
  { path: '/dashboard-wallet-transfer-bank-account', component: <WalletTransferBank /> },
  { path: '/dashboard-wallet-confirm-transfer', component: <ConfirmTransfer /> },
  { path: '/dashboard-wallet-ribs', component: <WalletRibs /> },
  // { path: '/dashboard-wallet-fiat-transfer', component: LocalFoeignTransfer },
];

// Withdraw, Transfer, and Deposit is handled as drawers on the
// `/Dashboard-wallet` route
