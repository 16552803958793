import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class LoanService {
  static async getUserOverdraft(token) {
    const { response } = await post({
      url: apiRoutes.getUserOverdraft,
      data: {
        token,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async applyOverdraft({
    amount, coin, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.applyOverdraft,
      data: {
        token: AuthService._getUserToken(),
        amount,
        coin,
        ...pin,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async repayOverdraft({
    amount, pin,
  }) {
    const { response } = await post({
      url: apiRoutes.paybackOverdraft,
      data: {
        token: AuthService._getUserToken(),
        amount,
        ...pin,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }

  static async getOverdraftConfigService(token) {
    const { response } = await post({
      url: apiRoutes.overdraftSettings,
      data: {
        token,
      },
    });
    if (response === false) {
      throw new Error('some error');
    }
    return response;
  }
}
