/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import Cookies from 'js-cookie';
import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import * as Keys from '../utils/storageKeys';

export default class AuthService {
  static async signUp(data) {
    AuthService._saveEmail(data.email);

    const response = await post({
      url: apiRoutes.register,
      data: {
        ...data,
        username: data.username.trim(),
        fname: data.fname.trim(),
        lname: data.lname.trim(),
      },
    });
    /**
     * [[ INSTRUCTION PERTAINING TO THE IF BLOCK STARTING ON THE NEXT LINE ]]
     * I'm checking if response.response === false or response.response.data.status === "fail"
     * because a failed request will return a response object that has a property response
     * which has a value of false. This code below is therefore, strictly dependent on
     * the structure of the data being returned from the server and if it changes at
     * some point, we'll need to update this code
     */

    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }
    AuthService._saveToken(response.response.data.data);
    // AuthService._saveUserPassword(data.password);

    return response.response.data;
  }

  static async entryVerify(identifier) {
    const response = await post({
      url: apiRoutes.entryVerify,
      data: {
        identifier,
      },
    });

    if (!response.response) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }

  static async sendSecurityCode({ type, destination }) {
    const response = await post({
      url: apiRoutes.sendSecurityCode,
      data: {
        type, destination,
      },
    });

    if (!response.response) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }

  static async checkSecurityCode({ type, destination, code }) {
    const response = await post({
      url: apiRoutes.checkSecurityCode,
      data: {
        type, destination, code,
      },
    });

    if (!response.response) {
      throw new Error(response.error.response.data.message);
    }

    return response.response;
  }

  static async sendForgotPasswordMail(email) {
    const { response } = await post({
      url: apiRoutes.passwordReset,
      data: {
        email,
      },
    });

    if (!response) {
      throw new Error(response.error.message);
    }

    return response;
  }

  static async signOut(email) {
    const { response } = await post({
      url: apiRoutes.signOut,
      data: {
        email,
      },
    });

    if (response === false) {
      throw new Error(response.error.message);
    }
    AuthService._signOutClear();

    return response.data;
  }

  static async submitVerificationToken(data) {
    const response = await post({
      url: '/user/verify/email',
      data,
    });
    if (response.response === false) {
      throw new Error(response.error.message);
    }
    return response.response.data;
  }

  static async resendToken() {
    const { response } = await post({
      url: apiRoutes.resendVerEmail,
      data: {
        token: AuthService._getUserToken(),
      },
    });
    if (response === false) {
      throw new Error(response.error.message);
    }
    if (response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response;
  }

  static async setTransactionPin(pin, password = '', checkPassword = true) {
    if (checkPassword) {
      // eslint-disable-next-line no-param-reassign
      password = AuthService._getPassword();
      if (password === null) {
        throw new Error('Incorrect password for user');
      }
    }
    const response = await post({
      url: '/user/set-pin',
      data: { pin, token: AuthService._getUserToken(), password },
    });
    if (response.response === false) {
      throw new Error(response.error.message);
    }
    if (checkPassword) {
      return AuthService._handleResponse(response);
    }
    return response.response;
  }

  static async fetchUser() {
    try {
      const response = await post({
        url: apiRoutes.getUserInfo,
        data: {
          token: AuthService._getUserToken(),
        },
        error: (resp) => {
          // this gets called before catch Error throw
          throw new Error(resp.data.message);
        },
      });
      if (response.response === false) {
        throw new Error(response.error.message);
      }
      return response.response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async setup2FAService({ token, pin }) {
    const { response } = await post({
      url: apiRoutes.setUp2FA,
      data: {
        token,
        pin, // users pin...
      },
    });
    return response.data;
  }

  static async confirm2FAService({ code, pin }) {
    const { response } = await post({
      url: apiRoutes.confirm2FA,
      data: {
        token: AuthService._getUserToken(),
        code, // code from 2fa app...
        pin,
      },
    });
    return response;
  }

  static async enable2FAService({ code, pin }) {
    const { response } = await post({
      url: apiRoutes.enable2FA,
      data: {
        token: AuthService._getUserToken(),
        code, // code from 2fa app...
        pin,
      },
    });
    return response;
  }

  static async disable2FAService({ code, pin }) {
    const { response } = await post({
      url: apiRoutes.disable2FA,
      data: {
        token: AuthService._getUserToken(),
        code, // code from 2fa app...
        pin,
      },
    });
    return response;
  }

  static async reset2FAService(pin) {
    const { response, error } = await post({
      url: apiRoutes.reset2FA,
      data: {
        token: AuthService._getUserToken(),
        pin,
      },
    });
    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response;
  }

  static async reset2FAConfirm({ s_code, e_code, auth }) {
    const { response } = await post({
      url: apiRoutes.reset2FAConfirm,
      data: {
        token: AuthService._getUserToken(),
        s_code,
        e_code,
        auth,
      },
    });
    if (response === false) {
      throw new Error('Could not reset your 2FA');
    }
    return response;
  }

  static setIsFirstTimeUser(value) {
    Cookies.set(Keys.FIRST_TIME_USER, value);
  }

  static getIsFirstTimeUser() {
    return Cookies.getItem(Keys.FIRST_TIME_USER);
  }

  static _handleResponse(response) {
    if (response.response === false) {
      throw new Error(response.error.message);
    }
    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.message;
  }

  static _saveUserPassword(password) {
    sessionStorage.setItem(Keys.PASSWORD, password);
  }

  static _saveToken(token) {
    localStorage.setItem(Keys.TOKEN, token);
  }

  static _saveUser(data) {
    localStorage.setItem(Keys.USER, JSON.stringify(data));
  }

  static _savePrimaryCurrency(data) {
    localStorage.setItem(Keys.PRIMARYCURRENCY, JSON.stringify(data));
  }

  static _saveEmail(email) {
    sessionStorage.setItem(Keys.EMAIL_TEMP, email);
  }

  static _getUserToken() {
    return localStorage.getItem(Keys.TOKEN);
  }

  // Are we storing password in sessionStorage?
  static _getPassword() {
    return sessionStorage.getItem(Keys.PASSWORD);
  }

  static _getUser() {
    return JSON.parse(localStorage.getItem(Keys.USER));
  }

  static _getPrimaryCurrency() {
    return JSON.parse(localStorage.getItem(Keys.PRIMARYCURRENCY));
  }

  static _getEmail() {
    return sessionStorage.getItem(Keys.EMAIL_TEMP);
  }

  static _clearPrimaryCurrency() {
    localStorage.removeItem(Keys.PRIMARYCURRENCY);
  }

  static _clearEmail() {
    sessionStorage.removeItem(Keys.EMAIL_TEMP);
  }

  static _clearPassword() {
    sessionStorage.removeItem(Keys.PASSWORD);
  }

  static _clearToken() {
    localStorage.removeItem(Keys.TOKEN);
  }

  static _clearUser() {
    localStorage.removeItem(Keys.USER);
  }

  static _signOutClear() {
    AuthService._clearToken();
    AuthService._saveUserPassword();
    AuthService._clearUser();
    AuthService._clearEmail();
    AuthService._clearPrimaryCurrency();
  }
}
