// import { icons } from "react-icons";
import { createGlobalStyle } from 'styled-components';
import '../../index.css';

export const GlobalStyles = createGlobalStyle`
:root {
  --theme-ui-color: ${({ theme }) => theme.custom_them_color};
}

body, .default-background, .white-black-bg, .default-drop-arrow::before, .default-drop-arrow::after, .yes-no-btn,
.myribs-box-container.recommended
  .myribs-body
  .bag-details
  .bagname-coin
  .coins
  .coin-preview-item, .ribs-bag-coins .coin-preview-item.body,
  .ribs-bag-dropdown.for-inspect
  .dropdown-body
  .table-section
  .ribs-table-header-section,
.beginner-cancel-confirmation.for-inspect
  .dropdown-body
  .table-section
  .ribs-table-header-section{
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text_color};
}

.dashboard-home-wrap .top-box .left-box .quick-action-box .action-box-wrap .box,
.btn-default-background {
  background-color: ${({ theme }) => theme.quick_action_box} !important;
  color: ${({ theme }) => theme.text_color};
}

.header-nav-content .page-title .page-title-text {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.getting-started-action-card, .dashboard-get-started .download-section,
.dashboard-get-started .learn-more .tutorial-articles,
.dashboard-get-started .news-section .news-articles,
.dashboard-news-index-wrap .news-articles, .auth-layout-wrap .auth-search-box, .myribs-box-container:not(.recommended),
.myribs-box-container:not(.recommended)
  .myribs-body
  .bag-details
  .bagname-coin
  .coins
  .coin-preview-item, .ribs-bag-coins .coin-preview-item.body_highlight,
.ribs-bag-dropdown .dropdown-body .table-section .ribs-table-header-section,
.myribs-box-container.recommended .myribs-footer:not(.not-default-type), .ribs-table-container,
.card-bg,
.ribs_table-flow-wrapper .wrapper-header,
.addcoin-drawer-body, .convert-drawer-container, .subflow-container .background-wrapper, .subflow-master .section__main, .pay-bill-wrap-two,
.paybill-in-drawer,
.add-new-currency-wrapper .add-new-currency-wrapper-inner {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.bag-distribution-box-item
  .main-details-section
  .coin-percentage
  .percentage-contianer
  .percentage-bar {
  background-color: ${({ theme }) => theme.body_highlight};
}

.username-drawer, .dashboard-setting-wrap {
  background-color: ${({ theme }) => theme.body_highlight};
}



.getting-started-action-card .content .desc, .dashboard-get-started .download-section .content .download-desc, .dashboard-get-started .welcome-header-subtitle,
.ribs-wrapper
  .ribs-page-section
  .ribs-filter-section
  .filter-section
  .filter-icons
  .icon-body, .ribs-table-container .ribs-table-row.ribs-table-header .ribs-row-cell,
  .ribs-wrapper
  .ribs-inspect-overview-container
  .bag-details-section
  .bag-coins-display-table
  .bag-coins-display-table-row
  .coins-display-table-row-item,
.raffle-watch-dialog .watch-dialog-aside .stage-steps .step-item {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.username-drawer .username-subheader {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.dashboard-get-started .learn-more .learn-more-header .view-more-btn, .dashboard-get-started .news-section .news-section-header .view-more-btn {
  color: ${({ theme }) => theme.chill_blue} !important;
}

.blog-article,
.news-article {
  background-color: ${({ theme }) => theme.nft_card} !important;
}

.blog-article .content .article-desc,
.news-article .content .article-desc {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.dashboard-wallet-wrap .top-box {
  background-color: ${({ theme }) => theme.body} !important;
}

.white-black-bg-container {
  background-color: ${({ theme }) => theme.body_container} !important;
  color: ${({ theme }) => theme.text_color};
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: ${({ theme }) => theme.text_color};
  caret-color: ${({ theme }) => theme.text_color};
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

body *, ::before {
  border-color: ${({ theme }) => theme.border_color} !important;
}

.ribs-bag-dropdown .dropdown-body, .ribs-summary-container, .ribs-summary-container .summary-item {
  border-color: ${({ theme }) => theme.border_color} !important;
}

.border-light, .card-bg  {
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.wot-coin-percent-no-color  {
  background-color: ${({ theme }) => theme.border_color2} !important;
}

.border-none {
  border: none !important;
}

.ribs-wrapper .fixed-buy-bag-wrapper {
  background-color: ${({ theme }) => theme.border_color2} !important;
}

.ribs-questions-container .ribs-bags-container .bag-item,
.ribs-questions-container
  .ribs-bags-container
  .bag-item
  .decription-section
  .coin-preview .coin-preview-item {
  border-color: ${({ theme }) => theme.border_color2} !important;
  background-color: ${({ theme }) => theme.border_color2} !important;
}

.border-light2 {
  border-color: ${({ theme }) => theme.input_stroke} !important;
}


.input, ::placeholder, .value {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.input-dark-only {
  color: #555C63 !important;

}

.side-bar, .tooltip-container, .price-alert-icon-container, .profile-container {
  background-color:  ${({ theme }) => theme.side_nav_background} !important;
}

.blue-color, .reg-user-preferences
  .preferences-section
  .p-section-items
  .p-section-item.active,
.ribs-wrapper
  .ribs-page-section
  .ribs-filter-section
  .filter-section
  .filter-icons
  .icon-body.active {
  color: ${({ theme }) => theme.chill_blue} !important;
}

.ribs-amount-suggestions .amount-suggestion.active,
.ribs-table-container
  .ribs-table-row
  .inner-row
  .select-active-years
  .a-period.active {
  border-color: ${({ theme }) => theme.chill_blue} !important;
}

.ribs-buy-bag-flow-wrapper
  .buy-bag-flow-stage-container
  .step-progress-indicator
  .step-progress-bar.active {
  background-color: ${({ theme }) => theme.chill_blue} !important;
}

.toggle-blue-color  {
  color: ${({ theme }) => theme.blue_color} !important;
}

.alert-color {
  color: ${({ theme }) => theme.alert_color} !important;
}

.toggle-help-link  {
  color: ${({ theme }) => theme.link_color} !important;
}


.card-color, .auth-email-list-container {
  background-color: ${({ theme }) => theme.body_highlight} !important;
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.notice-box {
  background-color: ${({ theme }) => theme.notice_color} !important;
}

.card-color2 {
  background-color: ${({ theme }) => theme.card_color2} !important;
  // border-color: ${({ theme }) => theme.card_color2_border} !important;
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.ribs-inspect-chart .ribs-chart-container .chart-action-container .bag-action-btn,
.beginner-chart-view .top-section .bag-button-section .bag-action-btn,
.beginner-chart-view .top-section.mbl .section-row .bag-action-btn,
.wheel-spin-next-container .note-box {
  background-color: ${({ theme }) => theme.card_color2} !important;
  border-color: ${({ theme }) => theme.card_color2_border} !important;
}

.ribs-inspect-chart
  .ribs-chart-container
  .chart-action-container
  .bag-coin-action-btn, .bag-coin-action-btn {
  background-color: ${({ theme }) => theme.body_container} !important;

  }

.card-color2-border-active {
  border-color: ${({ theme }) => theme.underline} !important;
}

.card-color-border {
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.card-color:hover {
  background-color: ${({ theme }) => theme.card_color_hover} !important;
  border-color: ${({ theme }) => theme.card_color_hover} !important;
}

.card-hover:hover {
  background-color: ${({ theme }) => theme.profile_card_color} !important;
}

.item-hover:hover {
  background-color: ${({ theme }) => theme.body_highlight2} !important;
}

.background-highlight {
  background-color: ${({ theme }) => theme.profile_card_color} !important;
}

.background-faint-danger {
  background-color: ${({ theme }) => theme.faint_danger} !important;
}


.list-hover:hover, .list-hover:active, .child-row-active, .item-wrap-active {
  background-color: ${({ theme }) => theme.card_color_hover} !important;
}

.white-black-color{
 color: ${({ theme }) => theme.text_color} !important;
}

.black-white-bg {
  background-color: ${({ theme }) => theme.black_white_bg} !important;
}

.white-black-color > *{
 color: ${({ theme }) => theme.text_color} !important;
}

.secondary-white-black-color,
.dashboard-loan-wrapper .loan-main .history-table thead th,
.dashboard-loan-wrapper
  .loan-main
  .header-section
  .tab-item-container
  .tab-item:not(.active),
.dashboard-loan-wrapper .loan-main .details-label {
 color: ${({ theme }) => theme.secondary_text} !important;
}

.secondary-border-white-black-color{
 border-color: ${({ theme }) => theme.secondary_text} !important;
}

.secondary-white-black-color > *{
 color: ${({ theme }) => theme.secondary_text} !important;
}

.secondary-white-black-color2 {
 color: ${({ theme }) => theme.secondary_text2} !important;
}

.btn-reuse-outline {
  color: ${({ theme }) => theme.text_color} !important;
  border-color: ${({ theme }) => theme.card_color2_border} !important;
  background-color: ${({ theme }) => theme.card_color2} !important;
}

.btn-outline {
  color: ${({ theme }) => theme.text_color} !important;
  border-color: ${({ theme }) => theme.btn_outline_border} !important;
  background-color: transparent !important;
}

.form-input-label-group .form-group,
.verification-dialog .pin-field,
.pin-setup-dialog-wrap .pin-field,
.phone-number-input .form-group {
  border-color: ${({ theme }) => theme.input_dark_border} !important;
  background-color: ${({ theme }) => theme.body_highlight} !important;
}
.blue-color{
  color: ${({ theme }) => theme.chill_blue} !important;
}
.blue-background{
  background-color: ${({ theme }) => theme.blue_color} !important;
}
.blue-icon {
  background-color: ${({ theme }) => theme.toggle_box_background_color} !important;
}
.blue-text {
  color: ${({ theme }) => theme.toggle_box_background_color} !important;
}

.container-wrap, .container-color, .dashboard-page-wrapper .page-card-wrapper,
.dashboard-news-index-wrap .page-search-input-group, .pay-bill-index-wrap {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.container-color-border {
  border: 1px solid ${({ theme }) => theme.body_highlight} !important;
}

.container-color2 {
  background-color: ${({ theme }) => theme.body_highlight2} !important;
}

.text-hover:hover,  .text-hover:hover *{
  color: ${({ theme }) => theme.text_color} !important;
}

.text-hover:active,  .text-hover:active *{
  color: ${({ theme }) => theme.text_color} !important;
}

.brightness{
  filter: ${({ theme }) => theme.brightness}
}

.search-box-default-color, .dashboard-home-wrap .top-box .left-box-wrap, .dashboard-card-container {
  background-color: ${({ theme }) => theme.body_highlight} !important
}

.search-box-in-container-color, .input-box-in-container-color {
  background-color: ${({ theme }) => theme.body} !important
}

.profile-color {
  background-color: ${({ theme }) => theme.profile_card_color} !important;
}

.note-color {
  background-color: ${({ theme }) => theme.note_color} !important;
}

.modal-secondary-background {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.modal-primary-background {
  background-color: ${({ theme }) => theme.modal_primary_background} !important;
}
.modal-body-background {
  background-color: ${({ theme }) => theme.body} !important;
}
.card-color {
  background-color: ${({ theme }) => theme.body_highlight} !important;
  border-color: ${({ theme }) => theme.border_color2} !important;
}
 .nft-card-color {
  background-color: ${({ theme }) => theme.nft_card} !important;
}
.chill-fountain-blue-color{
 color: ${({ theme }) => theme.chill_fountain_blue_color} !important;
}
.secondary-white-black-color3,
.kyc-main-container .kyc-container .kyc-tab-container .kyc-tab-item.verified:not(.active),
.reg-user-preferences .preferences-section .p-section-items .p-section-item,
.swap-inputs-wrapper .swap-section .section-row .section-label {
 color: ${({ theme }) => theme.secondary_text3} !important;
}
.secondary-white-black-color4 {
 color: ${({ theme }) => theme.secondary_text4} !important;
}
.container-color3 {
  background-color: ${({ theme }) => theme.body_highlight3} !important;
}
.item-preview-icons-bg {
  background-color: ${({ theme }) => theme.item_preview_icons_bg} !important;
}
.item-preview-screen-bg {
  background-color: ${({ theme }) => theme.item_preview_screen_bg} !important;
}
.item-preview-text {
  color: ${({ theme }) => theme.item_preview_text} !important;
}
.item-preview-date-container {
  background-color: ${({ theme }) => theme.item_preview_date_container} !important;
}
.item-preview-desc-preloader {
  background-color: ${({ theme }) => theme.item_preview_desc_preloader} !important;
}
.container-color4,
.ribs-select-investment-bag-container .body-container .row:hover {
  background-color: ${({ theme }) => theme.body_highlight4} !important;
}
.container-color5 {
  background-color: ${({ theme }) => theme.body_highlight5} !important;
}
.preloader-bg {
  background-color: ${({ theme }) => theme.preloader_bg} !important;
}
.input-color {
  background-color: ${({ theme }) => theme.input_color} !important;
}
.input-color2 {
  background-color: ${({ theme }) => theme.input_color2} !important;
}
.list-item-bg {
  background-color: ${({ theme }) => theme.list_item} !important;
}
.list-item-bg:hover {
  background-color: ${({ theme }) => theme.list_item_hover} !important;
}
.list-item-bg2 {
  background-color: ${({ theme }) => theme.list_item2} !important;
}
.social-icon {
  background-color: ${({ theme }) => theme.social_icon} !important;
}
.tab-btn:hover {
  color: ${({ theme }) => theme.tab_btn_hover} !important;
}
.tab-btn:hover img {
  color: ${({ theme }) => theme.nav_icon_color_nft} !important;
}
.co-creator-bg {
  background-color: ${({ theme }) => theme.co_creator_bg} !important;
}










/* Custom */
.detail-wrap .list .item .item-wrap .text{
  color: ${({ theme }) => theme.secondary_text} !important;
}

.side-bar .theme-box, .sidebar-wrap .theme-box-reduced{
  background-color: ${({ theme }) => theme.theme_box_background}
}

.currency-select-group .currency-options, .badge-dp-box.select-coin-group .currency-options,
.currency-select-alt-group .currency-options, .badge-dp-box.select-coin-group .currency-options{
  background-color: ${({ theme }) => theme.body};
}

.sidebar-wrap .list-wrap .list .item:hover .nav-link {
  color: ${({ theme }) => theme.secondary_text_hover};
}

.dashboard-home-wrap .top-box .left-box .quick-action-box .title-box p.quick {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.icon-color, .swap-arrow-box .img-box {
  filter: ${({ theme }) => theme.icon_color} !important;
}

.icon-color2, .icon-dim-color {
  filter: ${({ theme }) => theme.icon_color2} !important;

}

.side-nav-icon-dim {
  filter: ${({ theme }) => theme.sidenav_icon_color} !important;
}

/* .sidebar-wrap .list-wrap .list .item:hover .nav-link .img-box, .sidebar-wrap .list-wrap .list .item:hover .drop-icon {
  filter: ${({ theme }) => theme.icon_color_hover} !important;
} */

.sidebar-wrap .list-wrap .list .children-box .child-link:hover span {
  color: ${({ theme }) => theme.secondary_text_hover} !important;
}

.buy-asset-wrap .left-box .select-asset-wrap  {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}





.buy-sell-swap-box .link-box .link:hover span, .buy-sell-swap-box .link-box .link-active span {
  color:  ${({ theme }) => theme.secondary_text_hover} !important;
}

.sidebar-wrap .list-wrap .list .item-active * {
  color: white !important;
}

.side-bar .theme-box .active-item {
  background-color:  ${({ theme }) => theme.nav_toggle_active} !important;
  font-style: bold;
  font-weight: 600;
}

.sidebar-wrap .list-wrap .list .children-box .child-link-active span{
  color: ${({ theme }) => theme.text_color} !important;
}

.nav-link .img-box, .floating-nav--contianer .floating-nav .nav-item .img-box {
  ${({ theme }) => theme.nav_icon_color} ;
}

.top-list .img-box-top-list {
    ${({ theme }) => theme.nav_icon_color_nft} ;
}

.accordion-head-toggle {
  ${({ theme }) => theme.drop_down_icon}
}

.header-nav-content {
  /* background-color: ${({ theme }) => theme.body} !important; */
  color: ${({ theme }) => theme.text_color};
}

.header-nav-content.mobile {
  /* background-color: ${({ theme }) => theme.purple_haze} !important; */
}

.header-nav-content .toggle-box .icon {
  fill: red !important;
}

/* .header-nav-content .profile-name-box.mobile {
  background-color: transparent !important;
} */

.sidebar-wrap .logo-box .cancel-box .icon, .header-nav-content .toggle-box .icon,
.sidebar-wrap .list-wrap .list .item-active:hover .nav-link .img-box, .sidebar-wrap .list-wrap .list .item-active:hover .drop-icon{
  filter: brightness(1000%) !important;
  -webkit-filter: brightness(1000%) ;
  color: white !important;
}

.trending-asset-table-wrap .table tbody tr::after, .transactions-wrapper  .transaction-row::after  {
  background-color: ${({ theme }) => theme.hover} !important;
}

.content-wrap .common-link-wrap .link-item-active
{
  color:  ${({ theme }) => theme.text_color} !important;
  border-color:  ${({ theme }) => theme.blue_color}  !important;
}

.buy-asset-wrap .link-box .link-active > * {
  color:  ${({ theme }) => theme.text_color} !important;
}

.saving-main-wrap .left-box .saving-list-box .item-active, .saving-main-wrap .left-box .saving-list-box .item:hover {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.tab.active {
  border-bottom-color: ${({ theme }) => theme.chill_fountain_blue_color} !important;
}

.ribs-buy-bag-flow-wrapper .select-bag-investment-container .tab.active .tab-title-text {
  color: ${({ theme }) => theme.chill_fountain_blue_color} !important;
}

.create-card-wrap .select-card-type .select-card .card-type.card-type--active, .tab.active > p {
  color:  ${({ theme }) => theme.text_color} !important;
}

.help-support-wrap .article-page-wrap .article-side-nav .link:focus, .help-support-wrap .article-page-wrap .article-side-nav .link:hover , .help-support-wrap .article-page-wrap .article-side-nav .link:active {
  // color: ${({ theme }) => theme.blue_color} !important;
}

.page-counter .nav-button.active, .page-counter  .next-active  {
  color: ${({ theme }) => theme.blue_color} !important;
}

.page-counter .page-number-button:hover {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.page-counter .active:hover {
  background-color: #0069FF !important;
}
.dashboard-setting-wrap .settings-swap-box .link-box .link-active::after {
  background-color: ${({ theme }) => theme.blue_color_both} !important;
}

.dashboard-setting-wrap .settings-swap-box .link-box .link:hover span, .dashboard-setting-wrap .settings-swap-box .link-box .link-active span {
  color: ${({ theme }) => theme.text_color} !important;

}


.saving-main-wrap .left-box .saving-list-box .item-active, .payment-link-main-wrap
  .left-box
  .payment-link-list-box
  .item-active {
  background-color: ${({ theme }) => theme.profile_card_color} !important;
}

::-webkit-scrollbar-thumb {
  background: ${({ theme }) => theme.secondary_text} !important;
  opacity: .4 !important;
  border-radius: 1px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.chart-loader-wrapper {
  background-color: ${({ theme }) => theme.body_highlight} !important;
  border-color: ${({ theme }) => theme.border_color2} !important;
}


// recieve asset form
.recieve-asset-box .select-network .active,
.currency-select-group .currency-options li.active,
.currency-select-group .currency-options li:hover,
.currency-select-alt-group .currency-options li.active,
.currency-select-alt-group .currency-options li:hover,
.badge-dp-box.select-coin-group .currency-options li.active,
.badge-dp-box.select-coin-group .currency-options li:hover,
.phone-number-input .select-country-section .country-item:hover,
.swap-input-select-modal .item-list-section .list-item:hover {
  background-color: ${({ theme }) => theme.body_highlight2} !important;
}

.alert-head-bg-color {
  background-color: ${({ theme }) => theme.card_color} !important;
  border-color: ${({ theme }) => theme.card_color} !important;
}

.trending-asset-table thead tr {
  color: ${({ theme }) => theme.title_color} !important;
  border-bottom-color: ${({ theme }) => theme.border_color3} !important;
}

.ribs-table-container .ribs-table-row {
  border-bottom-color: ${({ theme }) => theme.border_color3} !important;
}

tr.trending-asset>.cell-serial-no {
  color: ${({ theme }) => theme.title_color} !important;
}

tr.trending-asset>.cell-name span.abr {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

tr.trending-asset>.cell-price {
  color: ${({ theme }) => theme.secondary_text_hover} !important;
}

.apexcharts-tooltip {
  background: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text_color} !important;
  box-shadow: ${({ theme }) => theme.chart_tooltip_box_shadow} !important;
}

.user-asset-name, .trending-asset-name, .user-asset-price {
  color: ${({ theme }) => theme.wallet_asset_name} !important;
}

.end-nav-drawer-wrap .end-nav-drawer-content .end-nav-drawer-main {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.end-nav-drawer-wrap .end-nav-drawer-content .end-nav-drawer-main .header, .bank-transfer .bank-transfer-instruction .instruction-box  {
  background-color: ${({ theme }) => theme.body} !important;
}

.end-nav-drawer-wrap .end-nav-drawer-content .end-nav-drawer-main .header .title {
  color: ${({ theme }) => theme.text_color} !important;
}

.coin-list-tile-wrap .coin-data-box .coin-name-symbol-box .coin-symbol {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.search-results-sheet-wrap, .search-results-popup-wrap {
  background-color: ${({ theme }) => theme.body} !important;
}

.search-results-sheet-wrap .search-results-title {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.search-result-tile .name {
  color: ${({ theme }) => theme.text_color} !important;
}

.search-result-tile .symbol {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.generic-dropdown-wrap .value-icon-box .value {
  color: ${({ theme }) => theme.text_color} !important;
}

.generic-dropdown-wrap .dropdown-items-component {
  background-color: ${({ theme }) => theme.side_nav_background} !important;
}

.search-results-popup-wrap .inner-search-box {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.end-nav-drawer-wrap .end-nav-drawer-content .end-nav-drawer-main .body .search-box {
  background-color: ${({ theme }) => theme.body} !important;
}

.end-nav-drawer-wrap .end-nav-drawer-content .end-nav-drawer-main .body .search-box .search-query-input {
  color: ${({ theme }) => theme.text_color} !important;
}

.loading-page-wrap {
  background-color: ${({ theme }) => theme.card_color} !important;
}

.pay-bill-wrap-two .bottom-box .content-wrap .form .form-group-bank-name .dropdown-items-box {
  background-color: ${({ theme }) => theme.body} !important;
}

.divider-underline {
  background-color: ${({ theme }) => theme.underline} !important;
}

.register-wrap .step-progress-indicator .step-progress-bar,
.ribs-buy-bag-flow-wrapper
  .buy-bag-flow-stage-container
  .step-progress-indicator
  .step-progress-bar {
  background-color: ${({ theme }) => theme.progress_bar_inactive} !important;
}

.register-wrap .step-progress-indicator .step-progress-bar.active  {
  background-color: ${({ theme }) => theme.blue_color} !important;
}

.register-wrap .current-step-text {
  color: ${({ theme }) => theme.text_color} !important;
}

.register-wrap .current-step-text .total-steps-text {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.auth-form-input-label {
  color: ${({ theme }) => theme.text_color} !important;
}

.new-signup-wrap .header, .legal-name-wrap .header, .phone-number-wrap .header, .set-transaction-pin-wrap .header, .verify-email-modal .header {
  color: ${({ theme }) => theme.text_color} !important;
}

.legal-name-wrap .subheader, .phone-number-wrap .subheader, .verify-email-modal .subheader {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.new-signup-wrap .input-wrap, .legal-name-wrap .input-wrap, .phone-number-wrap .input-wrap {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.new-signup-wrap .input-wrap .username-input::placeholder, .legal-name-wrap .input-wrap .username-input::placeholder {
  color: ${({ theme }) => theme.placeholder_text} !important;
}

.new-signup-wrap .sign-in {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.phone-number-wrap .phone-input-dropdown {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.verify-email-modal .content-wrapper .modal-content {
  background-color: ${({ theme }) => theme.body} !important;
}

.sign-up-complete .body .content .header {
  color: ${({ theme }) => theme.text_color} !important;
}

.sign-up-complete .body .content .subheader {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.auth-dialog .content-wrapper .modal-content {
  background-color: ${({ theme }) => theme.body} !important;
}

.divider-underline {
  background-color: ${({ theme }) => theme.underline} !important;
}

.register-wrap .current-step-text {
  color: ${({ theme }) => theme.text_color} !important;
}

.register-wrap .current-step-text .total-steps-text {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.auth-form-input-label {
  color: ${({ theme }) => theme.text_color} !important;
}

.new-signup-wrap .header, .legal-name-wrap .header, .phone-number-wrap .header, .set-transaction-pin-wrap .header, .verify-email-modal .header {
  color: ${({ theme }) => theme.text_color} !important;
}

.legal-name-wrap .subheader, .phone-number-wrap .subheader, .verify-email-modal .subheader {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.new-signup-wrap .input-wrap, .legal-name-wrap .input-wrap, .phone-number-wrap .input-wrap {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.new-signup-wrap .input-wrap .username-input::placeholder, .legal-name-wrap .input-wrap .username-input::placeholder,
#important-notice-modal-body .important-notice-message{
  color: ${({ theme }) => theme.placeholder_text} !important;
}

.new-signup-wrap .sign-in {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.checkbox .checkbox-not-active {
  border-color: ${({ theme }) => theme.secondary_text3} !important;
}

.phone-number-wrap .phone-input-dropdown {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.verify-email-modal .content-wrapper .modal-content {
  background-color: ${({ theme }) => theme.body} !important;
}

.sign-up-complete .body .content .header {
  color: ${({ theme }) => theme.text_color} !important;
}

.sign-up-complete .body .content .subheader {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.auth-dialog .content-wrapper .modal-content {
  background-color: ${({ theme }) => theme.body} !important;
}

.content-loader linearGradient stop[offset='0%'],
.content-loader linearGradient stop[offset='100%'] {
  stop-color: ${({ theme }) => theme.contentLoaderBg} !important;
}

.content-loader linearGradient stop[offset='50%'] {
  stop-color: ${({ theme }) => theme.contentLoaderFore} !important;
}

/* Input fields */
.dsh-input-group .input-group, .currency-select-group .c-select-group,
.currency-select-alt-group .c-select-group,
.currency-select-alt-group .c-select-group .checkbox-not-active {
  background-color: ${({ theme }) => theme.body} !important;
}

.dsh-input-group .input-group.input-group-transparent {
  background-color: transparent !important;
}

// Currency Input
.currency-select-group .currency-options li:hover, .price-form-group .select-filter-options .currency-item:hover,
.currency-select-alt-group .currency-options li:hover, .price-form-group .select-filter-options .currency-item:hover {
  background-color: ${({ theme }) => theme.body_highlight2} !important;
}

.link-anchor {
  color: ${({ theme }) => theme.link_blue} !important;
  text-decoration: underline;
}

// Radio at deposit
.end-nav-drawer-wrap
  .end-nav-drawer-content
  .end-nav-drawer-main
  .withdraw-wrapper
  .withdraw-routes-container
  .withdraw-route
  .radio.active {
  border-color: ${({ theme }) => theme.blue_color} !important;
}

.single-asset-wrap .right-box .buy-sell-box .title-box .tab-btn-container, .tab-btn-container {
  background-color: ${({ theme }) => theme.theme_box_background} !important
}

.single-asset-wrap .right-box .buy-sell-box .title-box .name {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.single-asset-wrap .right-box .buy-sell-box .title-box .name.name-active {
  background-color: #2764ff !important;
  color: #ffffff !important;
}

.single-asset-wrap .left-box .content-box .top-box .chart-box {
  background-color: ${({ theme }) => theme.body_highlight} !important;
  @media (max-width: 768px) {
    background-color: transparent !important;
  }
}

/* .single-asset-wrap .coin-details-actions-wrap .current-coin-actions .current-coin-action-btn {
  background-color: ${({ theme }) => theme.card_color2} !important; */
// Buy, Sell, Swap forms
.single-asset-wrap .right-box .buy-sell-box .title-box .name {
  color: ${({ theme }) => theme.secondary_text} !important;
}
/* .single-asset-wrap .right-box .buy-sell-box .title-box .name-active {
<<<<<<< Updated upstream
>>>>>>> Stashed changes
=======
>>>>>>> Stashed changes
  color: ${({ theme }) => theme.text_color} !important;
} */
.notification-wrapper {
  border-color: ${({ theme }) => theme.underline} !important;
}

.has-dashboard-tab .trending-assets-losers-section .tab-title-text {
  color: ${({ theme }) => theme.inactive_tab_text_color} !important;
}

.has-dashboard-tab .trending-assets-losers-section .tab.active .tab-title-text {
  color: ${({ theme }) => theme.text_color} !important;
}

.has-dashboard-tab .trending-assets-losers-section .tab-title-text.active {
  color: ${({ theme }) => theme.text_color} !important;
}

.trending-asset-card .heading .cryptocurrency-symbol {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.trending-asset-card .chart-price-box {
  background-color: ${({ theme }) => theme.losers_bg_color} !important;
  border-color: ${({ theme }) => theme.trending_asset_card_border} !important;
}

.trending-asset-card .chart-price-box .price-change-container,
.auth-layout-wrap .information-option-section .option-container,
.reg-user-preferences .preferences-section .p-section-items .p-section-item,
.reg-user-preferences
  .preferences-section
  .p-section-items
  .p-section-item.active,
.wot--container .wot-items-section .wot-item, .table-header-highlight,.username-wrapper,
.floating-nav--contianer .floating-nav .floating-nav-panel .nav-panel-item:not(.disabled-panel-item):hover,
.kyc-main-container .kyc-container .kyc-details-section .kyc-details-container,
.kyc-main-container .kyc-container .kyc-info-section,
.kyc-main-container .kyc-container .kyc-tab-container {
  background-color: ${({ theme }) => theme.losers_price_change_box_color} !important;
}

.ribs-bag-dropdown
  .dropdown-body
  .table-section
  .row.ribs-table-body-items:hover, .ribs-bag-dropdown .dropdown-body .ribs-bag-info-section {
  background-color: ${({ theme }) => theme.losers_price_change_box_color} !important;
}

.auth-email-list-container .email-list-item,
.floating-nav--contianer .floating-nav .floating-nav-panel,
.auth-layout-wrap .auth-country-items-container .auth-country-item {
  border-color: ${({ theme }) => theme.losers_price_change_box_color} !important;
}

.total-asset-value-box .action-btns .action-btn,
.ribs-wrapper .ribs-inspect-overview-container .bag-details-section .bag-details-section-header .bag-button-section .action-btn,
.beginner-chart-view .top-section .bag-button-section .action-btn,
.beginner-chart-view
  .top-section.mbl
  .section-row .action-btn {
  background-color: ${({ theme }) => theme.card_color2} !important;
}

.has-dashboard-tab .tab-btn-container-wrap .tab-btn-container,
.tab-btn-container-wrap .tab-btn-container {
  background-color: ${({ theme }) => theme.tab_btn_container} !important
}

.others-are-trading-section .other-coin-item-container .other-coin-item .content-container .progres {
  background-color: ${({ theme }) => theme.tab_btn_container} !important

}

.has-dashboard-tab .tab-btn-container-wrap .tab-btn-container.in-section,
.tab-btn-container-wrap .tab-btn-container.in-section {
  background-color: ${({ theme }) => theme.tab_btn_container2} !important
}

.has-dashboard-tab .tab-btn-container-wrap .tab-btn-container .tab-btn {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.has-dashboard-tab .tab-btn-container-wrap .tab-btn-container .tab-btn.active,
.tab-btn-container-wrap .tab-btn-container .tab-btn.active {
  background-color: ${({ theme }) => theme.tab_btn_active} !important;
  /* color: #ffffff !important; */
}

.tab-btn-container-wrap .tab-btn-container .tab-btn p,
.tab-btn-container-wrap .tab-btn-container .tab-btn.active p {
  color: ${({ theme }) => theme.text_color} !important;
}

.clickable-background-container {
  background-color: ${({ theme }) => theme.clickable_background} !important;
}

.pnl-box .pnl-title, .pnl-box .pnl-desc, .total-asset-value-box .new-info-section .new-total-box + .total-desc {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.dashboard-wallet-wrap .bottom-box .table-history-box .left-box {
  /* border-color: ${({ theme }) => theme.trending_asset_card_border} !important; */
}

.user-asset-table thead tr, .generic-dropdown-wrap, .light-border {
  border-color: ${({ theme }) => theme.trending_asset_card_border} !important;
}
// Modifying the 'border-this' class broke some uses of it that is why I commented it out.
// You can create a new clase for this useCase or target the class from it's parent containers so it doesn't affect other places where it is used

// .border-this {
//   border-color: ${({ theme }) => theme.trending_asset_card_border} !important;
// }

.trending-asset-table-wrap .trending-asset-table thead tr {
  border-color: ${({ theme }) => theme.trending_asset_card_border} !important;
}

.light-border {
  border-color: ${({ theme }) => theme.trending_asset_card_border} !important;
}

.document-verification .document-verification-subtitle, .document-verification .document-verification-body .continue-text {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.document-verification .document-verification-body .barcode-container {
  background-color: ${({ theme }) => theme.card_color2} !important;
}

.document-verification .document-verification-body .or-wrap hr {
  border-color: ${({ theme }) => theme.divider} !important;
}

.getting-started-action-card .card-img-wrap, .dashboard-get-started .download-section .img-wrapper {
  background-color: ${({ theme }) => theme.get_started_card} !important;
}

.getting-started-action-card .content .action-card-title-wrap .verification-status-icon {
  filter: ${({ theme }) => theme.get_started_tick_inactive} !important;
}

.light-bg {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.btn-secondary {
  color: ${({ theme }) => theme.secondary_btn_color} !important;
}

.tx-filter-dropdown .dropdown-btn-primary {
  border-color: ${({ theme }) => theme.border_color} !important;
  color: ${({ theme }) => theme.text_color} !important;
}

.tx-filter-dropdown .dropdown-btn-primary .selected-filter-text {
  color: ${({ theme }) => theme.text_color} !important;
}

.tx-filter-dropdown .tx-filter-options {
  background-color: ${({ theme }) => theme.body} !important;
}

.tx-filter-dropdown .tx-filter-options .dropdown-btn-secondary {
  color: ${({ theme }) => theme.text_color} !important;
}

.tx-table-header .cell {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.transaction-list-item .cell.desc .desc-subtitle {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.transactions-tab-wrap .mobile-dropdown-wrap .opaque-box {
  background-color: ${({ theme }) => theme.tab_btn_container} !important;
}

.chart-toolbar .interval-btn {
  color: ${({ theme }) => theme.text_color} !important;
  background-color: ${({ theme }) => theme.interval_btn_bg} !important;
}

.chart-toolbar .interval-btn.active {
  color: ${({ theme }) => theme.active_interval_btn_fg} !important;
  background-color: ${({ theme }) => theme.active_interval_btn_bg} !important;
}

.header-nav-content .language-nav div {
 color: ${({ theme }) => theme.secondary_text} !important;
}

.header-nav-content .language-nav div.active {
  border-color: ${({ theme }) => theme.blue_color} !important;
  color: ${({ theme }) => theme.text_color} !important;
}

.auth-email-list-container .email-list-item .action-wrapper .menu-body {
  border-color: ${({ theme }) => theme.input_stroke} !important;
  background-color: ${({ theme }) => theme.body} !important;
}

.floating-nav--contianer
  .floating-nav
  .floating-nav-panel
  .nav-panel-item
  .details-title-section
  .details-comingsoon-tag,
.payment-methods-section-wrap
  .payment-method-item
  .details-section
  .details-section-title
  .coming-soon {
  background-color: ${({ theme }) => theme.input_stroke} !important;
}

.auth-layout-wrap .auth-search-box, .reg-address-container .reg-address-dropdown .bottom-section {
  border-color: ${({ theme }) => theme.input_stroke} !important;
}

.news-article .visit-site-btn .visit-site-text,
.news-article .article-badge{
  color: ${({ theme }) => theme.chill_blue} !important;
}

.dashboard-news-index-wrap .page-search-container .search-results-container{
  background-color: ${({ theme }) => theme.text_color} !important;
}
.dashboard-news-index-wrap .page-search-container .list-result-container li:hover{
  background-color: ${({ theme }) => theme.tab_btn_container2} !important;
}
.dashboard-news-index-wrap .page-search-container .search-results-container{
  background-color: ${({ theme }) => theme.body} !important;
}

.chip-containment-sheet .empty-state-content .empty-chip-state-text {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.momo-setup-flow .momo-setup-card {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.momo-setup-flow .momo-setup-card .momo-setup-divider {
  border-color: transparent !important;
  border-bottom-color: ${({ theme }) => theme.toggle_box_background_color} !important;
}

.momo-setup-flow .flow-stage .title {
  color: ${({ theme }) => theme.text_color} !important;
}

.momo-setup-flow .flow-stage .subtitle {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.momo-setup-flow .flow-stage.confirm-details .detail-row .property {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.momo-setup-flow .flow-stage.confirm-details .detail-row .value {
  color: ${({ theme }) => theme.text_color} !important;
}

.momo-setup-flow .select-currency .subtitle,
.momo-setup-flow .select-currency .currency-select-placeholder {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.radio-btn .radio.active .radio-inner-marker {
  background-color: ${({ theme }) => theme.body} !important;
}

.search-component {
  background-color: ${({ theme }) => theme.input_color2} !important;
}

.search-component .search-input {
  color: ${({ theme }) => theme.text_color} !important;
}

.momo-setup-flow .enter-otp .resend-line .resend-btn {
  color: ${({ theme }) => theme.link_blue} !important;
}

.static-phone-input .label-form,
.static-phone-input .form-group-paybill .left-box .select-filter-box .text {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.autocomplete-btn {
  background-color: ${({ theme }) => theme.body_highlight2} !important;
}

.autocomplete-btn .autocomplete-btn-text {
  color: ${({ theme }) => theme.text_color} !important;
}

.momo-delete-modal-content .delete-modal-small-text {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.momo-delete-modal-content .list-tile .leading {
  background-color: ${({ theme }) => theme.contentLoaderBg} !important;
}

.momo-delete-modal-content .list-tile .content .user-details .user-detail-item .property {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.momo-delete-modal-content .list-tile .content .user-details .user-detail-item .value {
  color: ${({ theme }) => theme.text_color} !important;
}

.momo-delete-modal-content .btn-row .modal-action-btn.cancel-btn {
  background-color: ${({ theme }) => theme.theme_box_background} !important;
}

.momo-delete-modal-content .btn-row .modal-action-btn.cancel-btn .text {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.momo-delete-modal-content .list-tile .leading {
  background-color: ${({ theme }) => theme.leading_bg} !important;
}

.withdraw-route.confirm-withdrawal .confirm-withdrawal-subheader,
.withdraw-route.confirm-withdrawal .detail-box .detail-name,
.withdraw-route.confirm-withdrawal .instructional-text {
  color: ${({ theme }) => theme.secondary_text} !important
}
.tooltip-wrapper .tooltip{
  background-color: ${({ theme }) => theme.tooltip_background} !important;
  color: ${({ theme }) => theme.tooltip_text} !important;
}

.tooltip-wrapper .tooltip-arrow-top {
  border-color: transparent transparent ${({ theme }) => theme.tooltip_background} transparent !important;
}

.tooltip-wrapper .tooltip-arrow-right {
  border-color: transparent ${({ theme }) => theme.tooltip_background} transparent transparent !important;
}

.tooltip-wrapper .tooltip-arrow-top {
  border-color: ${({ theme }) => theme.tooltip_background} transparent transparent transparent !important;
}

.tooltip-wrapper .tooltip-arrow-bottom {
  border-color: transparent transparent ${({ theme }) => theme.tooltip_background} transparent !important;
}
.tooltip-wrapper .tooltip-arrow-left {
  border-color: transparent transparent transparent ${({ theme }) => theme.tooltip_background} !important;
}

.select-search-container .dropdown-container,
.select-search-mobile-wrapper{
  background-color: ${({ theme }) => theme.body} !important;
}

.select-search-container .dropdown-container .input-group,
.select-search-mobile-wrapper .input-group{
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.select-search-container .list-item-group:hover,
.select-search-mobile-wrapper .option-item-group:hover,
.reg-address-container .reg-address-dropdown .top-section .top-section-item:hover {
  background-color: ${({ theme }) => theme.option_hover_highlight} !important;
}

#deposit-choose-withdraw-route
.withdraw-route
.name-icon-box
.subtitle,
#bank-deposit-wrapper .info-section-container .info-section .info-title{
  color: ${({ theme }) => theme.secondary_text3} !important;
}

#deposit-choose-withdraw-route
.withdraw-route
.unavailable-text,
#bank-deposit-wrapper .change-payment-method-text{
  color: ${({ theme }) => theme.chill_blue} !important;
}

#deposit-choose-withdraw-route
.withdraw-route
.radio.active,
.reg-user-preferences
  .preferences-section
  .p-section-items
  .p-section-item.active {
  border-color: ${({ theme }) => theme.chill_blue} !important;
}

#deposit-choose-withdraw-route
.withdraw-route
.radio{
  border-color: ${({ theme }) => theme.black_tint_2} !important;
}

#bank-deposit-wrapper .bank-transfer-detail .section-title span{
  color: ${({ theme }) => theme.black_tint_2} !important;
}

#bank-deposit-wrapper .info-section-container .info-section .data{
  color: ${({ theme }) => theme.text_color} !important;
}

#bank-deposit-wrapper .bank-transfer-detail .bank-transfer-instruction,
#bank-deposit-wrapper
.bank-transfer-detail
.bank-transfer-instruction
.instruction-box ul::marker,
#bank-deposit-wrapper
.bank-transfer-detail
.bank-transfer-instruction
.instruction-box ul::marker,
#bank-transfer-modal-body ul li,
.deposit-success-queued{
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.whats-new-dialog {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.whats-new-dialog .desktop-banner {
  background-color: ${({ theme }) => theme.body} !important;
}

.whats-new-dialog .heading,
.whats-new-dialog .whats-new-content .new-features .new-features-header,
.whats-new-dialog .whats-new-content .highlight {
  color: ${({ theme }) => theme.text_color} !important;
}

.whats-new-dialog .content {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.whats-new-dialog .whats-new-content .highlight {
  background-color: ${({ theme }) => theme.trending_asset_card_border} !important;
}

.change-bg,
.swap-inputs-wrapper .swap-to-section,
.swap-inputs-wrapper .swap-from-section .swap-pointer-controller {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.swap-inputs-wrapper .swap-to-section {
  border-color: ${({ theme }) => theme.change_bg} !important;
}

.swap-inputs-wrapper .swap-from-section .swap-pointer-controller {
  border-color: ${({ theme }) => theme.body_highlight} !important;
}

.high-risk-banks-flow .outline.btn-reuse-outline.add-another {
  color: ${({ theme }) => theme.chill_blue} !important;
}

.high-risk-banks-verify .title {
  color: ${({ theme }) => theme.text_color} !important;
}

.high-risk-banks-verify .text,
.high-risk-banks-verify .pinput-label {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.span-wheel-container .continue-play-box {
  border-color: ${({ theme }) => theme.change_bg} !important;
  background-color: ${({ theme }) => theme.border_color2} !important;
}

.raffle-inspect-box {
  border-color: ${({ theme }) => theme.border_color} !important;
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.raffle-inspect-box.main-box .child-box {
  border-color: ${({ theme }) => theme.input_stroke} !important;
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.feature-getstarted-banner {
  background: ${({ theme }) => theme.feature_getstarted_banner} !important;
  color: ${({ theme }) => theme.feature_getstarted_banner_color} !important;
}

.myribs-box-container.create-new {
  border-color: ${({ theme }) => theme.create_new_ribs_box} !important;
}

/* .beginner-chart-view .bottom-section .bag-allocation-card .bag-allocaition-card-body .item-container {
  background-color: ${({ theme }) => theme.ribs_allocation_item} !important;
} */

.beginner-chart-view .bottom-section .bag-allocation-card .bag-allocaition-card-body .item-container .bar {
  background-color: ${({ theme }) => theme.ribs_allocation_item_bar} !important;
}

.ribs-beginner-card-wrapper .ribs-beginner-card .back-action,
.ribs-beginner-cancel-wrapper .ribs-beginner-card .back-action,
.ribs-back-action {
  color: ${({ theme }) => theme.ribs_back_button} !important;
}

.raffle-inspect-box.main-box .child-box .box-cta-section .cta-box {
  background: ${({ theme }) => theme.cta_box_bg} !important;
}

.wheel-spin-next-container .share-icon-item .icon-container {
  background: ${({ theme }) => theme.wheel_share_bg} !important;
}

.ribs-transaction-grid-container.coin-table.mbl .ribs-transaction-grid-item,
.ribs-allocation-summary-toggler {
  background: ${({ theme }) => theme.border_color2} !important;
}

.token-ribs-selector-container {
  border-color: ${({ theme }) => theme.border_color} !important;
  background-color: ${({ theme }) => theme.losers_price_change_box_color} !important;
}

.token-ribs-selector-container .selector-btn.active {
  background-color: ${({ theme }) => theme.tab_btn_active} !important;
}

.token-ribs-selector-container .selector-btn {
  color: ${({ theme }) => theme.text_color} !important;
}

.token-ribs-selector-container .selector-btn .selector-btn-text span {
  color: ${({ theme }) => theme.chill_blue} !important;
}

.ribs-table .ribs-table-cell.action .add-money {
  color: ${({ theme }) => theme.wallet_asset_name} !important;
}

.dashboard-wallet-wrap .bottom-box .table-header-section form input {
  color: ${({ theme }) => theme.text_color} !important;
}


// Ribs Custom Dual-theme Classes
.price-bullet-color {
  background-color: ${({ theme }) => theme.tab_btn_container} !important;
}

.price-bullet-color.active {
  background-color: ${({ theme }) => theme.tab_btn_container} !important;
  border: 1.5px solid  ${({ theme }) => theme.chill_blue} !important;
  color: ${({ theme }) => theme.wallet_asset_name} !important;
}

.ribs-summary-container-bg {
  border-color: ${({ theme }) => theme.border_color} !important;
  background-color: ${({ theme }) => theme.losers_price_change_box_color} !important;
}

.ribs-nav-bg {
  background-color: ${({ theme }) => theme.tab_btn_container} !important;
}

.ribs-table .ribs-table-header .ribs-table-header-cell {
  color: ${({ theme }) => theme.secondary_text3} !important;
}
.ribs-new-withdrawal-flow-container header {
  background-color: ${({ theme }) => theme.tab_btn_container} !important;
}
.ribs-withdrawal-distribution-modal{
  background-color: ${({ theme }) => theme.theme_box_background} !important;
}

.bag-overview .title-bar .actions .more-btn .text {
  color: ${({ theme }) => theme.text_color} !important;
}

.bag-overview .main-section .balance-title {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.bag-overview .main-section .toolbar .actions .chart-mode-toggle.active {
  background-color: ${({ theme }) => theme.chill_blue_opacity} !important;
}

.bag-overview .main-section .btn-row .primary {
  background-color: ${({ theme }) => theme.body} !important;
}

.bag-overview .main-section .btn-row .primary .btn-text {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.ribs-route-container,
.settings-swap-section-box .settings-section-box {
  background-color: ${({ theme }) => theme.body_highlight4} !important;
}

.settings-swap-section-box .settings-section-box {
  border-color: ${({ theme }) => theme.body_highlight_border4} !important;
}

.ribs-app-bar {
  background-color: ${({ theme }) => theme.tab_btn_container} !important;
}

.bag-progress-bar .info .name {
  color: ${({ theme }) => theme.text_color} !important;
}

.bag-progress-bar .info .balances .val {
  color: ${({ theme }) => theme.text_color} !important;
}

.bag-progress-bar .progress-gutter {
  background-color: ${({ theme }) => theme.secondary_text3_opacity} !important;
}

.select-bag-frequency .bag-toolbar {
  background-color: ${({ theme }) => theme.body} !important;
}

.select-bag-frequency .main-section .main-section-header {
  color: ${({ theme }) => theme.text_color} !important;
}

.select-bag-frequency .main-section .btn-row .cancel-btn {
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.bag-summary .light-border-card {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.bag-detail-item .property {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.bag-detail-item .val {
  color: ${({ theme }) => theme.text_color} !important;
}

.bag-txn-tile .first-col .timestamp {
  color: ${({ theme }) => theme.secondary_text2} !important;
}

.ribs-bag-card {
  background-color: ${({ theme }) => theme.body} !important;
}

.ribs-bag-card .bottom {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.ribs-bag-card .bottom .bag-balance .bag-balance-header {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.ribs-bag-card .bottom .add-money-btn {
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.floating-nav--contianer .floating-nav {
  background-color: ${({ theme }) => theme.floating_nav_bg} !important;
  box-shadow: ${({ theme }) => theme.floating_nav_shadow} !important;
  border-color: ${({ theme }) => theme.floating_nav_border_color} !important;
}

.floating-nav--contianer .floating-nav .nav-item.active .img-box {
  filter:  ${({ theme }) => theme.floating_nav_item_active_icon} !important;
}

.floating-nav--contianer .floating-nav .nav-item.active {
  background: ${({ theme }) => theme.floating_nav_active} !important;
}

.header-nav-content--container {
  background-color: ${({ theme }) => theme.header_nav_bg} !important;
  box-shadow: ${({ theme }) => theme.header_nav_shadow} !important;
}

.auth-layout-wrap
  .information-option-section
  .option-container
  .illustration-container {
  background-color: ${({ theme }) => theme.secondary_black_tint_2_washed} !important;
}

.register-bank-details .select-bank {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.register-bank-details .select-bank-text {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.register-momo-operator .momo-search-input {
  border-color: ${({ theme }) => theme.input_stroke} !important;
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.reg-address-container .reg-address-dropdown {
  border-color: ${({ theme }) => theme.input_stroke} !important;
  background-color: ${({ theme }) => theme.body} !important;
}

.register-momo-details .reg-momo-input {
  border-color: ${({ theme }) => theme.input_stroke} !important;
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.auth-layout-wrap .base-layout-content .steps {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.bank-setup-success {
  background-color: ${({ theme }) => theme.body} !important;
}

.bank-setup-success .success-box .success-main {
  color: ${({ theme }) => theme.text_color} !important;
}

.bank-setup-success .success-box .success-sub {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.tabs.with-bottom-border {
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.loading-page-wrap {
  background-color: ${({ theme }) => theme.body} !important;
}

.chart_arrow_box {
  background-color: ${({ theme }) => theme.body} !important;
}

.kyc-main-container .kyc-container .kyc-tab-container .kyc-tab-item.active {
  background-color: ${({ theme }) => theme.kyc_tab_active_bg} !important;
}

.bag-overview .bag-nav .more-btn .text {
    color: ${({ theme }) => theme.text_color} !important;
}

.invest-intro-h6 {
  color: ${({ theme }) => theme.text_color} !important;
}

.invest-intro-paragraph {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.investment-type-btn {
  background-color: ${({ theme }) => theme.modal_secondary_background} !important;
  border-color: ${({ theme }) => theme.modal_secondary_background} !important;
}

.investment-type-btn .texts .investment-type-title {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-type-btn .texts .investment-type-subtitle {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.invest-one-off .step-info {
  color: ${({ theme }) => theme.text_color} !important;
}

.invest-one-off .step-info span {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.invest-one-off .step-info span.complete {
  color: ${({ theme }) => theme.text_color} !important;
}

.invest-one-off .suggestions .amount-suggestion.active {
  border-color: ${({ theme }) => theme.chill_blue} !important;
}

.invest-summary .table-caption-row .value {
  color: ${({ theme }) => theme.text_color} !important;
}

.invest-summary .group-box {
  border-color: ${({ theme }) => theme.border_color} !important;
  background-color: ${({ theme }) => theme.profile_card_color} !important;
}

.invest-summary .group-box .table-title {
  color: ${({ theme }) => theme.text_color} !important;
}

.invest-summary .group-box .bag-distro-table-row .coin-name-box .coin-name {
  color: ${({ theme }) => theme.text_color} !important;
}

.invest-summary .group-box .bag-distro-table-row .coin-name-box .coin-name .coin-symbol {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.invest-summary .group-box .bag-distro-table-row .percentage-distro-box .percentage-distro {
  color: ${({ theme }) => theme.wallet_asset_name} !important;
}

.invest-summary .group-box.extra .fee-info .key {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.invest-summary .group-box.extra .fee-info .value {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-bag-picker .tab-header .tab-header-btn {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.investment-bag-picker .tab-header .tab-header-btn.active {
  color: ${({ theme }) => theme.chill_blue} !important;
  border-bottom-color: ${({ theme }) => theme.chill_blue} !important;
}

.investment-type-table .column-titles {
  background-color: ${({ theme }) => theme.body_highlight4} !important;
}

.investment-type-table .column-titles .title-box .title {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.investment-type-table .investment-item {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-type-table .investment-item .action-box .invest-chip p {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-type-table .investment-item:hover {
  background-color: ${({ theme }) => theme.body_highlight4} !important;
}

.invest-freq .modal-btn .modal-btn-txt {
  color: ${({ theme }) => theme.text_color} !important;
}

.freq-date-picker .prevnext .prevnext-btn-img {
  color: ${({ theme }) => theme.text_color} !important;
}

.freq-date-picker .days-in-month .day-btn {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.freq-date-picker .days-in-month .day-btn.disabled {
  color: #515667 !important;
}

.freq-date-picker .days-in-month .day-btn.disabled.selected {
  color: #515667 !important;
}

.freq-date-picker .days-in-month .day-btn.selected {
  color: #FFFFFF !important;
}

.freq-date-picker .btn-section {
  background-color: ${({ theme }) => theme.modal_secondary_background} !important;
}

.investment-types-mobile .category-name {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-types-mobile .top-performer {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.investment-types-mobile .top-performer .top-row .numbers .bag-name {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-types-mobile .top-performer .meta-row .meta .min-deposit {
  color: ${({ theme }) => theme.text_color} !important;
}

.investment-types-mobile .top-performer .meta-row .meta .min-deposit-amt {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.investment-types-mobile .top-performer .meta-row .action {
  background-color: ${({ theme }) => theme.modal_secondary_background} !important;
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.investment-types-mobile .top-performer .top-row .numbers .change-box .change {
  color: #25C26E !important;
}

.mobile-bag-card {
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.mobile-bag-card .bag-name {
  color: ${({ theme }) => theme.text_color} !important;
}

.mobile-bag-card .min-deposit {
  color: ${({ theme }) => theme.text_color} !important;
}

.mobile-bag-card .min-deposit-amt {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.mobile-bag-card .action {
  background-color: ${({ theme }) => theme.modal_secondary_background} !important;
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.rate-checker .select-rate-curr {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.rate-checker .rate-search-border {
  border-color: ${({ theme }) => theme.input_dark_border} !important;
  background-color: ${({ theme }) => theme.body_highlight} !important;
}

.rate-checker .check-type-btn-group {
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.rate-checker .check-type-btn {
  color: ${({ theme }) => theme.text_color} !important;
}

.rate-checker .check-type-btn.active {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.rate-checker .rate-curr-ic {
  border-color: ${({ theme }) => theme.body_highlight} !important;
}

.rate-checker main {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.rate-checker .rate-tile:not(:nth-last-of-type(1)) {
  border-bottom-color: ${({ theme }) => theme.body} !important;
}

.rate-checker .rate-tile .currency-pair {
  color: ${({ theme }) => theme.text_color} !important;
}

.rate-checker .rate-tile .currency-pair-desc {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.rate-checker .rate-tile .rate-info {
  color: ${({ theme }) => theme.text_color} !important;
}

.txn-info-form .placeholder {
  color: ${({ theme }) => theme.secondary_text} !important;
}

.txn-check-result .check-tab-btn-group {
  border-color: ${({ theme }) => theme.border_color2} !important;
}

.txn-check-result .check-tab-btn {
  color: ${({ theme }) => theme.text_color} !important;
}

.txn-check-result .check-tab-btn.active {
  background-color: ${({ theme }) => theme.change_bg} !important;
}

.txn-check-result .txn-result-name {
  color: ${({ theme }) => theme.text_color} !important;
}

.txn-check-result .txn-result-date {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.txn-check-result .summary-title {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.txn-check-result .surface {
  background: ${({ theme }) => theme.modal_secondary_background} !important;
}

.txn-check-result .summary-desc {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.txn-check-result .item:not(:nth-last-of-type(1)) {
  border-bottom-color: ${({ theme }) => theme.body} !important;
}

.txn-check-result .item .property {
  color: ${({ theme }) => theme.secondary_text3} !important;
}

.txn-check-result .item .hash {
  color: ${({ theme }) => theme.text_color} !important;
}

.txn-check-result .item .top {
  color: ${({ theme }) => theme.text_color} !important;
}

.txn-check-result .item .bottom {
  color: ${({ theme }) => theme.secondary_text3} !important;
}
`;

export const lightTheme = {
  kyc_tab_active_bg: 'rgba(3, 4, 4, 0.05)',
  custom_them_color: '#ff0000',
  chart_tooltip_bg: '#F8F8F9',
  chart_tooltip_box_shadow: '2px 2px 6px -4px #999',
  floating_nav_bg: 'rgba(235, 235, 235, 0.30)',
  floating_nav_shadow: '',
  floating_nav_border_color: '#E0E0E0',
  floating_nav_item_active_icon: 'brightness(0%) opacity(100%)',
  floating_nav_active: 'linear-gradient(109deg, rgba(192, 207, 254, 0.40) 0.42%, rgba(243, 223, 244, 0.40) 57.74%, rgba(249, 216, 229, 0.40) 98.62%)',
  header_nav_bg: '#FFFFFF',
  header_nav_shadow: '0px 4px 16px 0px #f8f8f9',
  wheel_share_bg: '#F1F1F1',
  cta_box_bg: '#2764FF',
  ribs_back_button: '#2764FF',
  ribs_allocation_item: '#F8F8F9',
  ribs_allocation_item_bar: '#A7B1BC',
  feature_getstarted_banner: '#F1F1F1',
  feature_getstarted_banner_color: '#1C2127',
  change_bg: '#F8F8F9',
  reg_disabled_text: '#737A91',
  input_stroke: '#CFD9E4',
  secondary_btn_color: '#555C63',
  body: '#F8F8F9',
  quick_action_box: '#F8F8F9',
  get_started_card: '#DAE2F7',
  get_started_tick_inactive:
    'filter: brightness(60%) opacity(20%) !important; -webkit-filter: brightness(60%) opacity(20%)',
  body_highlight: '#ffffff',
  input_dark_border: '#E1E1E1',
  body_highlight2: 'rgba(217, 217, 217, 0.3)',
  brightness: 'brightness(0%)',
  border_color: '#E1E1E1',
  body_highlight_border4: '#E1E1E1',
  border_color2: '#EDEDED',
  border_color3: '#EDEDED',
  secondary_text_hover: '#1C2127',
  hover: 'rgba(217, 217, 217, 0.24)',
  profile_card_color: '#EBEBEB',
  icon_color: ' filter: brightness(30%) !important; -webkit-filter: brightness(30%)',
  icon_color2:
    ' filter: brightness(30%) opacity(70%)!important; -webkit-filter: brightness(30%) opacity(70%)',
  sidenav_icon_color:
    ' filter: brightness(30%) opacity(70%)!important; -webkit-filter: brightness(30%) opacity(70%)',
  note_color: 'rgba(255, 185, 24, 0.24)',
  faint_danger: 'rgba(227, 36, 45, 0.1)',

  // Auth
  notice_color: '#F5EACD',

  // variables for the sideNav bar
  side_nav_background: '#ffffff',
  modal_primary_background: '#ffffff',
  theme_box_background: '#E1E1E1',
  nav_toggle_active: '#ffffff',
  nav_icon_color: ' filter: brightness(130%); -webkit-filter: brightness(130%)',
  icon_color_hover: 'filter: brightness(0%); -webkit-filter: brightness(0%);',
  toggle_box_background_color: '#2764FF',
  clickable_background: 'rgba(217, 217, 217, 0.3)',
  title_color: '#555C63',

  // variables for card
  card_color: '#ffffff',
  card_color_hover: 'rgba(217, 217, 217, 0.24)',
  // card_color2: '#EBEBEB',
  card_color2: 'rgba(217, 217, 217, 0.24)',
  card_color2_border: '#E1E1E1',
  losers_bg_color: '#FFFFFF',
  losers_price_change_box_color: '#F8F8F9',

  // variables for text
  black_white_bg: '#1C2127',
  text_color: '#1C2127',
  secondary_text: '#555C63',
  secondary_text2: '#A7B1BC',
  blue_color: '#0069FF',
  create_new_ribs_box: '#2764FF',
  progress_bar_inactive: 'rgba(0, 0, 0, 0.24)',
  alert_color: 'rgba(0, 102, 245, 1)',
  link_color: '#5C8DA4',
  blue_color_both: '#0069FF',
  chill_blue: '#0069FF',
  link_blue: '#0069FF',
  placeholder_text: '#555C638f',

  // modal colors
  modal_secondary_background: '#EBEBEB',

  // Nfts
  nft_card: '#FFFFFF',
  chill_fountain_blue_color: '#2764FF',
  secondary_text3: '#555C63',
  secondary_text3_opacity: '#555C6333',
  secondary_text4: '#555C63',
  secondary_black_tint_2_washed: 'rgba(115, 122, 145, 0.08)',
  body_highlight3: 'rgba(0, 0, 0, 0.1)',
  body_highlight4: '#FFFFFF',
  body_highlight5: '#FFFFFF',
  preloader_bg: '#FFFFFF',
  body_container: '#ffffff',
  item_preview_icons_bg: 'rgba(167, 177, 188, 0.24)',
  item_preview_screen_bg: '#FFFFFF',
  item_preview_text: '#1C2127',
  item_preview_date_container: '#FFFFFF',
  item_preview_desc_preloader: '#FFFFFF',
  input_color: '#FFFFFF',
  input_color2: '#F8F8F9 ',
  nav_icon_color_nft: 'filter: brightness(0%); -webkit-filter: brightness(0%)',
  drop_down_icon: 'filter: brightness(0%); -webkit-filter: brightness(0%)',
  list_item: '#FFFFFF',
  list_item_hover: '#a7b1bc',
  list_item2: '#a7b1bc',
  social_icon: '#FFFFFF',
  tab_btn_hover: '#1C2127',
  co_creator_bg: '#F1F1F1',
  // ApexChart colors
  apexcharts_background: '#F1F1F1',
  apexcharts_foreground: '#1C2127',

  wallet_asset_name: '#1C2127',

  // Input fields
  active_border: '#2764FF',
  active_box_shadow: 'rgba(0, 105, 255, 0.16)',
  underline: '#0069FF',
  divider: '#0069FF4D',

  contentLoaderBg: '#0000001a',
  contentLoaderFore: '#0000003d',

  tab_btn_active: '#FFFFFF',
  tab_btn_container: '#E0E0E080',
  purple_haze: '#FFFFFF',

  trending_asset_card_border: '#F1F1F1',
  inactive_tab_text_color: '#737A91',

  // Btn
  btn_outline_border: 'rgba(85, 92, 99, 0.24)',

  interval_btn_bg: '#F8F8F9',
  active_interval_btn_bg: '#2764FF',
  active_interval_btn_fg: '#FFFFFF',

  leading_bg: '#00000033',
  black_tint_2: '#737A91',
  tooltip_background: '#12171D',
  tooltip_text: '#fff',
  option_hover_highlight: '#F1F1F1',

  chill_blue_opacity: '#85d1f014',
};

//  background-blend-mode: multiply;
export const darkTheme = {
  kyc_tab_active_bg: 'rgba(233, 240, 255, 0.05)',
  custom_them_color: '#0000ff',
  chart_tooltip_bg: '#141414',
  chart_tooltip_box_shadow: '2px 2px 6px -4px #1f2329',
  floating_nav_bg: 'rgba(38, 40, 49, 0.8)',
  floating_nav_shadow: '0px 4px 16px 0px #1d2024',
  floating_nav_border_color: '',
  floating_nav_item_active_icon: 'brightness(330%) opacity(100%)',
  floating_nav_active: 'linear-gradient(109deg,rgba(192, 207, 254, 0.1) 0.42%,rgba(243, 223, 244, 0.1) 57.74%,rgba(249, 216, 229, 0.1) 98.62%)',
  header_nav_bg: 'rgba(38, 40, 49, 0.8)',
  header_nav_shadow: '0px 4px 16px 0px #1d2024',
  wheel_share_bg: '#2b343c',
  cta_box_bg: '#303c44',
  ribs_back_button: 'rgba(255,255,255,0.06)',
  ribs_allocation_item: '#282d33',
  ribs_allocation_item_bar: '#34393f',
  feature_getstarted_banner:
    'linear-gradient(135deg, rgba(192, 207, 254, 0.08) 0%, rgba(243, 223, 244, 0.08) 56.25%, rgba(249, 216, 229, 0.08) 96.35%)',
  feature_getstarted_banner_color: '#ffffff',
  change_bg: '#262B31',
  reg_disabled_text: '#737A91',
  input_stroke: '#373B3F',
  secondary_btn_color: '#ffffff',
  body_highlight2: 'rgba(217, 217, 217, 0.1)',
  body: '#1C2127',
  quick_action_box: '#1C2127',
  get_started_card: '#2a2f35',
  get_started_tick_inactive:
    'filter: brightness(100%) !important; -webkit-filter: brightness(100%)',
  body_highlight: '#20252B',
  input_dark_border: '#91919133',
  brightness: 'brightness(100%)',
  border_color: '#32383F',
  body_highlight_border4: '#32383F',
  border_color2: '#262932',
  border_color3: '#32383F',
  secondary_text_hover: '#EBEBEB',
  hover: 'rgba(0, 0, 0, 0.05)',
  profile_card_color: '#1C2127',
  icon_color: ' filter: brightness(200%) !important; -webkit-filter: brightness(200%)',
  icon_color2: ' filter: brightness(100%) !important; -webkit-filter: brightness(100%)',
  sidenav_icon_color:
    ' filter: brightness(60%) opacity(70%) !important; -webkit-filter: brightness(60%) opacity(70%)',
  note_color: 'rgba(44, 44, 51, 0.5);',
  faint_danger: 'rgba(227, 36, 45, 0.1)',

  // Auth
  notice_color: '#312F24',
  // variables for the sideNav bar
  side_nav_background: '#24273A',
  modal_primary_background: '#1C2127',
  theme_box_background: '#1C2127',
  nav_toggle_active: 'rgba(233, 240, 255, 0.05)',
  nav_icon_color: ' filter: brightness(150%); -webkit-filter: brightness(150%)',
  icon_color_hover: 'filter: brightness(1000%); -webkit-filter: brightness(1000%);',
  toggle_box_background_color: '#535C64',
  clickable_background: '#535C64',

  // variables for card
  card_color: '#23262F',
  card_color_hover: 'rgba(0, 0, 0, 0.05)',
  card_color2: '#252B31',
  card_color2_border: '#2D3339',
  losers_bg_color: '#20252B',
  losers_price_change_box_color: '#262B31',

  // variables for text
  black_white_bg: '#ffffff',
  text_color: '#ffffff',
  secondary_text: '#A5B1BD',
  secondary_text2: '#B1B5C4',
  blue_color: '#A0D2FE',
  create_new_ribs_box: 'rgba(133, 209, 240, 0.24)',
  progress_bar_inactive: 'rgba(255, 255, 255, 0.24)',
  alert_color: 'rgba(248, 212, 139, 1)',
  link_color: '#ADC0FA',
  blue_color_both: '#0069FF',
  chill_blue: '#85D1F0',
  link_blue: '#85d1f0',
  placeholder_text: '#a7b1bc8f',

  // modal colors
  modal_secondary_background: '#262B31',

  // Nfts
  nft_card: '#272C32',
  chill_fountain_blue_color: '#85D1F0',
  secondary_text3: '#A7B1BC',
  secondary_text3_opacity: '#A7B1BC33',
  secondary_text4: '#979797',
  secondary_black_tint_2_washed: 'rgba(115, 122, 145, 0.08)',
  body_highlight3: 'rgba(255, 255, 255, 0.1)',
  body_highlight4: '#24292F',
  body_highlight5: '#292E33',
  preloader_bg: '#474B50',
  body_container: '#1C2127',
  item_preview_icons_bg: 'rgba(255, 255, 255, 0.1)',
  item_preview_screen_bg: ' rgba(255, 255, 255, 0.05)',
  item_preview_text: '#A5B1BD',
  item_preview_date_container: '#rgba(255, 255, 255, 0.1)',
  item_preview_desc_preloader: 'rgba(255, 255, 255, 0.15)',
  input_color: '#1C2127',
  input_color2: '#1C2127',
  nav_icon_color_nft: 'filter: brightness(1000%); -webkit-filter: brightness(1000%)',
  list_item: '#24273A',
  list_item_hover: 'rgb(165, 177, 189, 0.1)',
  list_item2: 'rgb(165, 177, 189, 0.1)',
  social_icon: 'rgb(165, 177, 189, 0.1)',
  tab_btn_hover: '#ffffff',
  co_creator_bg: '#282E35',
  // ApexChart colors
  apexcharts_background: '#F1F1F1',
  apexcharts_foreground: '#1C2127',

  wallet_asset_name: '#FCFCFD',

  // Input fields
  active_border: '#2764FF',
  active_box_shadow: 'rgba(0, 105, 255, 0.16)',
  underline: '#5C8DA4',
  divider: '#5C8dA44D',

  contentLoaderBg: '#f5f5f51a',
  contentLoaderFore: '#f5f5f53d',

  tab_btn_active: 'rgba(233, 240, 255, 0.05)',
  tab_btn_container: '#20252B',
  tab_btn_container2: '#252A30',
  purple_haze: '#24273A',

  trending_asset_card_border: '#262932',
  inactive_tab_text_color: '#A7B1BC',

  // Btn
  btn_outline_border: 'rgba(167, 177, 188, 0.24)',

  interval_btn_bg: '#262C3038',
  active_interval_btn_bg: '#161A1F',
  active_interval_btn_fg: '#1A71FF',

  leading_bg: '#00000033',
  black_tint_2: 'rgba(255, 255, 255, 0.7)',
  tooltip_background: '#fff',
  tooltip_text: '#12171D',
  option_hover_highlight: '#252A30',

  chill_blue_opacity: '#85d1f014',
};

export const blackTheme = {
  kyc_tab_active_bg: 'rgba(233, 240, 255, 0.05)',
  custom_them_color: '#00ff00',
  chart_tooltip_bg: '#141414',
  chart_tooltip_box_shadow: '2px 2px 6px -4px #1f2329',
  floating_nav_bg: 'rgba(255, 255, 255, 0.06)',
  floating_nav_shadow: '',
  floating_nav_border_color: '#373B3F',
  floating_nav_item_active_icon: 'brightness(330%) opacity(100%)',
  floating_nav_active: 'linear-gradient(109deg, rgba(192, 207, 254, 0.10) 0.42%, rgba(243, 223, 244, 0.10) 57.74%, rgba(249, 216, 229, 0.10) 98.62%)',
  header_nav_bg: 'rgba(20, 21, 25, 0.80)',
  header_nav_shadow: '0px 4px 16px 0px #0c0e0f',
  wheel_share_bg: '#2b343c',
  cta_box_bg: '#303c44',
  ribs_back_button: 'rgba(255,255,255,0.06)',
  ribs_allocation_item: '#282d33',
  ribs_allocation_item_bar: '#34393f',
  feature_getstarted_banner:
    'linear-gradient(135deg, rgba(192, 207, 254, 0.08) 0%, rgba(243, 223, 244, 0.08) 56.25%, rgba(249, 216, 229, 0.08) 96.35%)',
  feature_getstarted_banner_color: '#ffffff',
  change_bg: '#1B1C21',
  input_stroke: '#373B3F',
  secondary_btn_color: '#ffffff',
  body_highlight2: 'rgba(217, 217, 217, 0.1)',
  body: '#0D0E10',
  quick_action_box: '#1B1C21',
  get_started_card: '#2a2f35',
  get_started_tick_inactive:
    'filter: brightness(100%) !important; -webkit-filter: brightness(100%)',
  body_highlight: '#141519',
  input_dark_border: '#91919133',
  brightness: 'brightness(100%)',
  border_color: '#1F2328',
  body_highlight_border4: '#262932',
  border_color2: '#262932',
  border_color3: '#1F2328',
  secondary_text_hover: '#EBEBEB',
  hover: 'rgba(0, 0, 0, 0.05)',
  profile_card_color: '#1C2127',
  icon_color: ' filter: brightness(200%) !important; -webkit-filter: brightness(200%)',
  icon_color2: ' filter: brightness(100%) !important; -webkit-filter: brightness(100%)',
  sidenav_icon_color:
    ' filter: brightness(60%) opacity(70%) !important; -webkit-filter: brightness(60%) opacity(70%)',
  note_color: 'rgba(44, 44, 51, 0.5);',
  faint_danger: 'rgba(227, 36, 45, 0.1)',

  // Auth
  notice_color: '#312F24',
  // variables for the sideNav bar
  side_nav_background: '#24273A',
  modal_primary_background: '#0D0E10',
  theme_box_background: '#1C2127',
  nav_toggle_active: 'rgba(233, 240, 255, 0.05)',
  nav_icon_color: ' filter: brightness(150%); -webkit-filter: brightness(150%)',
  icon_color_hover: 'filter: brightness(1000%); -webkit-filter: brightness(1000%);',
  toggle_box_background_color: '#535C64',
  clickable_background: '#535C64',

  // variables for card
  card_color: '#0D0E10',
  card_color_hover: 'rgba(0, 0, 0, 0.05)',
  card_color2: '#20252B',
  card_color2_border: '#2D3339',
  losers_bg_color: '#1B1C21',
  losers_price_change_box_color: '#282B2E',

  // variables for text
  black_white_bg: '#ffffff',
  text_color: '#ffffff',
  secondary_text: '#A5B1BD',
  secondary_text2: '#B1B5C4',
  blue_color: '#A0D2FE',
  create_new_ribs_box: 'rgba(133, 209, 240, 0.24)',
  progress_bar_inactive: 'rgba(255, 255, 255, 0.24)',
  alert_color: 'rgba(248, 212, 139, 1)',
  link_color: '#ADC0FA',
  blue_color_both: '#0069FF',
  chill_blue: '#85D1F0',
  link_blue: '#85d1f0',
  placeholder_text: '#a7b1bc8f',

  // modal colors
  modal_secondary_background: '#262B31',

  // Nfts
  nft_card: '#272C32',
  chill_fountain_blue_color: '#85D1F0',
  secondary_text3: '#A7B1BC',
  secondary_text3_opacity: '#A7B1BC33',
  secondary_text4: '#979797',
  body_highlight3: 'rgba(255, 255, 255, 0.1)',
  body_highlight4: '#1B1C21',
  body_highlight5: '#292E33',
  preloader_bg: '#474B50',
  body_container: '#1C2127',
  item_preview_icons_bg: 'rgba(255, 255, 255, 0.1)',
  item_preview_screen_bg: ' rgba(255, 255, 255, 0.05)',
  item_preview_text: '#A5B1BD',
  item_preview_date_container: '#rgba(255, 255, 255, 0.1)',
  item_preview_desc_preloader: 'rgba(255, 255, 255, 0.15)',
  input_color: '#1C2127',
  input_color2: '#1C2127',
  nav_icon_color_nft: 'filter: brightness(1000%); -webkit-filter: brightness(1000%)',
  list_item: '#24273A',
  list_item_hover: 'rgb(165, 177, 189, 0.1)',
  list_item2: 'rgb(165, 177, 189, 0.1)',
  social_icon: 'rgb(165, 177, 189, 0.1)',
  tab_btn_hover: '#ffffff',
  co_creator_bg: '#282E35',
  // ApexChart colors
  apexcharts_background: '#F1F1F1',
  apexcharts_foreground: '#1C2127',

  wallet_asset_name: '#FCFCFD',

  // Input fields
  active_border: '#2764FF',
  active_box_shadow: 'rgba(0, 105, 255, 0.16)',
  underline: '#5C8DA4',
  divider: '#5C8dA44D',

  contentLoaderBg: '#f5f5f51a',
  contentLoaderFore: '#f5f5f53d',

  tab_btn_active: 'rgba(233, 240, 255, 0.05)',
  tab_btn_container: '#20252B',
  tab_btn_container2: '#252A30',
  purple_haze: '#24273A',

  trending_asset_card_border: '#262932',
  inactive_tab_text_color: '#A7B1BC',

  // Btn
  btn_outline_border: 'rgba(167, 177, 188, 0.24)',

  interval_btn_bg: '#262C3038',
  active_interval_btn_bg: '#161A1F',
  active_interval_btn_fg: '#1A71FF',

  leading_bg: '#00000033',
  black_tint_2: 'rgba(255, 255, 255, 0.7)',
  tooltip_background: '#fff',
  tooltip_text: '#12171D',
  option_hover_highlight: '#252A30',

  chill_blue_opacity: '#85d1f014',
};
