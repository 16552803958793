/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';

function InputGroupLabel({
  name, label, ...rest
}) {
  return (
    <label
      htmlFor={name}
      {...rest}
      className="input-group-label secondary-white-black-color"
    >
      {label}
    </label>
  );
}

export default InputGroupLabel;
