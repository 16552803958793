/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import useUser from '../../../../../hooks/useUser';
import { formatNumber } from '../../../../../utils/simpleActions';
import ButtonReuse from '../../../Button';
import * as toastType from '../../../../../utils/toast-types';
import toastTypes from '../../../../../utils/toast-types';
import { setToggleToast } from '../../../../../slices/authSlice';

function VerifyOptions({ data, proceedData }) {
  const dispatch = useDispatch();
  const { user } = useUser();

  function checkIfTierVerified(_limit) {
    const _withdrawal_limit = user.withdrawal_limit;
    // if user limit is greater or equal to level limit
    return _withdrawal_limit >= _limit;
  }

  function checkCanVerifyStage(_index) {
    if (_index === 0) return true;
    const previous_verified = checkIfTierVerified(data?.config[_index - 1].limit);

    // return true;
    return previous_verified;
  }

  function proceed(_child, _index) {
    const can_proceed = checkCanVerifyStage(_index);
    if (!can_proceed) {
      // show toast
      dispatch(
        setToggleToast({
          type: toastTypes.warning,
          heading: 'Verification Warning',
          description: `To verify current Tier you need to verify Tier ${_index}`,
        }),
      );
      // return false;
    }

    if (can_proceed) {
      proceedData(_child);
    }
  }

  return (
    <div className="withdraw-routes-container">
      {data && data?.config.map((child, idx) => {
        // eslint-disable-next-line no-unused-vars
        const {
          description, key, limit, link, level,
        } = child;
        const verified = checkIfTierVerified(limit);
        // check if ancestor is verified before this proceed
        const ancestor_verified = checkCanVerifyStage(idx);
        return (
          <div key={key} className="withdraw-route for-verify-option">
            <div className="verify-title-tier">
              Tier
              {' '}
              {level}
              <div className={`status-box ${verified && 'active'}`}>
                <span>
                  {verified ? 'Verified' : 'Unverified'}
                </span>
              </div>
            </div>
            <div className="verify-title-tier tier-type">
              {description?.toUpperCase()}
            </div>
            <div className="verify-description-tier secondary-white-black-color3">
              Completing this step enables you to perform upto
              {' '}
              {formatNumber(limit)}
              {' '}
              USD worth of transactions
            </div>
            {(!verified && ancestor_verified) && (
            <div className="button-wrapper">
              <ButtonReuse
                style={{ padding: '1rem 4.8rem' }}
                onClick={() => proceed(child, idx)}
                text="Verify Now"
              />
            </div>
            )}

          </div>
        );
      })}
    </div>
  );
}

export default VerifyOptions;
