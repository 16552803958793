/* eslint-disable react/prop-types */
import '../../styles/fragments/LoadingPage.css';
import { useMemo } from 'react';
import { useIcon } from '../../hooks/useIcon';
import { THEME_MODES, useDarkMode } from '../../utils/themes/useDarkMode';
/* eslint-disable react/react-in-jsx-scope */
export default function LoadingPage({ fullscreen = true }) {
  const { loadingCircle } = useIcon();
  const [theme] = useDarkMode();

  const backgroundColor = useMemo(() => {
    let _ret_theme = 'transparent';

    switch (theme) {
      case THEME_MODES.black:
        _ret_theme = '#0D0E10';
        break;
      case THEME_MODES.light:
        _ret_theme = '#F8F8F9';
        break;
      case THEME_MODES.dark:
        _ret_theme = '#1C2127';
        break;

      default:
        _ret_theme = '#0D0E10';
        break;
    }

    return _ret_theme;
  }, [theme]);

  return (
    <>
      {' '}
      {fullscreen ? (
        <div className="loading-page-wrap" style={{ backgroundColor }}>
          <img
            className="loading-indicator"
            src={loadingCircle}
            alt="loading"
          />
        </div>
      ) : (
        <div className="loading-section-wrap" style={{ backgroundColor: 'transparent' }}>
          <img
            className="loading-indicator"
            src={loadingCircle}
            alt="loading"
          />
        </div>
      )}
    </>
  );
}
