/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import './DocumentVerification.css';
import { useSelector } from 'react-redux';
// import { toggleVerifyIdDrawer } from '../../../../../slices/UserSlice';
import QRCodeGen from '../../../QRCodeGen/QRCodeGen';
import { selectKYCCountryLink } from '../../../../../slices/KYCSlice';

export default function DocumentVerification({ options }) {
  const { data: country_data } = useSelector(selectKYCCountryLink);
  const [verificationLink, setVerificationLink] = useState('');
  // const dispatch = useDispatch();

  function verify() {
    // dispatch(toggleVerifyIdDrawer());
    window.open(verificationLink, '_blank');
  }

  useEffect(() => {
    if (country_data) {
      setVerificationLink(country_data);
    }
  }, [options, country_data]);
  return (
    <div className="document-verification">
      <h2 className="section-title" id="document-verification-title">Document verification</h2>
      <p className="document-verification-subtitle">
        Use the link or scan the barcode below to continue.
        You will be directed to a third party website to complete the next steps.
      </p>
      <div className="document-verification-body">
        <div className="barcode-container">
          {country_data && <QRCodeGen data={country_data} />}
        </div>
        <p className="continue-text">Want to continue verification on your device, scan QR Code above</p>
        <div className="or-wrap">
          <hr />
          <p className="text">OR</p>
          <hr />
        </div>
        <button onClick={verify} className="proceed-btn">Click here to proceed</button>
      </div>
    </div>
  );
}
