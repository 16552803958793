/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as states from '../states/ComponentStates';
import TransactionService from '../services/transactionsService';

const initialState = {
  status: states.BASE,
  transactions: [],
  explorerLinks: null,
  error: null,
};

export const fetchTransactions = createAsyncThunk('transactions/fetch', async (_, { rejectWithValue }) => {
  try {
    const response = await TransactionService.getTransactions();
    /**
     * [[ IMPORTANT ]]
     * It is recommended to return only the part of the response that you need from this thunk
     * If you return the whole response object from this function, you will have to access the
     * data you need inside
     * your builder.addCase() callback and if you run into any errors there, you will have to
     * handle the exceptions
     * there. It's better to catch and handle all exceptions here. That's why we need to access
     * the data here and
     * return it.
     */
    return response.response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getTransactionExplorerLink = createAsyncThunk('transactions/getExplorerLink', async (_, { rejectWithValue }) => {
  try {
    const response = await TransactionService.getBlockchainExplorerLink();
    return response.response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      if (!(action.payload === states.BASE
        || action.payload === states.FETCHING
        || action.payload === states.ERROR
        || action.payload === states.FETCHED
        || action.payload === states.POPULATING
        || action.payload === states.POPULATED)) {
        return;
      }
      state.status = action.payload;
    },
    updateTransactions: (state, action) => {
      state.transactions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload;
      state.status = states.FETCHED;
    });
    builder.addCase(fetchTransactions.rejected, (state) => {
      state.status = states.ERROR;
    });
    builder.addCase(getTransactionExplorerLink.pending, () => {});
    builder.addCase(getTransactionExplorerLink.fulfilled, (state, action) => {
      state.explorerLinks = action.payload;
    });
    builder.addCase(getTransactionExplorerLink.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const selectTransactionLengths = (state) => state.transactions.transactionLength;
export const selectActiveTab = (state) => state.transactions.transactionLength;

export default transactionsSlice.reducer;
export const { updateStatus, updateTransactions } = transactionsSlice.actions;
