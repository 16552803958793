import * as Keys from './storageKeys';

export default class OnboardingManager {
  static setTooltipPlayed() {
    localStorage.setItem(Keys.TOOLTIP_PLAYED, true);
  }

  static hasNotPlayedTooltip() {
    const value = localStorage.getItem(Keys.TOOLTIP_PLAYED);
    return value === undefined || value === null || !value;
  }

  static resetTooltipViewState() {
    localStorage.removeItem(Keys.TOOLTIP_PLAYED);
  }

  static setWhatsNewViewed() {
    localStorage.setItem(Keys.WHATS_NEW_VIEWED, true);
  }

  static hasNotViewedWhatsNew() {
    const value = localStorage.getItem(Keys.WHATS_NEW_VIEWED);
    return value === undefined || value === null || !value;
  }

  static resetWhatsNewViewState() {
    localStorage.removeItem(Keys.WHATS_NEW_VIEWED);
  }
}
