/* eslint-disable max-len */
import React from 'react';
import registrationRoutes from './registrationRoutes';
import authRoutes from './authRoutes';

/* eslint-disable import/prefer-default-export */
const ConfirmPin = React.lazy(() => import('../../pages/auth/ConfirmPin'));
const ForgetPassword = React.lazy(() => import('../../pages/auth/ForgetPassword'));
const SignIn = React.lazy(() => import('../../pages/auth/SignIn'));
const GetStarted = React.lazy(() => import('../../pages/auth/GetStarted'));
const SetNewPassowrd = React.lazy(() => import('../../pages/auth/SetNewPassword'));
const SetYourPin = React.lazy(() => import('../../pages/auth/SetYourPin'));
const VerifyAccount = React.lazy(() => import('../../pages/auth/VerifyAccount'));
const VerifyLoginAccount = React.lazy(() => import('../../pages/auth/VerifyLoginAccount'));
// const Register = React.lazy(() => import('../../pages/auth/Register'));
const SignUpComplete = React.lazy(() => import('../../pages/auth/sign_up_complete/SignUpComplete'));
const LegacySetPassword = React.lazy(() => import('../../pages/auth/LegacySetPassword'));
const Banned = React.lazy(() => import('../../pages/auth/banned'));
const AccountToLoginTo = React.lazy(() => import('../../pages/auth/SignIn/AccountToLoginTo'));
const ReportAccount = React.lazy(() => import('../../pages/auth/SignIn/ReportAccount'));
const SignInWithEmail = React.lazy(() => import('../../pages/auth/SignIn/SignInWithEmail'));

// Create-Pin flow
const RegistrationCreatePin = React.lazy(() => import('../../features/auth/registration/view/pages/RegistrationCreatePin/RegistrationCreatePin'));
const SuccessfulPinSetUp = React.lazy(() => import('../../features/auth/registration/view/pages/SuccessfulPinSetUp/SuccessfulPinSetUp'));
// const SetPushNotification = React.lazy(() => import('../../features/auth/registration/view/pages/SetPushNotification/SetPushNotification'));

// Personalization flow
const RegistrationForBanks = React.lazy(() => import('../../features/auth/registration/preferences/pages/RegistrationForBanks/RegistrationForBanks'));
// const RegisterBankDetails = React.lazy(() => import('../../features/auth/registration/preferences/pages/RegisterBankDetails/RegisterBankDetails'));
// const RegisterMomoOperator = React.lazy(() => import('../../features/auth/registration/preferences/pages/RegisterMomoOperator/RegisterMomoOperator'));
// const RegisterMomoDetails = React.lazy(() => import('../../features/auth/registration/preferences/pages/RegisterMomoDetails/RegisterMomoDetails'));
const BankSetupSuccess = React.lazy(() => import('../../features/auth/registration/preferences/pages/BankSetupSuccess/BankSetupSuccess'));
const UserPreferences = React.lazy(() => import('../../features/auth/registration/preferences/pages/UserPreferences/UserPreferences'));

const RegistrationVerifyIdentity = React.lazy(() => import('../../features/auth/registration/view/pages/RegistrationVerifyIdentity/RegistrationVerifyIdentity'));

const StartRegistration = React.lazy(() => import('../../features/auth/registration/view/pages/StartRegistration/StartRegistration'));
const RegistrationPersonalDetails = React.lazy(() => import('../../features/auth/registration/view/pages/RegistrationPersonalDetails/RegistrationPersonalDetails'));
const FindHomeAddress = React.lazy(() => import('../../features/auth/registration/view/pages/FindHomeAddress/FindHomeAddress'));
const EnterUsername = React.lazy(() => import('../../features/auth/registration/view/pages/EnterUsername/EnterUsername'));
const PersonalDetailVerified = React.lazy(() => import('../../features/auth/registration/view/pages/PersonalDetailVerified/PersonalDetailVerified'));
const CheckRegistrationReferral = React.lazy(() => import('../../features/auth/registration/preferences/pages/CheckRegistrationReferral/CheckRegistrationReferral'));
const UserPreferenceSuccess = React.lazy(() => import('../../features/auth/registration/preferences/pages/UserPreferenceSuccess/UserPreferenceSuccess'));

export const Onboarding_route_group = [
  { path: authRoutes.getStarted, component: <GetStarted /> },
  { path: authRoutes.signIn, component: <SignIn /> },
  { path: '/login/email', component: <SignInWithEmail /> },
  { path: '/login-with', component: <AccountToLoginTo /> },
  { path: '/report-account', component: <ReportAccount /> },
  // { path: '/register', component: Register },
  { path: '/verify-device', component: <VerifyLoginAccount /> },
  { path: '/verify-account', component: <VerifyAccount /> },
  { path: '/set-your-pin', component: <SetYourPin /> },
  { path: '/confirm-pin', component: <ConfirmPin /> },
  { path: '/forget-password', component: <ForgetPassword /> },
  // TODO: here is a redirect route
  { path: '/account/token/:passwordToken', component: <LegacySetPassword /> },
  { path: '/set-new-password/:passwordToken', component: <SetNewPassowrd /> },
  { path: '/signup-complete', component: <SignUpComplete /> },
  { path: '/banned', component: <Banned /> },

  { path: registrationRoutes.startRegistration, component: <StartRegistration /> },
  { path: registrationRoutes.personalDetails, component: <RegistrationPersonalDetails /> },
  { path: registrationRoutes.findHomeAddress, component: <FindHomeAddress /> },
  { path: registrationRoutes.enterUsername, component: <EnterUsername /> },
  { path: registrationRoutes.personalDetailsVerified, component: <PersonalDetailVerified /> },

  { path: registrationRoutes.createPin, component: <RegistrationCreatePin /> },
  { path: registrationRoutes.successPinSetup, component: <SuccessfulPinSetUp /> },
  // { path: '/register-new/set-push-notification', component: SetPushNotification },

  { path: registrationRoutes.verifyIdentity, component: <RegistrationVerifyIdentity /> },

  { path: registrationRoutes.bankIndex, component: <RegistrationForBanks /> },
  // { path: '/register-new/bank-details', component: RegisterBankDetails },
  // { path: '/register-new/momo-details', component: RegisterMomoDetails },
  // { path: '/register-new/momo-operator', component: RegisterMomoOperator },
  { path: registrationRoutes.bankSetupSuccess, component: <BankSetupSuccess /> },
  { path: registrationRoutes.userPreference, component: <UserPreferences /> },
  { path: registrationRoutes.referral, component: <CheckRegistrationReferral /> },
  { path: registrationRoutes.preferenceSuccess, component: <UserPreferenceSuccess /> },
];
