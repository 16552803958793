/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ribsService from '../services/ribsService';

export const LoadingStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  ERROR: 'error',
};

const initialState = {
  user: {
    myRIBs: [
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
    ],
  },
  userBags: {
    loadingState: LoadingStates.BASE,
    data: [],
    error: null,
  },
  recommended: {
    RIBs: [
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Bag name goes here',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'trx',
            name: 'Tron',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Quick Money',
      },
      {
        coins: [
          {
            symbol: 'btc',
            name: 'Bitcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'ltc',
            name: 'Litecoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'doge',
            name: 'Doge coin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'dash',
            name: 'Dashcoin',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'xrp',
            name: 'Ripple',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
          {
            symbol: 'eth',
            name: 'Ethereum',
            percentage: 0.14,
            price: 32000,
            change: 0.0104,
          },
        ],
        bagName: 'Bag name goes here',
      },
    ],
  },

  rinsApplicationDrawerOpen: false,

  inspectBagURL: '/dashboard-earn-ribs-inspect',

  fetchSingleBag: {
    status: LoadingStates.BASE,
    ribsBag: null,
    error: null,
  },
  dumpBag: {
    status: LoadingStates.BASE,
    error: null,
  },
};

export const getSingleBag = createAsyncThunk('ribsGlobal/getSingleBag', async (bagRef, { rejectWithValue }) => {
  try {
    const response = await ribsService.fetchSingleBag(bagRef);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const dumpSingleBag = createAsyncThunk('ribsGlobal/dumpSingleBag', async ({ bag_reference, currency }, { rejectWithValue }) => {
  try {
    const response = await ribsService.dumpBag({ bag_reference, currency });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getUserBags = createAsyncThunk('ribsGlobal/getUserBags', async (_, { rejectWithValue }) => {
  try {
    const response = await ribsService.getUserBags();
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

const ribsGlobalSlice = createSlice({
  name: 'ribsGlobal',
  initialState,
  reducers: {
    resetGetSingleBag(state) {
      state.fetchSingleBag.status = initialState.fetchSingleBag.status;
      state.fetchSingleBag.error = initialState.fetchSingleBag.error;
      state.fetchSingleBag.ribsBag = initialState.fetchSingleBag.ribsBag;
    },
  },
  extraReducers: (builder) => {
    // fetch single bag
    builder.addCase(getSingleBag.pending, (state) => {
      state.fetchSingleBag.status = LoadingStates.LOADING;
    });
    builder.addCase(getSingleBag.fulfilled, (state, { payload }) => {
      state.fetchSingleBag.ribsBag = payload;
      state.fetchSingleBag.status = LoadingStates.SUCCESSFUL;

      // if (payload.status !== 'fail') {
      //   state.fetchSingleBag.ribsBag = payload;
      //   state.fetchSingleBag.status = LoadingStates.SUCCESSFUL;
      // } else {
      //   state.fetchSingleBag.error = payload.message;
      //   state.fetchSingleBag.status = LoadingStates.ERROR;
      // }
    });
    builder.addCase(getSingleBag.rejected, (state, action) => {
      state.fetchSingleBag.error = action.payload;
      state.fetchSingleBag.status = LoadingStates.ERROR;
    });

    // dump bag
    builder.addCase(dumpSingleBag.pending, (state) => {
      state.dumpBag.status = LoadingStates.LOADING;
    });
    builder.addCase(dumpSingleBag.fulfilled, (state) => {
      state.dumpBag.status = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(dumpSingleBag.rejected, (state, action) => {
      state.dumpBag.status = LoadingStates.ERROR;
      state.dumpBag.error = action.payload;
    });

    // dump bag
    builder.addCase(getUserBags.pending, (state) => {
      state.userBags.status = LoadingStates.LOADING;
    });
    builder.addCase(getUserBags.fulfilled, (state, { payload }) => {
      state.userBags.data = payload;
      state.userBags.status = LoadingStates.SUCCESSFUL;
    });
    builder.addCase(getUserBags.rejected, (state, action) => {
      state.userBags.error = action.payload;
      state.userBags.status = LoadingStates.ERROR;
    });
  },
});

export function returnRIBsInspectBagURL(slug) {
  return initialState.inspectBagURL + slug;
}

export const selectUserRIBs = (state) => state.ribsGlobal.user;
export const selectInspectBagURL = (state) => state.ribsGlobal.inspectBagURL;
export const selectRecommendedRIBs = (state) => state.ribsGlobal.recommended;
export const selectUserBags = (state) => state.ribsGlobal.userBags;

export const { resetGetSingleBag } = ribsGlobalSlice.actions;
export default ribsGlobalSlice.reducer;
