/* eslint-disable react/prop-types */
import React from 'react';
import '../../../../styles/dashboard/layout/NotificationDropdown.css';
import moment from 'moment';
import ButtonReuse from '../../../fragments/Button';

function NotificationItemModal({ notificationModalItem, closeNotificationSelectModal }) {
  return (
    <div className="notification-item-modal-container">
      <div className="default-background notification-item-modal">
        <div className="modal-title">
          {typeof notificationModalItem?.title === 'string' ? notificationModalItem?.title : 'Title here'}
        </div>
        <div className="secondary-white-black-color3 modal-date">
          {/* 01-04-2023  01:20am */}
          {moment(notificationModalItem?.created_at).format('DD-MM-YYYY hh:mma')}
        </div>
        <div className="modal-details secondary-white-black-color3">
          {notificationModalItem?.body}
        </div>

        <div className="button-wrapper">
          <ButtonReuse text="Close" btnStyle="btn-reuse" onClick={() => closeNotificationSelectModal()} />
        </div>
      </div>
    </div>
  );
}

export default NotificationItemModal;
