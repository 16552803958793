/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as states from '../states/ComponentStates';
import SavingService from '../services/savingService';

export const savingStatus = {
  SAVE: 'save',
  WITHDRAW: 'withdraw'
};

export const supportedSavingType = {
  USDT: 'Tether USDT'
};

const initialState = {
  status: states.BASE,
  userSavings: [],
  selectedRate: {},
  selectedSavingFromDashboard: {},
  error: '',
};

// Get user saving
export const fetchUserSaving = createAsyncThunk(
  'saving/fetch',
  async (data, { rejectWithValue }) => {
    try {
      const { token } = data;
      const response = await SavingService.getUserSavings({ token });

      return response.response.data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// Create new USDT saving
export const createUSDTSaving = createAsyncThunk(
  'saving/create-USDT',
  async (data, { rejectWithValue }) => {
    try {
      const { token, ref, amount, pin } = data;
      const response = await SavingService.createNewUSDTSaving({
        token,
        ref,
        amount,
        pin,
      });

      return response.response.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// Withdraw USDT saving
export const withdrawUSDTSaving = createAsyncThunk(
  'saving/withdraw-USDT',
  async (data, { rejectWithValue }) => {
    try {
      const { save_id, amount, pin, } = data;
      const response = await SavingService.withdrawUSDTSaving({
        save_id,
        amount,
        pin,
      });

      return response.response.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

const savingSlice = createSlice({
  name: 'saving',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      if (
        !(
          action.payload === states.BASE ||
          action.payload === states.FETCHING ||
          action.payload === states.ERROR ||
          action.payload === states.FETCHED ||
          action.payload === states.POPULATING ||
          action.payload === states.POPULATED
        )
      ) {
        return;
      }
      state.status = action.payload;
    },

    selectSavingRate: (state, action) => {
      state.selectedRate = action.payload;
    },

    setSelectSavingFromDashboard: (state, action) => {
      state.selectedSavingFromDashboard = action.payload;
    },

    setSavingError: (state, action) => {
      state.error = action.payload;
    },

    resetSavingError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    // extraReducers for fetchUserSaving
    builder.addCase(fetchUserSaving.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(fetchUserSaving.fulfilled, (state, action) => {
      // Hard coded response for userSavings

      // state.userSavings = [
      // new >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // {
      //   current_amount: 20,
      //   id: 1,
      //   interest: 0.8,
      //   principal: 20,
      //   release_date: "2023-01-09 08:58:43.617"
      //   mature: false (needed)
      //   name: string (needed)
      // }
      // old >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      //   {
      //     name: 'Naira Saving',
      //     date: 'Sep 16, 2022',
      //     amount: '10 USDT',
      //     bonus: '8.0 APY',
      //     mature: true,
      //   },
      // ];
      state.userSavings = action.payload;
      state.status = states.FETCHED;
    });
    builder.addCase(fetchUserSaving.rejected, (state) => {
      state.status = states.ERROR;
    });

    // extraReducers for createUSDTSaving
    builder.addCase(createUSDTSaving.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(createUSDTSaving.fulfilled, (state, action) => {
      const { status, message } = action.payload;
      if (status === 'fail') {
        state.error = message;
      } else {
        state.status = states.SUCCESSFUL;
      }
    });
    builder.addCase(createUSDTSaving.rejected, (state) => {
      state.status = states.ERROR;
    });

    // extraReducers for withdrawUSDTSaving
    builder.addCase(withdrawUSDTSaving.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(withdrawUSDTSaving.fulfilled, (state, action) => {
      const { status, message } = action.payload;
      if (status === 'fail') {
        state.error = message;
        // state.status = states.BASE;
      } else {
        state.status = states.SUCCESSFUL;
      }
    });
    builder.addCase(withdrawUSDTSaving.rejected, (state) => {
      state.status = states.ERROR;
    });
  },
});

export default savingSlice.reducer;
export const {
  updateStatus,
  selectSavingRate,
  setSelectSavingFromDashboard,
  setSavingError,
  resetSavingError,
} = savingSlice.actions;
