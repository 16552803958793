/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LoanService from '../services/loanService';

export const LoanStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

export const LoanPayType = {
  REPAY: 'repay-loan',
  APPLY: 'apply-loan',
};

const initialState = {
  allOverdrafts: {
    status: LoanStates.LOADING,
    data: null,
    error: false,
  },
  applyOverdraft: {
    status: LoanStates.BASE,
    data: null,
    loanDetails: null,
    error: false,
  },
  repayOverdraft: {
    status: LoanStates.BASE,
    data: null,
    error: false,
    loanDetails: null,
  },
  overdraftConfig: {
    status: LoanStates.BASE,
    collaterals: null,
    error: false,
    ratio: 0,
    interest: 0,
  },
};

export const fetchUserOverdraft = createAsyncThunk(
  'loan/fetchUserOverdraft',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await LoanService.getUserOverdraft(token);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const applyForOverdraft = createAsyncThunk(
  'loan/applyForOverdraft',
  async ({
    amount, coin, pin,
  }, { rejectWithValue }) => {
    try {
      const { data } = await LoanService.applyOverdraft({
        amount, coin, pin,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const repayOverdraft = createAsyncThunk(
  'loan/repayOverdraft',
  async ({
    amount, pin,
  }, { rejectWithValue }) => {
    try {
      const { data } = await LoanService.repayOverdraft({
        amount, pin,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const getOverdraftSettings = createAsyncThunk(
  'loan/getOverdraftSettings',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await LoanService.getOverdraftConfigService(token);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    saveLoanDetails: (state, action) => {
      state.applyOverdraft.loanDetails = action.payload;
    },
    resetPayStatus: (state) => {
      state.applyOverdraft.status = initialState.applyOverdraft.status;
      state.repayOverdraft.status = initialState.repayOverdraft.status;
    },
  },
  extraReducers: (builder) => {
    // fetchUserOverdraft thunk reducers...
    builder.addCase(fetchUserOverdraft.pending, (state) => {
      state.allOverdrafts.status = LoanStates.LOADING;
    });
    builder.addCase(fetchUserOverdraft.fulfilled, (state, action) => {
      state.allOverdrafts.data = action.payload;
      state.allOverdrafts.status = LoanStates.SUCCESSFUL;
    });
    builder.addCase(fetchUserOverdraft.rejected, (state) => {
      state.allOverdrafts.status = LoanStates.FAILED;
    });

    // applyForOverdraft thunk reducers...
    builder.addCase(applyForOverdraft.pending, (state) => {
      state.applyOverdraft.status = LoanStates.LOADING;
    });
    builder.addCase(applyForOverdraft.fulfilled, (state, action) => {
      if (action.payload.status === 'fail') {
        state.applyOverdraft.status = LoanStates.FAILED;
        state.applyOverdraft.data = action.payload.message;
      } else {
        state.applyOverdraft.data = action.payload;
        state.applyOverdraft.status = LoanStates.SUCCESSFUL;
      }
    });
    builder.addCase(applyForOverdraft.rejected, (state) => {
      state.applyOverdraft.status = LoanStates.FAILED;
    });

    // repayOverdraft thunk reducers...
    builder.addCase(repayOverdraft.pending, (state) => {
      state.repayOverdraft.status = LoanStates.LOADING;
    });
    builder.addCase(repayOverdraft.fulfilled, (state, action) => {
      const { data, status, message } = action.payload;
      if (status !== 'fail') {
        state.repayOverdraft.data = data;
        state.repayOverdraft.status = LoanStates.SUCCESSFUL;
      } else {
        state.repayOverdraft.status = LoanStates.FAILED;
        state.repayOverdraft.error = message;
      }
    });
    builder.addCase(repayOverdraft.rejected, (state) => {
      state.repayOverdraft.status = LoanStates.FAILED;
    });

    // getOverdraftSettings thunk reducers...
    builder.addCase(getOverdraftSettings.pending, (state) => {
      state.overdraftConfig.status = LoanStates.LOADING;
    });
    builder.addCase(getOverdraftSettings.fulfilled, (state, action) => {
      state.overdraftConfig.collaterals = action.payload.collaterals;
      state.overdraftConfig.ratio = action.payload.ratio;
      state.overdraftConfig.interest = action.payload.interest;
      state.overdraftConfig.status = LoanStates.SUCCESSFUL;
    });
    builder.addCase(getOverdraftSettings.rejected, (state) => {
      state.overdraftConfig.status = LoanStates.FAILED;
    });
  },
});

export const selectAllOverdraft = (state) => state.loan.allOverdrafts;
export const selectApplyOverdraft = (state) => state.loan.applyOverdraft;
export const selectRepayOverdraft = (state) => state.loan.repayOverdraft;
export const selectOverdraftConfig = (state) => state.loan.overdraftConfig;
export const selectApplyLoanDetails = (state) => state.loan.applyOverdraft.loanDetails;

export default loanSlice.reducer;
export const { saveLoanDetails, resetPayStatus } = loanSlice.actions;
