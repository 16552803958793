import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import {
  getUser,
  resetUser,
  setToken,
  setUser,
  signOut,
} from '../slices/authSlice';
import { resetUserSlice } from '../slices/UserSlice';
import * as Keys from '../utils/storageKeys';

const useUser = () => {
  const openRoute = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  // const token = useSelector(getToken);
  const token = AuthService._getUserToken();

  function getUserInfo() {
    return user?.user_info;
  }

  function setTokenLocalstorage(tk) {
    localStorage.setItem('rjt', tk);
    dispatch(setToken(tk));
  }

  function setUserLocalstorage(data) {
    // save user in context state...
    dispatch(setUser(data));
    sessionStorage.setItem(Keys.EMAIL_TEMP, data.email);
    sessionStorage.setItem('user', JSON.stringify(data));
  }

  function signOutHandler() {
    dispatch(signOut());
    dispatch(resetUser());
    dispatch(resetUserSlice());
    openRoute('/login');
    // reload page on route to clear all state automatically
    // window.location.reload();
  }

  return {
    token,
    tx_charge: (getUserInfo()?.tx_charge || 1) / 100,
    signOut: signOutHandler,
    fullname: `${getUserInfo()?.fname} ${getUserInfo()?.lname}`,
    user: getUserInfo() || null,
    setUserLocalstorage,
    setTokenLocalstorage,
  };
};

export default useUser;
