/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Fragment, useMemo } from 'react';
import '../../../styles/dashboard/layout/LanguageDropdown.css';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';
import { useIcon } from '../../../hooks/useIcon';
import { THEME_MODES } from '../../../utils/themes/useDarkMode';

function ProfileDropDown({
  visible, onClose, toggleTheme, theme,
  signOut, openNotification, closeNotification,
}) {
  const history = useNavigate();
  const {
    signOut: signOutIcon,
    myAccountpd,
    helppd,
    notificationpd,
  } = useIcon();

  function handleDelayedClose() {
    setTimeout(() => {
      onClose();
    }, 100);
  }

  function handleSignOut() {
    signOut();
    handleDelayedClose();
  }

  function handleOpenNotification() {
    if (openNotification) {
      openNotification();
    }
    handleDelayedClose();
  }

  const dpItems = useMemo(() => [
    {
      title: 'My account',
      link: '/dashboard-setting',
      icon: myAccountpd,
    },
    {
      title: 'Help',
      link: '/dashboard-help-support',
      icon: helppd,
    },
  ], []);

  function handleNavClick(_link) {
    if (_link) {
      history(_link);
      if (openNotification) {
        closeNotification();
      }
      handleDelayedClose();
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => onClose()}>
      {visible && (
        <div className="language-dropdown profile-dropdown-dp default-drop-arrow default-background border-this container-fade-in-animation">
          <div className="profile-link-section">
            {dpItems.map((d) => (
              <Fragment key={d.link}>
                <div
                  className="link-item"
                  onClick={() => handleNavClick(d.link)}
                >
                  <figure className="img-box">
                    <img src={d.icon} alt="" className="img" />
                  </figure>
                  <span className="secondary-white-black-color3">{d.title}</span>
                </div>
              </Fragment>
            ))}
            <div className="link-item" style={{ cursor: 'pointer' }} onClick={() => handleOpenNotification()}>
              <figure className="img-box">
                <img src={notificationpd} alt="" className="img" />
              </figure>
              <span className="secondary-white-black-color3">Notifications</span>
            </div>
          </div>

          <div className="profile-mode-selection border-light">
            <div className="theme-container">
              <div
                className={`theme-item ${theme === THEME_MODES.black && 'active'}`}
                onClick={() => theme !== THEME_MODES.black && toggleTheme(THEME_MODES.black)}
              >
                Black
              </div>
              <div
                className={`theme-item ${theme === THEME_MODES.dark && 'active'}`}
                onClick={() => theme !== THEME_MODES.dark && toggleTheme(THEME_MODES.dark)}
              >
                Dark
              </div>
              <div
                className={`theme-item ${theme === THEME_MODES.light && 'active'}`}
                onClick={() => theme !== THEME_MODES.light && toggleTheme(THEME_MODES.light)}
              >
                Light
              </div>
            </div>
          </div>

          <div className="profile-link-section" style={{ cursor: 'pointer' }} onClick={() => handleSignOut()}>
            <div className="link-item">
              <figure className="img-box">
                <img src={signOutIcon} alt="" className="img" />
              </figure>
              <span className="secondary-white-black-color3">Sign out</span>
            </div>
          </div>
        </div>
      )}
    </OutsideClickHandler>
  );
}

export default ProfileDropDown;
