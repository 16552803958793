/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
import { Buffer, createCipheriv, createDecipheriv } from 'browser-crypto';
import axios from 'axios';
import naira from '../assets/country-flags/ngn-flag.svg';
import dollar from '../assets/country-flags/usd-flag.svg';
import euro from '../assets/country-flags/eur-flag.svg';
import itFlag from '../assets/country-flags/it-flag.svg';
import seFlag from '../assets/country-flags/sw-flag.svg';
import pound from '../assets/country-flags/gbp-flag.svg';
import cedi from '../assets/country-flags/ghs-flag.svg';
import registrationRoutes from '../navigation/auth/registrationRoutes';

export function canSkipRegistrationStep(_country, pathname = '') {
  // can NGN skip a step
  if (_country !== 'ng') {
    return false;
  }

  const _steps_can_skip = [
    registrationRoutes.verifyIdentity,
    registrationRoutes.bankIndex,
    registrationRoutes.userPreference,
    registrationRoutes.referral,
    registrationRoutes.preferenceSuccess,
  ];

  return _steps_can_skip.includes(pathname);
}

export function validateInputPassword(password) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
}

export const consoleLog = (...arg) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  console.log(...arg);
};

export const print = (message, ...optionalParams) => {
  if (process.env.NODE_ENV === 'production') return;
  // eslint-disable-next-line no-console
  console.log(message, ...optionalParams);
};

export const checkForTier = (limit) => {
  if (!limit || typeof limit !== 'number') return '';

  if (limit >= 0 && limit <= 10000) {
    // 0
    return 1; // tier to upgrade to
  }
  if (limit > 10000 && limit <= 16000) {
    // 1
    return 2;
  }
  if (limit > 16000 && limit <= 30000) {
    // 2
    return 3;
  }
  return 3; // 3
};

// This return the month from a given timestamp data
export const findMonth = (param) => {
  switch (param) {
    case 1:
      return 'JAN';
    case 2:
      return 'FEB';
    case 3:
      return 'MAR';
    case 4:
      return 'APR';
    case 5:
      return 'MAY';
    case 6:
      return 'JUN';
    case 7:
      return 'JUL';
    case 8:
      return 'AUG';
    case 9:
      return 'SEPT';
    case 10:
      return 'OCT';
    case 11:
      return 'NOV';
    case 12:
      return 'DEC';
    default:
      return '';
  }
};

export const getCurrency = (currency = '') => {
  let data = { c: currency, n: '' };
  if (!currency) return data;
  currency = currency?.toLowerCase();
  // s -> symbol (₦)
  // n -> name (Nigerian Naira)
  // c -> currency (ngn)
  switch (currency) {
    case 'ngn':
    case 'ng':
      data.s = '₦';
      data.n = 'Nigerian Naira';
      data.shortname = 'Naira';
      data.country = 'Nigeria';
      data.countryCode = 'ng';
      data.img = naira;
      return data;

    case 'usd':
    case 'us':
      data.s = '$';
      data.n = 'US Dollar';
      data.shortname = 'Dollar';
      data.country = 'United States of America';
      data.countryCode = 'us';
      data.img = dollar;
      return data;

    case 'gbp':
    case 'gb':
      data.s = '£';
      data.n = 'British Pounds';
      data.shortname = 'Pound';
      data.country = 'Great Britain';
      data.countryCode = 'gb';
      data.img = pound;
      return data;

    case 'eur':
    case 'eu':
      data.s = '€';
      data.n = 'Euro';
      data.shortname = 'Euro';
      data.country = 'Europe';
      data.countryCode = 'eu';
      data.img = euro;
      return data;

    case 'sw':
      data.s = 'kr ';
      data.n = 'Swedish Krona';
      data.shortname = 'Krona';
      data.country = 'Sweden';
      data.countryCode = 'sw';
      data.img = seFlag;
      return data;

    case 'ghs':
    case 'gh':
      data.s = '₵';
      data.n = 'Ghanaian Cedi';
      data.shortname = 'Cedi';
      data.country = 'Ghana';
      data.countryCode = 'gh';
      data.img = cedi;
      return data;
    // these countries are in Europe (Euro)
    case 'it':
      data.s = '€';
      data.n = 'Euro';
      data.shortname = 'Euro';
      data.country = 'Italy';
      data.countryCode = 'it';
      data.img = itFlag;
      return data;

    default:
      data = {
        c: currency,
        s: currency,
        n: '',
        img: '',
      };
      return data;
  }
};

/**
 * This function removes anything not a number
 * @param {String} str
 */
export const removeAlpha = (str) => {
  if (str && typeof str === 'string') {
    return str.replace(/[^0-9.]/g, '');
  }

  return str;
};

/**
 * This function takes a very small number and converts it based on the decimal place
 * @param {Number} num The number to check for
 * @param {Number} decimalPlaces The number of decimal places, defaulted to 6
 * @returns The complete floated number of the very small number if it is small
 */
export function checkForReallySmallNumbers(num, decimalPlaces = 6) {
  const _num = `${num}`;
  const _isReallySmall = _num.includes('e-');
  // return same number if not really small
  if (!_isReallySmall) return _num;

  const _numSeperated = _num.split('.');
  const _newnum = _numSeperated[0]; // whole part of number
  const _hasSuffix = _numSeperated.length > 1;

  // return first part of number if there are no numbers after '.'
  if (!_hasSuffix) return _newnum;

  const _expSplit = _num.split('e-');
  const _exponential = parseInt(_expSplit[1], 10);
  let eValue = '0.';

  if (_exponential < decimalPlaces) {
    for (let j = _exponential - 1; j > 0; j -= 1) {
      eValue += '0';
    }
    return eValue + _newnum + _expSplit[0].substring(0, decimalPlaces);
  }

  for (let j = decimalPlaces - 1; j > 0; j -= 1) {
    eValue += '0';
  }

  return eValue;
}

/**
 * Creates a custom floating number
 * @param {Number} prefix The number before the decimal place
 * @param {Number} suffix The numbers after the decimal place
 * @param {Number} decimalPlaces The number of decimal places
 * @returns {Number} A custom floating number
 */
const _formatNumberOutput = (prefix, suffix, decimalPlaces) => {
  let withoutSuffix = '.';
  for (let j = decimalPlaces; j > 0; j -= 1) {
    withoutSuffix += '0';
  }
  return `${prefix}${
    suffix ? `.${suffix.substring(0, decimalPlaces)}` : decimalPlaces ? withoutSuffix : ''
  }`;
};

/**
 * Format a number with comma, and very small numbers
 * @param {Number} val The number to be formatted
 * @param {Number} decimalPlaces The number of decimal places
 * @param {Boolean} formatComma If number should be formatted with comma's
 * @returns {String} Formatted number
 */
export const formatNumber = (val, decimalPlaces = 2, formatComma = true) => {
  // Check if the value is a number
  if (Number.isNaN(val)) return val;

  if (typeof number === 'string') {
    val = removeAlpha(val);
  }

  let newnum = `${val}`;
  let integerAndDecimal;
  let integerPart;
  let decimalPart;

  integerAndDecimal = newnum.split('.');
  integerPart = integerAndDecimal[0];
  decimalPart = integerAndDecimal[1];

  const hasDecimalFraction = integerAndDecimal.length > 1;

  if (hasDecimalFraction && integerAndDecimal[1].includes('e-')) {
    return checkForReallySmallNumbers(val);
  }

  // if (integerPart !== '0' && integerPart.length > 1) {
  //   return _formatNumberOutput(integerPart, hasDecimalFraction ? decimalPart : '', 2);
  // }

  if (integerPart === '0' || integerPart.length <= 3 || !formatComma) {
    // Check if length of string is less than three
    return _formatNumberOutput(integerPart, hasDecimalFraction ? decimalPart : '', decimalPlaces); // Less than three, no need to format
  }

  if (formatComma) {
    // Greater than three
    let count = 1;
    let newstr;
    let str = '';

    for (let i = integerPart.length - 1; i >= 0; i -= 1) {
      //
      if (count === 3) {
        newstr = integerPart.slice(i, i + 3);
        str = newstr + str;
        if (i > 0) {
          str = `,${str}`;
        }
        count = 0;
      } else if (count !== 3 && i === 0) {
        newstr = integerPart.slice(i, i + count);
        str = newstr + str;
      }
      count += 1;
    }
    return _formatNumberOutput(str, hasDecimalFraction ? decimalPart : '', decimalPlaces);
  }
};

/**
 * Format a number with comma, and very small numbers
 * @param {Number} val The number to be formatted
 * @param {Number} decimalPlaces The number of decimal places
 * @param {Boolean} formatComma If number should be formatted with comma's
 * @returns {String} Formatted number
 */
export const formatNumWithVariableDecimal = (val, decimalPlaces = 2, formatComma = true) => {
  // Check if the value is a number
  if (Number.isNaN(val)) return val;

  if (typeof number === 'string') {
    val = removeAlpha(val);
  }

  let newnum = `${val}`;
  let integerAndDecimal;
  let integerPart;
  let decimalPart;

  integerAndDecimal = newnum.split('.');
  integerPart = integerAndDecimal[0];
  decimalPart = integerAndDecimal[1];

  const hasDecimalFraction = integerAndDecimal.length > 1;

  if (hasDecimalFraction && integerAndDecimal[1].includes('e-')) {
    return checkForReallySmallNumbers(val);
  }

  // if (integerPart !== '0' && integerPart.length > 1) {
  //   return _formatNumberOutput(integerPart, hasDecimalFraction ? decimalPart : '', 2);
  // }

  if (integerPart === '0' || integerPart.length <= 3 || !formatComma) {
    // Check if length of string is less than three
    return _formatNumberOutput(integerPart, hasDecimalFraction ? decimalPart : '', decimalPlaces); // Less than three, no need to format
  }

  if (formatComma) {
    // Greater than three
    let count = 1;
    let newstr;
    let str = '';

    for (let i = integerPart.length - 1; i >= 0; i -= 1) {
      //
      if (count === 3) {
        newstr = integerPart.slice(i, i + 3);
        str = newstr + str;
        if (i > 0) {
          str = `,${str}`;
        }
        count = 0;
      } else if (count !== 3 && i === 0) {
        newstr = integerPart.slice(i, i + count);
        str = newstr + str;
      }
      count += 1;
    }
    return _formatNumberOutput(
      str,
      hasDecimalFraction ? decimalPart : '',
      integerPart !== '0' && integerPart.length > 1 ? 2 : decimalPlaces,
    );
  }
};

/**
 * Formats the input field value and also keeping track of caret
 * @param {Number} v The input field number to be formatted
 * @param {HTMLInput} inputRef The ref to the input field
 * @param {Number} decimalPlaces The number of decimal places
 * @returns {String} Formatted value
 */
export const formatCurrencyField = (v, inputRef, decimalPlaces = 2) => {
  function _formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (!v || v === '') return v;
  let _value = `${v}`;
  const original_len = v.length;
  let caret_pos = inputRef.current.selectionStart;
  if (_value.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimal_pos = _value.indexOf('.');

    // split number by decimal point
    let left_side = _value.substring(0, decimal_pos);
    let right_side = _value.substring(decimal_pos);

    // add commas to left side of number
    left_side = _formatNumber(left_side);
    // validate right side
    right_side = right_side.replace('.', '');

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, decimalPlaces);

    // join number by .
    _value = `${left_side}.${right_side}`;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    _value = _formatNumber(_value);
    _value = `${_value}`;
  }

  // put caret back in the right position
  const updated_len = _value.length;
  caret_pos = updated_len - original_len + caret_pos;
  inputRef.current.setSelectionRange(caret_pos, caret_pos);
  // let newValue;
  // if (_value) {
  //   newValue = `${formatNumber(parseFloat(`${_value || 0}`, 10), 0)}`;
  // } else {
  //   newValue = '';
  // }
  return _value;
};

// This truncate prices without rounding
export const toFixedWithoutRounding = (t, l = 4) => {
  const a = 10 ** l;
  const s = t * a;
  return Math.trunc(s) / a;
};

// Sort an array by a `prop`
export const sortDecendingDate = (data, prop) => data.sort((a, b) => {
  const dateA = new Date(a[prop]);
  const dateB = new Date(b[prop]);
  return dateB - dateA;
});

/**
 * This function takes either a date object or a date string in this format "1995-12-17T03:24:00"
 * and returns a date string in this format "MM DD"
 * @param date
 */
export const returnShortDate = (date) => {
  let stringDate = new Date(date).toString();
  stringDate = stringDate.split(' ');
  const shortDate = `${stringDate[1]} ${stringDate[2]}`;
  return shortDate;
};

/**
 * This function takes either a date object or a date string in this format "1995-12-17T03:24:00"
 * and returns a date string in this format "MM DD YY"
 * @param date
 */
export const returnMidDate = (date) => {
  let stringDate = new Date(date).toString();
  stringDate = stringDate.split(' ');
  const shortDate = `${stringDate[1]} ${stringDate[2]} ${stringDate[3]}`;
  return shortDate;
};

/**
 * This function takes either a date object or a date string in this format "1995-12-17T03:24:00"
 * and returns a date string in this format "DD/MM/YY"
 * @param date
 */
export const returnNotebookDate = (date) => {
  let stringDate = new Date(date);
  let month = stringDate.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = `${month}`;
  }
  stringDate = stringDate.toDateString().split(' ');
  const notebookDate = `${stringDate[2]}/${month}/${stringDate[3]}`;
  return notebookDate;
};

/**
 * This function capitalize the first letter of a string
 */
export const capitalizeFirst = (string) => {
  if (typeof string !== 'string') {
    return string;
  }
  const arr = string.split(' ');
  arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
  return arr.join(' ');
};

/**
 * This function hides part of string with *
 */
export const stringHide = (str, options) => {
  if (!str) {
    return str || '****';
  }
  const { min = 2, max = 4 } = options;
  let hideNum = [];
  for (let i = 0; i < str.length; i += 1) {
    if (i >= min && i < max) {
      hideNum.push('*');
    } else {
      hideNum.push(str[i]);
    }
  }
  return hideNum.join('');
};

export const truncateAddress = (address) => {
  if (address.length > 30) {
    return `${address.slice(0, 7)}...${address.slice(address.length - 7, address.length)}`;
  }
  return address;
};

export const truncateLink = (link, length) => {
  let usedLength = length || 30;
  if (link.length > usedLength) {
    return `${link.slice(0, usedLength)}...`;
  }
  return link;
};

function _generateRandomIV() {
  let code = '';
  let possible = '0123456789';

  for (let i = 0; i < 16; i += 1) {
    code += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return code;
}
// encrypt
const encyptionKey = process.env.REACT_APP_BALABLU;
export function encrypt(string) {
  let randomIV = _generateRandomIV();
  let iv = Buffer.from(randomIV);
  const data = Buffer.from(string);

  const cipher = createCipheriv('aes-256-ctr', encyptionKey, iv);
  let encrypted = Buffer.concat([cipher.update(data), cipher.final()]);

  let encryptedHex = encrypted.toString('hex');
  let ivHex = iv.toString('hex');

  return encryptedHex.slice(0, 10) + ivHex + encryptedHex.slice(10, encryptedHex.length);
}
// decrypt
export function decrypt(hash) {
  const iv = hash.slice(10, 42);
  const content = hash.slice(0, 10) + hash.slice(42, hash.length);

  const decipher = createDecipheriv('aes-256-ctr', encyptionKey, Buffer.from(iv, 'hex'));
  const decrypted = Buffer.concat([decipher.update(Buffer.from(content, 'hex')), decipher.final()]);

  return decrypted.toString();
}

// Translate using google translate API
export async function translate(text) {
  const fromLang = 'en';
  const toLang = localStorage.getItem('i18nextLng');

  if (toLang === 'en') {
    return text; // Return the default text if the user language is set to English
  }
  // Else translate and return the translated text
  const API_KEY = process.env.REACT_APP_TRANSLATION_API_KEY;
  const url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&source=${fromLang}&target=${toLang}&q=${text}`;
  const { data } = await axios.get(url);
  const translatedText = data.data.translations[0].translatedText;
  return translatedText;
}

export const getBankRequirementsData = (key) => {
  if (!key) return null;
  let data = {};
  switch (key) {
    case 'account number':
      data.label = 'Account Number';
      data.fieldType = 'input';
      data.placeholder = 'Enter Account Number';
      data.name = 'account_number';
      data.type = 'number';
      return data;
    case 'bank':
      data.label = 'Bank Name';
      data.fieldType = 'select';
      data.placeholder = 'Select a Bank';
      data.name = 'bank';
      return data;
    case 'account':
      data.label = 'Account';
      data.fieldType = 'input';
      data.placeholder = 'Enter Account';
      data.name = 'account';
      data.type = 'number';
      return data;
    case 'account_name':
    case 'account name':
      data.label = 'Account Name';
      data.fieldType = 'input';
      data.placeholder = 'Enter Account Name';
      data.name = 'account_name';
      return data;
    case 'ach routing number':
      data.label = 'ACH Routing Number';
      data.fieldType = 'input';
      data.placeholder = 'Enter ACH Routing Number';
      data.name = 'ach_routing_number';
      data.type = 'number';
      return data;
    case 'wire routing number':
      data.label = 'Wire Routing Number';
      data.fieldType = 'input';
      data.placeholder = 'Enter Wire Routing Number';
      data.name = 'wire_routing_number';
      data.type = 'number';
      return data;
    case 'iban':
      data.label = 'IBAN';
      data.fieldType = 'input';
      data.placeholder = 'Enter IBAN';
      data.name = 'iban';
      return data;
    case 'bic':
      data.label = 'BIC';
      data.fieldType = 'input';
      data.placeholder = 'Enter BIC';
      data.name = 'bic';
      return data;
    case 'sort_code':
    case 'sort code':
      data.label = 'Sort Code';
      data.fieldType = 'input';
      data.placeholder = 'Enter Sort Code';
      data.name = 'sort_code';
      data.type = 'number';
      return data;
    default:
      data = {
        label: '',
        fieldType: '',
        placeholder: '',
        name: '',
      };
      return data;
  }
};

// Convert Bank Names to Title Case
export const formatBankName = (name) => name
  ?.toLowerCase()
  .split(' ')
  .map((word) => word
    .split('-')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('-'))
  .join(' ');

// Function to check the validity of a Bitcoin address (simple validation for illustration)
function isValidBitcoinAddress(address) {
  // You might want to implement a more comprehensive validation based on Bitcoin standards
  return /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address);
}

export function trimWalletAddress(walletAddress, prefixLength = 6, suffixLength = 4) {
  // Check if the input is a valid Bitcoin address
  if (!isValidBitcoinAddress(walletAddress)) {
    // console.error('Invalid address');
    return walletAddress;
  }

  // Extract the prefix and suffix
  const prefix = walletAddress.substring(0, prefixLength);
  const suffix = walletAddress.substring(walletAddress.length - suffixLength);

  // Concatenate the prefix and suffix
  const trimmedAddress = `${prefix}...${suffix}`;

  return trimmedAddress;
}
