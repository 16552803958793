/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef } from 'react';
import { useIcon } from '../../../hooks/useIcon';
import { formatNumber } from '../../../utils/simpleActions';
import InputErrorMessage from './components/InputErrorMessage';
import InputMessage from './components/InputMessage';
import './styles.css';

const InputGroup = forwardRef(({
  name = 'input1',
  label = 'Label',
  leftIcon = false,
  isRightIcon = false,
  rightText = '',
  rightIcon = '',
  onClickRightIcon = () => { },
  type = 'text',
  placeholder = 'Input text',
  onChange = () => {},
  value = '',
  isError,
  errorMessage = 'Error Message',
  isInputMessage,
  inputMessage = 'Input Message',
  inputStyle = '',
  group = false,
  hasRightIconTooltip = false,
  tooltipMessage = 'Tooltip Message Goes Here',
  canFormatNumber = false,
  isTransparent = false,
  hasLabel = true,
  ...rest
}, ref) => {
  const { neutralExclamationIcon } = useIcon();
  function formatCurrencyField(v) {
    if (!v) return v;
    const _value = v.replace(/[^0-9.]/g, '');
    let newValue;
    if (_value) {
      newValue = `${formatNumber(parseInt(`${_value}`, 10), 0)}`;
    } else {
      newValue = '';
    }
    return newValue;
  }

  function onChangeInput(e) {
    if (canFormatNumber) {
      e.target.value = formatCurrencyField(e.target.value);
    }
    onChange(e);
  }
  return (
    <div className={`dsh-input-group ${group ? 'group' : ''}`}>
      {
        hasLabel && (
        <label
          htmlFor={name}
          className="input-group-label secondary-white-black-color"
        >
          {label}
        </label>
        )
      }
      <div
        className={`input-group ${inputStyle} ${isError ? 'has-error' : ''} ${isTransparent ? 'input-group-transparent' : ''}`}
        style={{ ...(!label ? { marginTop: 0 } : {}) }}
      >
        {leftIcon && (
          <label htmlFor={name} className="input-label ">
            <figure className="img-box">
              <img src={leftIcon} className="img" alt="" />
            </figure>
          </label>
        )}
        <input
          type={type}
          name={name}
          id={name}
          className="input white-black-color"
          placeholder={placeholder}
          onChange={onChangeInput}
          value={value}
          {...rest}
          ref={ref}
        />
        {isRightIcon && (
          <label htmlFor={name} className="input-label right-icon-label">
            <span className="info-text secondary-white-black-color">{rightText}</span>
            <figure className="img-box right-icon" onClick={onClickRightIcon}>
              <img src={rightIcon || neutralExclamationIcon} className="img" alt="" />
              <RightIconTooltip message={tooltipMessage} show={hasRightIconTooltip} />
            </figure>
          </label>
        )}
      </div>
      {(isError || isInputMessage) && (
      <div className="error-message-container" style={{ height: '12.67px' }}>
        {!isError && isInputMessage && (
        <InputMessage inputMessage={inputMessage} />
        )}
        {isError && <InputErrorMessage errorMessage={errorMessage} />}
      </div>
      )}
    </div>
  );
});

export default InputGroup;

function RightIconTooltip({ show, message }) {
  return (
    show
      ? (
        <div className="info-tooltip-container">
          <div className="tooltip-pointer" />
          <div className="tooltip-body white-black-color">
            {message}
          </div>
        </div>
      )
      : ''
  );
}
