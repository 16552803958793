/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import './styles/index.css';
import { useDispatch } from 'react-redux';
// import SubFlowLayout from '../../components/fragments/subFlow';
import { useIcon } from '../../hooks/useIcon';
import { toggleVerifyIdDrawer } from '../../slices/UserSlice';
import TierLayout from './components/TierLayout';
import { checkForTier } from '../../utils/simpleActions';
import useUser from '../../hooks/useUser';

function KYCScreenFlow({ data, proceedData }) {
  const { user } = useUser();
  const dispatch = useDispatch();
  const { kycVerifiedTickIllustration } = useIcon();

  const tiers = useMemo(() => (data ? data?.config : []), [data]);
  const [currentTab, setCurrentTab] = useState(
    parseInt(checkForTier(user.withdrawal_limit), 10),
  );
  const [usersPresentTier, setUserPresentTier] = useState(
    parseInt(checkForTier(user.withdrawal_limit), 10),
  );

  function handleGoBack() {
    dispatch(toggleVerifyIdDrawer());
  }

  function checkIfTierVerified(_limit) {
    const _withdrawal_limit = user.withdrawal_limit;
    // if user limit is greater or equal to level limit
    return _withdrawal_limit >= _limit;
  }

  useEffect(() => {
    const a = tiers.find((t) => user.withdrawal_limit < t.limit);
    if (a) {
      setCurrentTab(a?.level);
      setUserPresentTier(parseInt(a?.level, 10) - 1);
    }
  }, [tiers]);

  return (
  // <SubFlowLayout
  //   isVisible={isOpen}
  //   style={{ maxWidth: '83rem', padding: '0' }}
  // >
    <div className="dashboard-card-container kyc-main-container">
      <div className="kyc-container">
        <div className="border-light kyc-tab-container hide-scrollbar">
          {tiers.map((child) => {
            const {
              description, key, limit, link, level,
            } = child;
            const verified = checkIfTierVerified(limit);

            return (
              <Fragment key={level}>
                <div
                  className={`kyc-tab-item ${currentTab === level && 'active'} ${verified && 'verified'}`}
                  onClick={() => setCurrentTab(level)}
                >
                  <span>
                    Tier
                    {' '}
                    {level}
                  </span>
                  {verified && (
                    <figure className="img-box">
                      <img src={kycVerifiedTickIllustration} alt="" className="img" />
                    </figure>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>

        <TierLayout
          tiers={tiers}
          currentTier={tiers[currentTab - 1]}
          _index={currentTab - 1}
          isCompleted={checkIfTierVerified(tiers[currentTab - 1]?.limit)}
          proceedData={proceedData}
          usersPresentTier={usersPresentTier}
        />
      </div>
    </div>
  // {/* </SubFlowLayout> */}
  );
}

export default KYCScreenFlow;
