/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable consistent-return */
/* eslint-disable comma-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import WalletService from '../../../../../services/walletService';
import useCurrency from '../../../../../hooks/useCurrency';
import useUser from '../../../../../hooks/useUser';
import { formatNumber, getCurrency } from '../../../../../utils/simpleActions';
import * as toastType from '../../../../../utils/toast-types';
import transactionTypes from '../../TransactionTypes';
import AvailableBalanceBadge from '../../../../../components/fragments/AvailableBalanceBadge';
import GroupWithAvailableBalance from '../../../../../components/fragments/InputGroup/components/GroupWithAvailableBalance';
import SelectCurrency from '../../../../../components/fragments/SelectCurrency';
import InputGroupAlt from '../../../../../components/fragments/InputGroupAlt';
import { RETURN_TO_WALLET_PORTFOLIO_FLOW } from '../../../../../utils/storageKeys';
import { setToggleToast } from '../../../../../slices/authSlice';

const typeFunctions = {
  [transactionTypes.WITHDRAW]: WalletService.getCurrencyWithdrawRoutes,
  [transactionTypes.DEPOSIT]: WalletService.getCurrencyDepositRoutes,
  // !Replace when transfer endpoint is ready
  [transactionTypes.TRANSFER]: WalletService.getCurrencyWithdrawRoutes,
};

const ChooseCurrency = React.forwardRef(
  ({
    setCanProceed, dataName, title = 'withdraw', callback = () => { }
}, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
      stringCurrencyBalance,
      setSelectedCurrency,
      currencies,
      selectedCurrency,
      balance,
        kYCCurrency
    } = useCurrency();
    const initialDetails = {
      currency: kYCCurrency,
      amount: '',
    };

    const [details, setDetails] = useState(initialDetails);
    const [amountError, setAmountError] = useState(false);
    const [needUpgrade, setNeedUpgrade] = useState({
      display: false,
      callback: null,
    });
    const { user } = useUser();
    // const { push } = useNavigate();

    function handleRoutingToKYCScreen() {
        if (callback) {
            callback();
        }
    }

    // This function returns the usd equivalent of the amount
    function getUSDAmount() {
      // convert amount to dollar equivalent
      const _selectedCurrency = currencies.find(
        (d) => d.symbol === details.currency
      );
      const _amount = parseFloat(details.amount.replace(/[^0-9.]/g, '') || 0);
      return _amount / _selectedCurrency.rate;
    }

    function convertUSDToLocalCurrency(usd_amount) {
      const _selectedCurrency = currencies.find(
        (d) => d.symbol === details.currency
      );
      return (_selectedCurrency.rate || 0) * usd_amount;
    }

    function getCurrencyTradeFor(cur) {
      return currencies.find((d) => d.symbol === cur);
    }

    function getAmount() {
      return details.amount.replace(/[^0-9.]/g, '');
    }

    function formatCurrencyField(name, value) {
      let _value = value;
      if (name === 'amount' && _value) {
        const symbol = getCurrency(details.currency).s;
        _value = _value.replace(/[^0-9.]/g, '');
        let newValue;
        if (_value) {
          newValue = `${symbol}${formatNumber(parseInt(`${_value}`, 10), 0)}`;
        } else {
          newValue = '';
        }
        _value = newValue;
      }
      return _value;
    }

    const handleChange = ({ name, value }) => {
      let _value = formatCurrencyField(name, value);
      const data = { ...details, [name]: _value };
      if (name === 'currency') {
          setSelectedCurrency(value);
      }
      setDetails(data);
    };

    function shouldSelectChange(vlu) {
        let bool = !!user.kyc_currencies.includes(vlu);
        if (bool === false) {
            dispatch(
                setToggleToast({
                    type: toastType.WARNING,
                    name: 'Currency Unavailable',
                    desc: 'This currency is disabled for this user',
                }),
            );
        }
        return bool;
    }

    useEffect(() => {
      handleChange({ name: 'amount', value: details.amount });
    }, [details.currency]);

      useEffect(() => {
          if (sessionStorage.getItem(RETURN_TO_WALLET_PORTFOLIO_FLOW)) {
              sessionStorage.removeItem(RETURN_TO_WALLET_PORTFOLIO_FLOW);
          }
      }, []);

      function checkDepositCanProceed() {
          const _amount = getAmount();
          const _amount_usd = getUSDAmount();
          const _total_deposited = user.total_deposited;
          const _currency = details.currency;
          const _currency_details = getCurrencyTradeFor(details.currency);
          const _deposit_limit = user.deposit_limit;

          if (_amount_usd + _total_deposited <= _deposit_limit) {
              const _parsedAmount = parseFloat(_amount || 0);
              const _minTradeable = _currency_details.min_tradeable;
              const _maxTradeable = _currency_details.max_tradeable;

              if (_parsedAmount < _minTradeable) {
                  setAmountError(
                      `Minimum ${details.currency?.toUpperCase()} ${title} of ${getCurrency(_currency)?.s}${formatNumber(_minTradeable)}`
                  );
              } else if (_parsedAmount > _maxTradeable) {
                  setAmountError(
                      `Maximum ${details.currency?.toUpperCase()} ${title} of ${getCurrency(_currency)?.s}${formatNumber(_maxTradeable)}`
                  );
              } else {
                  setCanProceed(details.amount && details.currency);
              }

              setNeedUpgrade({
                  display: false,
                  callback: null,
              });
          } else {
              const _formattedDepositLimit = formatNumber(convertUSDToLocalCurrency(_deposit_limit));
              setAmountError(`Exceeded total ${title} limit of ${getCurrency(_currency)?.s}${_formattedDepositLimit}`);
              setNeedUpgrade({
                  display: true,
                  callback: handleRoutingToKYCScreen,
              });
              setCanProceed(false);
          }
      }

      function checkTransferCanProceed() {
          const _amount = getAmount();
          const _balance = parseFloat(balance || 1);

          if (_amount > _balance) {
              setAmountError(`Insufficient ${details.currency?.toUpperCase()} balance`);
              setNeedUpgrade({
                  display: false,
                  callback: null,
              });
              setCanProceed(false);
              return;
          }

          const _amount_usd = getUSDAmount();
          const _currency = details.currency;
          const _total_transferred = user.total_transferred;
          const _withdrawal_limit = user.withdrawal_limit;
          const _currency_details = getCurrencyTradeFor(details.currency);

          if (_amount_usd + _total_transferred <= _withdrawal_limit) {
              const _parsedAmount = parseFloat(_amount || 0);

              if (_parsedAmount < _currency_details.min_transfer) {
                  setAmountError(`Minimum ${details.currency?.toUpperCase()} ${title} of ${getCurrency(_currency)?.s}${formatNumber(_currency_details.min_transfer)}`);
              } else if (_parsedAmount > _currency_details.max_transfer) {
                  setAmountError(`Maximum ${details.currency?.toUpperCase()} ${title} of ${getCurrency(_currency)?.s}${formatNumber(_currency_details.max_transfer)}`);
              } else {
                  setCanProceed(details.amount && details.currency);
              }

              setNeedUpgrade({
                  display: false,
                  callback: null,
              });
          } else {
              setAmountError(`Exceeded total ${title} limit of ${getCurrency(_currency)?.s}${formatNumber(convertUSDToLocalCurrency(_withdrawal_limit))}`);
              setNeedUpgrade({
                  display: true,
                  callback: handleRoutingToKYCScreen,
              });
              setCanProceed(false);
          }
      }

      useEffect(() => {
          setAmountError(false);
          setCanProceed(false);
          const _amount = getAmount();

          if (_amount && currencies) {
              if (title === transactionTypes.TRANSFER) {
                  checkTransferCanProceed();
              } else if (title === transactionTypes.DEPOSIT) {
                  checkDepositCanProceed();
              }
          }
      }, [details.currency, details.amount, currencies]);

      React.useImperativeHandle(ref, () => ({
      async submit() {
        if (details.amount && details.currency) {
          if (title === transactionTypes.TRANSFER) {
            // proceed, transfer does not require routes
            return {
              name: dataName,
              currency: details.currency,
              amount: getAmount(),
            };
          }
          // TODO: Translate
          const { data } = await typeFunctions[title](details.currency);
          if (data.status !== 'fail') {
            const availableOptions = data.data;
            setDetails(initialDetails);
            return {
              name: dataName,
              currency: availableOptions.currency,
              amount: getAmount(),
              data: [
                {
                  id: 3,
                  key: 'p2p',
                  name: 'Wallet peer to peer',
                  subtitle: 'Seamlessly accept deposits into your wallet with ease.',
                  active: !!availableOptions.p2p,
                },
                {
                  id: 1,
                  key: 'bank',
                  name: 'Bank transfer',
                  subtitle: 'Deposit with ease using bank transfers',
                  active: !!availableOptions.bank,
                },
                {
                  id: 5,
                  key: 'payment_links',
                  name: 'Open banking',
                  subtitle: 'Effortless deposits with a simple open banking',
                  active: !!availableOptions.payment_links,
                },
                {
                  id: 4,
                  key: 'momo',
                  name: 'Mobile money',
                  subtitle: 'Deposit with your MoMo account',
                  active: !!availableOptions.momo,
                },
                {
                  id: 2,
                  key: 'card',
                  name: 'Card top-up',
                  subtitle: 'Deposit using your debit cards',
                  active: !!availableOptions.card,
                },
              ],
            };
          }
          dispatch(
            setToggleToast({
              type: toastType.WARNING,
              name: 'Currency Error',
              desc: data.message,
            })
          );
        }

        return false;
      },
    }));

    return (
      <>
        {/* Currency dropdown */}
        <SelectCurrency
          group
          label={
            title === transactionTypes.DEPOSIT
              ? t('dsh.wallet.chooseCurrencyDeposit')
              : title === transactionTypes.WITHDRAW
              ? t('dsh.wallet.chooseCurrencyWithdraw')
              : t('dsh.wallet.chooseCurrencyTransfer')
          }
          initialDefaultCurrency={selectedCurrency}
          onChange={(value) => handleChange({ name: 'currency', value })}
          shouldSelectChange={(value) => shouldSelectChange(value)}
        />

        <GroupWithAvailableBalance>
          <InputGroupAlt
            label={
              title === transactionTypes.DEPOSIT
                ? t('dsh.wallet.howMuchDeposit')
                : title === transactionTypes.WITHDRAW
                ? t('dsh.wallet.howMuchWithdraw')
                : t('dsh.wallet.howMuchTransfer')
            }
            value={details.amount}
            onChange={(e) =>
              handleChange({ name: 'amount', value: e.target.value })
            }
            name="amount"
            placeholder="Enter Amount"
            isError={!!amountError}
            errorMessage={amountError}
            hasLink={needUpgrade.display}
            linkCallback={needUpgrade.callback}
          />
          {title === transactionTypes.WITHDRAW && (
            <AvailableBalanceBadge value={stringCurrencyBalance} />
          )}
          {title === transactionTypes.TRANSFER && (
            <AvailableBalanceBadge value={stringCurrencyBalance} />
          )}
        </GroupWithAvailableBalance>
      </>
    );
  }
);

export default ChooseCurrency;
