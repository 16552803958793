/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import '../../../styles/dashboard/layout/DashboardLayout.css';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
// import AuthGuard from '../../guards/AuthGuard';
import ActionContext from '../../../context/ActionContext';
import {
  GlobalStyles,
} from '../../../utils/themes/theme';
import { selectClipboard } from '../../../slices/clipboardSlice';
// eslint-disable-next-line no-unused-vars
import { disableWhatsNew, fetchAllTokens, selectAllRates } from '../../../slices/globalSlice';
import { getPrimaryCurrency, getUser } from '../../../slices/authSlice';
import useCurrency from '../../../hooks/useCurrency';
import { useIcon } from '../../../hooks/useIcon';
import useThunkEffectCalls from '../../../hooks/useThunkEffectCalls';
import OnboardingManager from '../../../utils/OnboardingManager';
import ModalLayout from './ModalLayout';
import SelectCurrency from '../../fragments/SelectCurrency';
import ButtonReuse from '../../fragments/Button';
import WhatsNewDialog from '../../fragments/WhatsNewDialog/WhatsNewDialog';
import HeaderNavContent from './HeaderNavContent';
import FloatingNav from './FloatingNav';
import useThemeMode from '../../../hooks/useThemeMode';
import Copy from '../../fragments/Copy';

function DashboardRouteLayout() {
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const themeMode = useThemeMode(actionCtx?.theme);

  const { toast } = useSelector((state) => state.auth);

  // eslint-disable-next-line no-unused-vars
  const { toastIsOpen } = useSelector(selectClipboard);
  const { data: fiatRates } = useSelector(selectAllRates);
  const primaryCurrency = useSelector(getPrimaryCurrency);
  const { updatePrimaryCurrency, setSelectedCurrency } = useCurrency();
  const { info2 } = useIcon();
  const [showPrimaryCurrencyCheckModal, setShowPrimaryCurrencyCheckModal] = React.useState(false);
  const hasModalShown = useRef(false);
  const [showWhatsNew, setShowWhatsNew] = useState(false);
  const user = useSelector(getUser);
  const { canShow } = useSelector((state) => state.global.whatsNew);

  useThunkEffectCalls(() => dispatch(fetchAllTokens()));

  // const scrollBodyRef = useRef(null);

  // React.useImperativeHandle(dashboardRef, () => ({
  //   scrollToTop() {
  //     scrollBodyRef && scrollBodyRef?.current?.scrollTo(0, 0);
  //   }, scrollBodyRef
  // }));

  useEffect(() => {
    if (fiatRates && primaryCurrency && primaryCurrency.display !== '' && hasModalShown.current !== true) {
      const _selectedFiatRate = fiatRates.find((rate) => rate?.symbol === primaryCurrency?.display?.toLowerCase());
      if (_selectedFiatRate && _selectedFiatRate.status === 0) {
        setShowPrimaryCurrencyCheckModal(true);
      }

      return () => {
        hasModalShown.current = true;
      };
    }
  }, [fiatRates]);

  useEffect(() => {
    if (user && user.app_management && user.app_management.whatsnew) {
      if (canShow) {
        if (OnboardingManager.hasNotViewedWhatsNew()) {
          setTimeout(() => {
            setShowWhatsNew(true);
          }, 200);
        }
      }
    }
  }, [user, canShow]);

  const closeWhatsNewDialog = () => {
    setShowWhatsNew(false);
    OnboardingManager.setWhatsNewViewed();
    dispatch(disableWhatsNew());
  };

  const [newFromDisabledCurrency, setNewFromDisabledCurrency] = useState('');

  function handleSetUpdateFromDisabledCurrency(_value) {
    setNewFromDisabledCurrency(_value);
  }

  function closeUpdateFromDisabledCurrency() {
    setShowPrimaryCurrencyCheckModal(false);
  }

  function submitUpdateFromDisabledCurrency() {
    setSelectedCurrency(newFromDisabledCurrency);
    updatePrimaryCurrency(newFromDisabledCurrency);
    closeUpdateFromDisabledCurrency();
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={themeMode.color} />
      </Helmet>
      <ThemeProvider theme={themeMode.mode}>
        <GlobalStyles />

        <ModalLayout
          width="51.77rem"
          visible={showPrimaryCurrencyCheckModal}
        >
          <div id="important-notice-modal-body">
            <figure>
              <img src={info2} alt="" />
            </figure>
            <h3 className="important-notice-title">Primary Currency Disabled</h3>
            <div className="important-notice-message">
              Hi, your primary currency is currently disabled.
              <br />
              To proceed, please select your preferred currency from the dropdown menu below
            </div>

            <div className="important-notice-currency-wrapper">
              <SelectCurrency
                group
                label="Select Currency"
                onChange={(value) => handleSetUpdateFromDisabledCurrency(value)}
              />
            </div>

            <ButtonReuse
              onClick={() => submitUpdateFromDisabledCurrency()}
              btnStyle="transfer-modal-btn"
            >
              Save Changes
            </ButtonReuse>
          </div>
        </ModalLayout>
        <WhatsNewDialog visible={showWhatsNew} onClickClose={closeWhatsNewDialog} />
        <div className="dashboard-layout-wrap">
          <header
            className="header header-reduced"
          >
            {/* side bar reduce toggle icon */}
            {/* <div
              className={`side-bar-toggle-box blue-icon ${
                actionCtx?.isSidebarReduce && 'side-bar-toggle-box-reduced'
              }`}
              onClick={() => {
                actionCtx?.sidebarReduceFunc('any');
                // actionCtx?.isSidebarDropDownOpen("close");
              }}
            >
              {!actionCtx?.isSidebarReduce && <FaAngleLeft className="icon" />}
              {actionCtx?.isSidebarReduce && <FaAngleRight className="icon" />}
            </div> */}
            {/* end of sidebar reduce toggle icon */}
            {/* header content here start */}
            <HeaderNavContent
              theme={actionCtx?.theme}
              toggleTheme={actionCtx?.toggleTheme}
            />
            {/* header content here end */}
          </header>

          <Outlet />
          <FloatingNav />
        </div>
        {toast.isOpen && <Copy />}
      </ThemeProvider>
    </>
  );
}

export default DashboardRouteLayout;
