/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/react-in-jsx-scope */
import { useDispatch, useSelector } from 'react-redux';
import { useIcon } from '../../hooks/useIcon';
import './ToastV2.css';
import { closeToast, selectToastV2 } from '../../slices/toast-slice';
import Portal from '../widgets/Portal';
import toastTypes from '../../utils/toast-types';

export default function ToastV2() {
  const {
    newToastError, newToastPending, newToastSuccess, newToastWarning, newToastClose,
  } = useIcon();
  const {
    isOpen, type, heading, description,
  } = useSelector(selectToastV2);
  const dispatch = useDispatch();

  const bgColorFor = {
    [toastTypes.error]: '#F9B5B1',
    [toastTypes.pending]: '#9BB7FE',
    [toastTypes.success]: '#C7E9D7',
    [toastTypes.warning]: '#FCEED4',
  };

  function dismissToast(e) {
    e.stopPropagation();
    dispatch(closeToast());
  }

  function interceptCloseEvent(e) {
    e.stopPropagation();
  }

  return (
    <>
      {
        isOpen && (
          <Portal selector="#toast-p">
            <div className="toast-v2">
              <div className="backdrop" onClick={dismissToast}>
                <div className="toast-main">
                  <div className="toast-content" style={{ background: bgColorFor[type] }} onClick={interceptCloseEvent}>
                    <div className="top-row">
                      {type === toastTypes.warning && <img src={newToastWarning} alt="" className="toast-icon" />}
                      {type === toastTypes.error && <img src={newToastError} alt="" className="toast-icon" />}
                      {type === toastTypes.pending && <img src={newToastPending} alt="" className="toast-icon" />}
                      {type === toastTypes.success && <img src={newToastSuccess} alt="" className="toast-icon" />}
                      <button className="close-btn" onClick={dismissToast}>
                        <img src={newToastClose} alt="close toast" className="close-btn-icon" />
                      </button>
                    </div>
                    <h3 className="toast-heading">{heading ?? ''}</h3>
                    <p className="toast-description">{description ?? ''}</p>
                  </div>
                </div>
              </div>
            </div>
          </Portal>
        )
      }
    </>
  );
}
