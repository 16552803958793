/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import ButtonReuse from '../../components/fragments/Button';
import { useIcon } from '../../hooks/useIcon';
import ErrorPageWrapper from './ErrorPageWrapper';

function Error404({ what }) {
  const { error404 } = useIcon();
  const history = useNavigate();
  return (
    <ErrorPageWrapper>
      <div className="error-404-500">
        <figure className="illustration-img-box">
          <img src={error404} className="img" alt="" />
        </figure>
        <div className="error-description-box">
          <div className="error-description-text">
            Sorry. the
            {' '}
            {what || 'content'}
            {' '}
            you’re looking for doesn’t exist. Either it was removed,
            or you mistyped the link.
          </div>
          <div className="btn-box">
            <ButtonReuse
              btnStyle="btn-reuse"
              onClick={() => history('/dashboard-home', { replace: true })}
              text={
                <div className="btn-content">
                  <span>Go to your dashboard</span>
                  <FaChevronRight className="icon" style={{ color: 'white' }} />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </ErrorPageWrapper>
  );
}

export default Error404;
