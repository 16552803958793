/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentLinkService from '../services/PaymentLinkService';

export const fetchStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

const initialState = {
  links: {
    status: fetchStates.BASE,
    data: [],
    error: false,
  },
  newLink: {
    status: fetchStates.BASE,
    data: null,
    error: false,
  },
  orders: {
    status: fetchStates.BASE,
    data: [],
    error: false,
  },
};

export const createPaymentLink = createAsyncThunk(
  'paymentLinks/createPaymentLink',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await PaymentLinkService.createPaymentLink(params);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchPaymentLinks = createAsyncThunk(
  'paymentLinks/fetchPaymentLinks',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await PaymentLinkService.getPaymentLinks(token);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const fetchPaymentOrders = createAsyncThunk(
  'paymentLinks/fetchPaymentOrders',
  async ({ token, ref }, { rejectWithValue }) => {
    try {
      const { data } = await PaymentLinkService.getPaymentOrders({ token, ref });
      return data.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const disablePaymentLink = createAsyncThunk(
  'paymentLinks/disablePaymentLink',
  async ({ token, ref }, { rejectWithValue }) => {
    try {
      await PaymentLinkService.disablePaymentLink({ token, ref });
      return { token, ref };
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const paymentLinkSlice = createSlice({
  name: 'paymentLinks',
  initialState,
  reducers: {
    resetCreatePaymentLinkStatus: (state) => {
      state.newLink.status = initialState.newLink.status;
    },
  },
  extraReducers: (builder) => {
    // fetchPaymentLinks reducers...
    builder.addCase(fetchPaymentLinks.pending, (state) => {
      state.links.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchPaymentLinks.fulfilled, (state, action) => {
      state.links.data = action.payload;
      state.links.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchPaymentLinks.rejected, (state) => {
      state.links.status = fetchStates.ERROR;
    });

    // fetchPaymentOrders reducers...
    builder.addCase(fetchPaymentOrders.pending, (state) => {
      state.orders.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchPaymentOrders.fulfilled, (state, action) => {
      state.orders.data = action.payload.map((d) => ({
        id: d.transaction_id,
        currency: d.currency || '',
        name: d.narration || '',
        status: 'increase',
        date: d.created_at,
        amount: d.amount_paid,
      }));
      state.orders.status = fetchStates.FETCHED;
    });
    builder.addCase(fetchPaymentOrders.rejected, (state) => {
      state.orders.status = fetchStates.ERROR;
    });

    // disablePaymentLink reducers...
    builder.addCase(disablePaymentLink.pending, (state) => {
      state.links.status = fetchStates.FETCHING;
    });
    builder.addCase(disablePaymentLink.fulfilled, (state, action) => {
      state.links.data = state.links.data.filter(
        (d) => d.reference !== action.payload.ref,
      );
      state.links.status = fetchStates.FETCHED;
    });
    builder.addCase(disablePaymentLink.rejected, (state) => {
      state.links.status = fetchStates.ERROR;
    });

    // createPaymentLink reducers...
    builder.addCase(createPaymentLink.pending, (state) => {
      state.newLink.status = fetchStates.FETCHING;
    });
    builder.addCase(createPaymentLink.fulfilled, (state) => {
      state.newLink.status = fetchStates.FETCHED;
    });
    builder.addCase(createPaymentLink.rejected, (state) => {
      state.newLink.status = fetchStates.ERROR;
    });
  },
});

export const selectAllPaymentLinks = (state) => state.paymentLinks.links;
export const selectAllPaymentNewLink = (state) => state.paymentLinks.newLink;
export const selectAllPaymentOrders = (state) => state.paymentLinks.orders;

export default paymentLinkSlice.reducer;
export const { resetCreatePaymentLinkStatus } = paymentLinkSlice.actions;
