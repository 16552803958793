/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import useCurrency from '../../../hooks/useCurrency';

import './style.css';
import '../InputGroup/styles.css';
import useThunkEffectCalls from '../../../hooks/useThunkEffectCalls';
import {
  checkForReallySmallNumbers,
  formatCurrencyField,
  removeAlpha,
} from '../../../utils/simpleActions';
import InputMessage from '../InputGroup/components/InputMessage';
import InputErrorMessage from '../InputGroup/components/InputErrorMessage';
import InputGroupLabel from '../InputGroup/components/InputGroupLabel';
import { getCurrencyIcon } from '../../../utils/getStaticAssets';

/**
 * This component displays the dropdown amount field based on the current primary currency
 * when currency is change, users primary currency is not changed
 * @param props
 * @interface {
 * onChange: The change handler for currency change
 * setParentAmount: Setter for parent amount state
 * parentAmount: State for parent amount
 * placeholder: input field placeholder
 * canChange: Tells this component if currency can be changed
 * defaultCurrency: default currency for dropdown. Works hand in hand with `canChange` prop
 * priceDisabled: input field disabled
 * }
 */
function CurrencyPriceField({
  onChange,
  setParentAmount = () => {},
  parentAmount = '',
  placeholder,
  // items,
  // startIndex = 0,
  canChange = true,
  changeOnMount = true,
  defaultCurrency = null,
  priceDisabled = false,
  disabledPriceValue = '',
  inputStyle = '',
  isError,
  errorMessage = 'Error Message',
  isInputMessage,
  inputMessage = 'Input Message',
  hasMaxBadge = false,
  onClickMaxBadge = () => {},
  hasLabel = false,
  group = false,
  labelText = '',
  canFormatNumber = false,
  excludedCurrency = '',
}) {
  const {
    primaryCurrency,
    currencies,
    status: getCurrencyStatus,
  } = useCurrency();
  const [amount, setAmount] = React.useState(parentAmount);
  const [selectedItem, setSelectedItem] = React.useState((defaultCurrency || primaryCurrency));
  const [isOpen, setIsOpen] = React.useState(false);
  const [defaultCurrencyIcon, setDefaultCurrencyIcon] = useState(null);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  function handleChange(e) {
    let _value = checkForReallySmallNumbers(e.target.value);
    setParentAmount(removeAlpha(_value));
    if (canFormatNumber) {
      _value = formatCurrencyField(_value, inputRef, 2);
    }
    setAmount(_value);
  }

  useEffect(() => {
    setDefaultCurrencyIcon(
      getCurrencyIcon(selectedItem)
    );
  }, [selectedItem]);

  useEffect(() => {
    let _newAmount = checkForReallySmallNumbers(parentAmount);
    // setParentAmount(removeAlpha(_newAmount));
    if (canFormatNumber) {
      _newAmount = formatCurrencyField(_newAmount, inputRef, 2);
    }
    setAmount(_newAmount);
  }, [parentAmount]);

  const onItemClick = (value) => {
    setIsOpen((prev) => !prev);
    setSelectedItem(value);
    onChange && onChange(value);
    setDefaultCurrencyIcon(
      getCurrencyIcon(value)
    );
  };

  function setOnMount() {
    changeOnMount && onChange && onChange((defaultCurrency || primaryCurrency));
  }

  useThunkEffectCalls(setOnMount);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const idxs = defaultCurrency || primaryCurrency;
    setSelectedItem(idxs);
    setDefaultCurrencyIcon(
      getCurrencyIcon(selectedItem)
    );
  }, [currencies, defaultCurrency, getCurrencyStatus]);

  return (
    <div className={`dsh-input-group ${group ? 'group' : ''}`}>
      {hasLabel && <InputGroupLabel label={labelText} />}
      <div
        style={{ marginTop: hasLabel ? '0.8rem' : '' }}
        className={`input-group-with-state price-form-group input-box-in-container-color ${inputStyle} ${
          isError ? 'has-error' : ''
        }`}
      >
        <div className="p-left-box" onClick={() => canChange && toggleOpen()}>
          {canChange ? (
            <div className="select-filter-box">
              {/* <span className="symbol">₦</span> */}
              <figure className="img-box currency-icon-wrap">
                <img
                  className="currency-icon img"
                  src={defaultCurrencyIcon}
                  alt="currency icon"
                />
              </figure>{' '}
              <span className="text white-black-color">
                {defaultCurrency
                  ? currencies.find((currency) => currency.c === defaultCurrency)?.c
                  : selectedItem}
              </span>{' '}
              <FaAngleDown className="icon white-black-color" />
            </div>
          ) : (
            <div className="select-filter-box" style={{ cursor: 'default' }}>
              {/* <span className="symbol">₦</span> */}
              <div className="currency-icon-wrap">
                <img
                  className="currency-icon"
                  src={defaultCurrencyIcon}
                  alt="currency icon"
                />
              </div>{' '}
              <span className="text white-black-color">
                {selectedItem}
              </span>
            </div>
          )}
        </div>
        <div className="p-right-box max-container">
          <input
            type="text"
            name="amount"
            id="amount"
            ref={inputRef}
            className="form-input white-black-color"
            placeholder={placeholder || t('dsh.amount')}
            onChange={handleChange}
            value={priceDisabled ? disabledPriceValue : amount}
            disabled={priceDisabled}
          />
          {hasMaxBadge && (
            <div onClick={() => onClickMaxBadge()} className="badge">
              Max
            </div>
          )}
        </div>
        {isOpen && (
          <div className="select-filter-options input-box-in-container-color">
            <OutsideClickHandler onOutsideClick={() => toggleOpen()}>
              {currencies
                .filter((d) => d.c !== excludedCurrency)
                .map((item) => (
                  <div
                    className="currency-item"
                    onClick={() => onItemClick(item.c)}
                    key={item.c}
                  >
                    <figure className="img-box currency-icon-wrap">
                      <img
                        className="currency-icon img"
                        src={getCurrencyIcon(item.c)}
                        alt="currency icon"
                      />
                    </figure>
                    <div className="option-text">
                      <div className="currency-text">
                        {item.n}
                      </div>
                      <div className="currency">{item.c}</div>
                    </div>
                  </div>
                ))}
            </OutsideClickHandler>
          </div>
        )}
      </div>
      {(isError || isInputMessage) && (
        <div className="error-message-container" style={{ height: '12.67px' }}>
          {!isError && isInputMessage && (
          <InputMessage inputMessage={inputMessage} />
          )}
          {isError && <InputErrorMessage errorMessage={errorMessage} />}
        </div>
      )}
    </div>
  );
}

export default CurrencyPriceField;
