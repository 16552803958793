/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactPinField from 'react-pin-field';
import './PinField.css';

// eslint-disable-next-line no-unused-vars
function AuthPinField({ pin, setPin, center = false }) {
  const [pin1, setPin1] = useState('');
  const [pin2, setPin2] = useState('');
  const pinRef = useRef(null);
  const pin2Ref = useRef(null);

  const [boxWidth, setBoxWidth] = useState('');

  useLayoutEffect(() => {
    if (pinRef?.current?.[0]) {
      setBoxWidth(pinRef?.current?.[0]?.clientWidth);
    }
  }, [pinRef?.current?.[0], pinRef?.current?.[0]?.clientWidth]);

  // AUTO SKIP TO THE PREV REACT-PIN-FIELD
  useEffect(() => {
    setPin(pin1 + pin2);

    const handleBackspace = (event) => {
      if (event.key === 'Backspace' && document.activeElement === pin2Ref.current[0]) {
        pinRef.current[2].focus();
      }
    };

    document.addEventListener('keydown', handleBackspace);

    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, [pin1, pin2]);

  // AUTO SKIPS TO THE NEXT REACT-PIN-FIELD
  useEffect(() => {
    if (pinRef.current[2].value) {
      pin2Ref.current[0].focus();
    }
  }, [pin1[2]]);

  return (
    <div className={`auth-pin-field ${center ? 'pin-center' : ''}`} style={{ maxWidth: '100%' }}>
      <ReactPinField
        ref={pinRef}
        pattern="\d*"
        length={3}
        type="number"
        validate="0123456789"
        format={(digit) => digit}
        className="pin-field white-black-color white-black-bg"
        onChange={(input) => setPin1(input)}
        style={{ height: `${boxWidth}px` }}
      />

      <div className="pin-divider black-white-bg" style={{ width: 32, height: 1, margin: '0 7px' }} />

      <ReactPinField
        ref={pin2Ref}
        pattern="\d*"
        length={3}
        type="number"
        validate="0123456789"
        format={(digit) => digit}
        className="pin-field white-black-color white-black-bg"
        onChange={(input) => setPin2(input)}
        style={{ height: `${boxWidth}px` }}
      />
    </div>
  );
}

export default AuthPinField;
