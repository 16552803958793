import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import RoqqDialog from '../../RoqqDialog/RoqqDialog';
import { selectVerifyyId, toggleVerifyIdDrawer } from '../../../../../../../slices/UserSlice';
import useUser from '../../../../../../../hooks/useUser';

import './index.css';
import { useIcon } from '../../../../../../../hooks/useIcon';

function RoqqVerifyIdentity() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectVerifyyId);
  const history = useNavigate();
  const { user } = useUser();
  const { roqqVerfifyIdentityIcon } = useIcon();

  const [showVerificationDialog, setShowVerificationDialog] = useState(false);

  useEffect(() => {
    if (user && parseFloat(user?.withdrawal_limit) < 10000 && !isOpen) {
      setShowVerificationDialog(false); // TODO: change to "true" later
    }
  }, [user, isOpen]);

  function closeModal() {
    history('/dashboard-home');
    setShowVerificationDialog(false);
  }

  function verifyIdentity() {
    history('/dashboard-setting');
    dispatch(toggleVerifyIdDrawer());
  }

  return (
    <RoqqDialog
      show={showVerificationDialog}
      buttonType="single"
      onClickNeutralBtn={() => verifyIdentity()}
      onClose={() => closeModal()}
      neutralBtnText="Verify Identity"
      headerText="Verify your Identity"
    >
      <div className="verify-notice-container">
        <figure className="img-box">
          <img src={roqqVerfifyIdentityIcon} alt="" className="img" />
        </figure>
        <p>
          Looks like your KYC is pending. Confirm your identity to boost your chances of winning.
        </p>
      </div>
    </RoqqDialog>
  );
}

export default RoqqVerifyIdentity;
