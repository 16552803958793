/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
import axios from 'axios';
import genCaptchaKeyAsync from '../utils/captcha_keygen';
import { decrypt, encrypt } from '../utils/simpleActions';

const PRODUCTION_URL = process.env.NODE_ENV === 'development' ? 'https://staging.roqqu.com/v2' : 'https://api.roqqupay.com/v2.5';
// const PRODUCTION_URL = 'https://api.roqqupay.com/v2';
// Axios instance
export const axiosInstance = axios.create({
  baseURL: PRODUCTION_URL,
  headers: { 'Content-Type': 'application/json' },
});

// Request interceptor callbacks
// Request Success
async function requestInterceptorSuccessCB(successfulReq) {
  const ctoken = await genCaptchaKeyAsync(process.env.REACT_APP_CAPTCHA_KEY);
  if (successfulReq.method === 'post' || successfulReq.method === 'POST') {
    const dataWithCtoken = {
      ...successfulReq.data,
      ctoken,
    };
    // console.log({
    //   requestInterceptorSuccessCB: dataWithCtoken,
    // });
    const JSONData = JSON.stringify(dataWithCtoken);
    const data = encrypt(JSONData);
    successfulReq.data = {
      data,
    };
  }
  return successfulReq;
}

// Request Error
function requestInterceptorErrorCB(error) {
  if (error.config.method === 'post' || error.config.method === 'POST') {
    error.response = { ...error.response, data: JSON.parse(decrypt(error.response.data)) };
  }
  // console.log({
  //   requestInterceptorErrorCB: error.response,
  // });
  return Promise.reject(error);
}

// Response interceptor callbacks
// Response Success
function responseInterceptorSuccessCB(successRes) {
  if (successRes.config.method === 'post' || successRes.config.method === 'POST') {
    successRes.data = JSON.parse(decrypt(successRes.data));
  }
  // console.log({
  //   responseInterceptorSuccessCB: successRes,
  // });
  return successRes;
}

// Response Error
function responseInterceptorErrorCB(error) {
  if (error.config.method === 'post' || error.config.method === 'POST') {
    error.response = { ...error.response, data: JSON.parse(decrypt(error.response.data)) };
  }
  // console.log({
  //   responseInterceptorErrorCB: error.response,
  // });
  return Promise.reject(error);
}

(() => {
  if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
    // Request interceptor
    axiosInstance.interceptors.request.use(requestInterceptorSuccessCB, requestInterceptorErrorCB);

    // Response interceptor
    axiosInstance.interceptors.response.use(responseInterceptorSuccessCB, responseInterceptorErrorCB);
  }
})();

// Handle Response Data
function handleHttpResponse({ response, success }) {
  // No Data Was Returned
  if (!response.data) {
    return;
  }

  if (response.data.success) {
    success(response);
  }
}

// Handle Response Errors
function handleHttpError({
  response, error, formErrors,
}) {
  // No Response Was Returned
  if (!response) {
    error({ status: 449 });
    return;
  }

  error(response);

  // Handle Error States / Codes
  switch (response.status) {
    case 400:
      // Bad Request
      break;
    case 404:
      // Not Found
      break;
    case 419:
      // X-CSRF-TOKEN Error
      break;
    case 422:
      if (formErrors) {
        // Input Data Error
      }
      break;
    case 500:
      // Server Error
      break;
    case 504:
      // Gateway Timeout
      break;

    // ================================================================================
    // ================================================================================
    // Custom Error Codes
    // ================================================================================
    // ================================================================================
    case 449:
      // Just Try Again
      break;
    default:
      // Unknown Error
      break;
  }
}

// Send HTTP Request
async function ajax({
  method = 'GET',
  url,
  data,
  headers = {},
  before = () => {},
  after = () => {},
  mutate = false,
  success = () => {},
  error = () => {},
  handleError = true,
  serverError = false,
  formErrors = true,
  axiosProps = {},
}) {
  // Request Response And Error
  const result = { response: false, error: false };

  // Call Before Function
  before();

  // Send Request
  await axiosInstance({
    // Request URL
    url,
    // Request Method
    method,
    // Post Data
    data,
    // Request Headers
    headers,
    // Axios Specific Properties
    ...axiosProps,
  })
    .then((response) => {
      // Assign Request Response
      result.response = response;

      // Handle Responses
      handleHttpResponse({ response, mutate, success });
    })
    .catch((err) => {
      // Assign Response Error
      result.error = err;

      // Handle Errors
      if (handleError) {
        handleHttpError({
          ...err, error, serverError, formErrors,
        });
      }
    });

  // Call After Function With Response As Parameter
  after(result);

  return result;
}

// Send GET Requests
export const get = async (payload) => await ajax({ ...payload, method: 'GET' });

// Send POST Requests
export const post = async (payload) => await ajax({ ...payload, method: 'POST' });
