/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import './CountdownTimer.css';

export default function CountdownTimer({ label, hours, mins, secs }) {
  return (
    <div className="countdown-timer">
      <h2 className="countdown-label">{label}</h2>
      <section className="clock">
        <section className="clock-content">
          <div className="time-unit">{formatTimeUnit(hours)}</div>
          <div className="time-unit">:</div>
          <div className="time-unit">{formatTimeUnit(mins)}</div>
          <div className="time-unit">:</div>
          <div className="time-unit">{formatTimeUnit(secs)}</div>
        </section>
      </section>
      <section className="labels">
        <h3 className="time-unit-label">Hours</h3>
        <h3 className="time-unit-label">Minutes</h3>
        <h3 className="time-unit-label">Seconds</h3>
      </section>
    </div>
  );
}

function formatTimeUnit(timeUnit) {
  const formattedTimeUnit = `${timeUnit}`;
  if (formattedTimeUnit.length === 1) {
    return `0${formattedTimeUnit}`;
  }
  return formattedTimeUnit;
}
