/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';

export const TwoFAStates = {
  BASE: 'base',
  LOADING: 'loading',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  TWOFASTARTED: 'user-started-2fa',
};

const initialState = {
  setup2FA: {
    qr_code_img: null,
    status: TwoFAStates.BASE,
    error: '',
    recovery_code: null,
  },
  enableDisable: {
    status: TwoFAStates.BASE,
    error: false,
    data: null,
  },
  confirm2FA: {
    status: TwoFAStates.BASE,
    error: false,
    data: null,
  },
  reset2FA: {
    status: TwoFAStates.BASE,
    error: null,
    data: null,
  },
};

export const handleSetup2FA = createAsyncThunk(
  'twoFA/handleSetup2FA',
  async ({ token, pin }, { rejectWithValue }) => {
    try {
      const data = await AuthService.setup2FAService({ token, pin });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const handleConfirm2FA = createAsyncThunk(
  'twoFA/handleConfirm2FA',
  async ({ token, code }, { rejectWithValue }) => {
    try {
      const data = await AuthService.confirm2FAService({ token, code });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const handleEnable2FA = createAsyncThunk(
  'twoFA/handleEnable2FA',
  async ({ token, code }, { rejectWithValue }) => {
    try {
      const data = await AuthService.enable2FAService({ token, code });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const handleDisable2FA = createAsyncThunk(
  'twoFA/handleDisable2FA',
  async ({ token, code }, { rejectWithValue }) => {
    try {
      const data = await AuthService.disable2FAService({ token, code });
      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

export const handleReset2FA = createAsyncThunk(
  'twoFA/handleReset2FA',
  async (pin, { rejectWithValue }) => {
    try {
      return await AuthService.reset2FAService(pin);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const handleReset2FAConfirm = createAsyncThunk('twoFA/handleReset2FAConfirm', async (_, thunkAPI) => {
  try {
    return await AuthService.reset2FAConfirm(thunkAPI.getState().twoFA.reset2FA.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const twoFASlice = createSlice({
  name: 'twoFA',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.setup2FA.status = initialState.setup2FA.status;
      state.confirm2FA.status = initialState.confirm2FA.status;
      state.enableDisable.status = initialState.enableDisable.status;
      state.reset2FA.status = initialState.reset2FA.status;
    },
    resetQRNumber(state) {
      state.setup2FA.qr_code_img = initialState.setup2FA.qr_code_img;
      state.setup2FA.recovery_code = initialState.setup2FA.recovery_code;
    },
  },
  extraReducers: (builder) => {
    // handleSetup2FA reducers
    builder.addCase(handleSetup2FA.fulfilled, (state, action) => {
      const { status = 'fail', data, message: error } = action.payload;
      if (status !== 'fail') {
        state.setup2FA.status = TwoFAStates.SUCCESSFUL;
        state.setup2FA.qr_code_img = data.qr_code;
        state.setup2FA.recovery_code = data.recovery_code;
      } else {
        state.setup2FA.error = error;
        state.setup2FA.status = TwoFAStates.TWOFASTARTED;
      }
    });
    builder.addCase(handleSetup2FA.pending, (state) => {
      state.setup2FA.status = TwoFAStates.LOADING;
    });
    builder.addCase(handleSetup2FA.rejected, (state) => {
      state.setup2FA.status = TwoFAStates.FAILED;
    });

    // handleConfirm2FA reducers
    builder.addCase(handleConfirm2FA.fulfilled, (state, action) => {
      const { status = 'fail', message: error } = action.payload;
      if (status !== 'fail') {
        state.confirm2FA.status = TwoFAStates.SUCCESSFUL;
      } else {
        state.confirm2FA.error = error;
        state.confirm2FA.status = TwoFAStates.FAILED;
      }
    });
    builder.addCase(handleConfirm2FA.pending, (state) => {
      state.confirm2FA.status = TwoFAStates.LOADING;
    });
    builder.addCase(handleConfirm2FA.rejected, (state) => {
      state.confirm2FA.status = TwoFAStates.FAILED;
    });

    // handleEnable2FA reducers
    builder.addCase(handleEnable2FA.fulfilled, (state, action) => {
      const { status, message: error } = action.payload;
      if (status !== 'fail') {
        state.enableDisable.status = TwoFAStates.SUCCESSFUL;
      } else {
        state.enableDisable.error = error;
        state.enableDisable.status = TwoFAStates.FAILED;
      }
    });
    builder.addCase(handleEnable2FA.pending, (state) => {
      state.enableDisable.status = TwoFAStates.LOADING;
    });
    builder.addCase(handleEnable2FA.rejected, (state) => {
      state.enableDisable.status = TwoFAStates.FAILED;
    });

    // handleDisable2FA reducers
    builder.addCase(handleDisable2FA.fulfilled, (state, action) => {
      const { status, message: error } = action.payload;
      if (status !== 'fail') {
        state.enableDisable.status = TwoFAStates.SUCCESSFUL;
      } else {
        state.enableDisable.error = error;
        state.enableDisable.status = TwoFAStates.FAILED;
      }
    });
    builder.addCase(handleDisable2FA.pending, (state) => {
      state.enableDisable.status = TwoFAStates.LOADING;
    });
    builder.addCase(handleDisable2FA.rejected, (state) => {
      state.enableDisable.status = TwoFAStates.FAILED;
    });

    // handleReset2FA reducers
    builder.addCase(handleReset2FA.pending, (state) => {
      state.reset2FA.data = null;
      state.reset2FA.error = null;
      state.reset2FA.status = TwoFAStates.LOADING;
    });
    builder.addCase(handleReset2FA.fulfilled, (state, action) => {
      state.reset2FA.data = action.payload.data.data;
      state.reset2FA.status = TwoFAStates.TWOFASTARTED;
    });
    builder.addCase(handleReset2FA.rejected, (state, action) => {
      state.reset2FA.error = action.payload;
      state.reset2FA.status = TwoFAStates.FAILED;
    });

    // handleReset2FAConfirm reducers
    builder.addCase(handleReset2FAConfirm.pending, (state) => {
      state.reset2FA.error = null;
      state.reset2FA.status = TwoFAStates.LOADING;
    });
    builder.addCase(handleReset2FAConfirm.fulfilled, (state) => {
      state.reset2FA.status = TwoFAStates.SUCCESSFUL;
    });
    builder.addCase(handleReset2FAConfirm.rejected, (state, action) => {
      state.reset2FA.error = action.payload;
      state.reset2FA.status = TwoFAStates.FAILED;
    });
  },
});

export const selectQRImage = (state) => state.twoFA.qr_code_img;
export const selectTwoFA = (state) => state.twoFA.setup2FA;
export const selectConfirm2FA = (state) => state.twoFA.confirm2FA;
export const selectReset2FA = (state) => state.twoFA.reset2FA;

export default twoFASlice.reducer;
export const { resetStatus, resetQRNumber } = twoFASlice.actions;
