import apiRoutes from '../http/apiRoutes';
import { post } from '../http/https';
import AuthService from './AuthService';

export default class TradeService {
  static async buyCoin({
    amount, coin, currency, pegged,
  }) {
    const response = await post({
      url: apiRoutes.buy,
      data: {
        token: AuthService._getUserToken(),
        amount,
        coin,
        currency,
        pegged,
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }
    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async buyService({
    amount, coin, currency, pegged,
  }, afterCallback) {
    const { response } = await post({
      url: apiRoutes.buy,
      data: {
        token: AuthService._getUserToken(),
        amount,
        coin,
        currency,
        pegged,
      },
      after: afterCallback,
    });
    if (response === false) {
      throw new Error('failed to complete buy transactions');
    }
    return response;
  }

  static async sellCoin({
    amount, coin, currency, pegged,
  }) {
    const response = await post({
      url: apiRoutes.sell,
      data: {
        token: AuthService._getUserToken(),
        amount,
        coin,
        currency,
        pegged,
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }
    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async sellService({
    amount, coin, currency, pegged,
  }, afterCallback) {
    const { response } = await post({
      url: apiRoutes.sell,
      data: {
        token: AuthService._getUserToken(),
        amount,
        coin,
        currency,
        pegged,
      },
      after: afterCallback,
    });
    if (response === false) {
      throw new Error('failed to complete sell transactions');
    }
    return response;
  }

  static async swapCoin({
    amount, from, to, pegged,
  }) {
    const response = await post({
      url: apiRoutes.swap,
      data: {
        token: AuthService._getUserToken(),
        amount,
        from,
        to,
        pegged,
      },
    });
    if (response.response === false) {
      throw new Error(response.error.response.data.message);
    }
    if (response.response.data.status === 'fail') {
      throw new Error(response.response.data.message);
    }
    return response.response.data.data;
  }

  static async swapService({
    amount, from, to, pegged,
  }, afterCallback) {
    const rsp = await post({
      url: apiRoutes.swap,
      data: {
        token: AuthService._getUserToken(),
        amount,
        from,
        to,
        pegged,
      },
      after: afterCallback,
    });
    if (rsp.response === false) {
      throw new Error('failed to complete sell transactions');
    }
    return rsp.response;
  }

  static async swapCurrencyService({
    amount, from, to, pegged,
  }, afterCallback) {
    const { response } = await post({
      url: apiRoutes.swapCurrency,
      data: {
        token: AuthService._getUserToken(),
        amount,
        from,
        to,
        pegged,
      },
      after: afterCallback,
    });
    if (response === false) {
      throw new Error('failed to complete currency swap transactions');
    }
    return response;
  }

  static async get24hTradeAnalytics() {
    const { response, error } = await post({
      url: apiRoutes.trade24hAnalytics,
      data: {
        token: AuthService._getUserToken(),
      },
    });

    if (response === false) {
      throw new Error(error.response.data.message);
    }
    return response;
  }
}
