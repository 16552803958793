/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CoinService from '../services/coinService';
import * as states from '../states/ComponentStates';

const initialState = {
  status: states.BASE,
  coin: '',
  coinPrice: 0,
  transactionFees: {},
  meta_data: {
    description: '',
    status: states.BASE,
    error: null,
  },
};

export const fetchCoinPrice = createAsyncThunk('coin/fetch-price', async (data, { rejectWithValue }) => {
  try {
    const { symbol } = data;
    const response = await CoinService.getPrice({ symbol });

    return response.response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const fetchTransactionFees = createAsyncThunk('coin/fetch-fees', async (_, { rejectWithValue }) => {
  try {
    const response = await CoinService.getFees();

    return response.response.data.data;
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const fetchCoinMetaData = createAsyncThunk(
  'coin/fetchCoinMetaData',
  async (symbol, { rejectWithValue }) => {
    try {
      const { data } = await CoinService.getCoinMetaData(symbol);

      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  },
);

const coinSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      if (!(
        action.payload === states.BASE
        || action.payload === states.FETCHING
        || action.payload === states.ERROR
        || action.payload === states.FETCHED
        || action.payload === states.POPULATING
        || action.payload === states.POPULATED)) {
        return;
      }
      state.status = action.payload;
    },
    updateCoin: (state, action) => {
      state.coin = action.payload.symbol;
    },
  },

  extraReducers: (builder) => {
    // extraReducers for fetchCoinPrice
    builder.addCase(fetchCoinPrice.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(fetchCoinPrice.fulfilled, (state, action) => {
      state.coinPrice = action.payload;
      state.status = states.FETCHED;
    });
    builder.addCase(fetchCoinPrice.rejected, (state) => {
      state.status = states.ERROR;
    });

    // extraReducers for fetchTransactionFees
    builder.addCase(fetchTransactionFees.pending, (state) => {
      state.status = states.FETCHING;
    });
    builder.addCase(fetchTransactionFees.fulfilled, (state, action) => {
      state.transactionFees = action.payload;
      state.status = states.FETCHED;
    });
    builder.addCase(fetchTransactionFees.rejected, (state) => {
      state.status = states.ERROR;
    });

    // extraReducers for fetchCoinMetaData
    builder.addCase(fetchCoinMetaData.pending, (state) => {
      state.meta_data.status = states.FETCHING;
    });
    builder.addCase(fetchCoinMetaData.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.meta_data = data;
      state.meta_data.status = states.FETCHED;
    });
    builder.addCase(fetchCoinMetaData.rejected, (state) => {
      state.meta_data.status = states.ERROR;
    });
  },
});

export const selectMetaData = (state) => state.coin.meta_data;

export default coinSlice.reducer;
export const { updateStatus, updateCoin } = coinSlice.actions;
