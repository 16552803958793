/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ButtonReuse from '../../../../components/fragments/Button';
import tradeGIF from '../../../../assets/gif/trade-optimized.gif';
import magnifyGIF from '../../../../assets/gif/magnify-optimized.gif';
import currencyGIF from '../../../../assets/gif/currency-show-more-optimized.gif';
import '../../../../styles/dashboard/home/ToolTip.css';
import OnboardingManager from '../../../../utils/OnboardingManager';
import { enableWhatsNew } from '../../../../slices/globalSlice';
import ModalLayout from '../../../../components/layouts/dashboard/ModalLayout';

function ToolTip() {
  const [FirstTimeUser, setFirstTimeUser] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tooltip_message = [
    {
      id: 1,
      title: `${t('tooltip.screen2.title')}`,
      body: `${t('tooltip.screen2.body')}`,
      image: magnifyGIF,
    },
    {
      id: 2,
      title: `${t('tooltip.screen1.title')}`,
      body: `${t('tooltip.screen1.body')}`,
      image: currencyGIF,
    },
    {
      id: 3,
      title: `${t('tooltip.screen3.title')}`,
      body: `${t('tooltip.screen3.body')}`,
      image: tradeGIF,
    },
  ];

  const handleNext = () => {
    setPage((prev) => prev + 1);
  };

  const finishTooltip = () => {
    OnboardingManager.setTooltipPlayed();
    dispatch(enableWhatsNew());
    setFirstTimeUser(false);
    setPage(1);
  };

  useEffect(() => {
    // This help run the tooltip only once
    const hasNotPlayedTooltip = OnboardingManager.hasNotPlayedTooltip();
    setFirstTimeUser(hasNotPlayedTooltip);
  }, []);

  return (
  // <div className="modal-parent">
  // {/* <button
  //   type="button"
  //   style={{ width: 80 }}
  //   onClick={() => setFirstTimeUser(true)}
  // >
  //   Open
  // </button> */}

  // {/* <Modal effect="fadeInUp" visible={FirstTimeUser}> */ }
    <ModalLayout width="46rem" auth visible={FirstTimeUser}>
      <div className=" tooltip-screen">
        {tooltip_message.map(
          (item, idx) =>
            page - 1 === idx && (
            <div
              key={item.id}
              className="tooltip-modal-wrap slide-left dashboard-tooltip-container modal-primary-background"
            >
              <div className="tooltip-image ">
                <img className="" src={item.image} alt="" />
              </div>
              <div className="tooltip-message-container ">
                <p className="animate">{`${t('tooltip.welcome')}`}</p>
                <p className="tool-tip-header animate white-black-color">
                  {item.title}
                </p>
                <p className="tool-tip-body animate secondary-white-black-color">
                  {item.body}
                </p>

                <div className="tooltip-action-bar">
                  <div className="pagination">
                    <div
                      className={
                            page === 1
                              ? 'page-link focus icon-color'
                              : 'page-link icon-color'
                          }
                      onClick={() => setPage(1)}
                    />
                    <div
                      className={
                            page === 2
                              ? 'page-link focus icon-color'
                              : 'page-link icon-color'
                          }
                      onClick={() => setPage(2)}
                    />
                    <div
                      className={
                            page === 3
                              ? 'page-link focus icon-color'
                              : 'page-link icon-color'
                          }
                      onClick={() => setPage(3)}
                    />
                  </div>
                  <ButtonReuse
                    onClick={(e) => {
                      e.preventDefault();
                      if (page < tooltip_message.length) {
                        handleNext();
                      } else {
                        finishTooltip();
                      }
                    }}
                    text={
                          page < tooltip_message.length
                            ? `${t('tooltip.next')}`
                            : `${t('tooltip.getStarted')}`
                        }
                    btnStyle="btn-reuse"
                  />
                </div>
              </div>
            </div>
            )
        )}
      </div>
    </ModalLayout>
  //   {/* </Modal> */}
  // {/* </div> */}
  );
}

export default ToolTip;
