import React from 'react';

/* eslint-disable import/prefer-default-export */
const PaymentLink = React.lazy(() => import('../../../pages/dashboard/payment-link'));
const CreatePaymentLink = React.lazy(() => import('../../../pages/dashboard/payment-link/CreatePaymentLink'));

export const Dashboard_payment_link_route_group = [
  { path: '/payment-links', component: <PaymentLink /> },
  { path: '/create-payment-link', component: <CreatePaymentLink /> },
];
