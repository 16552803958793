/* eslint-disable react/jsx-indent */
/* eslint-disable react/react-in-jsx-scope */
import CountdownTimer from '../ui/CountdownTimer/CountdownTimer';
import RoqqDialogDivider from '../ui/RoqqDialogDivider/RoqqDialogDivider';
import RoqqDiamondTile from '../ui/RoqqDiamondTile/RoqqDiamondTile';

export default function InsufficientRubies() {
  return (
    <>
    <RoqqDiamondTile text="You do not have enough rubies to play on. Increase your transactions to earn more rubies." />
    <div style={{ height: '24px' }} />
    <RoqqDialogDivider />
    <div style={{ height: '24.5px' }} />
    <CountdownTimer label="Play next round in:" hours={6} mins={0} secs={0} />
    </>
  );
}
