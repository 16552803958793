/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useIcon } from '../../../../hooks/useIcon';
import NotificationItem from './NotificationItem';
import {
  fetchUserNotification,
  notificationStates,
  readAll,
} from '../../../../slices/notificationSlice';
import LoaderContainer from '../../../fragments/LoaderContainer';
import EmptyStateIllustration from '../../../../assets/notification-empty-state.svg';

export default function NotificationWrapper({ onClose, openNotificationSelectModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, unread, status } = useSelector((state) => state.notification);

  const { close } = useIcon();
  const getUserNotifications = () => {
    dispatch(fetchUserNotification());
  };

  const markAllAsRead = () => {
    dispatch(readAll());
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  return (
    <>
      {status === notificationStates.LOADING ? (
        <div className="notification-dropdown loading-state default-drop-arrow default-background border-this card-bg">
          {/* <div className="dialog-title">
            <p className="title">{t('dsh.notification.notification')}</p>
            <figure onClick={onClose}>
              <img src={close} alt="" />
            </figure>
          </div> */}
          <div className="notification-loader">
            <div className="notification-item">
              <figure className="icon">
                <LoaderContainer width={28} height={28}>
                  <rect
                    x="0"
                    y="0"
                    rx="22"
                    ry="22"
                    width="100%"
                    style={{ width: '100%' }}
                    height="28"
                  />
                </LoaderContainer>
              </figure>
              <div className="content">
                <div className="name">
                  <LoaderContainer
                    height={12}
                    width={520}
                    style={{ backgroundColor: 'red', width: '100%' }}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="8"
                      ry="8"
                      width="100%"
                    // style={{ width: '100%' }}
                      height="100%"
                    />
                  </LoaderContainer>
                </div>
                <div className="body secondary-white-black-color">
                  <LoaderContainer width={520} height={12}>
                    <rect
                      x="0"
                      y="0"
                      rx="8"
                      ry="8"
                      width="100%"
                      style={{ width: '100%' }}
                      height="100%"
                    />
                  </LoaderContainer>
                </div>
              </div>
            </div>
            <div className="notification-item">
              <figure className="icon">
                <LoaderContainer width={28} height={28}>
                  <rect
                    x="0"
                    y="0"
                    rx="22"
                    ry="22"
                    width="100%"
                    style={{ width: '100%' }}
                    height="28"
                  />
                </LoaderContainer>
              </figure>
              <div className="content">
                <div className="name">
                  <LoaderContainer
                    height={12}
                    width={520}
                    style={{ backgroundColor: 'red', width: '100%' }}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="8"
                      ry="8"
                      width="100%"
                    // style={{ width: '100%' }}
                      height="100%"
                    />
                  </LoaderContainer>
                </div>
                <div className="body secondary-white-black-color">
                  <LoaderContainer width={520} height={12}>
                    <rect
                      x="0"
                      y="0"
                      rx="8"
                      ry="8"
                      width="100%"
                      style={{ width: '100%' }}
                      height="100%"
                    />
                  </LoaderContainer>
                </div>
              </div>
            </div>
            <div className="notification-item">
              <figure className="icon">
                <LoaderContainer width={28} height={28}>
                  <rect
                    x="0"
                    y="0"
                    rx="22"
                    ry="22"
                    width="100%"
                    style={{ width: '100%' }}
                    height="28"
                  />
                </LoaderContainer>
              </figure>
              <div className="content">
                <div className="name">
                  <LoaderContainer
                    height={12}
                    width={520}
                    style={{ backgroundColor: 'red', width: '100%' }}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="8"
                      ry="8"
                      width="100%"
                    // style={{ width: '100%' }}
                      height="100%"
                    />
                  </LoaderContainer>
                </div>
                <div className="body secondary-white-black-color">
                  <LoaderContainer width={520} height={12}>
                    <rect
                      x="0"
                      y="0"
                      rx="8"
                      ry="8"
                      width="100%"
                      style={{ width: '100%' }}
                      height="100%"
                    />
                  </LoaderContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="notification-dropdown loading-state default-drop-arrow default-background border-this card-bg">
            {/* <div className="dialog-title">
            <p className="title">{t('dsh.notification.notification')}</p>
            <figure onClick={onClose}>
              <img src={close} alt="" />
            </figure>
          </div> */}
            {data && data.length > 0 ? (
              <>
                <div className="notification-header">
                  <div className="unread">
                    <p className="secondary-white-black-color">
                      {t('dsh.notification.unread')}
                    </p>
                    <p className="badge">{unread}</p>
                  </div>

                  <button
                    className="toggle-blue-color mark-as-read"
                    onClick={markAllAsRead}
                    type="button"
                  >
                    {t('dsh.notification.markAll')}
                  </button>
                </div>

                {data?.map((item) => (
                  <NotificationItem
                    key={uuid()}
                    item={item}
                    selectItem={() => openNotificationSelectModal(item)}
                  />
                ))}

              </>
            ) : (
              <div className="empty-state-container">
                <figure className="illustration-figure img-box">
                  <img src={EmptyStateIllustration} alt="" className="img" />
                </figure>
                <div className="empty-state-text">You have no transactions yet</div>
              </div>
            )}

          </div>
        </>
      )}
    </>
  );
}
