/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import toastTypes from '../utils/toast-types';
import { capitalizeFirst } from '../utils/simpleActions';

const initialState = {
  isOpen: false,
  type: toastTypes.error,
  heading: 'Error',
  description: 'This action can not be performed',
};

const toastSlice = createSlice({
  name: 'toastV2',
  initialState,
  reducers: {
    showToast(state, { payload }) {
      state.type = payload.type;
      state.heading = payload.heading ?? payload.name ?? '';
      state.description = capitalizeFirst(payload.description ?? payload.desc ?? 'something went wrong');
      state.isOpen = true;
    },
    closeToast(state) {
      state.isOpen = false;
      state.heading = null;
      state.description = null;
      state.type = null;
    },
  },
});

export default toastSlice.reducer;
export const { showToast, closeToast } = toastSlice.actions;
export const selectToastV2 = (state) => state.toastV2;
