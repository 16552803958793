/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useIcon } from '../../../../hooks/useIcon';

function InputErrorMessage({ errorMessage, hasLink, cb = () => { } }) {
  const { errorExclamationIcon } = useIcon();
  return (
    <>
      <figure className="img-box error-img-box">
        <img src={errorExclamationIcon} className="img" alt="" />
      </figure>
      <div className="error-message error">
        {errorMessage}
      </div>
      {hasLink
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          && <span onClick={cb} className="error-message link">Upgrade your limit.</span>}
    </>
  );
}

export default InputErrorMessage;
