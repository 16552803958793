/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import WalletService from '../../../../../services/walletService';
import {
  formatNumber,
  getCurrency,
  capitalizeFirst,
} from '../../../../../utils/simpleActions';
import transactionTypes from '../../TransactionTypes';
import flowType from '../flowType';
import * as toastType from '../../../../../utils/toast-types';
import DrawerConfirmation from '../../../../../components/fragments/Drawer/Sections/CompletionSections/DrawerConfirmation';
import { setToggleToast } from '../../../../../slices/authSlice';

const ConfirmProcess = React.forwardRef(
  (
    {
      data: confirmData,
      dataName,
      currency,
      amount,
      selectedOption,
      type,
      identifier,
      setCanProceed,
      title = 'withdraw',
      TransferDetails,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [pin, setPin] = useState('');
    const pinForm = useRef(null);
    const pinRef = useRef(null);

    function resetPin() {
      setPin('');
      pinForm.current.reset();
    }

    async function withdrawSubmit() {
      // only p2p is here because bank process is shorter and is initiated in `OptionProcess`
      const { data } = await WalletService.initiateP2PWithdraw({
        provider: confirmData?.provider,
        route: type,
        amount,
        currency,
        pin,
        recipient: identifier,
        sender: confirmData?.phone_number,
      });

      if (data.status !== 'fail') {
        resetPin();
        return {
          name: dataName,
          status: flowType.FINISH,
          successArray: [
            {
              name: t('dsh.wallet.amountWithdrawn'),
              value: `${getCurrency(currency).s}${formatNumber(amount)}`,
            },
            {
              name: t('dsh.wallet.to'),
              value: selectedOption?.app,
            },
            {
              name: t('dsh.username'),
              value: confirmData?.fullname,
            },
          ],
          data: {
            userIdentifier: identifier,
            amount,
            currency,
            provider: confirmData?.provider,
            providerName: selectedOption?.app,
            fullname: confirmData?.fullname,
            data: data.data,
          },
        };
      }

      dispatch(
        setToggleToast({
          type: toastType.WARNING,
          name: 'Confirmation Error',
          desc: capitalizeFirst(data.message),
        })
      );

      return false;
    }

    async function transferSubmit() {
      const { data } = await WalletService.transferFiat({
        currency,
        amount,
        identifier: TransferDetails.username,
        narration: TransferDetails.narration,
        ...pin
      });

      if (data.status !== 'fail') {
        resetPin();
        return {
          name: dataName,
          status: flowType.FINISH,
          successArray: [
            {
              name: t('dsh.wallet.amountTransfered'),
              value: `${getCurrency(currency).s}${formatNumber(amount)}`,
            },
            {
              name: t('dsh.wallet.to'),
              value: `${TransferDetails.fname} ${TransferDetails.lname}`,
            },
            {
              name: t('dsh.wallet.remark'),
              value: TransferDetails.narration,
            },
          ],
        };
      }

      dispatch(
        setToggleToast({
          type: toastType.WARNING,
          name: 'Confirmation Error',
          desc: capitalizeFirst(data.message),
        })
      );

      return false;
    }

    React.useImperativeHandle(ref, () => ({
      async submit() {
        switch (title) {
          case 'withdraw':
            return withdrawSubmit();

          case 'transfer':
            return transferSubmit();

          default:
            return false;
        }
      },

      // async submit() {
      //
      // },
    }));

    useEffect(() => {
      setCanProceed(
        pin
        && (
          (pin?.pin && pin?.pin.length === 6)
          || (pin?.two_f_code && pin?.two_f_code.length === 6)
        ),
      );
    }, [pin]);

    let amountTitle = '';
    let items = [];
    if (title === transactionTypes.WITHDRAW) {
      amountTitle = t('dsh.wallet.withdrawAmount');
      items = [
        {
          name: t('dsh.wallet.to'),
          value: selectedOption?.app
        },
        {
          name: t('dsh.wallet.customerUsername'),
          value: confirmData?.fullname
        }
      ];
    } else if (title === transactionTypes.TRANSFER) {
      amountTitle = t('dsh.wallet.transferAmount');
      items = [
        {
          name: t('dsh.wallet.to'),
          value: `${TransferDetails.fname} ${TransferDetails.lname}`
        },
        {
          name: t('dsh.wallet.remark'),
          value: TransferDetails.narration
        }
      ];
    }
    return (
      <DrawerConfirmation
        amount={`${getCurrency(currency)?.s}${formatNumber(amount)}`}
        amountTitle={amountTitle}
        items={items}
        setPin={setPin}
        pinForm={pinForm}
        pinRef={pinRef}
      />
    );
  }
);

export default ConfirmProcess;
