const routeNames = {
  base: '/',
  dashboard: '/dashboard',
  dashboardGetStarted: 'get-started',
  dashboardHome: 'home',
  dashboardSettings: 'settings',
  dashboardTransactions: 'transactions',
  dashboardWallet: 'get-started',
  getStarted: '/get-started',
  register: '/register',
  signup: '/signup',
  notFound: '/not-found',
  wildCard: '*',
  regBankDetails: 'reg-bank-details',
  regMomoOperator: 'reg-momo-operator',
  regMomoDetails: 'reg-momo-details',
};

export const getDashboardSubroute = (subrouteName) => `${routeNames.dashboard}-${subrouteName}`;

export default routeNames;
