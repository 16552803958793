export const ERROR = 'error';
export const PENDING = 'pending';
export const SUCCESS = 'success';
export const WARNING = 'warning';

const toastTypes = {
  error: ERROR,
  pending: PENDING,
  success: SUCCESS,
  warning: WARNING,
};

export default toastTypes;
