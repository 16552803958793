/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React from 'react';

const DashboardSavingIndex = React.lazy(() => import('../../../../pages/dashboard/home/savings'));
const SavingsFormPage = React.lazy(() => import('../../../../pages/dashboard/home/savings/SavingsFormPage'));
const SavingsSelect = React.lazy(() => import('../../../../pages/dashboard/home/savings/SavingsSelect'));
// const SavingsWelcome = React.lazy(() => import('../../../../pages/dashboard/home/savings/SavingsWelcome/SavingsWelcome'));

export const Dashboard_home_savings_route_group = [
  // { path: '/dashboard-home-savings-welcome', component: SavingsWelcome },
  { path: '/dashboard-home-savings', component: <DashboardSavingIndex /> },
  { path: '/dashboard-home-savings-select', component: <SavingsSelect /> },
  {
    path: '/dashboard-home-savings-form-page/:savingType',
    component: <SavingsFormPage />,
  },
];
