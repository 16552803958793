/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { fetchUser, selectAuth } from '../../slices/authSlice';
import useThunkEffectCalls from '../../hooks/useThunkEffectCalls';
import useUser from '../../hooks/useUser';
import LoadingPage from '../fragments/LoadingPage';
import {
  fetchAllCurrencyRates, fetchPrices, selectAllRates, selectPrices,
} from '../../slices/globalSlice';
import { fetchUserBalance, selectUserBalance } from '../../slices/UserSlice';

/**
 * If any component can only be accessed by authenticated users, wrap it in this component.
 * If an unauthorized user tries to access this component, they will be redirected to the
 * login page
 */
function AuthGuard() {
  const location = useLocation();

  const { token, user } = useUser();
  const dispatch = useDispatch();
  const [canLoad, setCanLoad] = useState(true);
  const {
    loading, error,
    user_account_discard,
    user_account_blocked,
  } = useSelector(selectAuth);
  const {
    currencies,
  } = useSelector(selectAllRates);
  const { data: userBalance } = useSelector(selectUserBalance);
  const { data: cryptoPrices } = useSelector(selectPrices);

  function getAllCurrencyRates() {
    if (!currencies || currencies.length === 0) {
      dispatch(fetchAllCurrencyRates()); // check if it exists first
    }
  }

  function getUserBalances() {
    if (!userBalance) {
      dispatch(fetchUserBalance()); // check if it exists first
    }
  }

  function getPrices() {
    if (!cryptoPrices) {
      dispatch(fetchPrices());
    }
  }

  function getUserInfo() {
    // if (!user) {
    dispatch(fetchUser());
    // }
  }

  useEffect(() => {
    if (!loading && error) {
      setCanLoad(false);
    }

    return () => {
      setCanLoad(true);
    };
  }, [loading, error]);

  useThunkEffectCalls(() => {
    getUserInfo();
    getAllCurrencyRates();
    getUserBalances();
    getPrices();
  });

  return (user_account_discard || user_account_blocked) ? (
    // <Redirect to="/banned" />
    <Navigate to="/banned" replace />
  )
    : !user && token && canLoad ? (
      <LoadingPage />
    ) : token && canLoad ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    // <Redirect to={`/login?returnUrl=${encodeURIComponent(location.pathname)}`} />
    );
}

export default AuthGuard;
