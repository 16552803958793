/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import ActionContext from '../../context/ActionContext';
import { useIcon } from '../../hooks/useIcon';
import {
  GlobalStyles,
} from '../../utils/themes/theme';
import useThemeMode from '../../hooks/useThemeMode';

function ErrorPageWrapper({ children }) {
  const actionCtx = useContext(ActionContext);
  const themeMode = useThemeMode(actionCtx?.theme);

  const { logoImg } = useIcon();

  const darkThemeStyles = {
    backgroundColor: '#1C2127',
    color: '#fff',
  };

  const lightThemeStyles = {
    backgroundColor: 'rgb(248, 248, 249)',
    color: 'rgb(28, 33, 39)',
  };
  return (
    <ThemeProvider theme={themeMode.mode}>
      <Helmet>
        <meta name="theme-color" content={themeMode.color} />
      </Helmet>
      <GlobalStyles />
      <div
        style={actionCtx?.theme === 'light'
          ? lightThemeStyles
          : darkThemeStyles}
        className="error-404-500-wrap"
      >
        <div className="">
          <figure
            className={`img-box ${
              actionCtx?.isSidebarReduce && 'img-box-reduced'
            }`}
            style={{ color: 'red' }}
          >
            <img src={logoImg} alt="" className="img" />
          </figure>
        </div>
        {children}
      </div>
    </ThemeProvider>
  );
}

export default ErrorPageWrapper;
