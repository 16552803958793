/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import KYCService from '../services/KYCService';

export const fetchStates = {
  BASE: 'base',
  FETCHING: 'fetching',
  ERROR: 'error',
  FETCHED: 'fetched',
  POPULATING: 'populating',
  POPULATED: 'populated',
};

const initialState = {
  countries: {
    data: null,
    status: fetchStates.BASE,
    error: null,
  },
  country_link: {
    data: null,
    status: fetchStates.BASE,
    error: null,
  },
};

export const fetchKYCCountries = createAsyncThunk(
  'kyc/fetchKYCCountries',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await KYCService.getKYCCountries();
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const fetchSelectedCountryData = createAsyncThunk(
  'kyc/fetchSelectedCountryData',
  async ({ country, level }, { rejectWithValue }) => {
    try {
      const { data } = await KYCService.getKYCSelectedLink({ country, level });
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    resetCountriesStatus: (state) => {
      state.countries.status = initialState.countries.status;
    },
    resetCountryDataStatus: (state) => {
      state.country_link.status = initialState.country_link.status;
    },
  },
  extraReducers: (builder) => {
    // fetchKYCCountries reducers
    builder.addCase(fetchKYCCountries.pending, (state) => {
      state.countries.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchKYCCountries.fulfilled, (state, action) => {
      const { data, status, message } = action.payload;
      if (status === 'fail') {
        state.countries.status = fetchStates.ERROR;
        state.countries.error = message;
      } else {
        state.countries.data = data;
        state.countries.status = fetchStates.FETCHED;
      }
    });
    builder.addCase(fetchKYCCountries.rejected, (state, action) => {
      state.countries.status = fetchStates.ERROR;
      state.countries.error = action.payload;
    });

    // fetchSelectedCountryData reducers
    builder.addCase(fetchSelectedCountryData.pending, (state) => {
      state.country_link.status = fetchStates.FETCHING;
    });
    builder.addCase(fetchSelectedCountryData.fulfilled, (state, action) => {
      const { data, status, message } = action.payload;
      if (status === 'fail') {
        state.country_link.status = fetchStates.ERROR;
        state.country_link.error = message;
      } else {
        state.country_link.data = data;
        state.country_link.status = fetchStates.FETCHED;
      }
    });
    builder.addCase(fetchSelectedCountryData.rejected, (state, action) => {
      state.country_link.status = fetchStates.ERROR;
      state.country_link.error = action.payload;
    });
  },
});

export const selectKYCCountries = (state) => state.kyc.countries;
export const selectKYCCountryLink = (state) => state.kyc.country_link;

export default kycSlice.reducer;
export const {
  resetCountriesStatus,
  resetCountryDataStatus,
} = kycSlice.actions;
